import React from 'react'
import 'react-table-6/react-table.css'
import { PayoutLedgerComponent } from './payoutLedgerComponent';

export class PayoutLedgerV2 extends React.Component<any> {

    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <div className=" mb-5 pb-5" >
                <h2 className="main-title m-0 p-3">Payout Ledger</h2>
                <hr className="m-0" />
                <div className="ml-3 mr-3">
                    <PayoutLedgerComponent props={this.props} isLedger={true}/>
                </div>
            </div>
        )
    }
}