import { FETCH_NETWORK_STATUS } from "../actions/types";

export default function(state = { connected: true }, action) {
  switch (action.type) {
    case FETCH_NETWORK_STATUS:
      return action.payload || {};
    default:
      return state;
  }
}
