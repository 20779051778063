import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { API, CDN_URL } from "../../APIAndConfig";
import { Modal, Spinner, Card } from "react-bootstrap";
import SimpleDropZone from "../../v2Sessions/simpleDropzone";
import { FaTrashAlt } from "react-icons/fa";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import "../../scss/create_session.scss"
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleLine, RiCloseCircleFill } from "react-icons/ri";
import { BiImageAdd } from "react-icons/bi";
import SessionImages from "../cards/SessionImages";
import ReactTable from "react-table";
import ImageUploadProgressBar from "./ImageUploadProgressbar";
import WindowedSelect from 'react-windowed-select';
import { createFilter } from 'react-select';

export class CreateSession extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sessionDetails: {
                author: this.props.auth.id,
                cost: 0,
                defaultAudioURL: null,
                duration: 15,
                name: "",
                category: "1",
                type: "single"
            },
            users: [],
            audios: [],
            loader: true,
            completedCount: [],
            messageModal: {
                state: false,
                header: '',
                message: ''
            },
            playUrl: "",
            addAudio: false,
            selectedAudioId: {
                id: false
            },
            addImages: false,
            images: [],
            images_0: {
                type: "",
                url: ""
            },
            images_1: {
                type: "",
                url: ""
            },
            steps: {
                percent: 0,
                current: 1
            },
            session: {},
            session_images: [],
            isProcessing: false,
            selectedDeleteImages: [],
            sessions: [],
            selectedChildSessions: []
        }
    }

    async componentDidMount() {
        this.getAllUsers()
        this.getAllAudios()
        this.getAllSession()
    }

    async getAllUsers() {
        await API.getUsers()
            .then(res => {
                if (res.successful && res.payload) {
                    this.setState({
                        users: res.payload.data,
                        loader: false
                    })
                } else {
                    this.setState({
                        loader: false
                    })
                    console.log("Error in fetching users : ", res);
                }
            })
    }

    async getAllSession() {
        await API.getAllSessions().then(
            res => {
                if (res.successful) {
                    this.setState({
                        sessions: res.payload.data
                    })
                } else {
                    console.log("Error in fetching sessions : ", res)
                }
            }
        )
    }

    async getAllAudios() {
        await API.getAudioList()
            .then(res => {
                if (res.successful && res.payload) {
                    this.setState({
                        audios: res.payload.data
                    })
                    if (this.state.selectedAudioId.id) {
                        let selectedTune = res.payload.data.filter(obj => obj.id === this.state.selectedAudioId.id)
                        this.setState({
                            playUrl: CDN_URL + '/b' + selectedTune[0].audio_url,
                            selectedAudioId: selectedTune[0]
                        })
                    }
                }
                else {
                    this.setState({
                        loader: false
                    })
                    console.log("Error in fetching audios : ", res);
                }
            })
    }

    renderAuthorOptions() {
        return (
            <WindowedSelect
                name="sessions"
                filterOption={createFilter({ ignoreAccents: false })}
                onChange={(e) => {
                    this.setState({
                        sessionDetails: {
                            ...this.state.sessionDetails,
                            author: e.label
                        }
                    })
                }}
                defaultValue={{
                    value: this.state.users.find(obj => obj.userId === this.props.auth.id), label: this.props.auth.id
                }}
                options={
                    this.state.users.map((obj) => {
                        return (
                            { value: obj, label: obj.userId }
                        )
                    })
                }
                styles={{
                    option: (base) => ({
                        ...base,
                        backgroundColor: 'transparent',
                        color: 'black',
                    }),
                }}

            />
        )
    }

    renderAudioOptions() {
        return (
            <select
                name="audio"
                id="audio"
                className="form-control"
                style={{ width: "100%" }}
                onChange={(e) => {
                    let audioObj = this.state.audios[Number(e.target.value)]
                    this.setState({
                        playUrl: CDN_URL + '/b' + audioObj.audio_url,
                        selectedAudioId: audioObj
                    })
                }}
            >
                <option selected disabled>Select</option>
                {this.state.audios.map((item, index) => {
                    return (
                        <option key={index} value={index} selected={Number(this.state.selectedAudioId.id) === Number(item.id)}>
                            {item.file_name}
                        </option>
                    );
                })}
            </select>
        )
    }

    renderMessageModal() {
        return (
            <Modal show={this.state.messageModal.state} >
                <Modal.Header> {this.state.messageModal.header}</Modal.Header>
                <Modal.Body>
                    {this.state.messageModal.message}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            this.setState({
                                messageModal: {
                                    header: '',
                                    message: '',
                                    state: false
                                }
                            })
                        }}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    handleChange(event) {
        this.setState({
            sessionDetails: {
                ...this.state.sessionDetails,
                [event.target.name]: event.target.value
            }
        })
    }

    async saveSessionDetails() {
        const { sessionDetails } = this.state
        await API.createSession(sessionDetails).then(
            res => {
                this.setState({ isProcessing: false })
                if (res.successful) {
                    this.setState({
                        steps: {
                            percent: Number(this.state.steps.percent) + 33.5,
                            current: this.state.steps.current + 1
                        },
                        session: res.payload
                    })
                } else {
                    console.log("Error in creating session ", res);
                    this.setState({
                        messageModal: {
                            state: true,
                            header: 'Error',
                            message: 'Failed to create session please reload and try again'
                        }
                    })
                }
            }
        )
    }

    async saveThumbnailCover() {
        const { images_0, images_1 } = this.state
        await API.updateSession(
            this.state.session.id,
            {
                [images_0.type]: [images_0.url],
                [images_1.type]: [images_1.url]
            }).then((res) => {
                this.setState({ isProcessing: false })
                if (res.successful) {
                    this.setState({
                        steps: {
                            percent: Number(this.state.steps.percent) + 33.5,
                            current: this.state.steps.current + 1
                        }
                    })
                }
                else {
                    this.setState({
                        messageModal: {
                            state: true,
                            header: 'Error',
                            message: 'Failed to update session thumbnail please try again'
                        }
                    })
                }
            })
    }

    async saveAudio() {
        let payload = {
            sessionId: this.state.session.id,
            audioUrl: this.state.selectedAudioId.audio_url
        }

        await API.updateAudio(payload).then(
            res => {
                this.setState({ isProcessing: false })
                if (res.successful) {
                    this.setState({
                        steps: {
                            percent: Number(this.state.steps.percent) + 33.5,
                            current: this.state.steps.current + 1
                        }
                    })
                }
                else {
                    this.setState({
                        messageModal: {
                            state: true,
                            header: 'Error',
                            message: 'Failed to update session audio please try again'
                        }
                    })
                }
            }
        )

    }

    async updateChildSessions() {
        let payload = {
            childSessions: this.state.selectedChildSessions.toString(),
            sessionId: this.state.session.id
        }
        await API.assignChildSessions(payload).then(
            res => {
                if (res.successful) {
                    this.resetState()
                } else {
                    this.setState({
                        messageModal: {
                            state: true,
                            header: 'Error',
                            message: 'Failed to update session audio please try again'
                        }
                    })
                }
            }
        )
    }

    async handleCreateClick() {
        const { steps, images, selectedAudioId } = this.state
        this.setState({ isProcessing: true })
        if (steps.current === 1) {
            await this.saveSessionDetails()
        }
        else if (steps.current === 2) {
            if (images.length > 0) {
                await this.saveThumbnailCover()
            } else {
                this.setState({
                    steps: {
                        percent: Number(this.state.steps.percent) + 33.5,
                        current: this.state.steps.current + 1
                    },
                    isProcessing: false
                })
            }
        }
        else if (steps.current === 3) {
            if (!this.state.selectedAudioId.id) {
                this.setState({
                    steps: {
                        percent: Number(this.state.steps.percent) + 33.5,
                        current: this.state.steps.current + 1
                    },
                    isProcessing: false
                })
            } else {
                this.saveAudio()
            }
        }
        else {
            if (this.state.sessionDetails.type === 'single') {
                this.onSubmitSessionImageUpload(this.state.session_images)
            } else {
                this.updateChildSessions()
            }
        }
    }

    resetState() {
        // this.setState({
        //     messageModal: {
        //         state: true,
        //         header: "Success",
        //         message: "The session is created successfully you can enjoy the session now"
        //     },
        //     sessionDetails: {
        //         author: this.props.auth.id,
        //         cost: 0,
        //         defaultAudioURL: null,
        //         duration: 15,
        //         name: "",
        //         type: "single"
        //     },
        //     completedCount: [],
        //     playUrl: "",
        //     addAudio: false,
        //     selectedAudioId: {
        //         id: false
        //     },
        //     addImages: false,
        //     images: [],
        //     images_0: {
        //         type: "",
        //         url: ""
        //     },
        //     images_1: {
        //         type: "",
        //         url: ""
        //     },
        //     steps: {
        //         percent: 0,
        //         current: 1
        //     },
        //     session: {},
        //     session_images: [],
        //     isProcessing: false,
        //     selectedDeleteImages: [],
        //     selectedChildSessions: []
        // })

        this.props.history.push({pathname : "/session/curator", state: {
            message : "create_session", 
            session_id : this.state.session.id
        }})

    }

    disableCreateButton() {
        const {
            sessionDetails,
            images,
            images_0,
            images_1,
            steps
        } = this.state

        if (this.state.isProcessing) {
            return true
        }

        if (steps.current === 1) {
            if (sessionDetails.author === "" || sessionDetails.name === "" || sessionDetails.type === "") {
                return true
            }
        }
        if (steps.current === 2) {
            if (images.length > 0) {
                if (
                    (images.length === 1 && images_0.type === "") ||
                    (images.length === 2 && (images_0.type === "" || images_1.type === "")) ||
                    (images_0.type === images_1.type)
                ) {
                    return true
                }
            }
        }
        if (steps.current === 3) {
            return false
        }
        if (steps.current === 4) {
            if (this.state.sessionDetails.type === 'single') {
                if (this.state.session_images.length < 10) {
                    return true
                }
            } else {
                if (this.state.selectedChildSessions.length < 2) {
                    return true
                }
            }
        }
        return false
    }

    successCount(count) {
        let data = [...this.state.completedCount]
        data.push(count)
        this.setState({
            completedCount: data
        })
    }

    async onSubmitUploadAudioModal(files) {
        const uploadResponce = await API.uploadAudioFiles(
            files,
            this.successCount.bind(this)
        )

        let resData = uploadResponce[0] && uploadResponce[0].payload ? uploadResponce[0].payload.data : false
        this.setState({
            completedCount: [],
            addAudio: false,
            selectedAudioId: resData ? resData : { id: false }
        }, () => {
            this.getAllAudios()
        })
    }

    async onSubmitUploadImagesModal(files) {
        if (files && files.length > 0) {
            await API.uploadFiles(
                files,
                null,
                this.successCount.bind(this),
                'img',
                'thumbnail',
                this.state.session.id
            ).then(async res => {
                const userUploads = await API.getMedia({
                    purpose: "thumbnail",
                    variant: "original",
                    session: this.state.session.id
                });
                let data = userUploads.payload.data
                this.setState({
                    images: data,
                    addImages: false,
                    completedCount: [],
                    images_0: {
                        type: "",
                        url: ""
                    },
                    images_1: {
                        type: "",
                        url: ""
                    }
                })
            })
        }
    }

    onFileChange(e) {
        let data = [...this.state.session_images]
        this.setState({
            session_images: data.concat(Object.values(e.target.files))
        })
    }

    async onSubmitSessionImageUpload(files) {
        if (files && files.length > 0) {
            await API.uploadFiles(
                files,
                null,
                this.successCount.bind(this),
                'img',
                'session',
                this.state.session.id
            )
            this.resetState()
        }
    }

    async removeByAttr(arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {

                arr.splice(i, 1);

            }
        }
        return arr;
    }

    async deleteImage(item, multipleDeleteState) {
        let payload = {
            idString: item
        }
        await API.deleteMultipleMedia(payload).then(
            async res => {
                if (res.successful) {
                    let data = [...this.state.images]
                    let filteredImages = []
                    if (!multipleDeleteState.status) {
                        filteredImages = await this.removeByAttr(data, 'id', multipleDeleteState.itemId)
                        if (Number(multipleDeleteState.index) === 0) {
                            let image1Data = { ...this.state.images_1 }
                            this.setState({
                                images_0: image1Data,
                                images_1: {
                                    type: "",
                                    url: ""
                                }
                            })
                        } else {
                            this.setState({
                                [multipleDeleteState.stateName]: {
                                    type: "",
                                    url: ""
                                }
                            })
                        }
                        this.setState({
                            images: filteredImages
                        })
                    }
                    else {
                    }
                } else {
                    this.setState({
                        messageModal: {
                            state: true,
                            header: 'Failed',
                            message: 'Failed to delete image'
                        }
                    })
                }
            }
        )
    }

    renderThumbnailImages() {
        return this.state.images.map((item, index) => {
            return (
                <div className="d-flex m-2 w-100">
                    <img
                        key={index}
                        style={{ maxHeight: "220px", maxWidth: "300px", border: "5px white solid" }}
                        src={process.env.REACT_APP_CDN + '/' + item.object.url}
                    />
                    <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="d-flex align-items-center my-1">
                            <input style={{ width: '16px', height: '16px' }} type="radio" id={`cover_${index}`} name={`images_${index}`} value="coverphoto"
                                checked={this.state[`images_${index}`].type === 'coverphoto'}
                                onChange={(e) => {
                                    this.setState({
                                        [e.target.name]: {
                                            type: e.target.value,
                                            url: item.object.url
                                        }
                                    })
                                }} />
                            <label className="ml-2 my-0 font-weight-bolder" for={`cover_${index}`}>Cover Photo</label>
                        </div>
                        <div className="d-flex align-items-center my-1">
                            <input style={{ width: '16px', height: '16px' }} type="radio" id={`thumbnail_${index}`} name={`images_${index}`} value="thumbnail"
                                checked={this.state[`images_${index}`].type === 'thumbnail'}
                                onChange={(e) => {
                                    this.setState({
                                        [e.target.name]: {
                                            type: e.target.value,
                                            url: item.object.url
                                        }
                                    })
                                }} />
                            <label className="ml-2 my-0 font-weight-bolder" for={`thumbnail_${index}`}>Thumbnail Photo</label>
                        </div>
                        <div className="mx-auto my-2">
                            <button className="btn x-secondary text-white d-flex align-items-center"
                                onClick={() => {
                                    this.deleteImage(item.id, {
                                        status: false,
                                        itemId: item.id,
                                        stateName: `images_${index}`,
                                        index: index
                                    })
                                }}>
                                <FaTrashAlt size={15} color="white" /><span className="ml-2">Delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderStepProgressBar() {
        return (
            <div className="m-5" >
                <ProgressBar
                    percent={this.state.steps.percent}
                    filledBackground="linear-gradient(315deg, #e056fd 0%, #000000 74%)"
                    unfilledBackground="#ebedf0"
                >
                    <Step>
                        {({ accomplished, index }) => (
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            >
                                {index + 1}
                            </div>
                        )}
                    </Step>
                    <Step>
                        {({ accomplished, index }) => (
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            >
                                {index + 1}
                            </div>
                        )}
                    </Step>
                    <Step>
                        {({ accomplished, index }) => (
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            >
                                {index + 1}
                            </div>
                        )}
                    </Step>
                    <Step>
                        {({ accomplished, index }) => (
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                            >
                                {index + 1}
                            </div>
                        )}
                    </Step>
                </ProgressBar>
            </div>
        )
    }

    onCheck = (id, event) => {
        let data = this.state.selectedDeleteImages ? [...this.state.selectedDeleteImages] : []
        if (event.target.checked) {
            data.push(Number(id))
            this.setState({
                selectedDeleteImages: data
            })
        }
        else {
            const index = data.indexOf(Number(id));
            if (index > -1) {
                data.splice(index, 1);
                if (data.length === 0) {
                    this.setState({
                        selectedDeleteImages: []
                    })
                } else {
                    this.setState({
                        selectedDeleteImages: data
                    })
                }
            }
        }
    }

    getButtonTitle() {
        switch (this.state.steps.current) {
            case 1:
                return 'Add Session Details'
            case 2:
                if (this.state.images.length > 0) {
                    return 'Set thumbnail and cover photo'
                } else {
                    return 'Skip'
                }
            case 3:
                if (!this.state.selectedAudioId.id) {
                    return 'Skip'
                } else {
                    return 'Set default audio'
                }
            case 4:
                if (this.state.sessionDetails.type === 'single') {
                    return 'Create Session'
                } else {
                    return 'Create SuperMix'
                }
            default:
                break;
        }
    }

    onDeleteSelected(deleteArr) {
        let removeValFromIndex = deleteArr, imageArr = [...this.state.session_images]
        for (var i = 0; i < removeValFromIndex.length; i++) {
            imageArr.splice(removeValFromIndex[i], 1)
        }
        this.setState({
            session_images: imageArr,
            selectedDeleteImages: []
        })
    }

    render() {
        return (
            this.state.loader ?
                <div className={"text-center mt-4"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div > :
                <>
                    <div className="row">
                        <div className='col-6 my-4'>
                            <h2 className='main-title'>
                                Create Session
                            </h2>
                        </div>
                    </div>
                    {this.renderStepProgressBar()}
                    {
                        this.state.steps.current === 1 ? this.renderDetailsForm() :
                            this.state.steps.current === 2 ? this.renderThumbnailForm() :
                                this.state.steps.current === 3 ? this.renderAudioForm() :
                                    this.state.sessionDetails.type === 'single' ? this.renderImagesForm() : this.renderChildSessions()
                    }
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-4 m-4">
                            <button
                                className="btn x-secondary text-white w-100"
                                disabled={this.disableCreateButton()}
                                onClick={this.handleCreateClick.bind(this)}
                            >{
                                    this.state.isProcessing ?
                                        <Spinner animation="border" size="sm" className="p-2" /> :
                                        <span className="p-2">{this.getButtonTitle()}</span>
                                }</button>
                        </div>
                    </div>
                    {this.renderMessageModal()}
                    <Modal show={this.state.addAudio} onHide={() => { this.setState({ addAudio: true }) }}>
                        <Modal.Header>
                            <div className='w-100'>
                                <label>Upload Audio</label>
                                <button
                                    className="x-secondary"
                                    style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                    onClick={() => { this.setState({ addAudio: false, completedCount: [] }) }}
                                >X</button>
                            </div>
                        </Modal.Header>
                        <SimpleDropZone
                            onUpload={this.onSubmitUploadAudioModal.bind(this)}
                            completedCount={this.state.completedCount.length}
                            acceptTypes="audio/mpeg, audio/mp3"
                            message="Click Here To Select Audio From Your Device"
                            inputType="Audio"
                            maxFiles={1}
                        />
                    </Modal>
                    <Modal show={this.state.addImages} onHide={() => { this.setState({ addImages: true }) }}>
                        <Modal.Header>
                            <div className='w-100'>
                                <label>Upload Images</label>
                                <button
                                    className="x-secondary"
                                    style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                    onClick={() => { this.setState({ addImages: false, completedCount: [] }) }}
                                >X</button>
                            </div>
                        </Modal.Header>
                        <SimpleDropZone
                            onUpload={this.onSubmitUploadImagesModal.bind(this)}
                            completedCount={this.state.completedCount.length}
                            acceptTypes={"image/jpg, image/jpeg, image/png, .heic"}
                            message={"Please select thumbnail and cover photo"}
                            inputType="Images"
                            maxFiles={2 - this.state.images.length}
                        />
                    </Modal>
                </>

        )
    }

    renderDetailsForm() {
        return (
            <div className="row d-flex justify-content-center">
                <div className="col-6">
                    <div className="setting-card card h-100">
                        <div className="card-header">
                            <p className="setting-card-header mb-0 p-2">
                                Create Session
                            </p>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="form-group">
                                    <label>Author</label>
                                    {this.renderAuthorOptions()}
                                </div>
                            </form>
                            <form>
                                <div className="form-group">
                                    <label>Session Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.sessionDetails.name}
                                        onChange={this.handleChange.bind(this)}
                                        placeholder="Session Name"
                                    />
                                </div>
                            </form>
                            <form>
                                <div className="form-group">
                                    <label>Duration</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="duration"
                                        value={this.state.sessionDetails.duration}
                                        disabled={true}
                                        placeholder="Session Duration"
                                    />
                                </div>
                            </form>
                            <form>
                                <div className="form-group">
                                    <label>Cost</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="cost"
                                        value={this.state.sessionDetails.cost}
                                        disabled={true}
                                        placeholder="Cost"
                                    />
                                </div>
                            </form>
                            <form>
                                <div className="form-group">
                                    <label>Category</label>
                                    <select name="category" id="category" className="form-control" onChange={this.handleChange.bind(this)}>
                                        <option value={1}>Happiness</option>
                                        <option value={2}>Reflection</option>
                                        <option value={3}>Healing</option>
                                        <option value={4}>Success</option>
                                        <option value={5}>Spirituality</option>
                                        <option value={6}>Money</option>
                                        <option value={7}>Wellness</option>
                                        <option value={8}>Leading</option>
                                        <option value={9}>Relationships</option>
                                        <option value={10}>Kids</option>
                                    </select>
                                </div>
                            </form>
                            <form>
                                <div className="form-group">
                                    <label>Type</label>
                                    <select name="type" id="type" className="form-control" onChange={this.handleChange.bind(this)}>
                                        <option value="single">Single</option>
                                        <option value="mega">Mega</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderThumbnailForm() {
        return (
            <div className="row d-flex justify-content-center">
                <div className="col-6">
                    <div className="setting-card card h-100">
                        <div className="card-header">
                            <p className="setting-card-header mb-0 p-2">
                                Thumbnail And Cover Photo
                            </p>
                        </div>
                        <div className="card-body">
                            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center" >
                                {
                                    this.state.images.length > 0 ?
                                        this.renderThumbnailImages()
                                        :
                                        <p style={{ fontSize: "18px" }} className="mt-5">Click the button to upload images for session thumbnail and cover photo</p>
                                }
                                {
                                    this.state.images.length < 2 ?
                                        <button className='x-btn btn x-secondary mb-5'
                                            onClick={() => { this.setState({ addImages: true }) }}>
                                            Upload New Images
                                        </button>
                                        :
                                        <>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAudioForm() {
        return (
            <div className="row d-flex justify-content-center mt-4">
                <div className="col-12">
                    <div className="setting-card card h-100">
                        <div className="card-header">
                            <p className="setting-card-header mb-0 p-2">
                                Add Music
                            </p>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-center align-items-center">
                            {this.renderAudioOptions()}
                            <div className="mt-4 w-100">
                                <audio src={this.state.playUrl} className="w-100" controls />
                            </div>
                            <button className='x-btn btn x-secondary m-4' onClick={() => {
                                this.setState({ addAudio: true })
                            }}>
                                Upload New Audio
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderImagesForm() {
        return (
            <div className="row">
                <div className='col-12 my-4'>
                    <div className="card card-blur">
                        <div className="card-header d-flex align-items-center row">
                            <div className="col-md-auto col-12">
                                Upload Images
                            </div>
                            <div className="ml-auto col-md-auto col-12">
                                <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                    disabled={!this.state.selectedDeleteImages.length}
                                    onClick={async () => {
                                        if (this.state.session_images.length === this.state.selectedDeleteImages.length) {
                                            this.setState({
                                                selectedDeleteImages: [],
                                                session_images: []
                                            })
                                        } else {
                                            var removeValFromIndex = [...this.state.selectedDeleteImages].sort((a, b) => { return b - a });
                                            this.setState({
                                                selectedDeleteImages: removeValFromIndex
                                            }, () => {
                                                this.onDeleteSelected(removeValFromIndex)
                                            })
                                        }
                                    }}
                                >
                                    <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                        <FaTrashAlt size={22} color="white" />
                                    </span></button>

                                {
                                    this.state.session_images.length > 0 ?
                                        <>
                                            {
                                                this.state.selectedDeleteImages && this.state.selectedDeleteImages.length === this.state.session_images.length ?
                                                    <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedDeleteImages: []
                                                            })
                                                        }}
                                                    >
                                                        <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                            <RiCheckboxMultipleBlankLine size={24} color="white" />
                                                        </span>
                                                    </button>
                                                    :
                                                    <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                                        onClick={() => {
                                                            let data = []
                                                            for (let i = 0; i < this.state.session_images.length; i++) {
                                                                data.push(i)
                                                            }
                                                            this.setState({
                                                                selectedDeleteImages: data
                                                            })
                                                        }}
                                                    >
                                                        <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                            <RiCheckboxMultipleLine size={24} color="white" />
                                                        </span>
                                                    </button>
                                            }
                                        </> : <></>
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                this.state.isProcessing ?
                                    <ImageUploadProgressBar
                                        done={this.state.session_images.length > 0 ? ((Number(this.state.completedCount.length) / this.state.session_images.length) * 100) : 0}
                                    />
                                    :
                                    <div className="d-flex justify-content-center align-items-center">
                                        <label className="create-session-select-file p-4 text-center mx-5" for="default-file"
                                            style={{ fontSize: "22px", fontFamily: "inherit", color: "white", fontWeight: 'bolder' }} >Click here to upload Images</label>
                                        <input
                                            id="default-file"
                                            className="create-session-default-file"
                                            onChange={this.onFileChange.bind(this)}
                                            type="file"
                                            accept="image/jpg, image/gif, image/bmp, image/jpeg, image/tiff, image/png, .heic"
                                            multiple
                                        />
                                    </div>
                            }
                            <div className="mt-5">
                                {
                                    this.state.session_images.length > 0 ?
                                        <>
                                            <ReactTable
                                                filterable
                                                data={this.state.session_images}
                                                columns={[
                                                    {
                                                        id: "check",
                                                        Header: 'Check',
                                                        Cell: d => {
                                                            return (
                                                                <input type="checkbox" style={{ width: "20px", height: "20px" }}
                                                                    checked={this.state.selectedDeleteImages.includes(d.index)}
                                                                    onClick={(e) => {
                                                                        this.onCheck(d.index, e)
                                                                    }} />
                                                            )
                                                        },
                                                        maxWidth: 100,
                                                        headerClassName: 'p-3 text-light',
                                                        className: 'text-center'
                                                    },
                                                    {
                                                        id: 'file_name',
                                                        Header: 'File Name',
                                                        accessor: 'name',
                                                        headerClassName: 'p-3 text-light',
                                                        className: 'text-center'
                                                    },
                                                    {
                                                        id: 'size',
                                                        Header: 'Size',
                                                        accessor: (d) => {
                                                            return d.size / 1000 + "kb"
                                                        },
                                                        headerClassName: 'p-3 text-light',
                                                        className: 'text-center'
                                                    },
                                                    {
                                                        id: 'action',
                                                        Header: 'Action',
                                                        Cell: d => {
                                                            return (
                                                                <div onClick={() => {
                                                                    let data = [...this.state.session_images]
                                                                    data.splice(d.index, 1)
                                                                    this.setState({
                                                                        session_images: data
                                                                    })
                                                                }}
                                                                >
                                                                    <RiCloseCircleFill color="white" size={18} />
                                                                </div>
                                                            )
                                                        },
                                                        headerClassName: 'p-3 text-light',
                                                        className: 'text-center'
                                                    }
                                                ]}
                                                defaultPageSize={10}
                                                noDataText='No Data Found'
                                                style={{ background: "rgb(111 79 136 / 1)" }}
                                                getTrProps={(state, rowInfo, column, instance) => {
                                                    if (typeof rowInfo !== "undefined") {
                                                        return {
                                                            style: {
                                                                background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                                                color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                                            },
                                                        }
                                                    }
                                                    else {
                                                        return {
                                                            style: {
                                                                background: 'transparent',
                                                                color: 'white'
                                                            },
                                                        }
                                                    }
                                                }}
                                            />
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderChildSessions() {
        return (
            < div >
                <div className="font-weight-bolder text-center m-4" style={{ fontSize: "20px" }}>
                    Please select the sessions you wish to be part of the SuperMix
                </div>
                <Card className="card-blur">
                    <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Sessions</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            filterable
                            data={this.state.sessions}
                            columns={[
                                {
                                    id: "check",
                                    Header: 'Check',
                                    accessor: (d) => {
                                        return (
                                            <input type="checkbox" style={{ width: "20px", height: "20px" }}
                                                id={d.id} name={d.id} value={d.id} checked={this.state.selectedChildSessions.includes(d.id)} onClick={(e) => {
                                                    let data = [...this.state.selectedChildSessions]
                                                    if (data.includes(d.id)) {
                                                        data.splice(data.indexOf(d.id), 1)
                                                    } else {
                                                        data.push(d.id)
                                                    }
                                                    this.setState({
                                                        selectedChildSessions: data
                                                    })
                                                }} />
                                        )
                                    },
                                    maxWidth: 100,
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'session_id',
                                    Header: 'Session Id',
                                    accessor: 'id',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'session_name',
                                    Header: 'Session Name',
                                    accessor: 'name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'category',
                                    Header: 'Category',
                                    accessor: 'category',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'author',
                                    Header: 'Author',
                                    accessor: (d) => {
                                        return (
                                            <p>{d.author.fullName}</p>
                                        )
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                }
                            ]}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            style={{ background: "rgb(111 79 136 / 1)" }}
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </ div>
        )
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}


export default connect(mapStateToProps, actions)(CreateSession)