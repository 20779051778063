import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Firebase } from '../../firebase';
// import { Link, withRouter } from 'react-router-dom';
// import { reduxForm } from 'redux-form';
import * as actions from '../../actions';
// import map from 'lodash/map';
import _ from 'lodash';
import '../../scss/shop.css';
import PPTable2 from '../common/PPTable2';
import { makePlayerImageUrl, makeThumbImageUrl } from "../../APIAndConfig";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns = [
   {
      Header: () => <div className="text-left" />,
      Cell: row => {
         //console.log(row);
         let pathSrc = makeThumbImageUrl(row.original.product.icon_image_url);
         return (
            <div>
               <img height={75} width={75} src={pathSrc} />
            </div>
         );
      },
      accessor: 'product.icon_image_url',
      width: 85,
      filterable: false,
      sortable: false
   },
   {
      Header: () => <div className="text-left">Product</div>,
      accessor: 'product.title'
   },
   {
      Header: () => <div className="text-left">Author</div>,
      accessor: 'product.author_full_name'
   },
   {
      Header: () => <div className="text-left">Description</div>,
      accessor: 'product.short_description'
   },
   {
      Header: () => <div className="text-left">Cost (Credits)</div>,
      accessor: 'product.cost',
      filterable: false
   }
];


class Shop extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null
      };
   }

   componentDidMount() {
      this.props.resetError();
      Firebase.Auth.onAuthStateChanged(async authUser => {
         if (authUser) {
            await this.props.fetchProducts();
            await this.props.fetchNewReleaseProducts();
            await this.props.fetchFeaturedProducts();

            await this.props.fetchProducts();

         } else {
            this.props.history.push('/login');
         }
      });
   }

   componentWillUnmount() {
      this.props.resetError();
   }

   onOpenNav(e) {
      document.getElementById('sidebar').classList.add('sidebarOpen');
   }

   onSearchClick(e) {
      e.preventDefault();

      let search = document.getElementById('productSearchBar');
      let option = document.getElementById('productSearchOption');

      this.props.history.push(
         `/shopSearchResults?query=${search.value}&option=${option.value}`
      );
   }

   onProductClick(rowInfo) {
      //onsole.log('Passed in function!!!', rowInfo);
      const { original } = rowInfo;
      //console.log(original);
      //this.props.setSelectedSession(parseInt(original.id), original.name);

      this.props.history.push(`/productDetail/${original.product.id}`);
   }

   renderPlaceHolder() {
      return (
         <div className="carousel-inner">
            <div className="carousel-item active">
               <img
                  className="imageContainer grayscale"
                  src="./assets/images/Kristine_Carlson.png"
               />
               <div className="overlay overlayBlue" />
               <div className="imageCaption mx-5 px-5">
                  <p className="imageCaptionAuthor text-white">
                     Kristine Carlson
                  </p>
                  <p className="imageCaptionTitle text-white m-0">
                     Living the Big stuff
                  </p>
                  <p className="imageCaptionDesc text-white">
                     If you are missing peace and joy due to heartbreak,
                     adversity or other major life event, uplift yourself with
                     this self-nurturing session by Kristine Carlson of Don't
                     Sweat the Small Stuff.
                  </p>
                  <button className="btn btn-outline-light">Get it now</button>
               </div>
            </div>
            <div className="carousel-item">
               <img
                  className="imageContainer grayscale"
                  src="./assets/images/Kristine_Carlson.png"
               />
               <div className="overlay overlayGreen" />
               <div className="imageCaption mx-5 px-5">
                  <p className="imageCaptionAuthor text-white">
                     Kristine Carlson
                  </p>
                  <p className="imageCaptionTitle text-white m-0">
                     Living the Big stuff
                  </p>
                  <p className="imageCaptionDesc text-white">
                     If you are missing peace and joy due to heartbreak,
                     adversity or other major life event, uplift yourself with
                     this self-nurturing session by Kristine Carlson of Don't
                     Sweat the Small Stuff.
                  </p>
                  <button className="btn btn-outline-light">Get it now</button>
               </div>
            </div>
            <div className="carousel-item">
               <img
                  className="imageContainer grayscale"
                  src="./assets/images/Kristine_Carlson.png"
               />
               <div className="overlay overlayOrange" />
               <div className="imageCaption mx-5 px-5">
                  <p className="imageCaptionAuthor text-white">
                     Kristine Carlson
                  </p>
                  <p className="imageCaptionTitle text-white m-0">
                     Living the Big stuff
                  </p>
                  <p className="imageCaptionDesc text-white">
                     If you are missing peace and joy due to heartbreak,
                     adversity or other major life event, uplift yourself with
                     this self-nurturing session by Kristine Carlson of Don't
                     Sweat the Small Stuff.
                  </p>
                  <button className="btn btn-outline-light">Get it now</button>
               </div>
            </div>
         </div>
      );
   }

   renderCarouselItems() {
      if (!this.props.productsFeatured) {
         return;
      }

      return _.map(this.props.productsFeatured, (item, index) => {
         let classList = 'carousel-item';

         if (index === 0) {
            classList += ' active';
         }

         let authorName = '';

         if (item.product.author_first_name || item.product.author_last_name) {
            authorName =
               item.product.author_first_name +
               ' ' +
               item.product.author_last_name;
         }

         let featuredImg = makePlayerImageUrl(item.product.featured_bg_image_url);
         let overlayClassList = 'overlay overlayBlue';
         if (index === 0) {
         } else if (index % 2 === 0) {
            overlayClassList = 'overlay overlayOrange';
         } else {
            overlayClassList = 'overlay overlayGreen';
         }

         return (
            <div key={item.product.id} className={classList}>
               <img className="imageContainer grayscale" src={featuredImg} />
               <div className={overlayClassList} />
               <div className="imageCaption mx-5 px-0 px-sm-5">
                  <p className="imageCaptionAuthor text-white">{authorName}</p>
                  <p className="imageCaptionTitle text-white m-0">
                     {item.product.title}
                  </p>
                  <p className="imageCaptionDesc text-white">
                     {item.product.short_description}
                  </p>
                  <button
                     className="btn btn-outline-light"
                     onClick={() => {
                        this.props.history.push(
                           `/productDetail/${item.product.id}`
                        );
                     }}
                  >
                     Details
                  </button>
               </div>
            </div>
         );
      });
   }

   renderNewItems() {
      if (!this.props.productsNew) {
         return;
      }

      return _.map(this.props.productsNew, (item, index) => {
         let authorName = '';

         if (item.product.author_first_name || item.product.author_last_name) {
            authorName =
               item.product.author_first_name +
               ' ' +
               item.product.author_last_name;
         }

         let featuredImg = makePlayerImageUrl(item.product.featured_bg_image_url);
         return (
            <div key={item.product.id} className="col-sm-6">
               <div className="card cardMarginBottom">
                  <img
                     className="card-img-top ppCard"
                     alt="..."
                     src={featuredImg}
                  />
                  <div className="card-body">
                     <h5 className="card-title">{item.product.title}</h5>
                     <p className="card-text text-secondary pp-block-with-text-shop">
                        {item.product.short_description}
                     </p>
                     <button
                        className="btn btn-primary"
                        onClick={() => {
                           this.props.history.push(
                              `/productDetail/${item.product.id}`
                           );
                        }}
                     >
                        Details
                     </button>

                     {/* NOTE: For some reason this doesn't cause the component to mount
                     <Link to={`/productDetail/${item.product.id}`} className="btn btn-primary">
                        Go to session
                     </Link> */}
                  </div>
               </div>
            </div>
         );
      });
   }

   render() {
      return (
         <div className="mainWidth">
            <div className="d-flex">
               <button
                  className="btn bg-transparent d-md-none"
                  onClick={this.onOpenNav.bind(this)}
               >
                  <FontAwesomeIcon icon={faBars} />
               </button>
               <p className="text-secondary m-0 px-0 py-3 px-md-3">Shop Sessions</p>
            </div>
            <hr className="m-0" />
            <div className="px-4 pt-5 px-lg-5">
               <div className="input-group">
                  <div className="input-group-prepend">
                     <select
                        className="custom-select searchAll bg-transparent"
                        id="productSearchOption"
                     >
                        <option defaultValue>All</option>
                        {/* <option>Category</option> */}
                     </select>
                  </div>
                  <input
                     type="text"
                     id="productSearchBar"
                     className="form-control"
                     placeholder="Search"
                     aria-label="Text input with dropdown button"
                  />
                  <div className="input-group-append">
                     <button
                        id="searchButton"
                        className="btn btn-primary"
                        type="button"
                        onClick={this.onSearchClick.bind(this)}
                     >
                        Search
                     </button>
                  </div>
               </div>
            </div>
            <div className="px-4 pt-5 px-lg-5">
               <p className="h4 m-0">Featured</p>
               <p className="text-secondary">Our favorite sessions</p>
            </div>
            <div className="px-0 px-sm-4 pb-5 px-lg-5">
               <div
                  id="carouselShop"
                  className="carousel slide"
                  data-ride="carousel"
               >
                  <div className="carousel-inner">
                     {this.renderCarouselItems()}
                  </div>
                  {/* {this.renderPlaceHolder()} */}
                  <a
                     className="carousel-control-prev"
                     href="#carouselShop"
                     role="button"
                     data-slide="prev"
                  >
                     <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                     />
                     <span className="sr-only">Previous</span>
                  </a>
                  <a
                     className="carousel-control-next"
                     href="#carouselShop"
                     role="button"
                     data-slide="next"
                  >
                     <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                     />
                     <span className="sr-only">Next</span>
                  </a>
               </div>
            </div>
            <div className="px-4 pb-5 px-lg-5">
               <p className="h4 m-0">New Sessions</p>
               <p className="text-secondary">Our newest sessions</p>
               <div className="row">{this.renderNewItems()}</div>
            </div>

            <div className="px-4 pb-2 px-lg-5">
               <p className="h4 m-0">All Sessions</p>
               <p className="text-secondary">Our classic sessions</p>
            </div>
            <div className="cpx-4 pb-2 px-lg-5">
               <PPTable2
                  list={this.props.products}
                  columns={columns}
                  noDataText={'No Items Found'}
                  onSelectRow={this.onProductClick.bind(this)}
               />
            </div>
            <br />

         </div>
      );
   }
}

function mapStateToProps(state) {
   return {
      auth: state.auth,
      products: state.products,
      productsNew: state.productsNew,
      productsFeatured: state.productsFeatured
   };
}

export default connect(
   mapStateToProps,
   actions
)(Shop);
