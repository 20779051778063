import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux'
import * as actions from "../../actions";
import { withRouter } from "react-router-dom";
import { APIAxios, Config } from '../../APIAndConfig';
import { Firebase } from '../../firebase';
import axios from 'axios';
import clsx from 'clsx';
import WindowedSelect from 'react-windowed-select';
import { createFilter } from 'react-select';
import { Spinner } from 'react-bootstrap';
import { set } from 'lodash';

export const ManualFeaturedSessions = (props) => {

  const [featuredSessions, setFeaturedSessions] = useState([])
  const [firstSelect, setFirstSelect] = useState({ value: { id: 'e08ad7e4-a8c9-11eb-bcbc-0242ac130002' }, label: 'Select ..' })
  const [secondSelect, setSecondSelect] = useState({ value: { id: '3449d195-e703-432c-af41-0402f8782583' }, label: 'Select ..' })
  const [thirdSelect, setThirdSelect] = useState({ value: { id: '6f93a43e-2484-41c6-9930-bfefa39eab89' }, label: 'Select ..' })
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessingReset, setIsProcessingReset] = useState(false)

  const fetchFeaturedSession = async () => {
    const token = await Firebase.Auth.currentUser.getIdToken();

    const header = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    try {
      const res = await axios.get(`${Config.APIUrl}/featuredSessions?type=allFeaturedSessions`, {
        headers: header,
      });
      if (res.data.status === 200) {

        setFirstSelect(res.data.data.filter(obj => obj.promoted === 1)[0] ? {
          value: res.data.data.filter(obj => obj.promoted === 1)[0],
          label: res.data.data.filter(obj => obj.promoted === 1)[0].name
        } : { value: { id: 'e08ad7e4-a8c9-11eb-bcbc-0242ac130002' }, label: 'Select ..' })

        setSecondSelect(res.data.data.filter(obj => obj.promoted === 2)[0] ? {
          value: res.data.data.filter(obj => obj.promoted === 2)[0],
          label: res.data.data.filter(obj => obj.promoted === 2)[0].name
        } : { value: { id: '3449d195-e703-432c-af41-0402f8782583' }, label: 'Select ..' })

        setThirdSelect(res.data.data.filter(obj => obj.promoted === 3)[0] ? {
          value: res.data.data.filter(obj => obj.promoted === 3)[0],
          label: res.data.data.filter(obj => obj.promoted === 3)[0].name
        } : { value: { id: '6f93a43e-2484-41c6-9930-bfefa39eab89' }, label: 'Select ..' })

        setFeaturedSessions(res.data.data);
      }
    } catch (err) {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
            window.location.reload()
        }
          break;
        case "BAD_CONNECTION":
          case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem('service_unavailable_status', "failed");
          await Firebase.Auth.signOut();
          window.location.href = "/error"
          break;
        default:
          console.log("Error Occured While fetching category data ", err);
          break;
      }
    }
  };

  const setSessions = (e, count) => {
    if (count === "first") {
      setFirstSelect(e)
    } else if (count === "second") {
      setSecondSelect(e)
    } else {
      setThirdSelect(e)
    }

  }

  const renderSelectSession = (count) => {
    return (
      <div>
        <WindowedSelect
          name="sessions"
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={(e) => setSessions(e, count)}
          value={count === "first" ? firstSelect : count === "second" ? secondSelect : thirdSelect}
          options={
            featuredSessions?.filter((obj) => {
              if (obj.id === firstSelect.value.id || obj.id === secondSelect.value.id || obj.id === thirdSelect.value.id) {
                return false;
              }
              return true;
            }).map((obj) => { return { value: obj, label: obj.name } })
          }
          styles={{
            option: (base) => ({
              ...base,
              backgroundColor: 'transparent',
              color: 'black',
            }),
          }}
        />
      </div>
    );
  }

  const submitSessions = async () => {

    const payload = {}

    if (firstSelect && firstSelect.value.id !== "e08ad7e4-a8c9-11eb-bcbc-0242ac130002") payload["firstSession"] = firstSelect.value.id
    if (secondSelect && secondSelect.value.id !== "3449d195-e703-432c-af41-0402f8782583") payload["secondSession"] = secondSelect.value.id
    if (thirdSelect && thirdSelect.value.id !== "6f93a43e-2484-41c6-9930-bfefa39eab89") payload["thirdSession"] = thirdSelect.value.id

    setIsProcessing(true)
    const token = await Firebase.Auth.currentUser.getIdToken();

    const header = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    try {
      const res = await axios.post(`${Config.APIUrl}/setfeaturedSessions`, payload, {
        headers: header,
      });
      if (res.data.status === 200) {
        setIsProcessing(false)
        alert('Sessions updated successfully')
      }
    } catch (err) {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
            window.location.reload()
        }
          break;
        case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
          window.location.href = "/error"
          break;
        default:
          setIsProcessing(false)
          console.log("Error Occured updating data ", err);
          break;
      }
    }
  }

  const resetSessions = async () => {
    setIsProcessingReset(true)
    const token = await Firebase.Auth.currentUser.getIdToken();

    const header = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    try {
      const res = await axios.get(`${Config.APIUrl}/resetfeaturedSessions`, {
        headers: header,
      });
      if (res.data.status === 200) {
        setIsProcessingReset(false)
        alert('Reset successfull')
        setFirstSelect({ value: { id: 'e08ad7e4-a8c9-11eb-bcbc-0242ac130002' }, label: 'Select ..' })
        setSecondSelect({ value: { id: '3449d195-e703-432c-af41-0402f8782583' }, label: 'Select ..' })
        setThirdSelect({ value: { id: '6f93a43e-2484-41c6-9930-bfefa39eab89' }, label: 'Select ..' })

      }
    } catch (err) {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
            window.location.reload()
          }
          break;
        case "BAD_CONNECTION":
          case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
          window.location.href = "/error"
          break;
        default:
          setIsProcessingReset(false)
          console.log("Error Occured reseting data ", err);
          break;
      }
    }
  }

  useEffect(() => {
    fetchFeaturedSession()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={clsx('col-12 my-4')}>
          <h2 className={clsx('main-title')}>
            Manual Featured Sessions
          </h2>
        </div>
      </div>
      <div className="row ">
        <div className={clsx('col-12 my-4')}>
          <label >First Session</label>
          {renderSelectSession("first")}
        </div>
      </div>
      <div className="row ">
        <div className={clsx('col-12 my-4')}>
          <label >Second Session</label>
          {renderSelectSession("second")}
        </div>
      </div>
      <div className="row ">
        <div className={clsx('col-12 my-4')}>
          <label >Third Session</label>
          {renderSelectSession("third")}
        </div>
      </div>
      <div className="row">
        <div className="text-muted col-12 col-sm-12 col-md-3 mt-3 pl-0">
          <button className={clsx('btn btn-lg setting-button w-100')} onClick={() => resetSessions()} disabled={
            firstSelect.value.id === "e08ad7e4-a8c9-11eb-bcbc-0242ac130002" &&
            secondSelect.value.id === "3449d195-e703-432c-af41-0402f8782583" &&
            thirdSelect.value.id === "6f93a43e-2484-41c6-9930-bfefa39eab89"
          }> {isProcessingReset ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Reset Sessions</span>}</button>
        </div>
        <div className="col-0 col-md-6" ></div>
        <div className="text-muted col-12 col-sm-12 col-md-3 mt-3 pl-0">
          <button className={clsx('btn btn-lg setting-button w-100')} disabled={
            firstSelect.value.id === "e08ad7e4-a8c9-11eb-bcbc-0242ac130002" &&
            secondSelect.value.id === "3449d195-e703-432c-af41-0402f8782583" &&
            thirdSelect.value.id === "6f93a43e-2484-41c6-9930-bfefa39eab89"
          } onClick={() => submitSessions()}> {isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Submit Sessions</span>}</button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  console.log("State : ", state);
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, actions)(withRouter(ManualFeaturedSessions))
