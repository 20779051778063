// import external modules
import React from "react";
import { Route, Redirect } from "react-router-dom";

const InviteRoute = ({ requiredRoles, user, ...rest }) => {
    const userHasRequiredRole = requiredRoles.includes(user.role.id)
    const invite = user.invite_parent
    if (userHasRequiredRole && !invite) {
        return <Route {...rest} />
    } else {
        return <Redirect to="/404" />
    }
};

export default InviteRoute;
