import Axios from "axios";
import React, { Component } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { API, CDN_URL, Media_URL } from "../../APIAndConfig";
import SimpleDropZone from "../../v2Sessions/simpleDropzone";
import * as actions from "../../actions";
import { FiTrash2 } from "react-icons/fi";
import clsx from "clsx";

export class MusicCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            musicList: [],
            addAudio: false,
            completedCount: [],
            selectedMusic: {},
            isProcessing: false,
            messageModal: {
                state: false,
                header: '',
                message: ''
            },
            deleteModal: false
        }
    }

    async componentDidMount() {
        await this.getAudioList()
    }

    async getAudioList() {
        await API.getAudioList()
            .then(res => {
                if (res.successful && res.payload) {
                    this.setState({
                        musicList: res.payload.data
                    })
                }
            })
    }

    successCount(count) {
        let data = [...this.state.completedCount]
        data.push(count)
        this.setState({
            completedCount: data
        })
    }

    async onSubmitUploadAudioModal(files) {
        await API.uploadAudioFiles(
            files,
            this.successCount.bind(this)
        )

        this.setState({
            completedCount: [],
            addAudio: false
        })

        this.getAudioList()
    }

    async removeAudio() {
        let musicList = [...this.state.musicList]
        let sameFileCheck = musicList.filter(obj => obj.audio_url === this.state.selectedMusic.audio_url)
        if (sameFileCheck.length > 1) {
            let payload = {
                paths: [this.state.selectedMusic.audio_url.substring(1, this.state.selectedMusic.audio_url.length)],
                ids: this.state.selectedMusic.id,
                hash: this.state.selectedMusic.hash
            }
            await API.removeAudio(payload).then(
                res => {
                    if (res.successful) {
                        this.getAudioList()
                        this.setState({
                            messageModal: {
                                header: 'Success',
                                message: 'Audio file deleted successfully',
                                state: true
                            },
                            deleteModal: false
                        })
                    }
                    else {
                        this.setState({
                            messageModal: {
                                header: 'Failed',
                                message: 'Failed to delete audio file',
                                state: true
                            },
                            deleteModal: false
                        })
                    }
                }
            )
        } else {
            if (Number( this.state.selectedMusic.count ) > 0) {
                this.setState({
                    messageModal: {
                        header: 'Alert',
                        message: 'This audio file cannot be deleted, it is currently in use.',
                        state: true
                    },
                    deleteModal: false
                })
            } else {
                let payload = {
                    paths: [this.state.selectedMusic.audio_url.substring(1, this.state.selectedMusic.audio_url.length)],
                    ids: this.state.selectedMusic.id,
                    hash: this.state.selectedMusic.hash
                }
                await API.removeAudio(payload).then(
                    res => {
                        if (res.successful) {
                            this.getAudioList()
                            this.setState({
                                messageModal: {
                                    header: 'Success',
                                    message: 'Audio file deleted successfully',
                                    state: true
                                },
                                deleteModal: false
                            })
                        }
                        else {
                            this.setState({
                                messageModal: {
                                    header: 'Failed',
                                    message: 'Failed to delete audio file',
                                    state: true
                                },
                                deleteModal: false
                            })
                        }
                    }
                )
            }
        }
    }

    renderMessageModal() {
        return (
            <Modal show={this.state.messageModal.state} >
                <Modal.Header> {this.state.messageModal.header}</Modal.Header>
                <Modal.Body>
                    {this.state.messageModal.message}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            this.setState({
                                messageModal: {
                                    header: '',
                                    message: '',
                                    state: false
                                }
                            })
                        }}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] ?
                String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                false
        );
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className={clsx('col-12 my-4')}>
                        <h2 className={clsx('main-title')}>
                            Music Center
                        </h2>
                    </div>
                </div>
                <Row>
                    <Col>
                        <button className="x-btn btn x-secondary float-right mx-4 mb-4"
                            onClick={() => {
                                this.setState({ addAudio: true })
                            }}
                        >Add Audio</button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReactTable
                            filterable
                            data={this.state.musicList}
                            defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                            columns={[
                                {
                                    id: "name",
                                    Header: 'Name',
                                    accessor: 'file_name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'file_type',
                                    Header: 'File Type',
                                    accessor: 'file_type',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'file_size',
                                    Header: 'File Size',
                                    accessor: (d) => {
                                        return parseFloat((Number(d.file_size) / 1000000).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) + " mb" 
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'count',
                                    Header: 'Count',
                                    accessor: 'count',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'listen',
                                    Header: 'Listen',
                                    accessor: (d) => {
                                        return (
                                            <div className="d-flex justify-content-center align-items-center">
                                                <audio style={{ height : "30px" }} src={CDN_URL + '/b' + d.audio_url} type={d.mime} controls />
                                            </div>
                                        )
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'action',
                                    Header: 'Action',
                                    accessor: (d) => {
                                        return (
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button className="btn x-secondary d-flex align-items-center justify-content-center"
                                                    onClick={() => {
                                                        this.setState({
                                                            deleteModal : true,
                                                            selectedMusic : d
                                                        })
                                                    }}
                                                    >
                                                    <FiTrash2 size={14} color="white" />
                                                </button>
                                            </div>)
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                }
                            ]}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            style={{ background: "rgb(111 79 136 / 1)" }}
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Modal show={this.state.addAudio} onHide={() => { this.setState({ addAudio: true }) }}>
                    <Modal.Header>
                        <div className='w-100'>
                            <label>Upload Audio</label>
                            <button
                                className="x-secondary"
                                style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                onClick={() => { this.setState({ addAudio: false, completedCount: [] }) }}
                            >X</button>
                        </div>
                    </Modal.Header>
                    <SimpleDropZone
                        onUpload={this.onSubmitUploadAudioModal.bind(this)}
                        completedCount={this.state.completedCount.length}
                        acceptTypes="audio/mpeg, audio/mp3"
                        message="Click Here To Select Audio From Your Device"
                        inputType="Audio"
                        maxFiles={50}
                    />
                </Modal>
                <Modal show={this.state.deleteModal} onHide={() => { this.setState({ delete: true }) }}>
                    <Modal.Header>
                        <div className='w-100'>
                            <label>Delete Audio</label>
                            <button
                                className="x-secondary"
                                style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                onClick={() => { this.setState({ deleteModal: false, selectedMusic: {} }) }}
                            >X</button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete music ?
                    </Modal.Body>
                    <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            this.removeAudio()
                        }}
                    >
                        Delete
                    </button>
                    </Modal.Footer>
                </Modal>
                {this.renderMessageModal()}
            </>
        );
    }

}


export default MusicCenter