import React, { Component } from "react";
import { connect } from "react-redux";
import { Firebase } from "../../firebase";
// import fixOrientation from "fix-orientation";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import Pagination from "react-js-pagination";
import map from "lodash/map";
import _ from "lodash";

import "./sessions.css";
import Loader from "../common/Loader";
import CreateStatement from "./CreateStatement";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

function arrayRemove(array, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === value) {
      array.splice(i, 1);
    }
  }
}
class EditSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //authUser: null,
      session: null,
      personalImages: null,
      newName: null,
      selectedItems: [],
      selectedImages: [],
      retrievedImage: true,
      error: null,
      currentPage: 1,
      imagesPerPage: 50,
      activePage: 15,
    };

    this.handlePageNumberClick = this.handlePageNumberClick.bind(this);
    this.initImagesCheckbox = this.initImagesCheckbox.bind(this);
  }

  // handlePageNumberClick(event) {
  //   this.setState({
  //     currentPage: Number(event.target.id)
  //   });
  // }

  handlePageNumberClick(pageNumber) {
    this.setState({
      currentPage: Number(pageNumber)
    });
  }

  componentDidMount() {
    this.props.resetError();
    let session_id = parseInt(this.props.match.params._session_id);

    if (isNaN(session_id)) {
      this.props.history.push("/notFound");
      return;
    }

    if (this.props.auth && !this.state.session) {
      const lookup = async function () {
        let data = await this.props.fetchSessionDetailById(
          session_id
        );

        //console.log(data);
        this.setState({ session: data });
        this.state.session = data;
      }.bind(this);

      lookup(this);

    }
  }

  async componentDidUpdate() {

    let session_id = parseInt(this.props.match.params._session_id);

    if (isNaN(session_id)) {
      this.props.history.push("/notFound");
      return;
    }

    if (this.props.auth && !this.state.session) {
      let data = await this.props.fetchSessionDetailById(
        session_id
      );

      //console.log(data);
      this.setState({ session: data });
      this.state.session = data;
    }

    if (this.props.auth && !this.state.personalImages) {
      let imageData = await this.props.fetchUserUploadedImages();

      this.setState({ personalImages: imageData });
      this.initImagesCheckbox();
    }
  }

  componentWillUnmount() {
    //this.props.resetError();
    this.setState({
      //authUser: null,
      session: null,
      newName: null,
      selectedItems: [],
      error: null
    });
  }

  onOpenNav(e) {
    document.getElementById("sidebar").classList.add("sidebarOpen");
  }

  async onSelectAllClicked(e) {
    const { personalImages, currentPage, imagesPerPage } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    );

    map(currentImages, userImage => {
      let id = `image-input-${userImage.id}`;
      let elem = document.getElementById(id);

      if (!elem.checked) {
        elem.click();
      }
    });
  }

  async onDeselectAllClicked(e) {
    const { personalImages, currentPage, imagesPerPage } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    );

    map(currentImages, userImage => {
      let id = `image-input-${userImage.id}`;
      let elem = document.getElementById(id);

      if (elem.checked) {
        elem.click();
      }
    });
  }

  async onSaveClicked(e) {
    e.preventDefault();
    //console.log("Click save current state", this.state);
    let result = await this.props.editSessionDetails(
      this.props.auth.id,
      this.state.session.id,
      this.state.newName,
      this.state.selectedImages
    );

    //console.log(result);
    if (result.error) {
      alert("There was a problem with saving your change.");
    } else {
      alert("Your change was saved");
    }

    window.location.reload();
  }

  onChangeName(e) {
    e.preventDefault();
    //console.log('Changing name', e.target.value);
    this.setState({ newName: e.target.value });
  }

  /**
   * Begin User images logic
   */

  initImagesCheckbox() {
    const { session } = this.state;

    this.state.selectedImages = [];
    if (session && session.personal_images) {
      for (let i = 0; i < session.personal_images.length; ++i) {
        let image = session.personal_images[i];

        let id = `image-input-${image.id}`;
        let elem = document.getElementById(id);

        // if (elem) {
        //    elem.checked = true;
        // }

        this.state.selectedImages.push(parseInt(image.id));
      }
    }
  }

  async onSubmitUpload(e) {
    e.preventDefault();
    const modal = document.getElementById("uploadModalContainer");
    const modalLoader = document.getElementById("modalLoader");
    const imagePreview = document.getElementById("imagePreview");
    imagePreview.style.display = "none";
    modalLoader.style.display = "block";
    const inputElem = document.getElementById("sessionInputFile");

    //console.log('onSubmitUpload - uploading file object:', this.state.file);
    // TODO: move the modal, modalLoader, and imagePreview out of the submitImage
    if (this.state.files && this.state.files.length > 0) {
      let data = await this.props.submitImage(
        this.props.auth,
        this.state.files,
        modal,
        this,
        imagePreview,
        modalLoader,
        inputElem
      );

      this.setState({ personalImages: data });
    }
  }

  onOpenAddImage(e) {
    e.preventDefault();
    const modal = document.getElementById("uploadModalContainer");
    modal.style.display = "block";
  }

  onClose(e) {
    e.preventDefault();
    const modal = document.getElementById("uploadModalContainer");
    modal.style.display = "none";
    const inputElem = document.getElementById("sessionInputFile");
    inputElem.value = "";

    const imagePreview = document.getElementById("imagePreview");
    imagePreview.innerHTML = "";
  }

  onFileChange(e) {
    e.preventDefault();
    //this.setState({ file: event.target.files[0] });
    //console.log("onFileChange - event object:", e.target.files);

    let fileList = [];
    let readerList = [];
    for (let i = 0; i < e.target.files.length; ++i) {
      let reader = new FileReader();
      let file = e.target.files[i];

      reader.onloadend = () => {
        fileList.push(file);
        readerList.push(reader.result);
        //console.log('Setting states for file and imagePreviewUrl', this.state);
        this.setState({
          files: fileList,
          uploadImgPreviewList: readerList
        });
      };
      reader.readAsDataURL(file);
    }
    this.setState({
      files: fileList,
      uploadImgPreviewList: readerList
    });

    //console.log("onFileChange state", this.state);
  }

  async onTrashClick(e) {
    //console.log("Which click onTrash", e.target.nodeName);
    let value = parseInt(e.target.getAttribute("name"));
    if (e.target.nodeName === "path") {
      value = parseInt(e.target.parentElement.getAttribute("name"));
    }

    // Why is this returning NaN?
    //console.log("onTrashClick - event image index = ", value);
    if (isNaN(value)) {
      return;
    }

    // Send delete message
    let data = await this.props.deleteImage(value);
    this.setState({ personalImages: data });
  }

  onImageCheckboxClick(e) {
    const value = parseInt(e.target.name);
    //console.log("onImageCheckboxClick - event image index = ", value);
    //console.log("checkbox state - ", e.target.checked);

    if (!e.target.checked) {
      if (this.state.session && this.state.session.personal_images) {
        for (let i = 0; i < this.state.session.personal_images.length; ++i) {
          let image = this.state.session.personal_images[i];

          //console.log("imageId: ", image.id);

          if (image.id === value) {
            this.state.session.personal_images.splice(i, 1);
          }
        }
      }
      _.remove(this.state.selectedImages, n => {
        if (n === value) return n;
      });
    }

    if (e.target.checked) {
      this.state.selectedImages.push(value);
      this.setState({ error: null });
    }
    //console.log('Selected images - ', this.state.selectedImages);
  }

  onCreateStatement(e) {
    e.preventDefault();
    //alert('NOTE: Not implemented yet');
    const modal = document.getElementById("stmtModalContainer");
    modal.style.display = "block";
  }

  onCloseStatement(e) {
    e.preventDefault();
    const modal = document.getElementById("stmtModalContainer");
    modal.style.display = "none";
  }

  renderError() {
    if (this.state.error) {
      return (
        <p className="font-italic text-danger text-center font-weight-bold">
          {this.state.error}
        </p>
      );
    }

    if (this.props.error) {
      return (
        <p className="font-italic text-danger text-center font-weight-bold">
          {this.props.error}
        </p>
      );
    }
  }

  renderUserImages() {
    if (
      !this.props.auth ||
      this.props.auth === undefined ||
      !this.state.personalImages
    ) {
      // console.log("NO AUTH");  scvbn mfds
      return <div />;
    } else {
      //console.log("YES AUTH");
    }

    const { personalImages, currentPage, imagesPerPage } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages ? personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    ) : [];

    const { session } = this.state;

    return map(currentImages, userImage => {
      const fullpath =
        process.env.REACT_APP_CDN + '/' +
        userImage.object.url;

      let checkImage = false;
      if (session && session.personal_images) {
        for (let i = 0; i < session.personal_images.length; ++i) {
          let image = session.personal_images[i];

          if (image.id === userImage.id) {
            checkImage = true;
          }
        }
      }

      return (
        <div key={userImage.id} className="m-2 p-2 bg-white border shadow">
          <div className="pp-img-container">
            <div className="pp-action-container">
              <div
                className="pp-img-trash-icon"
                name={userImage.id}
                onClick={this.onTrashClick.bind(this)}
              >
                <FontAwesomeIcon icon={faTrashAlt} name={userImage.id} />
              </div>
              <input
                type="checkbox"
                name={userImage.id}
                id={`image-input-${userImage.id}`}
                className="selectImgCheckbox"
                onClick={this.onImageCheckboxClick.bind(this)}
                defaultChecked={checkImage}
              />
            </div>

            <img
              id={`image-${userImage.id}`}
              alt={userImage.name}
              src={fullpath}
              className="userImageCard"
            />
          </div>
        </div>
      );
    });
  }

  renderPageNumbers() {
    if (
      !this.props.auth ||
      this.props.auth === undefined ||
      !this.state.personalImages
    ) {
      return <div />;
    } else {

    }

    const { personalImages, currentPage, imagesPerPage } = this.state;
    // Logic for displaying page numbers
    const pageNumbers = [];
    const totalPageCount = personalImages.length;

    return (
      <div>
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          prevPageText='prev'
          nextPageText='next'
          firstPageText='first'
          lastPageText='last'
          activePage={currentPage}
          itemsCountPerPage={imagesPerPage}
          totalItemsCount={totalPageCount}
          pageRangeDisplayed={10}
          onChange={this.handlePageNumberClick}
        />
      </div>
    );
  }

  renderModal() {
    let { files, uploadImgPreviewList } = this.state;
    let $imagePreview = null;

    if (uploadImgPreviewList && uploadImgPreviewList.length > 0) {
      $imagePreview = _.map(uploadImgPreviewList, (value, index) => {
        let $img = (
          <div
            key={index}
            style={{
              width: "fit-content",
              display: "inline-block"
            }}
          >
            <img alt={files[index].name} src={value} />{" "}
          </div>
        );
        return $img;
      });
    }

    return (
      <div id="uploadModalContainer" className="modalContainer ">
        <div className="uploadModalContent pp-animate-bottom">
          <span className="close" onClick={this.onClose.bind(this)}>
            &times;
          </span>
          <p>Select images from your computer to upload.</p>
          <input
            id="sessionInputFile"
            onChange={this.onFileChange.bind(this)}
            type="file"
            name="imageUpload"
            accept="image/*"
            multiple
          />
          <div id="imagePreview" className="imgPreview">
            {$imagePreview}
          </div>
          <Loader />
          {this.renderError()}
          <button
            className="btn btn-primary"
            style={{ margin: "10px 0px" }}
            type="submit"
            onClick={this.onSubmitUpload.bind(this)}
          >
            Upload Now
          </button>
        </div>
      </div>
    );
  }

  renderCreateStatement() {
    return (
      <div id="stmtModalContainer" className="modalContainer">
        <div className="stmtModalContent pp-animate-bottom">
          <span className="close" onClick={this.onCloseStatement.bind(this)}>
            &times;
          </span>
          <CreateStatement auth={this.props.auth} />
        </div>
      </div>
    );
  }
  /**
   * End User images logic
   */

  render() {
    console.log("props", this.props.auth)
    if (!this.props.auth || this.state.session === null)
      return <div />;

    return (
      <div className="mainWidth">
        <div className="d-flex">
          <button
            className="btn bg-transparent d-md-none"
            onClick={this.onOpenNav.bind(this)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <p className="text-secondary m-0 px-0 py-3 px-md-3">Edit Session</p>
        </div>
        <hr className="m-0" />
        <div className="row m-0 px-0 pt-5 px-lg-4">
          <div className="col-12 px-4">
            <div className="bg-white p-4 shadow">
              <div className="flex-grow-1 bg-primary p-4">
                <p className="h4 text-white m-0">Edit Name</p>
              </div>
              <input
                className="form-control my-4"
                id="edit-session-title"
                name="title"
                label="TITLE"
                placeholder="Enter New Session Title"
                defaultValue={this.state.session.name}
                type="text"
                onChange={this.onChangeName.bind(this)}
                readOnly={
                  this.state.session.author === this.props.auth.id
                    ? false
                    : true
                }
              />
            </div>
          </div>

          <div className="col-12 px-4 mt-3">
            <div className="bg-white p-4 shadow">
              <div className="flex-grow-1 bg-primary p-4">
                <p className="h4 text-white m-0">Edit Personal Images</p>
              </div>
              <div className="py-4">
                <button
                  className="btn btn-primary mr-2"
                  onClick={this.onSelectAllClicked.bind(this)}
                >
                  Select All
                </button>
                <button
                  className="btn btn-success mr-2"
                  onClick={this.onDeselectAllClicked.bind(this)}
                >
                  Deselect All
                </button>
                <div className="userImageContainer d-flex flex-wrap bg-light p-2 border">
                  {this.renderUserImages()}
                </div>

                <div className="my-4 p-4 d-flex justify-content-center bg-light">
                  {this.renderPageNumbers()}
                </div>

                <div className="d-block d-sm-flex align-items-center bg-light mb-2 p-4">
                  <p className="text-secondary m-sm-0">
                    Add an image from your computer!
                  </p>
                  <button
                    className="btn btn-primary ml-auto"
                    // style={{ margin: '15px', height: '40px' }}
                    type="submit"
                    onClick={this.onOpenAddImage.bind(this)}
                  >
                    Add New Image
                  </button>
                </div>

                {/* <div className="d-block d-sm-flex align-items-center bg-light mb-3 p-4">
                  <p className="text-secondary m-sm-0">
                    Add text to one of our image templates!
                  </p>
                  <button
                    className="btn btn-primary ml-auto"
                    // style={{ margin: '20px', height: '40px' }}
                    type="submit"
                    onClick={this.onCreateStatement.bind(this)}
                  >
                    Create a Statement
                  </button>
                </div> */}

                {this.renderModal()}
                {this.renderCreateStatement()}
                {this.renderError()}
              </div>
            </div>
          </div>

          <div className="col-12 px-4 mt-3 mb-5">
            <div className="bg-white p-4 shadow">
              <button
                className="btn btn-primary mr-2"
                onClick={() => {
                  this.props.history.push("/dashboard");
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success mr-2"
                onClick={this.onSaveClicked.bind(this)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    categories: state.categories,
    userUploadedImages: state.userUploadedImages,
    error: state.error
  };
}

export default connect(mapStateToProps, actions)(withRouter(EditSession));
