import _ from "lodash";
import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import { MODE_DEFAULT } from "../../actions/types";
import { Firebase } from "../../firebase";
import "../../scss/Dashboard.css";
import WelcomeModal from "./WelcomeModal";
import { makeAssetsUrl, makePlayerImageUrl } from "../../APIAndConfig";
import { faBars, faMapMarkerAlt, faUsers, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown, faPlayCircle } from "@fortawesome/free-regular-svg-icons";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSessionId: -1,
      selectedSessionTitle: "",
      totalSessions: 0,
      totalClients: 0,
      runtimeMinute: 15,
      loaded: false,
      token: null,
      displayWelcome: true,
      theGraphData: {},
      theComputedGraphData: [],
      renderSessionGraph: "",
      renderedGraph: false,
      renderDonutGraph: "",
      renderLineGraph: "",
      sdate: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      toMonthValue: "",
      allSessions: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.renderDonutGraph = this.renderDonutGraph.bind(this);
    this.renderGraph = this.renderGraph.bind(this);
    this.analyticYMSHandleChange = this.analyticYMSHandleChange.bind(this);
  }

  async componentDidMount() {
    const { state } = this.props.location;

    // const UserId = await this.props.auth.id;
    // const status = await this.props.auth.orientation_status
    // const orientationState = { userId : UserId, status : status }

    // if(this.props.location.state != undefined){
    //     console.log("In If in dashboard")
    //   }
    //   else{
    //     status !== null ? console.log("User has known the orientation page") : this.props.history.push({pathname : "/orientations", state: orientationState})
    //   }

    if (state && state.previousRoute && state.previousRoute === "signup") {
      const modal = document.getElementById("thankYouModalBtn");
      if (modal) {
        modal.click();
      } else {
        this.state.displayWelcome = false;
      }
    }

    this.props.resetError();
    this.props.setMode(MODE_DEFAULT);
    Firebase.Auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // fetch user sessions
        const token = await authUser.getIdToken();
        //this.setState({ token });
        if (token) {
          await this.props.fetchUser(token);
        }

        if (this.props.auth) {
          this.state.loaded = true;
          await this.props.fetchUserSession(this.props.auth);

          await this.props.fetchUserClients(this.props.auth);

          this.setState({
            totalClients: this.props.userClients
              ? this.props.userClients.length || 0
              : 0,
          });

          await this.props.fetchRecentlyWatchSession(this.props.auth, 3);

          if (this.props.recentlyWatchSession && this.props.recentlyWatchSession.length > 0) {
            this.props.setSelectedSession(
              parseInt(this.props.recentlyWatchSession[0].id),
              this.props.recentlyWatchSession[0].name
            );

            this.setState({
              selectedSessionId: this.props.recentlyWatchSession[0].id,
              selectedSessionTitle: this.props.recentlyWatchSession[0].name,
            });
          }

          await this.props.fetchOwnerSessionForUser(this.props.auth);

          await this.props.fetchPurchasesByUserId(this.props.auth.id);

          //Analytics work around
          switch (this.props.auth.role.id) {
            case 1:
              await this.props.fetchUserSession(this.props.auth, "admin");
              this.setState({
                allSessions: this.props.userSessions,
              });
              break;
            default:
              break;
          }
          await this.props.fetchUserOwnersSessionHistoryYears(this.props.auth);

          await this.props.fetchUserOwnersSessionHistory(this.props.auth);
        }

        if (this.props.userSessions && this.props.userSessions.length > 0) {
          if (
            this.props.recentlyWatchSession &&
            this.props.recentlyWatchSession.length <= 0
          ) {
            this.props.setSelectedSession(
              parseInt(this.props.userSessions[0].id),
              this.props.userSessions[0].name
            );
            this.setState({
              selectedSessionId: this.props.userSessions[0].id,
              selectedSessionTitle: this.props.userSessions[0].name,
            });
          }

          let total = 0;
          if (
            this.props.purchasedSessions &&
            this.props.purchasedSessions.length > 0
          ) {
            total =
              this.props.purchasedSessions.length +
              this.props.userSessions.length;
          }

          this.setState({ totalSessions: total });
        }

        if (this.props.auth) {
          await this.props.fetchFeaturedProducts(3);
        }
      } else {
        this.props.history.push("/login");
      }
    });
  }

  async componentDidUpdate() {

    if (this.state.loaded) {
      return;
    }

    const { state } = this.props.location;
    if (
      state &&
      state.previousRoute &&
      state.previousRoute === "signup" &&
      !this.state.displayWelcome
    ) {
      const modal = document.getElementById("thankYouModalBtn");
      if (modal) {
        modal.click();
        this.state.displayWelcome = true;
      } else {
        this.state.displayWelcome = false;
      }
    }

    if (this.props.auth && !this.props.productsFeatured) {
      await this.props.fetchFeaturedProducts(3);
    }

    if (this.props.auth && !this.props.userSessions) {
      if (!this.props.userClients) {
        await this.props.fetchUserClients(this.props.auth);
        this.setState({ totalClients: this.props.userClients ? this.props.userClients.length : 0 });
      }

      if (!this.props.recentlyWatchSession) {
        await this.props.fetchRecentlyWatchSession(this.props.auth, 3);
        if (this.props.recentlyWatchSession && this.props.recentlyWatchSession.length > 0) {
          this.props.setSelectedSession(
            parseInt(this.props.recentlyWatchSession[0].id),
            this.props.recentlyWatchSession[0].name
          );

          this.setState({
            selectedSessionId: this.props.recentlyWatchSession[0].id,
            selectedSessionTitle: this.props.recentlyWatchSession[0].name,
          });
        }
      }

      if (!this.props.ownerSessionForUser) {
        await this.props.fetchOwnerSessionForUser(this.props.auth);
      }

      if (!this.props.purchasedSessions) {
        await this.props.fetchPurchasesByUserId(this.props.auth.id);
      }

      await this.props.fetchUserSession(this.props.auth);

      //Analytics work around
      switch (this.props.auth.role.id) {
        case 1:
          await this.props.fetchUserSession(this.props.auth, "admin");
          this.setState({
            allSessions: this.props.userSessions,
          });
          break;
      }

      this.state.loaded = true;
      if (this.props.userSessions && this.props.userSessions.length > 0) {
        if (this.props.recentlyWatchSession.length <= 0) {
          this.props.setSelectedSession(
            parseInt(this.props.userSessions[0].id),
            this.props.userSessions[0].name
          );

          this.setState({
            selectedSessionId: this.props.userSessions[0].id,
            selectedSessionTitle: this.props.userSessions[0].name,
          });
        }

        let total = 0;

        if (this.props.purchasedSessions !== null) {
          total =
            this.props.purchasedSessions.length +
            this.props.userSessions.length;
        }

        this.setState({ totalSessions: total });
      }
    }
  }

  handleChange(e) {
    const sessionId = e.target.value;
    const sessionTitle = e.target.options[e.target.selectedIndex].text;

    this.setState({
      selectedSessionId: parseInt(sessionId),
      selectedSessionTitle: sessionTitle,
    });

    console.log(this.props)

    this.props.setSelectedSession(parseInt(sessionId), sessionTitle);
  }

  handleSubmit(event) {
    alert("This is not implemented yet");
    event.preventDefault();
  }

  onOpenNav(e) {
    document.getElementById("sidebar").classList.add("sidebarOpen");
  }

  onRuntimeChange(e) {
    let runtimeValue = parseFloat(e.target.value);
    if (e.target.value.trim().length > 0 && isNaN(runtimeValue)) {
      alert("Must be set as a number");
    }

    this.setState({ runtimeMinute: runtimeValue });
  }

  onWatchNow(e) {
    e.preventDefault();

    const select = document.getElementById("dashboard-session-select");
    const input = document.getElementById("dashboard-runtime-input");

    if (select) {
      this.props.history.push(`/images/${select.value}?runtime=${input.value}`);
    }
  }

  onEditSession(e) {
    e.preventDefault();
    if (this.props.auth.role.id === 2) {
      this.props.history.push(`/upgradeAccount`);
    }
    else {
      const select = document.getElementById("dashboard-session-select");
      if (select) {
        this.props.history.push(`/session/edit/${select.value}`);
      }
    }
  }

  renderWelcomeModal() {
    return;
  }

  renderSelectSession() {
    let sessionIds = [];
    let sessionsArray = [];
    if (this.props.userSessions) {
      if (this.props.userSessions === null) return <div />;
      sessionsArray = this.props.userSessions;
    }

    if (this.props.ownerSessionForUser) {
      if (this.props.ownerSessionForUser === null) return <div />;
      sessionsArray = sessionsArray.concat(this.props.ownerSessionForUser);
    }

    if (this.props.purchasedSessions) {
      if (this.props.purchasedSessions === null) return <div />;
      sessionsArray = sessionsArray.concat(this.props.purchasedSessions);
    }

    const sessions = Array.from(new Set(sessionsArray.map((a) => a.id))).map(
      (id) => {
        return sessionsArray.find((a) => a.id === id);
      }
    );

    sessions.sort((a, b) => (a.name > b.name ? 1 : -1));
    
    return (
      <select
        id="dashboard-session-select"
        value={this.state.selectedSessionId}
        onChange={this.handleChange.bind(this)}
        className="custom-select"
      >
        {_.map(sessions, (value) => {
          sessionIds.push(value.id);
          return (
            <option key={`option-item-${value.id}`} value={value.id}>
              {value.name}
            </option>
          );
        })}
      </select>
    );
  }

  renderCoachArea() {
    if (
      !this.props.auth ||
      (this.props.auth.role.id !== 1 && this.props.auth.role.id !== 4)
    ) {
      return <div />;
    }

    let location = "";
    if (this.props.auth.city) {
      location += this.props.auth.city;
    }

    if (this.props.auth.state) {
      if (location.length > 0) {
        location += ", ";
      }

      location += this.props.auth.state;
    }

    if (this.props.auth.country) {
      if (location.length > 0) {
        location += ", ";
      }

      location += this.props.auth.country;
    }

    let $marker = <FontAwesomeIcon icon={faMapMarkerAlt} className="text-secondary" />;

    return (
      <div className="row bg-white m-0 p-4 align-items-center">
        <div className="col-12 col-lg-7 pl-4">
          <div className="pl-0 pl-lg-5">
            <p className="h1">Welcome Coach {this.props.auth.firstName}!</p>
            {location.length > 0 ? $marker : ""}
            <p>{location}</p>
            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                this.props.history.push("/users");
              }}
            >
              Add Client
            </button>
            {/* <button
                     className="btn btn-primary"
                     onClick={() => {
                        this.props.history.push('/session/category');
                     }}
                  >
                     Create Session
                  </button> */}
          </div>
        </div>
        <div className="col-12 col-lg-5 p-4">
          <div className="row bg-primary align-items-center m-0 my-2 rounded shadow">
            <div className="col p-3">
              <FontAwesomeIcon icon={faVideo} className="text-white h2 m-0" />
            </div>
            <div className="col-auto">
              <p className="text-white m-0">Sessions</p>
              <p className="h3 text-white text-right m-0">
                {this.state.totalSessions}
              </p>
            </div>
          </div>
          <div className="row bg-primary align-items-center m-0 my-2 rounded shadow">
            <div className="col p-3">
              <FontAwesomeIcon icon={faUsers} className="text-white h2 m-0" />
            </div>
            <div className="col-auto">
              <p className="text-white m-0">Clients</p>
              <p className="h3 text-white text-right m-0">
                {this.state.totalClients}
              </p>
            </div>
          </div>
          <div className="row bg-white align-items-center m-0 my-2 rounded shadow">
            <iframe
              className="advertisement"
              height="315"
              src="https://www.youtube.com/embed/ALDJjjkujWQ"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          {/* <div className="row bg-primary align-items-center m-0 my-2 rounded shadow">
                     <div className="col p-3">
                        <i className="fas fa-trophy h2 text-white m-0" />
                     </div>
                     <div className="col-auto">
                        <p className="text-white m-0">Rewards</p>
                        <p className="h3 text-white text-right m-0">5</p>
                     </div>
                  </div> */}
        </div>
      </div>
    );
  }

  renderTabNavigation() {
    if (!this.props.auth || this.props.auth.role.id !== 1) {
      return <div />;
    }

    return (
      <nav>
        <div className="nav bg-white px-4 px-lg-5" id="nav-tab" role="tablist">
          <a
            className="nav-item nav-link pb-3 active"
            id="nav-home-tab"
            data-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Watch
          </a>
          <a
            className="nav-item nav-link pb-3"
            id="nav-profile-tab"
            data-toggle="tab"
            href="#nav-profile"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
            onClick={this.renderGraph}
          >
            Analytics
          </a>
        </div>
      </nav>
    );
  }

  renderFeatureHeader() {
    if (
      !this.props.auth ||
      this.props.auth.role.id === 1 ||
      this.props.auth.role.id === 4
    ) {
      return <div />;
    }
    return (
      <div className="row m-0">
        <div className="col-12 pt-5 px-4 pb-0 px-lg-5">
          <p className="h4 m-0">Featured Sessions</p>
          <p className="text-secondary">Our favorite Sessions</p>
        </div>
      </div>
    );
  }

  renderFeatureContentArea() {
    // If the user is admin or coach don't show feature area
    if (
      !this.props.auth ||
      this.props.auth.role.id === 1 ||
      this.props.auth.role.id === 4
    ) {
      return <div />;
    }

    return (
      <div className="row m-0 p-0 px-lg-4">{this.renderFeatureContent()}</div>
    );
  }

  renderFeatureContent() {
    return _.map(this.props.productsFeatured, (item, index) => {
      let featuredImg = makePlayerImageUrl(item.product.featured_bg_image_url);

      let overlayClassList = "overlay overlayBlue";
      let mainDivClassList = "col-12 col-md-6 col-lg-4 px-4 pb-4 pb-lg-0";
      if (index === 0) {
      } else if (index % 2 === 0) {
        overlayClassList = "overlay overlayOrange";
        mainDivClassList = "col-12 col-md-6 col-lg-4 px-4";
      } else {
        overlayClassList = "overlay overlayGreen";
      }

      return (
        <div
          key={`featured-item-${item.product.id}`}
          className={mainDivClassList}
        >
          <div
            className="position-relative shadow featuredImageContainer"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.history.push(`/productDetail/${item.product.id}`);
            }}
          >
            <img
              alt={`featured-img-${item.product.id}`}
              className="imageContainer grayscale featureMaxHeight"
              src={featuredImg}
            />
            <div className={overlayClassList} />
            <div className="imageCaption p-4">
              <p className="imageCaptionTitleFeat text-white m-0">
                {item.product.title}
              </p>
              <p className="imageCaptionDescFeat text-white m-0 pp-block-with-text">
                {item.product.short_description}
              </p>
            </div>
          </div>
        </div>
      );
    });
  }

  renderRecentlyWatch() {
    return _.map(this.props.recentlyWatchSession, (item, index) => {
      let author = "";

      author = item.author_first_name + " " + item.author_last_name;
      return (
        <tr key={`recent-item-${index}`}>
          <td>{item.name}</td>
          <td>{author}</td>
          <td
            onClick={() => {
              this.props.history.push(
                `/images/${item.id}?runtime=${item.duration}`
              );
            }}
          >
            <FontAwesomeIcon icon={faPlayCircle} className="text-primary" />
          </td>
        </tr>
      );
    });
  }

  renderEditBtn() {
    // if (!this.props.auth || this.props.auth.role.id === 2) {
    //   return <div />;
    // }

    return (
      <button
        className="btn btn-primary right"
        style={{ marginRight: 48 }}
        onClick={this.onEditSession.bind(this)}
      >
        Personal Uploads / Edit <br /> Transform Sessions
      </button>
    );
  }

  renderCreateBtn() {
    if (!this.props.auth || this.props.auth.role.id === 2) {
      return <div />;
    }

    return (
      <button
        className="btn btn-primary right"
        style={{ marginRight: 180 }}
        onClick={() => {
          this.props.history.push("/session/category");
        }}
      >
        Create Session
      </button>
    );
  }
  /******************************************
   * Start working on Graph
   ******************************************/
  computeGraphData() {
    let year = this.selYear.value;

    if (this.state.theGraphData.length < 0) {
      return;
    }

    const histories = this.props.userOwnersSessionHistory;

    if (histories == null) {
      return;
    }

    let sessionName = new Object();
    let sessionHistories = new Object();
    let totalWatchStatus = new Object();
    let sessionID = 0;

    for (let s in this.props.userSessions) {
      sessionID = this.props.userSessions[s].id;

      sessionName[sessionID] = this.props.userSessions[s].name;

      sessionHistories[sessionID] = [];
      totalWatchStatus[sessionID] = {
        totalWatched: 0,
        totalFinished: 0,
        totalUnfinshed: 0,
      };
    }

    let fromMonth =
      this.selFromMonth.value != "" ? parseInt(this.selFromMonth.value) + 1 : 1;
    let toMonth =
      this.selToMonth.value != "" ? parseInt(this.selToMonth.value) + 1 : 12;

    for (let x in histories[year]) {
      if (
        histories[year][x].watch_month >= fromMonth &&
        histories[year][x].watch_month <= toMonth
      ) {
        if (this.selAnalyticSessions.value !== "") {
          if (
            parseInt(this.selAnalyticSessions.value) !=
            histories[year][x].session_id
          ) {
            continue;
          }
        }

        let unfinished =
          histories[year][x].unfinished !== null
            ? histories[year][x].unfinished
            : 0;
        let finished = histories[year][x].total_watched - unfinished;

        sessionHistories[histories[year][x].session_id].push([
          new Date(year, histories[year][x].watch_month - 1),
          finished,
          unfinished,
        ]);

        totalWatchStatus[histories[year][x].session_id].totalWatched +=
          histories[year][x].total_watched;
        totalWatchStatus[
          histories[year][x].session_id
        ].totalFinished += finished;
        totalWatchStatus[
          histories[year][x].session_id
        ].totalUnfinshed += unfinished;
      }
    }

    let graphData = [];

    for (let s in sessionHistories) {
      let data = [
        [
          {
            type: "date",
            label: totalWatchStatus[s].totalWatched + " watched",
          },
          "Finished",
          "Unfinished",
        ],
      ];

      graphData.push({
        sid: s,
        name: sessionName[s],
        total_watched: totalWatchStatus[s].totalWatched,
        total_finished: totalWatchStatus[s].totalFinished,
        total_unfisnished: totalWatchStatus[s].totalUnfinshed,
        data: data.concat(sessionHistories[s]),
      });
    }

    return graphData.sort((a, b) => {
      return b.total_watched - a.total_watched;
    });
  }

  renderSelectYear() {
    const historiesYears = this.props.userOwnersSessionHistoryYears;

    if (historiesYears === null) {
      return;
    }

    return (
      <div className="col-12 col-lg-3 pt-0 px-4 pb-4">
        <div className="p-4 bg-white shadow">
          <form>
            <div className="form-group">
              <label htmlFor="inputSession">Select Year</label>
              <select
                id="dashboard-year-select"
                onChange={this.analyticYMSHandleChange}
                className="custom-select"
                ref={(input) => (this.selYear = input)}
              >
                {_.map(historiesYears, (value) => {
                  return (
                    <option
                      key={`option-item-${value.year}`}
                      value={value.year}
                    >
                      {value.year}
                    </option>
                  );
                })}
              </select>
              {/* {this.selectWatchedYears()} */}
            </div>
          </form>
        </div>
      </div>
    );
  }

  fromMonthHandleChange(e) {
    let fromValue = e.target.value;

    if (fromValue >= 0) {
      const months = this.state.months;

      let to = _.map(months, (item, key) => {
        let disabled = true;

        if (fromValue <= key) {
          disabled = false;
        }

        return (
          <option value={key} disabled={disabled}>
            {item}
          </option>
        );
      });

      this.setState({
        toMonthValue: to,
      });
    }

    if (fromValue > this.selToMonth.value && this.selToMonth.value !== "") {
      this.selToMonth.value = fromValue;
    }

    if (this.selToMonth.disabled == true) {
      this.selToMonth.disabled = false;
      this.selToMonth.focus();
    }

    if (this.selToMonth.value !== "") {
      this.analyticYMSHandleChange();
    }
  }

  renderSelectMonths() {
    const months = this.state.months;

    let from = _.map(months, (item, key) => {
      return <option value={key}>{item}</option>;
    });

    return (
      <div className="col-12 col-lg-4 pt-0 px-4 pb-4">
        <div className="p-4 bg-white shadow text-secondary">
          <label htmlFor="inputSession">Select Month Range</label>
          <form>
            <div className="form-group row">
              <div className="col-6 control-label">
                <select
                  ref={(fromMonth) => (this.selFromMonth = fromMonth)}
                  className="form-control"
                  onChange={this.fromMonthHandleChange.bind(this)}
                  defaultValue=""
                >
                  <option value="" disabled>
                    --from--
                  </option>
                  {from}
                </select>
              </div>
              <div className="col-6 control-label">
                <select
                  ref={(toMonth) => (this.selToMonth = toMonth)}
                  onChange={this.analyticYMSHandleChange}
                  className="form-control"
                  defaultValue=""
                  disabled
                >
                  <option value="" disabled>
                    --to--
                  </option>
                  {this.state.toMonthValue}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  //Analytics sessions
  renderSelectAnalyticSessions() {
    return (
      <div className="col-12 col-lg-5 pt-0 px-4 pb-4">
        <div className="p-4 bg-white shadow text-secondary">
          <label htmlFor="inputSession">Select Session</label>
          <form>
            <div className="form-group row">
              <div className="col-12 control-label">
                <select
                  id="dashboard-analytics-session-select"
                  onChange={this.analyticYMSHandleChange}
                  className="custom-select"
                  defaultValue=""
                  ref={(analyticSessions) =>
                    (this.selAnalyticSessions = analyticSessions)
                  }
                >
                  <option value="">--All--</option>
                  {_.map(this.state.allSessions, (value) => {
                    return (
                      <option key={`option-item-${value.id}`} value={value.id}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  // Donut
  displayDonutGraph() {
    const gdata = this.computeGraphData();

    let data = [["Task", "Watched"]];

    for (let i in gdata) {
      data.push([gdata[i].name, gdata[i].total_watched]);
    }

    return (
      <Chart
        width="100%"
        height="500px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          title: "Session activities",
          // Just add this option
          pieHole: 0.4,
        }}
        rootProps={{ "data-donutid": "1" }}
      />
    );
  }

  // Line
  displayLineGraph() {
    let graphData = this.computeGraphData();

    return _.map(graphData, (item, index) => {
      if (item.data.length === 1) {
        if (this.selAnalyticSessions.value !== "") {
          if (parseInt(this.selAnalyticSessions.value) !== item.sid) {
            return;
          }
        }

        return (
          <div className="col-12 col-lg-12 pt-0 px-4 pb-4">
            <div className="p-4 bg-white shadow">
              <p className="text-secondary">{item.name}</p>
              <p className="text-secondary">
                {item.total_watched} watched
                <br />
                {item.total_finished} finished
                <br />
                {item.total_unfisnished} unfinished
              </p>
            </div>
          </div>
        );
      }

      let options = {
        chart: {
          title: item.name,
        },
        // width: "100%",
        //  height: 500,
        series: {
          // Gives each series an axis name that matches the Y-axis below.
          0: { axis: "Finished" },
          1: { axis: "Unfinished" },
        },
        axes: {
          // Adds labels to each axis; they don't have to match the axis names.
          y: {
            Finished: { label: "" },
            Unfinished: { label: "" },
          },
        },
      };
      return (
        <div className="col-12 col-lg-12 pt-0 px-4 pb-4">
          <div className="p-4 bg-white shadow">
            <Chart
              width={"100%"}
              height={"500"}
              chartType="Line"
              loader={<p>Loading Chart...</p>}
              data={item.data}
              options={options}
              rootProps={{ "data-testid": index + 1 }}
            />
            <p className="text-secondary">
              {item.total_watched} watched
              <br />
              {item.total_finished} finished
              <br />
              {item.total_unfisnished} unfinished
            </p>
          </div>
        </div>
      );
    });
  }

  renderGraph() {
    if (this.state.renderedGraph == false) {
      let el = this.selYear;

      if (el == null) {
        return;
      }

      this.setState({
        renderDonutGraph: this.displayDonutGraph(),
        renderLineGraph: this.displayLineGraph(),
        renderedGraph: true,
      });
    }

    // if (this.selAnalyticSessions.value == "") {
    //   this.setState({
    //     renderSessionGraph: this.displayDonutGraph()
    //   });
    // } else {
    //   this.setState({
    //     renderSessionGraph: this.displayLineGraph()
    //   });
    // }
  }

  analyticYMSHandleChange(e) {
    if (e.target.value === "") {
      this.setState({
        renderSessionGraph: this.displayDonutGraph(),
      });
    } else {
      this.setState({
        renderSessionGraph: this.displayLineGraph(),
      });
    }
  }

  /******************************************
   * end of Graph
   ******************************************/

  render() {
    if (this.props.auth === "undefined" || !this.props.auth) {
      return <div />;
    }

    const advertisement = makeAssetsUrl('collage%20family.png');

    return (
      <div className="mainWidth">
        <div className="d-flex bg-white">
          <button
            className="btn bg-transparent d-md-none"
            onClick={this.onOpenNav.bind(this)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <p className="text-secondary m-0 px-0 py-3 px-md-3">Dashboard</p>
        </div>
        <hr className="m-0" />

        {this.renderFeatureHeader()}
        {this.renderFeatureContentArea()}

        {this.renderCoachArea()}

        {this.renderTabNavigation()}

        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="row m-0">
              <div className="col-12 pt-5 px-4 pb-0 px-lg-5">
                <p className="h4 m-0">Watch a Session</p>
                <p className="text-secondary">
                  Watch one of your Sessions you created or purchased
                </p>
              </div>
            </div>
            <div className="row m-0 p-0 px-lg-4">
              <div className="col-12 col-lg-7 pt-0 px-4 pb-4">
                <div className="p-4 bg-white shadow">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="inputSession">Select a Session</label>
                      {/* <select className="form-control">
                                    <option>Session</option>
                                    <option>Session</option>
                                 </select> */}
                      {this.renderSelectSession()}
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputLength">Run Time (Minutes)</label>
                      <input
                        id="dashboard-runtime-input"
                        type="text"
                        className="form-control"
                        defaultValue="15"
                        onChange={this.onRuntimeChange.bind(this)}
                      />
                    </div>

                    <button
                      className="btn btn-success pl-4 pr-4"
                      onClick={this.onWatchNow.bind(this)}
                    >
                      Watch Now <br /> PLAY
                    </button>

                    {/* {this.renderCreateBtn()} */}
                    {this.renderEditBtn()}
                  </form>
                </div>

                <div className="p-0 pt-4 text-center">
                  <a
                    href="https://www.positiveprime.com/love/"
                    className="nav-link p-0"
                    target="_blank"
                  >
                    <img
                      alt="advertisement"
                      src={advertisement}
                      className="advertisement"
                    />
                  </a>
                </div>
              </div>

              <div className="col-12 col-lg-5 pt-0 px-4 pb-4">
                <div className="p-4 bg-white shadow">
                  <label>Recently watched</label>
                  <table className="table table-hover table-striped table-borderless m-0">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Author</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{this.renderRecentlyWatch()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <div className="row m-0">
              <div className="col-12 pt-5 px-4 pb-0 px-lg-5">
                <p className="h4 m-0">Your Sessions Analytic</p>
                <p className="text-secondary">
                  Session finished and unfinished watched.
                </p>
              </div>
            </div>
            <div className="row m-0 p-0 px-lg-12">
              {this.renderSelectYear()}
              {this.renderSelectMonths()}
              {this.renderSelectAnalyticSessions()}
            </div>
            <div className="row m-0 p-0 px-lg-12">
              {this.state.renderSessionGraph}
            </div>
          </div>
          {/* <div
            className="tab-pane fade"
            id="nav-contact"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            Third
          </div> */}
        </div>

        <button
          id="thankYouModalBtn"
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#modalThankYou"
          style={{ display: "none" }}
        >
          Thank you
        </button>

        <WelcomeModal auth={this.props.auth} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log( "Dashboard state : ", state )
  return {
    auth: state.auth,
    mode: state.mode,
    userSessions: state.userSessions,
    productsFeatured: state.productsFeatured,
    userClients: state.userClients,
    recentlyWatchSession: state.recentlyWatchSession,
    ownerSessionForUser: state.ownerSessionForUser,
    userOwnersSessionHistory: state.userOwnersSessionHistory,
    userOwnersSessionHistoryYears: state.userOwnersSessionHistoryYears,
    purchasedSessions: state.purchasedSessions,
  };
}

export default connect(mapStateToProps, actions)(withRouter(Dashboard));
