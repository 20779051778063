import React, {Component} from 'react';
import {connect} from 'react-redux';
import './privacyterms.css';

class Privacy extends Component {
   componentDidMount() {}

   render() {
      //Reference: https://www.Positive Prime.com/privacy.html
      return (
         <div className="scrollGood">
            <p className="text-secondary m-0 p-3">Privacy</p>
            <hr className="m-0" />
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h3 m-0">Privacy Policy and Terms of Service</p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p>
                  This page should be considered collectively with our Terms and
                  Conditions.
               </p>
               <p className="m-0">
                  We believe in 'doing unto others, as we would wish would be
                  done unto us" and the principles of reciprocity and balance.
                  Please us let know via our Contact Page, at any time, how we
                  can improve. Suggestions are always welcome and considered
                  seriously. We are devoted to ensuring that we provide
                  exceptional value and service to YOU, our cherished customer.
                  We appreciate your patience and understanding as we grow and
                  expand the many ways we can help you improve your life.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Legal Disclaimer</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  Positive Prime and its associated marketing website pages
                  respect your right to privacy under the Privacy Act 1988 (Cth)
                  and is required to comply with the national privacy principles
                  in respect of the collection of personal information from
                  individuals. On our website, you can order products, enter
                  your email address to join our mailing list and subscribe to
                  our online newsletter.
               </p>
               <p>
                  We occasionally collect personal information such as your
                  name, address, email address, telephone number, credit card
                  information and information about your areas of interest. You
                  may request access to personal information we hold about you
                  by contacting us via our contact page.
               </p>
               <p>
                  If you believe that the personal information we hold about you
                  is incomplete or inaccurate, then you may ask us to amend it.
               </p>
               <p>
                  If you have any questions about our policy, or any complaint
                  regarding treatment of your privacy by us, please contact us.
               </p>
               <p>
                  We may use your information to enable us to fulfill any online
                  orders you place, to send you our newsletter by mail or to
                  keep you up to date with new titles and special offers and
                  events by email.
               </p>
               <p>
                  Please note that you may remove yourself from the distribution
                  list by simply typing "remove me" in the subject line of a
                  reply email. We do have a simple "Unsubscribe Link" in the
                  footer of ALL emails.
               </p>
               <p>
                  We will not, without your prior consent, disclose ANYpersonal
                  information collected about you on this website to any other
                  party except in the case of our carriers to whom we supply
                  your name and address details, as well as any particular
                  delivery instructions you have given us.
               </p>
               <p>
                  We do not sell or rent any customer lists. We value your
                  security.
               </p>
               <p>
                  Positive Prime expends a great effort to assist and inspire
                  individuals.
               </p>
               <p>
                  We make no guarantees and give no advice as to which idea or
                  activities are worth your time and money. We do however,
                  suggest or recommend that which we have found or discovered to
                  be useful or beneficial ourselves, given our personal
                  circumstances. ALL information presented on our websites or
                  distributed materials are for informational purposes ONLY and
                  are not intended to diagnose, treat or prevent any emotional,
                  mental or physical condition whatsoever. By purchasing our
                  products and/or services, YOU AGREE to these terms and
                  conditions and AGREE that any use of said products is at your
                  discretion and you alone are responsible for any results you
                  may or may not achieve.
               </p>
               <p>
                  We take great care to present to you information that has
                  worked for us, with the intention of helping you improve your
                  life. Positive Prime does not represent or endorse the
                  accuracy or reliability of any of the information, content or
                  advertisements (collectively, the "Materials") contained on,
                  distributed through, or linked, downloaded or accessed from
                  any of the services contained on this website (the "Service"),
                  nor the quality of any products, information or other
                  materials displayed, purchased, or obtained by you as a result
                  of an advertisement or any other information or offer in or in
                  connection with the Service (the "Products").
               </p>
               <p>
                  You hereby acknowledge that any reliance upon any Materials
                  shall be at your sole risk. The Company reserves the right, in
                  its sole discretion and without any obligation, to make
                  improvements to, or correct any error or omissions in any
                  portion of the service or the Materials.
               </p>
               <p className="fontBold">
                  THE SERVICE AND THE MATERIALS ARE PROVIDED BY Positive Prime
                  ARE ON AN "AS IS" BASIS.
               </p>
               <p>
                  The Company expressly disclaims any and all warranties,
                  expressed or implied, including without limitation warranties
                  of merchantiability and fitness for a particular purpose, with
                  respect to the service or any materials or products.
               </p>
               <p>
                  In no event shall the Company, it's employees, contractors,
                  associates, and owners be liable for any direct, indirect,
                  incidental, punitive, or consequential damages of any kind
                  whatsoever with respect to the service, the materials, the
                  promises and the products.
               </p>
               <p>
                  Positive Prime encourages you to exercise discretion while
                  browsing the Internet using this site. The Company makes no
                  representations concerning any endeavour to review the content
                  of discussions or representations or any of the Materials
                  between you and individuals or companies who have obtained
                  your contact information from us, and so The Company isn't
                  responsible for the accuracy, copyright compliance, legality
                  or decency of material contained in these sites,
                  conversations, and written materials after we place you in
                  contact.
               </p>
               <p>
                  Your privacy is very important to us. We want to make your
                  experience on the Internet as enjoyable and rewarding as
                  possible, and we want you to use the Internet's vast array of
                  information, tools, and opportunities with complete
                  confidence.
               </p>
               <p className="m-0">
                  We have created this Privacy Policy to demonstrate our firm
                  commitment to privacy and security. This Privacy Policy
                  describes how our company collects information from all end
                  users of our Internet services (the "Services")-those who
                  access some of our Services but do not have accounts
                  ("Visitors") as well as those who may purchase Products and/or
                  pay a monthly service fee to subscribe to the Service
                  ("Members")-what we do with the information we collect, and
                  the choices Visitors and Members have concerning the
                  collection and use of such information. We request that you
                  read this Privacy Policy carefully.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 text-secondary text-center m-0 p-3 border border-secondary">
                  Information We Collect
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Personally Identifiable Information</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  We may collect personally identifiable information from Users
                  in a variety of ways, including, but not limited to, when
                  Users visit the Site, register on the Site, subscribe to the
                  newsletter, respond to a survey, fill out a form, and in
                  connection with other activities, services, features, or
                  resources we make available through our Services. Users may be
                  asked for, as appropriate, name, email address, mailing
                  address, date of birth, gender, and/or marital status. Users
                  can always refuse to supply the personally identifiable
                  information that we request, except that it may prevent them
                  from engaging in certain Site-related activities.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Non Personally Identifiable Information</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  We may collect non-personally identifiable information about
                  Users whenever they interact with the Site. Non-personally
                  identifiable information may include the browser name, the
                  type of computer,the files viewed on the Site, clickstream
                  data, and technical information about Users’ means of
                  connection to the Site, such as the operating system and the
                  Internet service providers utilized and other similar
                  information. To the extent that we link this information with
                  a User’s personally identifiable information, this Privacy
                  Policy shall govern our use of such information.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Cookies and Other Tracking Technologies</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  As is true of most websites, we gather certain information
                  automatically and store it in log files. This information may
                  include Internet protocol (IP) addresses, browser type,
                  internet service provider (ISP), referring/exit pages, the
                  files viewed on our Site (e.g., HTML pages, graphics, etc.),
                  operating system, date/time stamp, and/or clickstream data to
                  analyze trends in the aggregate and administer the Site.
               </p>
               <p>
                  We use cookies or similar technologies to store session
                  information, analyze trends, administer the Site, track users’
                  movements around the Site, and to gather demographic
                  information about our user base as a whole. A cookie is a
                  small amount of data, which often includes an anonymous unique
                  identifier, which is sent to your browser from a website’s
                  computers and stored on your computer’s hard drive.
               </p>
               <p>
                  We use both session ID cookies and persistent cookies. A
                  session ID cookie expires when you close your browser. We use
                  session cookies to track your login status. This cookie is
                  only ever transmitted over HTTPS. A persistent cookie remains
                  on your hard drive for an extended period of time. We use
                  persistent cookies to determine from where you were referred
                  to our Sites so that we may provide commissions or referral
                  fees, and recognize the last user ID that you used to log in.
                  We may set and access Positive Prime cookies on your computer;
                  cookies are required to use the Site.
               </p>
               <p className="m-0 wrapText">
                  You can remove persistent cookies by following directions
                  provided in your Internet browser’s “help” directory. You can
                  go to www.aboutcookies.com for more information on cookies,
                  including how to disable them. If you choose to disable
                  cookies, it may limit your use of certain features or
                  functions on our Sites or service. To manage Flash cookies,
                  please click here:
                  http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">
                  How We Use Personally Identifiable Information
               </p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  Positive Prime may collect and use Users’ personally
                  identifiable information for the following purposes:
               </p>
               <ul className="m-0">
                  <li>
                     To process transactions
                     <p>
                        Information you provide allows us to process
                        transactions made on the Site or as otherwise necessary
                        for performance of our contractual obligations to you,
                        including as applicable, order confirmation, billing,
                        and delivering products or services.
                     </p>
                  </li>
                  <li>
                     To improve customer service
                     <p>
                        Information you provide helps us respond to your
                        customer service requests and support needs more
                        efficiently. We will do this on the basis of our
                        legitimate interests.
                     </p>
                  </li>
                  <li>
                     To personalize user experience
                     <p>
                        We may use information in the aggregate to understand
                        how our Users as a group use the Services. We will do
                        this on the basis of our legitimate business interests.
                     </p>
                  </li>
                  <li>
                     To improve our services
                     <p>
                        We may use feedback you provide to improve our Services.
                        We will do this on the basis of our legitimate business
                        interests.
                     </p>
                  </li>
                  <li>
                     To run a promotion, contest, survey, or other site feature
                     <p>
                        With your consent, we will use your personally
                        identifiable information to keep you informed by email,
                        text, web or telephone about our products and services,
                        including tailored special offers, discounts,
                        promotions, event and competitions. You are free to opt
                        out from receiving such communications at any time.
                     </p>
                  </li>
                  <li>
                     To communicate directly with you
                     <p>
                        We may use your personally identifiable information to
                        respond to you inquiries, questions, and/or other
                        requests. We do this on the basis of our contractual
                        obligations to you, our legal obligations, and our
                        legitimate interests.
                     </p>
                  </li>
                  <li>
                     To share offers from third parties that may be of interest
                     to you
                     <p>
                        With your consent, we may use your information to let
                        you know about offers, programs, products, or services,
                        from us or third parties, that may be of interest to
                        you.
                     </p>
                  </li>
                  <li>
                     To fulfull any other purpose for which you provide
                     personally identifiable information
                     <p className="m-0">
                        We may use your personally identifiable information for
                        purposes that we make known to you at the time of
                        collection of such information or otherwise upon your
                        consent.
                     </p>
                  </li>
               </ul>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">How We Protect Your Information</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  The security of your personally identifiable information is
                  important to us. We have adopted generally accepted industry
                  standards in connection with our data collection, storage, and
                  processing practices and security measures to protect against
                  unauthorized access, alteration, disclosure, or destruction of
                  your personally identifiable information, username, password,
                  transaction information, and data stored on the Site. No
                  method of transmission over the Internet, or method of
                  electronic storage, is 100% secure, however. Therefore, while
                  we strive to use commercially acceptable means to protect your
                  personally identifiable information, we cannot guarantee its
                  absolute security.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">
                  Disclosure of Your Personally Identifiable Information
               </p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  We may disclose aggregated information about our Users, and
                  information that does not identify any individual user,
                  without restriction. We may disclose personally identifiable
                  information about you in the following ways:
               </p>
               <ul className="m-0">
                  <li>
                     Affiliates
                     <p>
                        We may disclose personally identifiable information
                        about you to our parents, affiliates, joint venturers,
                        or promotion partners, for their use in a manner similar
                        to the purpose described in this Privacy Policy.
                     </p>
                  </li>
                  <li>
                     Agents and Service Providers
                     <p>
                        We may disclose personally identifiable information
                        about you to contractors, service providers, and other
                        third parties we use to support our business, provide
                        the Services, and who complete transactions or perform
                        services on our behalf or for your benefit. For example
                        we use a third party processor to process payments for
                        the Services.
                     </p>
                  </li>
                  <li>
                     Marketing
                     <p>
                        With your consent, we may disclose personally
                        identifiable information about you to third parties for
                        their own direct marketing purposes, to provide you with
                        information about products that may be of interest to
                        you, and for other purposes as specifically set forth in
                        this Privacy Policy or to which you consent to.
                     </p>
                  </li>
                  <li>
                     Legal Process
                     <p>
                        We may disclose personally identifiable information
                        about you as required by law, such as to comply with a
                        subpoena or other legal process, or when we believe in
                        good faith that disclosure is necessary to protect our
                        rights, protect your safety or the safety of others,
                        investigate fraud, or respond to a government request.
                     </p>
                  </li>
                  <li>
                     Certain Business Transfers
                     <p>
                        We may disclose personally identifiable information
                        about you as part of a corporate business transaction,
                        such as a merger, acquisition, joint venture, or
                        financing or sale of company assets. It may also be
                        disclosed in the event of insolvency, bankruptcy, or
                        receivership. We will use reasonable efforts to direct
                        any such transferee to use your personally identifiable
                        information in a manner that is consistent with our
                        Privacy Policy.
                     </p>
                  </li>
                  <li>
                     Consent
                     <p className="m-0">
                        We may disclose personally identifiable information
                        about you in additional ways upon your consent.
                     </p>
                  </li>
               </ul>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 text-secondary text-center m-0 p-3 border border-secondary">
                  Your Rights
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p>
                  You have the right to request a copy of any personally
                  identifiable information that we hold about you. If you would
                  like a copy of your personally identifiable information,
                  please contact us using the contact information below. We may
                  request proof of your identity before sharing such
                  information. If you discover that the information we hold
                  about you is incorrect or out of date, you may ask us to
                  correct that information by emailing us at mpthelp@gmail.com
               </p>
               <p>
                  You may ask us to delete the personally identifiable data we
                  hold about you in certain circumstances. It may not be
                  possible for us to delete all of the information we hold about
                  you where we are fulfilling a transaction or have a legal
                  basis to retain the information, however please contact us to
                  discuss how we can assist you with your request.
               </p>
               <p>
                  When we process your information on the basis that you have
                  consented to such processing, you have the right to withdraw
                  your consent, or ask us to stop or restrict processing the
                  personally identifiable information we have about you, at any
                  time by emailing us at mpthelp@gmail.com.
               </p>
               <p>
                  Please direct any questions, including any requests to
                  exercise your legal rights, to mpthelp@gmail.com.
               </p>
               <p>
                  If you are in the European Union, you have the right to make a
                  complaint at any time to the relevant data protection
                  authority in your country.
               </p>
               <p>
                  We will retain your information for as long as your account is
                  active or as needed to fulfill your requests, provide you
                  services, comply with our legal obligations, resolve disputes,
                  and enforce our agreements.
               </p>
               <p className="m-0">
                  If you receive marketing emails from us, you can unsubscribe
                  to our emails by clicking “unsubscribe” within each email. You
                  may not opt-out of service-related communications, which are
                  not promotional in nature.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 text-secondary text-center m-0 p-3 border border-secondary">
                  Personal Information Our Company Collects and How it is Used
               </p>
            </div>
            <div className="pt-5 px-4 pb-5 px-lg-5">
               <ul className="m-0">
                  <li>
                     Introduction
                     <p>
                        Our company collects information in different ways from
                        Visitors and Members who access the various parts of our
                        Services and the network of Web sites accessible through
                        our Service. We use this information primarily to
                        provide a customized experience as you use our Products
                        and Services, and generally, do not share this
                        information with third parties. However, we may disclose
                        personal information collected if we have received your
                        permission beforehand or in very special circumstances,
                        such as when we believe that such disclosure is required
                        by law or other special cases described below.
                     </p>
                  </li>
                  <li>
                     Registration
                     <p>
                        Members may be asked to provide certain personal
                        information when they sign up for our Products or
                        Services including name, address, telephone number,
                        billing information (such as a credit card number), and
                        the type of personal computer being used to access the
                        Services. The personal information collected from
                        Members during the registration process is used to
                        manage each Member's account (such as for billing
                        purposes). This information is not shared with third
                        parties, unless specifically stated otherwise or in
                        special circumstances.
                     </p>
                     <p>
                        However, in instances where our company and a partner
                        jointly promote our Services, we may provide the partner
                        certain personal information, such as the name, address,
                        and username of persons who subscribed to the Services
                        as a result of the joint promotion for the sole purpose
                        of allowing us and the partner to assess the results of
                        the promotion.
                     </p>
                     <p>
                        In this instance, personal information may not be used
                        by the partner for any other purpose. We may also
                        generate non-identifying and aggregate profiles from
                        personal information Members provide during registration
                        (such as the total number, but not the names, of
                        Members). As explained in more detail below, we may use
                        this aggregated and non-identifying information to sell
                        advertisements that appear on the Services.
                     </p>
                  </li>
                  <li>
                     Our Company Partners and Sponsers
                     <p>
                        Some products and services may be offered to Visitors
                        and Members in conjunction with an affiliate,
                        independent contractor seller or non-affiliated partner.
                        To provide Visitors and Members some of these products
                        and services, the partner may need to collect and
                        maintain personal information. In these instances, you
                        will be notified before any such data is collected or
                        transferred and may decide not to use that particular
                        service or feature.
                     </p>
                     <p>
                        Additionally, our partners may have advertisements or
                        co-branded Web Pages that are cosponsored by an
                        affiliate, independent contractor seller, or
                        non-affiliated partner. Our company may share
                        non-identifying and aggregate information (except as
                        described above), but not personal information, with
                        such partners in order to administer the co-branded
                        products or services offered.
                     </p>
                  </li>
                  <li>
                     Online Shopping
                     <p>
                        At some Web sites, you can purchase products and
                        services or register to receive materials, such as a
                        newsletter, catalog or new product and service updates.
                        In many cases, you may be asked to provide contact
                        information, such as your name, address, email address,
                        phone number, and credit/debit card information.
                     </p>
                     <p>
                        If you complete an order for someone else, such as an
                        online gift order sent directly to a recipient, you may
                        be asked to provide information about the recipient,
                        such as the recipient's name, address, and phone number.
                        Our company has no control over the third parties' use
                        of any personal information you provide when placing
                        such an order. Please exercise care when doing so.
                     </p>
                     <p>
                        If you order services or products directly from our
                        company we will use the personal information you provide
                        only to process that order. We do not share this
                        information with outside parties except to the extent
                        necessary to complete that order.
                     </p>
                  </li>
                  <li>
                     Online Advertisements
                     <p>
                        Our company may display our online advertisements. In
                        those cases we share aggregated and non-identifying
                        information about our Visitors and Members collected
                        through the registration process as well as through
                        online surveys and promotions with these advertisers.
                     </p>
                     <p>
                        Additionally, in some instances, we use this aggregated
                        and non-identifying information to deliver tailored
                        advertisements or joint ventures. For instance, an
                        advertiser or joint venture company tells us the
                        audience they want to reach and provides us an
                        advertisement tailored to the audience. Based upon the
                        aggregated and non-identifying information we have
                        collected, we may then display or send the advertisement
                        to the intended audience. Our company does not share
                        personal information about its Visitors or Members with
                        these advertisers or joint venture companies.
                     </p>
                  </li>
                  <li>
                     Responses to Email Inquiries
                     <p>
                        When Visitors or Members send email inquiries to our
                        company, the return email address is used to answer the
                        email inquiry we receive. Our company does not use the
                        return email address for any other purpose and does not
                        share the return email address with any third party.
                     </p>
                  </li>
                  <li>
                     Voluntary Customer Surveys
                     <p>
                        We may periodically conduct both business and individual
                        customer surveys. We encourage our customers to
                        participate in these surveys because they provide us
                        with important information that helps us to improve the
                        types of products and services we offer and how we
                        provide them to you. Your personal information and
                        responses will remain strictly confidential, even if the
                        survey is conducted by a third party. Participation in
                        our customer surveys is voluntary.
                     </p>
                     <p>
                        We may take the information we receive from individuals
                        responding to our Customer Surveys and combine (or
                        aggregate) it with the responses of other customers we
                        may have, to create broader, generic responses to the
                        survey questions (such as gender, age, residence,
                        hobbies, education, employment, industry sector, or
                        other demographic information). We then use the
                        aggregated information to improve the quality of our
                        services to you, and to develop new services and
                        products. This aggregated, non-personally identifying
                        information may be shared with third parties.
                     </p>
                     <p>
                        Special Cases: It is our company's policy not to use or
                        share the personal information about Visitors of Members
                        in ways unrelated to the ones described above without
                        also providing you an opportunity to opt out or
                        otherwise prohibit such unrelated uses.
                     </p>
                     <p>
                        However, we may disclose personal information about
                        Visitors or Members, or information regarding your use
                        of the Services or Web sites accessible through our
                        Services, for any reason if, in our sole discretion, we
                        believe that it is reasonable to do so, including:
                        credit agencies, collection agencies, merchant database
                        agencies, law enforcement, or to satisfy laws, such as
                        the Electronic Communications Privacy Act, the Child
                        Online Privacy Act, regulations, or governmental or
                        legal requests for such information; to disclose
                        information that is necessary to identify, contact, or
                        bring legal action against someone who may be violating
                        our Acceptable Use Policy or Terms Of Service, or other
                        user policies; to operate the Services properly; or to
                        protect our company and our Members.
                     </p>
                     <p>
                        "Cookies" and How Our Company Uses Them. A "cookie" is a
                        small data file that can be placed on your hard drive
                        when you visit certain Web sites. Our company may use
                        cookies to collect, store, and sometimes track
                        information for statistical purposes to improve the
                        products and services we provide and to manage our
                        telecommunications networks.
                     </p>
                     <p>
                        If you are a Visitor or Member we may use a cookie to
                        save your settings and to provide customizable and
                        personalized services. These cookies do not enable third
                        parties to access any of your customer information.
                        Additionally, be aware that if you visit other Web sites
                        where you are prompted to log in or that are
                        customizable, you may be required to accept cookies.
                     </p>
                     <p className="m-0">
                        Advertisers and partners may also use their own cookies.
                        We do not control use of these cookies and expressly
                        disclaim responsibility for information collected
                        through them.
                     </p>
                  </li>
               </ul>
            </div>
         </div>
      );
   }
}

function mapStateToProps(state) {
   return { auth: state.auth };
}

export default connect(mapStateToProps)(Privacy);
