import React from 'react';
import clsx from 'clsx';
function PictureCard(props){
    return(
        <div className={clsx('container','picture-card')} onClick={props.onClick}>
            <img src={props.image} alt={'picture-card__image'} className={'picture-card__image'} />
            <div className={'picture-card__text'}>
                <p className={clsx('picture-card__text-title','x-card-title')}>{props.title}</p>
                <p className={clsx('picture-card__text-subtitle','x-card-subtitle')}>{props.subtitle}</p>
            </div>
        </div>
    )
}

export default PictureCard;