import React from 'react';

const WelcomeModal = ({ onClose, auth }) => {
   return (
      <div
         className="modal fade"
         id="modalThankYou"
         tabIndex="-1"
         role="dialog"
         aria-labelledby="exampleModalCenterTitle"
         aria-hidden="true"
      >
         <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
               <div className="modal-header">
                  <button
                     type="button"
                     className="close"
                     data-dismiss="modal"
                     aria-label="Close"
                  >
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div className="modal-body p-5">
                  <p className="h3 text-center">Welcome to Positive Prime!</p>
                  <p className="text-center">
                     Thank you for creating a Positive Prime account! We hope
                     you enjoy your stay! Don't forget to finish your profile in
                     the "Settings" page!
                  </p>
                  <p className="text-center">Your referral code is:</p>
                  <p className="text-center m-0">{auth.referral_code}</p>
               </div>
               {/* <div className="modal-footer justify-content-center">
                  <button type="button" className="btn btn-primary">
                     Continue to Dashboard
                  </button>
               </div> */}
            </div>
         </div>
      </div>
   );
};

export default WelcomeModal;
