import {RouteComponentProps, withRouter} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import * as actions from "../../actions";
import {Card, Col, Row, Form, Table, Button} from "react-bootstrap";
export interface TopUpProps extends RouteComponentProps<any>, React.Props<any> {
    auth?: any;
}

export interface TopUpState {
    prices: Price[],
    selectedCredits: number
}

type Price = {
    credits: number,
    amount: number,
    save?: string | null
}

class TopUpClass extends React.Component<TopUpProps, TopUpState> {

    constructor(props: TopUpProps) {
        super(props);

        this.state = {
            prices: [],
            selectedCredits: 100
        }
        this.state.prices.push({
            credits: 50,
            amount: 1000
        })

        this.state.prices.push({
            credits: 100,
            amount: 1500
        })

        this.state.prices.push({
            credits: 136,
            amount: 2000
        })

        this.state.prices.push({
            credits: 220,
            amount: 3000
        })

    }

    render() {

        return null;
        const checkboxes = [];

        let price: Price;
        for (price of this.state.prices) {
            checkboxes.push(this.renderPriceLabel(price))
        }
        return (
            <div className="mainWidth">
                <button className="btn d-md-none" onClick={this.onOpenNav.bind(this)}>
                    <i className="fas fa-bars"/>
                </button>
                <p className="text-secondary m-0 p-3">Add Credits </p>
                <hr className="m-0"/>

                <div className={"m-5"}>
                    <Card className="shadow">
                        <Card.Header className="text-white bg-primary">Add credits to your account</Card.Header>
                        <Card.Body>
                            <p>How many credits you would like to purchase today?</p>
                            <Row>
                                {checkboxes}
                            </Row>

                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }

    renderPriceLabel(price: Price) {
        const num = Intl.NumberFormat('en-US', {style: "currency", currency: "USD"})
        const isActive = price.credits===this.state.selectedCredits ? true : false;
        return (
            <Col xs={12} sm={6} md={6} lg={3} xl={3} className={"px-5 py-3"}>
                <Button onClick={setCredits(this, price.credits)}  variant={isActive?"primary":"outline-primary"} className={"py-3 "+(isActive?" active":"")} block>
                    <p className="numberOfCredits"><span>{price.credits}</span> credits</p>
                    <span style={{fontSize:"2em", marginTop: "1em"}}>{num.format(price.amount/100)}</span>
                </Button>
            </Col>
        );
        /*
        return <tr className="priceRow" data-credits={price.credits} onClick={this.handlePriceClick.bind(this)}>
            <td>
                <Form.Check
                    custom
                    type="radio"
                    checked={this.state.selectedCredits===price.credits}
                    label=""
                />
            </td>
            <td>{price.credits}</td>
            <td>{num.format(price.amount/100)}</td>
        </tr>*/
    }

    handlePriceClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        const credits = event.currentTarget?.getAttribute("data-credits");
        if (credits)
            this.setState({selectedCredits: Number(credits)})
    }

    onOpenNav(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const sidebar = document.getElementById('sidebar');
        if (sidebar)
            sidebar.classList.add('sidebarOpen');
    }
}
function mapStateToProps(state: any) {
    return {auth: state.auth, createdSessions: state.userSessions};
}
export const TopUp = connect(mapStateToProps, actions)(withRouter(TopUpClass));

function setCredits(comp: TopUpClass, credits: number) {
    return function(event: React.MouseEvent<HTMLButtonElement>) {
        comp.setState({selectedCredits: credits});
    }
}
