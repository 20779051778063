import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions';

import './sessions.css';
import Loader from '../components/common/Loader';
import { makeLegacyCDNUrl } from "../APIAndConfig";

class CreateStatement extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null,
         template1Loaded: false
      };
   }

   calculateY(text, maxWidth, lineHeight) {
      let numOfCharacters = maxWidth / lineHeight; // number of character on 1 line
      numOfCharacters *= 2;
      return (text.length / numOfCharacters) * lineHeight;
   }

   wrapText(context, text, x, y, maxWidth, lineHeight) {
      let words = text.split(' '),
         line = '',
         lineCount = 0,
         i,
         test,
         metrics;

      for (i = 0; i < words.length; i++) {
         test = words[i];
         metrics = context.measureText(test);
         while (metrics.width > maxWidth) {
            // Determine how much of the word will fit
            test = test.substring(0, test.length - 1);
            metrics = context.measureText(test);
         }
         if (words[i] != test) {
            words.splice(i + 1, 0, words[i].substr(test.length));
            words[i] = test;
         }

         test = line + words[i] + ' ';
         metrics = context.measureText(test);

         if (metrics.width > maxWidth && i > 0) {
            context.fillText(line, x, y);
            line = words[i] + ' ';
            y += lineHeight;
            lineCount++;
         } else {
            line = test;
         }
      }

      context.fillText(line, x, y);
   }

   async uploadCanvas(img, text) {
      const modalLoader = document.getElementById('statementLoader');
      let canvas = document.getElementById('ppCanvas');
      let context = canvas.getContext('2d');

      modalLoader.style.display = 'block';

      //   canvas.width = img.naturalWidth;
      //   canvas.hidden = img.naturalHeight;

      // Draw text on image.
      let x = img.naturalWidth * 0.5;

      let endY = this.calculateY(text, img.naturalWidth - 60, 50);

      let y = img.naturalHeight * 0.5 - endY * 0.5;

      context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
      context.font = '80pt Helvetica Neue';
      context.fillStyle = 'white';
      context.textAlign = 'center';
      //context.fillText(text, x, y);
      this.wrapText(context, text, x, y, img.naturalWidth - 60, 60);

      let dataURL = canvas.toDataURL('image/png');


      let blobBin = atob(dataURL.split(',')[1]);
      let array = [];
      for (let i = 0; i < blobBin.length; i++) {
         array.push(blobBin.charCodeAt(i));
      }

      let file = new Blob([new Uint8Array(array)], {
         type: 'image/png'
      });
      let fileName = require('uuid').v4() + '.png';

      const data = await this.props.submitStatementImage(this.props.auth, file, fileName);
      this.setState({ personalImages: data });

      modalLoader.style.display = 'none';

      const modal = document.getElementById('stmtModalContainer');
      modal.style.display = 'none';

      const inputElem = document.getElementById('statement-text');
      inputElem.value = '';

      //canvas.style.display = 'block';
   }

   onSubmitUploadStatement(e) {
      e.preventDefault();

      const template1 = document.getElementById('pp-img-template-1');
      const inputElem = document.getElementById('statement-text');

      if (this.state.template1Loaded && template1) {

         let statement = inputElem.value.trim();

         if (statement) {
            this.uploadCanvas(template1, statement);
         }
      }
   }

   onTemplate1Load(e) {
      e.preventDefault();
      this.state.template1Loaded = true;

      let canvas = document.getElementById('ppCanvas');
      let context = canvas.getContext('2d');

      canvas.width = e.target.naturalWidth;
      canvas.height = e.target.naturalHeight;
   }

   renderTemplateImages() {
      const fullpath = makeLegacyCDNUrl('/default/templates/template_statement_1.jpg?t=' + new Date().getTime());

      return (
         <img
            crossOrigin="anonymous"
            id="pp-img-template-1"
            src={fullpath}
            className="pp-stmt-img-card"
            onLoad={this.onTemplate1Load.bind(this)}
         />
      );
   }

   renderError() {
      if (this.state.error) {
         return (
            <p className="font-italic text-danger text-center font-weight-bold">
               {this.state.error}
            </p>
         );
      }
   }

   render() {
      return (
         <div>
            <p className="h4" style={{ marginBottom: 10 }}>
               Create A Statement
            </p>
            <p
               className="fontRegular"
               style={{ opacity: '0.75', marginBottom: 5 }}
            >
               Empower your session with a personal statement or affirmation!
               (Limit to 512 characters.)
            </p>

            <input
               className="form-control"
               style={{ marginBottom: 10 }}
               id="statement-text"
               name="statement"
               label="statement"
               placeholder="I am a Leader"
               type="text"
            />

            <p
               className="fontRegular"
               style={{ opacity: '0.75', marginBottom: 10 }}
            >
               Pick a background image to go with your personal statement.
            </p>

            <div
               className="userImageContainer"
               style={{ width: '100%', minHeight: 150 }}
            >
               {this.renderTemplateImages()}
            </div>
            <canvas id="ppCanvas" style={{ display: 'none' }} />
            <Loader id="statementLoader" />
            <button
               className="btn btn-primary"
               style={{ margin: '10px 0px' }}
               type="submit"
               onClick={this.onSubmitUploadStatement.bind(this)}
            >
               Upload Now
            </button>
         </div>
      );
   }
}

export default connect(
   null,
   actions
)(withRouter(CreateStatement));
