import React from 'react'
import {
    DropdownButton,
    Dropdown,
    Row,
    Container,
    Col,
    Card,
    Spinner,
    Alert,
    Table

} from 'react-bootstrap'
import { ReferalDefaults } from './referalsDefaults'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import 'react-dates/initialize';
import "../../scss/analytics.scss";
import { API } from "../../APIAndConfig";
import { get, set, omitBy, isNil } from "lodash";
import Chart from 'react-google-charts'

var a = [1, 2, 3, 4, 5, 6, 7, 8, 9]

interface propsinterface {
    temp: string,
    userId: string,
}

interface stateinterface {
    titleReferals: string,
    titleDays: string,
    startDate: any,
    endDate: any,
    focusedInput: any,
    totalClicks: number,
    referals: any[],
    graphData: any[],
    tableData: any[],
    isLoading: boolean,
    countByPeriod: number,
    fakeDropdown: string | number,
    customSelect: boolean
}

var SelectedStartDate = moment();
var SelectedEndDate = moment();
var tempGraphData: any[]
var tempTableData: any[]
var data = [['Date', 'Visit Clicks']]

var dataDrop = [1, 2, 3, 4, 6, 6]

class ReferalRangePicker extends React.Component<propsinterface, stateinterface> {

    currentUserId: string = "";

    constructor(props: propsinterface) {
        super(props);

        this.state = {
            titleReferals: "Referral Codes",
            titleDays: ReferalDefaults.ranges.today.label || "",
            focusedInput: null,
            startDate: SelectedStartDate,
            endDate: SelectedEndDate,
            totalClicks: 0,
            referals: new Array(),
            graphData: data,
            tableData: new Array(),
            isLoading: true,
            countByPeriod: 0,
            fakeDropdown: "1",
            customSelect: false
        }
    }


    async initComponent(){
        
        tempTableData = new Array()
        data.splice(1)
        console.log(this.props.userId,"-------");
        const referals = this.props.userId != ""? await API.getReferalAnalysis(this.props.userId) : await API.getReferalAnalysis();
        const referals_result = await get(referals, 'payload.referralCodes')

        const visits = await API.getVisitCounts({
            slug: referals_result[0].referralCode,
            start: moment(this.state.startDate).format("YYYY-MM-DD"),
            end: moment(this.state.endDate).format("YYYY-MM-DD")
        })

        const graphData = await get(visits, 'payload.result')
        if (graphData !== undefined) {
            if (graphData.length > 0) {
                await graphData.map((obj: any, index: number) => {
                    tempGraphData = new Array()
                    tempGraphData.push(moment(obj.DATE).format("YYYY-MM-DD"))
                    tempGraphData.push(Number(obj.Counts))
                    data.push(tempGraphData)
                    tempTableData.push(<tr key={index}>
                        <td className="text-white"> {moment(obj.DATE).format("YYYY-MM-DD")} </td>
                        <td className="text-white"> {Number(obj.Counts)} </td>
                    </tr>)
                })
            }
            else {
                data.splice(1)
                tempTableData = []
            }
        }
        this.setState({
            totalClicks: get(visits, 'payload.totalCount') || 0,
            referals: referals_result,
            titleReferals: referals_result[0].referralCode,
            graphData: data,
            tableData: tempTableData,
            isLoading: false,
            countByPeriod: get(visits, 'payload.periodCount') || 0
        })
    }

    async componentWillMount(): Promise<void> {
        await this.initComponent();
    }

    async componentDidUpdate() {
        if(this.props.userId != this.currentUserId){
            await this.initComponent();
            this.currentUserId = this.props.userId;
        }
    }

    async handleReferals(object: string) {

        this.setState({
            titleReferals: object + "",
            isLoading: true
        })

        const visits = await API.getVisitCounts({
            slug: object,
            start: moment(this.state.startDate).format("YYYY-MM-DD"),
            end: moment(this.state.startDate).isSame(this.state.endDate) ? moment(this.state.endDate).add(1, 'days').format("YYYY-MM-DD") : moment(this.state.endDate).format("YYYY-MM-DD")
        })

        const graphData = await get(visits, 'payload.result')

        if (graphData !== undefined) {
            if (graphData.length > 0) {
                data.splice(1)
                tempTableData = new Array()

                await graphData.map((obj: any, index: number) => {
                    tempGraphData = new Array()
                    tempGraphData.push(moment(obj.DATE).format("YYYY-MM-DD"))
                    tempGraphData.push(Number(obj.Counts))
                    data.push(tempGraphData)
                    tempTableData.push(<tr key={index}>
                        <td className="text-white"> {moment(obj.DATE).format("YYYY-MM-DD")} </td>
                        <td className="text-white"> {Number(obj.Counts)} </td>
                    </tr>)
                })
            }
            else {
                data.splice(1)
                tempTableData = []
            }
        }
        this.setState({
            totalClicks: get(visits, 'payload.totalCount') || 0,
            graphData: data,
            tableData: tempTableData,
            isLoading: false,
            countByPeriod: get(visits, 'payload.periodCount') || 0
        })
    }


    async daysFilterChange(object: string) {

        if (object === "Today") {
            this.setState({
                startDate: moment(new Date()),
                endDate: moment(new Date())
            })
        }

        else if (object === "Yesterday") {
            this.setState({
                startDate: moment().subtract(1, "days"),
                endDate: moment().subtract(1, "days")
            })
        }

        else if (object === "Last 7 Days") {
            this.setState({
                startDate: moment().subtract(6, "days"),
                endDate: moment()
            })
        }

        else if (object === "Last 14 Days") {
            this.setState({
                startDate: moment().subtract(13, "days"),
                endDate: moment(new Date())
            })
        }

        else if (object === "Last 28 Days") {
            this.setState({
                startDate: moment().subtract(27, "days"),
                endDate: moment(new Date())
            })
        }

        else if (object === "This Week") {
            this.setState({
                startDate: moment().startOf('isoWeek').subtract(1, "days"),
                endDate: moment(new Date())
            })
        }

        else if (object === "Last Week") {
            this.setState({
                startDate: moment().subtract(1, 'weeks').startOf('isoWeek').subtract(1, 'days'),
                endDate: moment().subtract(1, 'weeks').endOf('isoWeek').subtract(1, 'days'),
            })
        }

        else if (object === "This Month") {
            this.setState({
                startDate: moment().startOf('month'),
                endDate: moment(new Date())
            })
        }

        else if (object === "Last Month") {
            this.setState({
                startDate: moment().subtract(1, "month").startOf('month'),
                endDate: moment().subtract(1, "month").endOf('month')
            })
        }

        else if (object === "This Year") {
            this.setState({
                startDate: moment().startOf('year'),
                endDate: moment().endOf('year')
            })
        }

        else if (object === "Last Year") {
            this.setState({
                startDate: moment().subtract(1, 'year').startOf('year'),
                endDate: moment().subtract(1, 'year').endOf('year')
            })
        }

    }

    async handleDays(object: string) {

        this.setState({
            titleDays: object + "",
            customSelect: false
        })
        await this.daysFilterChange(object)
        this.handleReferals(this.state.titleReferals)
    }

    onDateChange(sDate: any, eDate: any) {


        this.setState({
            startDate: moment(sDate),
            endDate: moment(eDate),
            titleDays: "Custom Select",
            customSelect: true
        }, () => {
            this.handleReferals(this.state.titleReferals)
        })
    }

    renderDatePicker() {
        return (
            <DateRangePicker
                startDate={moment(this.state.startDate)} // momentPropTypes.momentObj or null,
                startDateId={"stateDate"} // PropTypes.string.isRequired,
                endDate={moment(this.state.endDate)} // momentPropTypes.momentObj or null,
                endDateId={"endDate"} // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => { this.onDateChange(startDate, endDate) }} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }} // PropTypes.func.isRequired,
                isOutsideRange={day => (false)}
                displayFormat={() => "Do MMMM YYYY"}
                minimumNights={0}
            />
        )
    }


    fakeDropdown(obj: string | number) {
        this.setState({
            fakeDropdown: obj
        })
    }

    setReferalActive(obj: any) {
        return this.state.titleReferals === obj ? true : false
    }

    setDaysActive(obj: any) {
        return this.state.titleDays === obj ? true : false
    }

    render() {
        const $loading = <div className={"text-center mt-4"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>;

        return <div className="mb-2">
            <Container>
                <Row className="d-flex justify-content-center">

                    <div className="col-sm-6">
                        <Row>
                            <div className="col-xs-6">
                                <DropdownButton
                                    key="Referrals"
                                    title={`${this.state.titleReferals}`}
                                    size="lg"
                                    className="ml-1 btn btn-block w-100"
                                    id="ReferralCodes"
                                >{this.state.referals.map((obj, index) => (
                                    <Dropdown.Item key={index} eventKey={String(index)} onClick={() => { this.handleReferals(obj.referralCode) }} active={this.setReferalActive(obj.referralCode)}>{`${obj.referralCode}`}</Dropdown.Item>
                                ))}</DropdownButton>
                            </div>

                            <div className="col-xs-6">
                                <DropdownButton
                                    key="Days"
                                    title={`${this.state.titleDays}`}
                                    size="lg"
                                    className="ml-1 btn btn-block w-100"
                                    variant={this.state.customSelect ? "outline-primary" : "primary"}
                                    id="ReferralCodes"
                                >{
                                        (Object.keys(ReferalDefaults.ranges) as Array<keyof typeof ReferalDefaults.ranges>).map((obj, index) => (
                                            <Dropdown.Item key={index} eventKey={String(index)} onClick={() => { this.handleDays(ReferalDefaults.ranges[obj].label) }} active={this.setDaysActive(ReferalDefaults.ranges[obj].label)} >{`${ReferalDefaults.ranges[obj].label}`}</Dropdown.Item>
                                        ), [] as (typeof ReferalDefaults.ranges[keyof typeof ReferalDefaults.ranges])[])
                                    }
                                </DropdownButton>
                            </div>
                        </Row>
                    </div>


                    <div className="col-sm-6 p-2">
                        {this.renderDatePicker()}
                    </div>
                </Row>
            </Container>

            <Row>
                <Col>
                    {this.state.isLoading ? $loading :
                        <Card className="mb-2 card-blur text-white mt-4">
                            <Card.Body>
                                <h3>{this.state.totalClicks}</h3>
                                <h4>All Time Total Clicks</h4>
                            </Card.Body>
                        </Card>
                    }
                </Col>
                <Col>
                    {this.state.isLoading ? $loading :
                        <Card className="mb-2 card-blur text-white mt-4">
                            <Card.Body>
                                <h3>{this.state.countByPeriod}</h3>
                                <h4>Total Clicks For Period</h4>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>

            <Card className="shadow mt-3 card-blur">
                <Card.Body >
                    {this.state.isLoading ? $loading :
                        this.state.graphData.length > 1 ? <Chart
                            height={'400px'}
                            chartType="Line"
                            options={{
                                colors: ['#3498DB'],
                            }}
                            loader={$loading}
                            data={this.state.graphData}

                            rootProps={{ 'data-testid': '3' }}
                        /> :
                            <Alert variant="danger" className="m-5">No data for this period</Alert>
                    }
                </Card.Body>
            </Card>

            <Col className="text-center p-0">
                <Card className="shadow mt-4 card-blur">
                    <Card.Header className="text-white">Visit Clicks</Card.Header>
                    <Card.Body className="p-0">
                        {this.state.isLoading ? $loading :
                            this.state.tableData.length > 0 ?
                                <Table className="">
                                    <thead>
                                        <tr className='text-white'>
                                            <th >Dates</th>
                                            <th>Clicks</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-white'>
                                        {this.state.tableData}
                                    </tbody>
                                </Table> :
                                <Alert variant="danger" className="m-5">No data for this period</Alert>
                        }
                    </Card.Body>
                </Card>
            </Col>

        </div >
    }
}
export default ReferalRangePicker