import React, { useEffect, useState, useContext } from "react";
import {
  getOfflineSessions,
  getFinishedOfflineSessions,
  removeOfflineSession,
} from "../helpers/offlineSession";
import clsx from "clsx";
import SessionCard from "../components/cards/SessionCard";
import { Media_URL } from "../APIAndConfig";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../Routers/DashboardRouter";
import { CATEGORY_ROUTES } from "./Category";
import { useSelector } from "react-redux";
import SessionContext from "../contexts/SessionContext";

const Downloads = () => {
  const [sessions, setSessions] = useState([]);
  const { refresh } = useContext(SessionContext.Context);
  const history = useHistory();
  const networkStatus = useSelector((state) => state.networkStatus);

  useEffect(() => {
    async function fetchData() {
      const result = networkStatus.connected
        ? await getOfflineSessions(false)
        : await getFinishedOfflineSessions();
      setSessions(result);
    }
    fetchData();
  }, [refresh]);

  const handleSessionCardClick = ({ id, category }) => {
    history.push(
      ROUTES.CATEGORY + "/" + category + CATEGORY_ROUTES.PLAY + "/" + id
    );
  };

  const downloadsSection = (
    <div className={"row"}>
      {sessions.map((session) => {
        return (
          <div className={clsx("col-lg-3", "col-sm-12")} key={session.id}>
            <SessionCard
              onClick={() => handleSessionCardClick(session)}
              id={session.id}
              title={session.name}
              author={session.author ? session.author.fullName : ""}
              img={
                session.thumbnail
                  ? process.env.REACT_APP_CDN + "/" + session.thumbnail
                  : session.images[0] && session.images[0].media_url
                  ? Media_URL + "/" + session.images[0].media_url
                  : "https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png"
              }
              categoryId={1}
              viewCount={
                session.sessionCount ? session.sessionCount : undefined
              }
            />
          </div>
        );
      })}
    </div>
  );

  const emptyDownloads = (
    <div className={"row"}>
      <div className="col-lg-3">No sessions found.</div>
    </div>
  );

  return (
    <>
      <div className={clsx("container", "wellness-collection")}>
        <div className={"wellness-collection__header"}>
          <h2
            className={clsx("wellness-collection__header-title", "main-title")}
          >
            Downloads
          </h2>
        </div>
        <div className={"wellness-collection__main"}>
          {!!sessions.length ? downloadsSection : emptyDownloads}
        </div>
      </div>
    </>
  );
};

export default Downloads;
