import React from 'react';
import clsx from 'clsx';
import Collection from './categoryView/Collection';
import Play from './categoryView/Play';
import Session from './categoryView/Session';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from '../Routers/DashboardRouter';
export const CATEGORY_ROUTES = {
    MAIN: '',
    SESSION: '/session',
    PLAY: '/play'
}
function Category(props) {
    const categories = props.categories
    return (
        <div className={clsx('wellness-container,container')}>
            <Switch>
                <Route exact path={ROUTES.CATEGORY + CATEGORY_ROUTES.MAIN + '/:categoryId'} render={(props) => <Collection categories={categories} {...props}/>}/>
                <Route exact path={ROUTES.CATEGORY + '/:categoryId' + CATEGORY_ROUTES.SESSION + '/:sessionId'} component={Session} />
                <Route exact path={ROUTES.CATEGORY + '/:categoryId' + CATEGORY_ROUTES.PLAY + '/:sessionId'} component={Play} />
            </Switch>
        </div>
    )
}
export default Category;