import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Firebase } from '../../firebase';
import * as actions from '../../actions';
import './SessionDetails.css';

import Loader from '../common/Loader';
import { makeAssetsUrl, makeLegacyCDNUrl, makePlayerImageUrl, makeProfileImageUrl } from "../../APIAndConfig";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SessionDetails extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null,
         purchased: false
      };

      this.ownSession = this.ownSession.bind(this);
   }

   componentDidMount() {
      this.props.resetError();
      let product_id = parseInt(this.props.match.params._product_id);
      if (isNaN(product_id)) {
         this.props.history.push('/notFound');
         return;
      }

      Firebase.Auth.onAuthStateChanged(async authUser => {
         if (authUser) {
            await this.props.fetchProductByProductId(
               this.props.auth,
               this.props.match.params._product_id
            );
            this.renderScreenShots();

            if (this.props.auth) {
               await this.props.fetchPurchasesByUserId(this.props.auth.id);

               await this.props.fetchUserOwners(this.props.auth);

            }
         } else {
            this.props.history.push('/login');
         }
      });
   }

   async componentDidUpdate() {
      if (this.props.auth) {
         if (!this.props.purchasedSessions) {
            await this.props.fetchPurchasesByUserId(this.props.auth.id);

         }

         if (!this.props.userOwners) {
            await this.props.fetchUserOwners(this.props.auth);

         }
      }
   }

   componentWillUnmount() {
      this.props.resetError();
      this.setState({ purchased: false });
   }

   onOpenNav(e) {
      document.getElementById('sidebar').classList.add('sidebarOpen');
   }

   onModalClose(e) {
      e.preventDefault();
      const modal = document.getElementById('purchaseModal');
      modal.style.display = 'none';
   }

   onModalOpen(e) {
      e.preventDefault();
      const modal = document.getElementById('purchaseModal');
      modal.style.display = 'block';
   }

   async onPurchase(e) {
      e.preventDefault();
      const modal = document.getElementById('purchaseModal');
      const modalLoader = document.getElementById('modalLoader');
      modalLoader.style.display = 'block';
      await this.props.purchaseProduct(
         this.props.auth,
         this.props.productDetail.product
      );

      modalLoader.style.display = 'none';

      let display = `Thank you for purchasing ${this.props.productDetail.product.title
         }! You can watch this session now!`;
      const displayElem = document.getElementById('purchaseMessage');
      displayElem.innerHTML = display;
      const watchBtn = document.getElementById('watchBtn');
      const buyBtn = document.getElementById('buyBtn');
      const creditLink = document.getElementById('creditLink');

      if (buyBtn) {
         buyBtn.style.display = 'none';
      }

      if (watchBtn) {
         watchBtn.style.display = 'block';
      }

      if (creditLink) {
         creditLink.style.display = 'none';
      }
      this.setState({ purchased: true });
   }

   onWatch(e) {
      e.preventDefault();
      const modal = document.getElementById('purchaseModal');
      modal.style.display = 'none';
      if (
         this.props.productDetail.sessions &&
         this.props.productDetail.sessions.length > 0
      ) {
         this.props.history.push(
            `/images/${this.props.productDetail.sessions[0].id}?runtime=${this.props.productDetail.sessions[0].duration
            }`
         );
      }
   }

   ownSession(session) {
      const { purchasedSessions, userOwners } = this.props;
      if (purchasedSessions) {
         for (let i = 0; i < this.props.purchasedSessions.length; ++i) {
            if (session.id === purchasedSessions[i].id) {
               return true;
            }
         }
      }

      if (userOwners) {
         for (let i = 0; i < this.props.userOwners.length; ++i) {
            if (session.author === userOwners[i].id) {
               return true;
            }
         }
      }

      return false;
   }

   renderPurchaseModal() {
      if (!this.props.auth) return <div />;

      const { product } = this.props.productDetail;
      let display = `Get ${product.title} for ${product.cost} credits`;

      let userCredits = parseInt(this.props.auth.credits);
      let productCost = parseInt(product.cost);
      let showBuyBtn = true;
      let $buyBtn = (
         <button
            id="buyBtn"
            className="btn btn-primary"
            style={{ margin: '10px 0px' }}
            type="submit"
            onClick={this.onPurchase.bind(this)}
         >
            Buy
         </button>
      );

      let $creditLink = (
         <small id="creditLink" className="text-muted">
            <a href="/topup">Purchase Credits</a>
         </small>
      );

      if (
         isNaN(userCredits) ||
         isNaN(productCost) ||
         userCredits < productCost
      ) {
         display = `The session cost ${product.cost
            } credits. Please add more credits to your account.`;
         showBuyBtn = false;
      }

      return (
         <div id="purchaseModal" className="purchaseModal ">
            <div className="purchaseModalContent pp-animate-bottom">
               <span className="close" onClick={this.onModalClose.bind(this)}>
                  &times;
               </span>

               <center>
                  <p id="purchaseMessage">{display}</p>
               </center>

               <Loader />
               <center>
                  {showBuyBtn ? $buyBtn : $creditLink}

                  <button
                     id="watchBtn"
                     className="btn btn-primary"
                     style={{ margin: '10px 0px', display: 'none' }}
                     type="submit"
                     onClick={this.onWatch.bind(this)}
                  >
                     Watch
                  </button>
               </center>
            </div>
         </div>
      );
   }

   renderSearchBar() {
      return (
         <div className="px-3 pb-3">
            <form>
               <div className="d-flex">
                  <select
                     className="custom-select searchAll bg-transparent"
                     id="exampleFormControlSelect1"
                  >
                     <option defaultValue>All</option>
                     <option>Category</option>
                  </select>
                  <input
                     type="text"
                     id="searchBar"
                     className="form-control"
                     placeholder="Search"
                     aria-label="Text input with dropdown button"
                  />
                  <div className="input-group-append">
                     <button
                        id="searchButton"
                        className="btn btn-primary"
                        type="button"
                     >
                        Search
                     </button>
                  </div>
               </div>
            </form>
         </div>
      );
   }

   renderScreenShotsPlaceHolder() {
      return (
         <div className="carousel-inner">
            <div className="carousel-item active">
               <div className="row m-0">
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
               </div>
            </div>
            <div className="carousel-item">
               <div className="row m-0">
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
               </div>
            </div>
            <div className="carousel-item">
               <div className="row m-0">
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="../assets/images/Girl.jpg"
                     />
                  </div>
                  <div className="col-4">
                     <img
                        className="imageContainer"
                        src="https://s3-us-west-2.amazonaws.com/my-test-bucket-123456/1/sessions/c783cbef-c6b1-4a05-994d-d1dff045050c.png"
                     />
                  </div>
               </div>
            </div>
         </div>
      );
   }

   renderScreenShots() {
      if (!this.props.auth && !this.props.productDetail.product) {
         return;
      }

      let { screen_shot_list } = this.props.productDetail;

      let element = '',
         mainElement = '';

      let firstSet = true;

      for (let i = 0; i < screen_shot_list.length; ++i) {
         if (i % 3 === 0 && i !== 0) {
            if (firstSet) {
               mainElement += `<div class="carousel-item active"><div class="row m-0">${element}</div></div>`;
            } else {
               mainElement += `<div class="carousel-item"><div class="row m-0">${element}</div></div>`;
            }

            element = '';
            firstSet = false;
         }

         const url = makePlayerImageUrl(screen_shot_list[i])
         element += `<div class="col-4 p-0">
            <img class="screenshot-imageContainer px-0 px-sm-2" src="${url}"/>
         </div>`;
      }

      if (element.length > 0) {
         if (firstSet) {
            mainElement += `<div class="carousel-item active"><div class="row m-0">${element}</div></div>`;
         } else {
            mainElement += `<div class="carousel-item"><div class="row m-0">${element}</div></div>`;
         }
      }

      const carousel = document.getElementById('productCarousel');
      if (carousel) {
         carousel.innerHTML = mainElement;
      }

   }

   render() {
      if (!this.props.auth && !this.props.productDetail.product) {
         return <div />;
      }

      const { product, sessions } = this.props.productDetail;
      //console.log(product);
      if (!product || product === 'undefined') {
         return <div />;
      }

      let $productBtn = (
         <button
            className="btn btn-outline-light"
            onClick={this.onModalOpen.bind(this)}
         >
            Get it now
         </button>
      );

      if (sessions && sessions.length > 0 && this.props.auth) {
         if (
            sessions[0].author === this.props.auth.id ||
            this.props.auth.subscription?.plan.nickname == 'signature-monthly' ||
            this.props.auth.subscription?.plan.nickname == 'signature-yearly' ||
            this.props.auth.subscription?.plan.nickname == 'pro-monthly' ||
            this.props.auth.subscription?.plan.nickname == 'pro-yearly' ||
            this.props.auth.subscription?.plan.nickname == 'vip-discount' ||
            this.props.auth.subscription?.plan.nickname == 'vip-yearly' ||
            this.ownSession(sessions[0]) ||
            this.state.purchased
         ) {
            $productBtn = (
               <div>
                  <p className="text-white">You own this session</p>
                  <button
                     className="btn btn-outline-light"
                     onClick={() => {
                        this.props.history.push(
                           `/images/${this.props.productDetail.sessions[0].id
                           }?runtime=${this.props.productDetail.sessions[0].duration
                           }`
                        );
                     }}
                  >
                     Watch
                  </button>
               </div>
            );
         }
      }

      let featuredImg = makePlayerImageUrl(product.featured_bg_image_url);

      let authorName = '';
      if (product.author_first_name || product.author_last_name) {
         authorName =
            product.author_first_name + ' ' + product.author_last_name;
      }

      if (authorName.trim().length <= 0) {
         authorName = '';
      }


      let imageProfileSrc =
         product.author_image_url ?
            makeProfileImageUrl(product.author_image_url) : makeAssetsUrl('user_default.png');


      return (
         <div className="mainWidth">
            <div className="d-flex">
               <button
                  className="btn bg-transparent d-md-none"
                  onClick={this.onOpenNav.bind(this)}
               >
                  <FontAwesomeIcon icon={faBars} />
               </button>
               <p className="text-secondary m-0 px-0 py-3 px-md-3">
                  Session Details
               </p>
            </div>
            <hr className="m-0" />
            <div className="p-0 px-sm-5 pt-sm-5">
               <div className="position-relative">
                  <img className="imageContainer grayscale" src={featuredImg} />
                  <div className="overlay overlayBlue" />
                  <div className="imageCaption mx-0 px-4 mx-sm-5 px-sm-5">
                     <p className="imageCaptionAuthor text-white">
                        {authorName}
                     </p>
                     <p className="imageCaptionTitle text-white m-0">
                        {this.props.productDetail.product.title}
                     </p>
                     <p className="imageCaptionDesc text-white d-none d-sm-block">
                        {product.short_description}
                     </p>
                     {$productBtn}
                  </div>
               </div>
            </div>
            <div className="p-4 px-sm-5 pt-sm-5">
               <p className="h5">Description</p>
               {/* <p className="text-secondary">{product.description}</p> */}
               <p
                  dangerouslySetInnerHTML={{ __html: product.description }}
                  className="text-secondary"
               />
            </div>
            <div className="p-4 p-sm-5 bg-white">
               <div className="d-flex">
                  <img
                     className="imageSessionAuthor rounded-circle shadow"
                     src={imageProfileSrc}
                  />
                  <div className="ml-4">
                     <p className="h5 m-0">About {authorName}</p>
                     <p className="text-secondary">{product.author_bio}</p>
                  </div>
               </div>
               <div />
            </div>
            <div className="p-4 px-sm-5 pt-sm-5 pb-sm-3">
               <p className="h5">Screenshots</p>
            </div>
            <div className="px-0 px-sm-2 pb-5">
               <div
                  id="carouselScreenshots"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="false"
               >
                  <div id="productCarousel" className="carousel-inner" />

                  {/* {this.renderScreenShotsPlaceHolder()} */}
                  <a
                     className="carousel-control-prev"
                     href="#carouselScreenshots"
                     role="button"
                     data-slide="prev"
                  >
                     <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                     />
                     <span className="sr-only">Previous</span>
                  </a>
                  <a
                     className="carousel-control-next"
                     href="#carouselScreenshots"
                     role="button"
                     data-slide="next"
                  >
                     <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                     />
                     <span className="sr-only">Next</span>
                  </a>
               </div>
            </div>
            {this.renderPurchaseModal()}
            <br />
            <br />
         </div>
      );
   }
}

function mapStateToProps(state) {
   return {
      auth: state.auth,
      productDetail: state.productDetail,
      purchasedSessions: state.purchasedSessions,
      userOwners: state.userOwners
   };
}

export default connect(
   mapStateToProps,
   actions
)(SessionDetails);
