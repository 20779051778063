import React, { useEffect, useRef, useState } from 'react';
import './embedModal.css';
import beautify from 'js-beautify'
import { APIAxios, Config } from "../../APIAndConfig";
import { Firebase } from "../../firebase";
import axios from "axios";
import { set } from 'lodash';

const EmbedModal = (props) => {
   const Preview = useRef(null)
   const { setOpen, selected } = props

   const [border, setBorder] = useState(false);
   const [shadow, setShadow] = useState(false);
   const [width, setWidth] = useState(640);
   const [show, setShow] = useState(false)
   const [embedCode, setEmbedCode] = useState(null)

   useEffect(() => {
      const fetchEmbedCode = async () => {
         const token = await Firebase.Auth.currentUser.getIdToken();

         const header = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + token
         };

         const code = await axios.get(
            `${Config.APIUrl}/userEmbed/${selected.id}/preference`,
            {
               headers: header
            }
         ).then(res => res.data.data).catch(async (err) => {
            switch (err.response.data) {
               case "SESSION_EXPIRED":
                  const token = await Firebase.Auth.currentUser?.getIdToken();
                  if (token) {
                     await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                     window.location.reload()
                 }
                  break;
               case "BAD_CONNECTION":
                  case "UNCAUGHT_ERROR":
               case "DATABASE_LIMIT_EXCEEDED":
                  localStorage.setItem('service_unavailable_status', "failed");
                  await Firebase.Auth.signOut();
                  window.location.href = "/error"
                  break;
               default:
                  console.log("Error Occured While fetching category data ", err);
                  break;
            }
         })

         if (code !== null) {

            let exWidth = code.substring(258, 300).match(/(\d+)/);
            setWidth(Number(exWidth[0]))

            if (code.includes('.embed-container{box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.15);border: .5rem solid white; }')) {
               setBorder(true)
               setShadow(true)
            }
            else if (code.includes('.embed-container{box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.15);}')) {
               setShadow(true)
            }
            else if (code.includes('.embed-container{border: .5rem solid white; }')) {
               setBorder(true)
            }
         }
      }

      fetchEmbedCode()
   }, [])


   useEffect(() => {

      let newCode = `<style>.embed-container {position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }.embed-parent{max-width:${width}px;max-height:360px;margin:0 auto}${border && !shadow ? `.embed-container{border: .5rem solid white; }` : border && shadow ? `.embed-container{box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.15);border: .5rem solid white; }` : !border && shadow ? `.embed-container{box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.15);}` : ''}</style><div class="embed-parent"><div class='embed-container'><iframe src='https://experience.positiveprime.com/embed/${selected.key}' style='border:0' scrolling="no"></iframe></div></div>`
      setEmbedCode(newCode)

      if (Preview.current !== null) {

         let prevField = Preview.current
         var prevDiv = document.createElement('div')
         prevDiv.className = "prevDiv"
         prevDiv.innerHTML = newCode
         while (prevField.firstChild) {
            prevField.removeChild(prevField.lastChild);
         }
         prevField.appendChild(prevDiv)
      }
   }, [shadow, border, width, embedCode, selected])


   const handleSave = async () => {

      const token = await Firebase.Auth.currentUser.getIdToken();

      const header = {
         "Content-Type": "application/json",
         "Access-Control-Allow-Origin": "*",
         Authorization: "Bearer " + token
      };

      let data = {
         codePref: embedCode
      }

      const results = await axios.post(
         `${Config.APIUrl}/userEmbed/${selected.id}/savePreference`,
         data,
         {
            headers: header
         }
      ).then(res => window.location.reload()).catch(
         async (err) => {
            switch (err.response.data) {
               case "SESSION_EXPIRED":
                  const token = await Firebase.Auth.currentUser?.getIdToken();
                  if (token) {
                     await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                     window.location.reload()
                 }
                  break;
               case "BAD_CONNECTION":
                  case "UNCAUGHT_ERROR":
               case "DATABASE_LIMIT_EXCEEDED":
                  localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
                  window.location.href = "/error"
                  break;
               default:
                  console.log("Error ", err);
                  break;
            }
         });
   }

   const formatString = (code) => {
      let cssFormatter = beautify.css
      let htmlFormatter = beautify.html
      let cssRes = cssFormatter(code, { indent_size: 2, space_in_empty_paren: true })
      let htmlRes = htmlFormatter(cssRes, { indent_size: 2, space_in_empty_paren: true, extra_liners: ["iframe"] })
      return htmlRes
   }

   const copyURL = () => {
      navigator.clipboard.writeText(embedCode).then(function () {
         console.log('Async: Copying to clipboard was successful!');
      }, function (err) {
         console.error('Async: Could not copy text: ', err);
      });
   }

   return (
      <div className="embedModal">
         <div className="d-flex align-items-center justify-content-center h-100">
            <div className="embedModalContent pp-animate-bottom shadow">
               <p className="modalClose text-right"
                  onClick={() => setOpen(false)}>
                  &times;
               </p>
               <div className="py-4">
                  <p className="h4 m-0 px-0 text-dark" style={{ textAlign: 'center' }}>Experience Code</p>
               </div>

               <div className="select-styles">
                  <div className="option">
                     <input type="checkbox" checked={shadow}
                        onChange={() => setShadow(!shadow)} />
                     <label>Shadow</label>
                  </div>
                  <div className="option">
                     <input type="checkbox" checked={border}
                        onChange={() => setBorder(!border)} />
                     <label>Border</label>
                  </div>
                  <div className="option">
                     <label className="show-btn" onClick={() => setShow(!show)}>Click to select max width</label>
                     {show && (<input value={width} onChange={(e) => setWidth(e.target.value)} />)}
                  </div>
                  <div className="option">
                     <label className="show-btn" onClick={handleSave}>Save Embed</label>
                  </div>
               </div>

               <div className="code-field">
                  <div className="code-part">
                     <pre>
                        <code>{formatString(embedCode)}</code>
                     </pre>

                     <button onClick={copyURL} className="copy-btn">
                        Copy Experience Code
                     </button>
                  </div>
                  <div className="preview-container" >
                     <div ref={Preview}></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default EmbedModal;
