import {FETCH_USER_OWNERS_SESSION_HISTORY_YEARS} from "../actions/types";

export default function(state = null, action) {
  switch (action.type) {
    case FETCH_USER_OWNERS_SESSION_HISTORY_YEARS:
      return action.payload || null;
    default:
      return state;
  }
}
