import React, { Component } from 'react'
import {
	BrowserRouter,
	Route,
	RouteComponentProps,
	Switch,
	useHistory,
	Redirect,
	useLocation,
	withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { get, set, unset } from 'lodash'

import * as actions from '../actions'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { Firebase } from '../firebase'
import Header from './Header'
import SideBar from './SideBar'
import Footer from './Footer'
import Loader from './common/Loader'
// import SurveyThankYou from './SurveyThankYou';
// import SurveyNew from './surveys/SurveyNew';
import SignUp from './login/SignUp'
import Login from './login/Login'
import ImagePlayer from './imagePlayer/ImagePlayer'

//TODO: move this into an index to be exported.
import Dashboard from './navigation/Dashboard'
import Shop from './navigation/Shop'
import Sessions from './navigation/Sessions'
import Favorites from './navigation/Favorites'
import Settings from './navigation/Settings'

import Users from './navigation/Users'
import SessionDetails from './shop/SessionDetails'
import Privacy from './static/Privacy'
import Terms from './static/Terms'
import UpgradeAccount from './navigation/UpgradeAccount'

import ShopSearchResults from './navigation/ShopSearchResults'
import NotFound from './NotFound'

import ForgetPassword from './login/ForgetPassword'

import SubHeader from './SubHeader'
import UserDetails from './users/UserDetails'
import EditSession from './sessions/EditSession'
import { Unauthenticated } from './layout/Unauthenticated'
import { Alert, Col, Row, Spinner } from 'react-bootstrap'
import { AnalyticsDashboard } from './analytics/AnalyticsDashboard'
import { SessionAnalytics } from './analytics/SessionAnalytics'
import functions, { User } from 'firebase'
import { Userpilot } from 'userpilot'

import { Splash } from './Splash'
import { API, APIAxios, Config } from '../APIAndConfig'
import { TopUp } from './navigation/TopUp'
import { Affiliate } from './navigation/Affiliate'

import referalAnalysis from './referalAnalysis/referalAnalysis'
import { PaymentLedger } from './UserManagement/ledger'
import { PayoutLedger } from './UserManagement/payoutLedger'
import { UserManagement } from './UserManagement/userManagement'
import AdminDashboard from './AdminDashboard/AdminDashboard'
import SignupWithYearly from './login/SignupWithYearly'
import Authentication from './login/authentication'
import SignupWithTrial from './login/SignupWithTrial'
import DashboardRouter from '../Routers/DashboardRouter'
import UpgradeAccountLife from './navigation/UpgradeAccountLife'
import LoginV2 from './login/LoginV2'
import LoginV3 from './login/LoginV3'
import ForgetPasswordV2 from './login/ForgetPasswordV2'
import InviteSignup from './login/InviteSignup'
import SignUpB from './login/SignUpB'
import SignUpBn from './login/SignUpBn'
import ServiceUnavailable from '../views/ServiceUnavailable'
import SignUp10Yearly from './login/Signup10Yearly'
import SignUpNoTrial from './login/SignUpNoTrial'
import CoachingSignup from './login/coachingSignup'
import SignupThanksgivingTrial from './login/SignupThanksgivingTrial'
import SignUpCp from './login/SignUpCp'
import Day30ChallengeAuto from './login/Day30ChallengeAuto'
import Day30ChallengeToUpgrade from './login/Day30ChallengeToUpgrade'
import Day365Challenge from './login/Day365Challenge'
import Promo from './login/RegistrationPromo'
import WillPowerSignup from './login/willpowerSignup'
import {
	createStore,
	getOfflineStartUserWatchSession,
	getOfflineStartUserWatchSessionIds,
	getOfflineSurveyAfterSession,
	getOfflineSurveyBeforeSession,
	getSessionPauseTime,
	removeOfflineStartUserWatchSessionId,
} from '../helpers/offlineSession'

import { SplashScreen } from '@capacitor/splash-screen'
import { Network } from '@capacitor/network'
import moment from 'moment'
import SessionContext from '../contexts/SessionContext'
import { AppTrackingTransparency } from 'capacitor-ios-app-tracking'

// import Orientation from "../components/orientation/orientation"
// Create an element on the fly
//const ImagePlayer = () => <h2>Image Player</h2>;
// import Landing from './Landing';
var CryptoJS = require('crypto-js')

library.add(faStroopwafel)
library.add(faFacebook)

export interface AppProps {
	auth?: any
	fetchUser?: Function
	networkStatus: NetworkStatus
	setNetworkStatus: Function
	surveyBeforeSession: Function
	surveyAfterSession: Function
	endUserWatchSession: Function
	startUserWatchSession: Function
	sessionSurvey: any
}

export interface AppState {
	isConfigLoaded: boolean
	isAuthLoaded: boolean
	authenticated?: boolean
	user?: User | null
	categories: object[]
	loading: boolean
}

declare global {
	interface Window {
		userpilot: any
	}
}

interface NetworkStatus {
	connected: boolean
	connectionType: 'wifi' | 'cellular' | 'none' | 'unknown'
}

interface NetworkStatus {
	connected: boolean
	connectionType: 'wifi' | 'cellular' | 'none' | 'unknown'
}

class App extends Component<AppProps, AppState> {
	constructor(props: AppProps) {
		super(props)
		this.state = Object.assign(this.state || {}, {
			isReady: false,
		})
		Firebase.Auth.onAuthStateChanged(this.handleAuthChange.bind(this))
	}

	// This will be where we want to initialize data
	async componentDidMount(): Promise<void> {
		if (window.location.search.substr(1).split('=')[0] === 'code') {
			if (
				CryptoJS.AES &&
				typeof window.location.search.substr(1).split('=')[1] !== 'undefined'
			) {
				let data = CryptoJS.AES.decrypt(
					CryptoJS.enc.Utf8.parse(
						window.location.search.substr(1).split('=')[1]
					),
					process.env.REACT_APP_MAIL_UUID
				).toString(CryptoJS.enc.Utf8)
				try {
					data = JSON.parse(data)
					if (
						window.location.pathname.includes('inviteSignup') &&
						data.uniqueKey === process.env.REACT_APP_INVITES_UNIQUE_KEY
					) {
						await Firebase.Auth.signOut()
					}
				} catch (error) {
					console.log('error here')
				}
			}
		}
		await this.loadFirebaseConfig()
		this.loadAPIConfig()
		await createStore()
		await SplashScreen.hide()
		console.log('componentDidMount')
		const networkStatus = await Network.getStatus()
		this.props.setNetworkStatus(networkStatus)

		Network.addListener(
			'networkStatusChange',
			async (status: NetworkStatus) => {
				this.props.setNetworkStatus(status)

				if (!status.connected) {
					window.location.href = '/downloads'
				} else {
					window.location.href = '/'
				}
			}
		)

		AppTrackingTransparency.getTrackingStatus().then((status) => {
			console.log(status, '----')
		})

		AppTrackingTransparency.requestPermission().then((status) => {
			console.log(status, '----')
		})
	}

	componentWillUnmount() {
		Network.removeAllListeners()
	}

	async loadFirebaseConfig() {
		if (Firebase.RemoteConfig) await Firebase.RemoteConfig.fetchAndActivate()
		Config.parseFirebase()
	}

	loadAPIConfig() {
		if (!this.props.networkStatus.connected && this.isValidLoggedIn()) {
			// fake flag
			this.setState({ isConfigLoaded: true })

			return false
		}

		const loop = this.loadAPIConfig.bind(this)
		const done = this.configLoaded.bind(this)
		;(async function () {
			if (await Config.fetchFromAPI()) return done()
			else setTimeout(loop, 1000)
		})()
	}

	async configLoaded() {
		await this.setState({ isConfigLoaded: true })
	}

	isValidLoggedIn() {
		const lastLoggedIn = JSON.parse(
			localStorage.getItem('last_logged_in') || '{}'
		)
		const isValidLogin = moment().isBefore(
			moment(lastLoggedIn.date).add(7, 'd')
		)

		return isValidLogin
	}

	async handleAuthChange(user: User | null) {
		await this.setState({
			isAuthLoaded: true,
			authenticated: undefined,
			user: user,
		})
		await this.authenticate()
	}

	async authenticate(): Promise<boolean | undefined> {
		let search = window.location.search
		let params = new URLSearchParams(search)
		let authToken = params.get('authToken')

		if (!this.props.networkStatus.connected) return false

		if (authToken) await Firebase.Auth.signInWithCustomToken(authToken)

		const token = this.state.user
			? await this.state.user.getIdToken()
			: undefined
		if (token)
			set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`)
		else unset(APIAxios.defaults.headers, 'authorization')

		const result = await API.authenticate()

		if (
			result.status === 401 &&
			(result.payload === 'BAD_CONNECTION' ||
				result.payload === 'UNCAUGHT_ERROR' ||
				result.payload === 'DATABASE_LIMIT_EXCEEDED')
		) {
			localStorage.setItem('service_unavailable_status', 'failed')
			await Firebase.Auth.signOut()
			window.location.href = '/error'
		} else {
			if (result.status === 400 || result.status === 404) {
				await Firebase.Auth.signOut()
			} else if (!result.successful) {
				//retry authentication again in 2 seconds
				setTimeout(this.authenticate.bind(this), 2500)
				return
			}

			let userId = get(result.payload, 'userId')
			let role = get(result.payload, 'role')
			let authenticated: boolean = userId ? true : false

			this.setState({
				authenticated: authenticated,
			})
			if (authenticated) {
				await this.loadCurrentUser()

				const lastLoggedIn = {
					humanise: moment(new Date()).format('D/MM/YYYY'),
					date: new Date().getTime(),
				}
				localStorage.setItem('last_logged_in', JSON.stringify(lastLoggedIn))
			}
		}
	}

	async loadCurrentUser() {
		if (!this.state.isAuthLoaded || !this.state.isConfigLoaded) {
			setTimeout(() => {
				this.loadCurrentUser()
			}, 500)
			return
		}

		const user = Firebase.Auth.currentUser
		const token = user ? await user.getIdToken() : undefined

		if (this.props.fetchUser) {
			await this.props.fetchUser(token)

			const syncBeforeAndAfterSurveySession = async () => {
				this.setState({ loading: true })
				const userWatchSessionIds = await getOfflineStartUserWatchSessionIds()
				const sessionIds = Object.keys(userWatchSessionIds || {})

				for (let i = 0; i < sessionIds.length; i++) {
					const sessionId = sessionIds[i]
					const { currentEmotion } = await getOfflineSurveyBeforeSession(
						sessionId
					)

					const survey = await this.props.surveyBeforeSession(
						this.props.auth.id,
						sessionId,
						currentEmotion
					)

					const userWatchHistory = await this.props.startUserWatchSession(
						this.props.auth,
						sessionId,
						survey
					)

					const surveyAfterSession = await getOfflineSurveyAfterSession(
						sessionId
					)
					if (surveyAfterSession) {
						await this.props.surveyAfterSession(
							this.props.auth.id,
							surveyAfterSession.sessionId,
							this.props.sessionSurvey.id,
							surveyAfterSession.currentEmotion
						)
					}

					const sessionPauseTime = await getSessionPauseTime(sessionId)
					if (!!Object.keys(sessionPauseTime).length) {
						await this.props.endUserWatchSession(
							this.props.auth,
							sessionId,
							userWatchHistory,
							false,
							moment()
								.subtract('seconds', sessionPauseTime.pauseTime.time / 1000)
								.toISOString()
						)
					}

					await removeOfflineStartUserWatchSessionId(sessionId)
				}

				this.setState({ loading: false })
			}

			await syncBeforeAndAfterSurveySession()

			if (this.props.auth && Config.UserpilotAppToken) {
				Userpilot.identify(this.props.auth.id, {
					name: this.props.auth.firstName + ' ' + this.props.auth.lastName,
					email: this.props.auth.email,
					created_at: Date.parse(this.props.auth.created),
				})
			}
			await this.getAllCategory()
		}
	}

	async getAllCategory() {
		await API.getCategories().then((res: any) => {
			if (res.successful) {
				this.setState({
					categories: res.payload.categories,
				})
			} else {
				this.getAllCategory()
				console.log('Error in fetching sessions : ', res)
			}
		})
	}

	renderOfflineInvalidLogin() {
		return (
			<Row className="alertWrap m-0 p-0 h-100 min-vh-100">
				<Col className="d-flex flex-column justify-content-center align-items-center text-center">
					<Alert className="m-auto px-5 py-3" variant="primary">
						<Alert.Link>
							You will need to connect to the internet to re-authenticate
						</Alert.Link>
					</Alert>
				</Col>
			</Row>
		)
	}

	renderAuthenticated() {
		const props = this.props
		if (!this.props.auth) {
			return (
				<Row className="alertWrap m-0 p-0 h-100 min-vh-100">
					<Col className="d-flex flex-column justify-content-center align-items-center text-center">
						<Alert className="m-auto px-5 py-3" variant="primary">
							Something has gone wrong. <br /> Try
							<Alert.Link
								onClick={function () {
									if (!!document?.location) {
										document.location.reload()
									}
								}}
							>
								refreshing the page
							</Alert.Link>
							.
						</Alert>
					</Col>
				</Row>
			)
		}

		return (
			<div>
				<BrowserRouter>
					<Route
						path="/"
						render={({ location }) => {
							if (Firebase.Analytics)
								Firebase.Analytics.logEvent('page_view', {
									page_path: location.pathname + location.search,
									page_location: document.location.href,
								})

							if (Config.UserpilotAppToken) {
								if (!!window?.userpilot) {
									window.userpilot.reload()
								}
							}

							return null
						}}
					/>
					<div id="modalLoader">
						<Loader />
					</div>
					<div>
						<SubHeader />
						<SideBar />
						<Switch>
							{/* <Route exact path="/" component={Landing} /> */}
							{/* <Route path="/orientations" component={Orientation} /> */}
							<Route path="/embed/:key" component={embedRedirect} />
							<Route exact path="/v1" component={Dashboard} />
							<Route exact path="/v1/dashboard" component={Dashboard} />
							<Route path="/v1/shop" component={Shop} />
							<Route
								path="/v1/shopSearchResults"
								component={ShopSearchResults}
							/>
							<Route path="/v1/sessions" component={Sessions} />
							<Route path="/v1/favorites" component={Favorites} />
							<Route path="/v1/purchaseCredits" component={topUpRedirect} />
							<Route path="/v1/topup" component={TopUp} />
							{<Route path="/v1/referral" component={Affiliate} />}

							{<Route path="/v1/referralGraphs" component={referalAnalysis} />}

							<Route path="/v1/settings" component={Settings} />
							{/* <Route path="/v1/help" component={Help} /> */}
							<Route path="/v1/privacy" component={Privacy} />
							<Route path="/v1/terms" component={Terms} />
							<Route path="/v1/upgradeAccount" component={UpgradeAccount} />
							<Route
								path="/v1/upgradeAccountLife"
								component={UpgradeAccountLife}
							/>
							{/* <Route path="/v1/sandbox" component={Sandbox} /> */}

							<Route path="/v1/users" component={Users} />
							<Route path="/v1/userDetails" component={UserDetails} />

							{/* <Route path="/v1/surveys/new" component={SurveyNew} />
                    <Route path="/v1/surveys/thanks" component={SurveyThankYou} /> */}
							<Route path="/v1/signup">
								<Redirect to="/v1/dashboard" />
							</Route>

							<Route path="/v1/login">
								<Redirect to="/v1/dashboard" />
							</Route>

							<Route path="/v1/forgetPassword">
								<Redirect to="/v1/dashboard" />
							</Route>

							<Route exact path="/v1/images/:_id" component={ImagePlayer} />
							<Route
								path="/v1/session/edit/:_session_id"
								component={EditSession}
							/>
							<Route
								exact
								path="/v1/productDetail/:_product_id"
								component={SessionDetails}
							/>

							{/* <Route
                                exact path="/v1/orientation" component={Orientation}
                            /> */}

							<Route
								exact
								path="/v1/analytics"
								component={AnalyticsDashboard}
							/>
							<Route
								exact
								path="/v1/analytics/session/:_session_id"
								component={SessionAnalytics}
							/>

							<Route exact path="/v1/ledger" component={PaymentLedger} />
							<Route exact path="/v1/payoutledger" component={PayoutLedger} />
							<Route
								exact
								path="/v1/usermanagement"
								component={UserManagement}
							/>
							<Route exact path="/v1/admindash" component={AdminDashboard} />

							<Route path="notFound" component={NotFound} />
							{/* This should be the last Route */}
							<Route path="*" component={NotFound} />
						</Switch>
					</div>
				</BrowserRouter>
			</div>
		)
	}

	render() {
		if (!this.props.networkStatus.connected && !this.isValidLoggedIn()) {
			return this.renderOfflineInvalidLogin()
		}

		if (
			!this.state.isConfigLoaded ||
			!this.state.isAuthLoaded ||
			this.state.loading
		)
			return this.renderLoader()

		if (
			this.state.authenticated === true &&
			document.location.pathname.startsWith('/v1') &&
			this.props.auth
		)
			return this.renderAuthenticated()

		if (this.state.authenticated === false) return this.renderUnauthenticated()

		console.log('AUTHENTICATED_CHECK : ', this.state.authenticated)
		console.log('AUTH_CHECK : ', this.props.auth)
		console.log('CATEGORIES_CHECK : ', this.state.categories)

		if (
			(this.state.authenticated && this.props.auth && this.state.categories) ||
			(!this.props.networkStatus.connected && this.isValidLoggedIn())
		) {
			return (
				<BrowserRouter>
					<SessionContext>
						<DashboardRouter
							user={this.props.auth || { role: {} }}
							categories={this.state.categories || []}
						/>
					</SessionContext>
				</BrowserRouter>
			)
		}

		return this.renderLoader()
	}

	renderUnauthenticated() {
		return (
			<BrowserRouter>
				<Switch>
					<Route path="/embed/:key" component={embedRedirect} />
					<Route exact path="/signup" component={SignUpB} />
					<Route exact path="/v1/signup" component={SignUp} />
					{/* <Route exact path="/signup7b" component={Authentication} /> */}
					<Route exact path="/signup7" component={SignUpB} />
					<Route exact path="/signupnm" component={SignUpNoTrial} />
					<Route exact path="/coachingsignup" component={CoachingSignup} />
					<Route exact path="/WillpowerLegacy" component={WillPowerSignup} />
					<Route exact path="/signupbn" component={SignUpBn} />
					<Route exact path="/signupcp" component={SignUpCp} />
					<Route
						exact
						path="/30DayChallengeAuto"
						component={Day30ChallengeAuto}
					/>
					<Route
						exact
						path="/30DayChallengeToUpgrade"
						component={Day30ChallengeToUpgrade}
					/>
					<Route
						exact
						path="/365DayChallengeToUpgrade"
						component={Day365Challenge}
					/>
					<Route exact path="/promo" component={Promo} />
					<Route exact path="/signupbna" component={SignUpBn} />
					{/* <Route exact path="/signup7life" component={SignupWithTrial} /> */}
					{/* <Route exact path="/signup7yearly" component={SignupWithYearly} /> */}
					<Route exact path="/signup7b" component={SignUpB} />
					<Route exact path="/signup7life" component={SignUpB} />
					<Route exact path="/signup7yearly" component={SignUp10Yearly} />
					<Route path="/inviteSignup" component={InviteSignup} />
					<Route
						exact
						path="/signup_holidayoffer"
						component={SignupThanksgivingTrial}
					/>
					<Route exact path="/v1/forgetPassword" component={ForgetPassword} />
					<Route exact path="/forgetPassword" component={ForgetPasswordV2} />
					<Route path="/privacy" component={Privacy} />
					<Route path="/terms" component={Terms} />
					<Route path="/v1/login" component={Login} />
					<Route path="/v3/login" component={LoginV2} />
					<Route path="/error" component={ServiceUnavailable} />
					<Route component={LoginV3} />
				</Switch>
			</BrowserRouter>
		)
	}

	renderLoader() {
		return <Splash />
	}
}

function mapStateToProps(state: any) {
	return {
		auth: state.auth,
		networkStatus: state.networkStatus,
		sessionSurvey: state.sessionSurvey,
	}
}

function embedRedirect() {
	let url = process.env.REACT_APP_PLAY || 'https://play.positiveprime.com'
	url += document.location.pathname
	url += document.location.search || ''
	window.location.href = url
	return null
}

function topUpRedirect() {
	return <Redirect to="/topup" />
}

export default connect(mapStateToProps, actions)(App)
