import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import quoteIcon from '../../assets/quoteIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Firebase } from "../../firebase";
import "../../scss/autoSuggest.scss"
import Autosuggest from 'react-autosuggest';
import { Config, API, APIAxios } from "../../APIAndConfig";
import { ROUTES } from '../../Routers/DashboardRouter';
import { CATEGORY_ROUTES } from "../../views/Category";
import { useHistory } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { useSelector } from 'react-redux';

function Appbar(props) {

    const history = useHistory();
    const [suggestions, setSuggestions] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [sessions, setSessions] = useState([])
    const [selectedSearch, setSelectedSearch] = useState({})
    const networkStatus = useSelector(state => state.networkStatus)

    history.listen((location, action) => {
        setSearchValue('')
    })

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        let matchedArray = sessions.filter(obj =>
            (obj.category && obj.id) && (
                (obj.name && obj.name.toLowerCase().includes(inputValue)) ||
                (obj.author && obj.author.fullName.toLowerCase().includes(inputValue))
                // (obj.description && obj.description.description.toLowerCase().includes(inputValue))
            )
        );

        return inputLength === 0 ? [] : matchedArray.sort(obj => {
            if (obj.name && obj.name.toLowerCase().startsWith(inputValue)) {
                return -1;
            }
            return 0
        })
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    }

    const onSuggestionsClearRequested = () => {
        setSelectedSearch({})
        setSuggestions([])
    }

    let newSearchObj = {}
    const getSuggestionValue = suggestion => {
        if (selectedSearch === suggestion) {
            setSearchValue('')
            let url = ROUTES.CATEGORY + '/' + selectedSearch.category + CATEGORY_ROUTES.SESSION + '/' + selectedSearch.id
            setSelectedSearch({})
            if (selectedSearch.category && selectedSearch.id) {
                history.push(url)
            }
        }
        setSelectedSearch(suggestion)
        newSearchObj = suggestion
        return suggestion.name;
    };

    const renderSuggestion = suggestion => (
        <>
            {
                suggestion.author ? 
                ( <div> { suggestion.name + " - " + suggestion.author.fullName } </div> ) : 
                ( <div> { suggestion.name } </div> )
            }
        </>
        );

    const onChange = (event, { newValue }) => {
        if (event.type === "click") {
            setSearchValue('')
            let category = selectedSearch.category ? selectedSearch.category : newSearchObj.category;
            let id = selectedSearch.id ? selectedSearch.id : newSearchObj.id;
            let url = ROUTES.CATEGORY + '/' + category + CATEGORY_ROUTES.SESSION + '/' + id
            setSelectedSearch({})
            if (category && id) {
                history.push(url)
            }
        }
        else {
            setSearchValue(newValue)
        }
    };

    const renderInputComponent = inputProps => (
        <div>
            <input className="searchBlock" className="searchBar" {...inputProps} />
            <FiSearch size={15} color="black" className="searchBarIcon" />
        </div>
    );

    const inputProps = {
        value: searchValue,
        onChange: onChange
    };

    useEffect(() => {
        (async () => {
            if (!networkStatus.connected) {
                return false
            }

            let payload = await API.getAllSessions()

            if (payload.payload && payload.payload?.data) {
                let filteredData =  payload.payload?.data.filter(obj => ( obj.status === 'enabled' && obj.visibility === "open") || ( obj.status === 'enabled' && obj.visibility === "private" && Number(obj.author.id) === Number(props.user.id)))
                setSessions(filteredData || [])
            }
        })();
    }, []);

    return (
        <div className={clsx("appbar", "container-fluid", "justify-content-center, 'mt-3'")}>
            <div className="row w-100" >
                <div className={clsx('appbar__hamburgerIcon2', 'faIconButton', 'appBarHeight', 'mt-3')} onClick={props.onOpenSidebar}>
                    <FontAwesomeIcon
                        icon={faBars}
                    />
                </div>
                <div className={clsx('appbar__quote', "col-md-6", "m-2", "order-md-1")}>
                    <img src={quoteIcon} alt={'quote-icon'} className={'appbar__quote-icon'} />
                    <p className={'appbar__quote-text'}>{props.quote}</p>
                </div>
                <div className={'appbar__user', "col-md-2 m-2 d-flex align-items-center justify-content-center order-1 order-md-3"}>
                    <img src={localStorage.getItem('user_profile_image_url') ?? props.user?.profileImageUrl} alt={'profile-photo'} className={'appbar__user-photo d-inline'} />

                    <div className="dropdown d-inline">
                        <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                            <span className={'appbar__user-text', "text-white"}>{props.user?.firstName} {props.user?.lastName}</span></button>
                        <ul className="dropdown-menu">
                            {networkStatus.connected && 
                            <>
                                {
                                    props.user.role.id === 2 || props.user.invite_parent !== null ?
                                        <></> :
                                        <li className="dropdown-header">     <a className="text-dark font-weight-bold"
                                            href="/invites"
                                        >Invites</a></li>
                                }
                                <li className="dropdown-header">     <a className="text-dark font-weight-bold"
                                    href="/settings"
                                >Settings</a></li>
                                <li className="dropdown-header">     
                                <a className="text-dark font-weight-bold"
                                    href="/watched_sessions"
                                >Session History</a></li>
                                <li className="dropdown-header"><a className="text-dark font-weight-bold"
                                    href="/tutorial"
                                >Tutorial</a></li>
                            </>}

                            <li className="dropdown-header">     <a className="text-dark font-weight-bold"
                                href="/"
                                onClick={logOut.bind(this)}
                            >Log out</a></li>
                        </ul>
                    </div>
                </div>
                {networkStatus.connected && <div className={"col-md-4 m-2 d-flex align-items-center justify-content-center justify-content-md-end order-2 order-md-2"}>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={(e) => onSuggestionsClearRequested(e)}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        renderInputComponent={renderInputComponent}
                        inputProps={inputProps}
                    />
                </div>}
            </div>
        </div >
    )
    async function logOut() {
        await Firebase.Auth.signOut();
    }

}

export default Appbar;