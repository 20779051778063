import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { saveUserProfile } from "../../actions";
import { API } from "../../APIAndConfig";

const VaultTile = () => {
  const [vault, setVault] = useState(0);
  const [fetchingToken, setFetchingToken] = useState(false);
  const [tokenResponse, setTokenResponse] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [bscWalletAddress, setBscWalletAddress] = useState("");
  const [withdrawing, setWithdrawing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [showEducateModal, setShowEducateModal] = useState(false);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setFetchingToken(true);
      setTokenResponse(null);

      const { payload } = await API.getUserTokenVault();

      setTokenResponse({
        url: payload?.url,
        data: payload?.data,
        successful: payload?.successful,
      });

      if (payload.successful) {
        setVault(payload?.data);
      }

      setFetchingToken(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setBscWalletAddress(auth.bsc_wallet_address);
  }, [auth.bsc_wallet_address]);

  const handleWithdrawToken = async () => {
    try {
      setErrorMessage(null);
      setWithdrawing(true);
      setShowEducateModal(false);

      dispatch(
        saveUserProfile(auth.id, { bsc_wallet_address: bscWalletAddress })
      );

      const { status, payload } = await API.withdrawTokens("current", {
        recipient: bscWalletAddress,
        amount: vault,
      });

      if (payload?.internalErrorCode) {
        setShowModal(false);
        setShowEducateModal(true);
      }

      if (status !== 200) {
        throw new Error(payload.message);
      }

      setVault(0);

      setWithdrawing(false);

      setShowModal(false);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setWithdrawing(false);
    }
  };

  const TokenDetails = ({ loading, details }) => {
    if (loading || !details) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <h4>Fetching your token...</h4>
        </div>
      );
    }

    if (!details.successful) {
      return (
        <>
          <h4>Something went wrong!</h4>
          <p>
            Having problems connecting to <u>{details.url}</u>
          </p>
        </>
      );
    }

    return (
      <>
        <h4>Positivity</h4>
        <p className="flex-grow-1">
          Your total Positivity tokens earned through watching Positive Prime
          sessions
        </p>
        <h3>
          {vault} tokens
          <Button
            variant="link"
            className="text-white"
            size="sm"
            onClick={() => setShowModal(true)}
          >
            <u>(Withdraw)</u>
          </Button>
        </h3>
      </>
    );
  };

  return (
    <div
      className="container stats-card primary-bg d-flex flex-column"
      style={{ height: "100%" }}
    >
      <TokenDetails loading={fetchingToken} details={tokenResponse} />

      <Modal show={showEducateModal} onHide={() => setShowEducateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Upgrade your membership to withdraw tokens to your wallet
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowEducateModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Wallet address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>BSC wallet address</Form.Label>
            <Form.Control
              isInvalid={!!errorMessage}
              placeholder="Enter your bsc wallet address..."
              disabled={withdrawing}
              value={bscWalletAddress}
              onChange={(e) => setBscWalletAddress(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              We'll never share your wallet address with anyone else.
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleWithdrawToken}
            disabled={withdrawing || vault <= 0}
          >
            {withdrawing ? "Withdrawing..." : "Withdraw"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VaultTile;
