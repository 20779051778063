import React, { Component } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
    StripeProvider,
    Elements,
} from 'react-stripe-elements';
import { getStripe } from "../../stripe";
import "../../scss/checkoutLoader.scss"
import { FaCheckCircle } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Spinner } from "react-bootstrap";
import ReactPixel from 'react-facebook-pixel';

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },

            },
            invalid: {
                color: '#c23d4b',
            },
        },
    };
};

class _SplitFieldsForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: '',
            cardNumber: {
                error: true,
                value: false
            },
            cardExpiry: {
                error: true,
                value: false
            },
            cardCvc: {
                error: true,
                value: false
            },
            inProcess: false,
            confirmed: false,
            chkbox: false,
            count: 0
        }

    }

    componentDidMount() {
        ReactPixel.init(process.env.REACT_APP_FB_KEY);
    }

    handleChange = ({ elementType, complete, error }) => {

        if (error) {
            this.setState({
                errorMessage: error.message,
                [elementType]: {
                    error: error,
                    value: complete
                }
            });
        }
        else {
            this.setState({
                errorMessage: '',
                [elementType]: {
                    error: error,
                    value: complete
                }
            }, () => {
                console.log(this.state);
            })
        }
    };

    handleSubmit = async (evt) => {
        evt.preventDefault();
        this.setState({ errorMessage: "", inProcess: true });
        if (this.props.stripe) {
            await this.props.stripe.createToken()
                .then((res) => {
                    if (res.token) {

                        ReactPixel.track('Purchase', {
                            currency: 'USD',
                            value: 0.00,
                            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
                        });

                        this.setState({ confirmed: 'confirmed', inProcess: false });
                        this.props.handleResult(res)
                    } else if (res.error) {
                        this.setState({ confirmed: 'failed', inProcess: false, errorMessage: res.error.message });
                    }
                })

        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    onFocus() {
        this.setState({
            count : this.state.count + 1
        }, () => {
            if (this.state.count === 1) {
                ReactPixel.track('InitiateCheckout', {
                    eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
                });
            }
        })
    }

    render() {
        return (
            <form id="credit-card" onSubmit={this.handleSubmit.bind(this)}>
                <div>
                    {/* <div className="col-12">
                        <div className="row justify-content-center">
                            <div className="col-md-5 p-0">
                                <h2 className="title p-4">Billing Information</h2>
                            </div>
                        </div>
                    </div> */}
                    <div className="justify-content-center align-items-center">
                        {/* <div className="card-head">
                            <div className="error errorMessage form-group" role="alert">
                                {this.props.message}
                            </div> 
                        </div> */}
                        <div className="w-100">
                            <label className="font-weight-bold">Credit Card Details</label>
                            <div className="form-group">
                                <CardNumberElement
                                    {...createOptions()}
                                    placeholder=''
                                    onFocus={this.onFocus.bind(this)}
                                    disabled={this.state.inProcess || this.state.confirmed === 'confirmed'}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-6 form-group">
                                    <CardExpiryElement
                                        {...createOptions()}
                                        disabled={this.state.inProcess || this.state.confirmed === 'confirmed'}
                                        onChange={this.handleChange}
                                    />

                                </div>
                                <div className="col-6 form-group">
                                    <CardCVCElement
                                        {...createOptions()}
                                        disabled={this.state.inProcess || this.state.confirmed === 'confirmed'}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            {
                                this.state.errorMessage ?
                                    <div className="alert alert-warning p-2 d-flex justify-content-center" role="alert">
                                        {this.state.errorMessage}
                                    </div> : <></>
                            }
                            {
                                this.props.page ?
                                    <>
                                        <div className="form-group mt-3">
                                            <label className="container-checkbox">Yes! I want to make sure I’m always being kept informed and up to date with the latest offerings from Positive Prime via email and other electronic communications. You can unsubscribe from our communications at any time simply by contacting us.
                                                <input type="checkbox" defaultChecked={this.state.chkbox} onChange={() => this.setState({ chkbox: !this.state.chkbox })} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>


                                        <div className="form-group">
                                            <p className="text-left billingText">
                                                If you do not provide us your personal information we may not
                                                be able to provide our Services to you. See our
                                            <a href="#">
                                                    <u> Privacy Policy</u>
                                                </a>
                                            </p>
                                        </div>
                                    </> : <></>
                            }

                            <button
                                type="submit"
                                className="btn btn-block signup-button-css"
                                onClick={this.handleSubmit}
                                // style={{ background: "#11142f", hover : { color : "red" } }}
                                disabled={
                                    this.state.confirmed === 'confirmed' ||
                                    this.state.inProcess ||
                                    (this.state.cardCvc.error || !this.state.cardCvc.value) ||
                                    (this.state.cardExpiry.error || !this.state.cardExpiry.value) ||
                                    (this.state.cardNumber.error || !this.state.cardNumber.value)
                                }
                            >
                                {this.props.processing ? <Spinner animation="border" size="sm" /> : <span>Complete Order</span>}
                            </button>
                            <br />
                        </div>
                    </div>
                </div>
            </form>

        );
    }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

export default class CheckoutFormB extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: ""
        }
        this.handleResult = this.handleResult.bind(this)
    }

    handleResult(e) {
        if (e.error) {
            this.setState({ errorMessage: e.error.message })
        } else {
            this.setState({ errorMessage: "" })
            this.props.handler(e.token.id,this.state.chkbox)
        }
    }
    render() {
        return (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Elements>
                    <SplitFieldsForm handleResult={this.handleResult} status={this.props.status} message={this.state.errorMessage} processing={this.props.processing} page={this.props.page} />
                </Elements>
            </StripeProvider>
        );
    }
}
