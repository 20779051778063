import {ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";
import {get} from "lodash";
import React, {SyntheticEvent} from "react";

export type OnSelectCallback = (
    selected: string,
) => void;

export interface SimpleDropdownButtonProps {
    id: string,
    options: {[key: string]: string},
    active: string | null,
    variant?: string,
    nullTitle?: string,
    nullVariant?: string,
    className?: string
    onSelect?: OnSelectCallback
}

export class SimpleDropdownButton extends React.Component<SimpleDropdownButtonProps> {

    getActive(): string | null {
        if (this.props.active)
            return this.props.active;

        if (this.props.nullTitle)
            return null;

        return Object.keys(this.props.options)[0];
    }
    getVariant(): string {
        if (this.getActive() === null && this.props.nullVariant)
            return this.props.nullVariant;

        return this.props.variant || 'primary';
    }
    getTitle(): string {
        const active = this.getActive();
        if (active)
            return this.props.options[active] || ' ';

        return this.props.nullTitle || '';
    }
    onSelect(key: string | null, e: SyntheticEvent | any) {
        if (this.props.onSelect && key)
            this.props.onSelect.apply(this, [key]);
    }

    render() {
        const active = this.getActive();
        const items: any[] = [];

        let opt: string;
        for (opt of Object.keys(this.props.options))
            items.push(
                <Dropdown.Item
                    key={opt}
                    eventKey={opt}
                    active={active === opt ? true : false}>
                    {get(this.props.options,opt)}
                </Dropdown.Item>
            );

        return (
            <DropdownButton
                id={this.props.id}
                onSelect={this.onSelect.bind(this)}
                as={ButtonGroup}
                className={this.props.className}
                size="lg"
                title={this.getTitle()}
                variant={this.getVariant()}
            >
                {items}
            </DropdownButton>
        );
    }
}
