import { Config, API, makeAPIUrl, APIAxios } from "../APIAndConfig";

import axios from "axios";
import { Firebase } from "../firebase";
import {
  FETCH_USER,
  FETCH_USER_CLIENTS,
  FETCH_USER_EMBED,
  FETCH_USER_OWNERS,
  FETCH_USER_REFEREE,
} from "./types";

import { get } from "lodash";
import set from "date-fns/set";

export const fetchUserClients = (auth) => async (dispatch) => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };


  await axios.get(makeAPIUrl(`/api/user_clients/${auth.id}`), {
    headers: header,
  }).then((clients) => {
    dispatch({ type: FETCH_USER_CLIENTS, payload: clients.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
        await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
};

export const fetchUserOwners = (auth) => async (dispatch) => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  await axios.get(
    `${Config.APIUrl}/api/user_owners/${auth.id}`,
    {
      headers: header,
    }
  ).then((owners) => {
    dispatch({ type: FETCH_USER_OWNERS, payload: owners.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

};

export const fetchUserReferee = (auth) => async (dispatch) => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  await axios.get(
    `${Config.APIUrl}/api/user_referee/${auth.id}`,
    {
      headers: header,
    }
  ).then((referee) => {
    dispatch({ type: FETCH_USER_REFEREE, payload: referee.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

};

export const addClient = (auth, firstName, lastName, email) => async (
  dispatch
) => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  await axios.post(
    `${Config.APIUrl}/api/add_client`,
    {
      owner_user_id: auth.id,
      first_name: firstName,
      last_name: lastName,
      email: email,
    },
    {
      headers: header,
    }
  ).then((res) => {
    console.log(res)
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

  await axios.get(
    `${Config.APIUrl}/api/user_clients/${auth.id}`,
    {
      headers: header,
    }
  ).then((clients) => {
    dispatch({ type: FETCH_USER_CLIENTS, payload: clients.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
};

export const saveUserProfile = (userId, userObject) => async (dispatch) => {
  const result = await API.updateUser(userId, userObject);

  const user = get(result, "payload.user");
  if (user) dispatch({ type: FETCH_USER, payload: user });
};
