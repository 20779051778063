import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Config, API, APIAxios } from "../../APIAndConfig";
import { Firebase } from "../../firebase";
import ImagePlayer from "../../components/players/ImagePlayer/ImagePlayer";
import { ROUTES } from "../../Routers/DashboardRouter";
import { Media_URL } from "../../APIAndConfig.ts";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import {
  fetchOwnerSessionForUser,
  fetchPurchasesByUserId,
  fetchUserSession,
} from "../../actions";
import { Spinner } from "react-bootstrap";
import WindowedSelect from "react-windowed-select";
import { createFilter } from "react-select";

import "../../scss/ExperienceBuilder.css";
import "./ExperienceBuilder.css"

import "date-fns";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import TextField from "@material-ui/core/TextField";

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CheckRounded, ClearRounded, EditOutlined } from "@material-ui/icons";
import axios from "axios";
import EmbedModal from "../Modals/EmbedModal"
import EditDetailsModal from "../Modals/EditDetailsModal";
import SearchIcon from '@material-ui/icons/Search';
import { set } from "lodash";

const PurpleSwitch = withStyles({
  switchBase: {
    color: red[900],
    "&$checked": {
      color: "#1b5e20",
    },
    "&$checked + $track": {
      backgroundColor: "#2e7d32",
    },
  },
  checked: {},
  track: {},
})(Switch);

const tableStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const styleValues = [
  {
    name: "Parent Dimension",
    statu: false
  },
  {
    name: "Container Borders & Shadows",
    statu: false
  },
]

export const ExperienceBuilder = () => {
  const tableClasses = tableStyles();

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [session, setSession] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [cover, setCover] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);

  const [data, setData] = useState(
    {
      sessionId: null,
      status: 'deactive',
      expiryDate: null,
      description: '',
      url: ''
    }
  )
  const [response, setResponse] = useState([])
  const [pages, setPages] = useState(null)
  const [left, setLeft] = useState(0)
  const [right, setRight] = useState(25)
  const [curPage, setCurPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false)
  const [selected, setSelected] = useState({})
  const [query, setQuery] = useState('')
  const [expResults, setExpResults] = useState([])


  useEffect(() => {
    (async () => {
      const token = await Firebase.Auth.currentUser.getIdToken();
      setLoading(true)
      const header = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };

      const results = await axios.get(
        `${Config.APIUrl}/userEmbeds`,
        {
          headers: header
        }
      ).then(res => {
          setLoading(false)
          return res.data.data
        }).catch( async (err) => {
          switch (err.response.data) {
            case "SESSION_EXPIRED":
              const token = await Firebase.Auth.currentUser?.getIdToken();
              if (token) {
                await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                window.location.reload()
            }
              break;
            case "BAD_CONNECTION":
              case "UNCAUGHT_ERROR":
            case "DATABASE_LIMIT_EXCEEDED":
              localStorage.setItem('service_unavailable_status', "failed");
              await Firebase.Auth.signOut();
              window.location.href = "/error"
              break;
            default:
              console.log("Error Occured While fetching category data ", err);
              break;
          }
        });

      setResponse(results.reverse())

    })();
  }, []);

  useEffect(() => {

    if (response.length) {
      let reqResult = response.filter(r => {
        if (r.embedSession.name.toLowerCase().includes(query.toLowerCase())) {
          return true
        }
        if (r.embedUser.firstName.toLowerCase().includes(query.toLowerCase())) {
          return true
        }
        if (r.domain.toLowerCase().includes(query.toLowerCase())) {
          return true
        }
        return false
      })

      setExpResults(reqResult)
      setPages(Math.ceil(reqResult.length / 25))
    }

  }, [query, response.length])


  const handleDateChange = (date) => {
    let timeStamp = new Date(date._d).getTime()
    setData({ ...data, expiryDate: timeStamp })
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(data)
    if (data.sessionId !== null
      && data.description !== '') {

      const token = await Firebase.Auth.currentUser.getIdToken();

      const header = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };

      const results = await axios.post(
        `${Config.APIUrl}/addUserEmbed`,
        data,
        {
          headers: header
        }
      ).then(res => res.data)
      .then(res => handleClickOpen(res.data))
      .catch(
        async (err) => {
          switch (err.response.data) {
            case "SESSION_EXPIRED":
              const token = await Firebase.Auth.currentUser?.getIdToken();
              if (token) {
                await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                window.location.reload()
            }
              break;
            case "BAD_CONNECTION":
              case "UNCAUGHT_ERROR":
            case "DATABASE_LIMIT_EXCEEDED":
              localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
              window.location.href = "/error"
              break;
            default:
              console.log("Error ", err);
              break;
          }
        });
    }
    else {
      if (data.expiryDate === Date.now()) {
        alert("Expiry date cannot be same as today.")
      }
      else if (data.sessionId === null) {
        alert("Select a session.")
      }
      else {
        alert("Fill all the form.")
      }

    }
  }

  const handleEdit = (s) => {
    setEdit(true)
    setSelected(s)
  }

  const handleClickOpen = (res) => {
    setSelected(res)
    setOpen(true);
  };

  const handleRight = () => {
    if (curPage < pages) {
      setLeft(left + 25)
      setRight(right + 25)
      setCurPage(curPage + 1)
    }
  }

  const handleLeft = () => {
    if (curPage > 1) {
      setLeft(left - 25)
      setRight(right - 25)
      setCurPage(curPage - 1)
    }
  }

  const fileUploader = useRef(null);

  const renderSessions = async () => {
    let payload = await API.getAllSessions();
    setSessions(payload.payload?.data || []);
  };

  const getImages = async () => {
    if (session) {
      console.log("file", fileUploader);
      const userUploads = await API.getMedia({
        purpose: "thumbnail",
        variant: "original",
        session: session.id,
      });
      console.log("object1", userUploads);
      let covers = userUploads.payload.data.map((image) => {
        return image.object.url;
      });
      setImages(covers);
    }
  };
  useEffect(() => {
    renderSessions();
  }, []);
  useEffect(() => {
    getImages();
    setCover(session?.coverphoto);
    setThumbnail(session?.thumbnail);
  }, [session]);

  const columns = [
    { id: "sessionName", label: "Session Name", minWidth: 180 },
    { id: "expiryDate", label: "Expiry Date", minWidth: 100 },
    {
      id: "status",
      label: "Status",
      minWidth: 30,
      align: "center",
      // format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "edit",
      label: "Edit",
      minWidth: 30,
      align: "center",
      // format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "embedCode",
      label: "Generate Embed Code",
      minWidth: 110,
      align: "center",
      // format: (value) => value.toFixed(2),
    },
  ];

  function createData(sessionName, expiryDate, status, edit, embedCode) {
    return { sessionName, expiryDate, status, edit, embedCode };
  }
  const setSessionId = ( e ) => {
    setData( {...data, sessionId: e.value } )
  }
  const rows = [
    createData(
      "Think and Grow Rich: Mystery of Sex Transmutation by Kim Serafini",
      "2021-05-28",
      <CheckRounded style={{ color: "#4CAF50" }} />,
      <EditOutlined
        style={{ color: "#90CAF9", cursor: "pointer" }}
        onClick={() => {
          alert("I was Clicked");
        }}
      />,
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Generate
      </Button>
    ),
    createData(
      "Co-parenting with the Universe and feeling safe by Murielle Fellous",
      "2021-05-29",
      <ClearRounded style={{ color: "#d50000" }} />,
      <EditOutlined style={{ color: "#90CAF9", cursor: "pointer" }} />,
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Generate
      </Button>
    ),
  ];

  return !sessions ? (
    <div className={"text-center mt-4"}>
      <Spinner
        animation={"grow"}
        variant={"primary"}
        style={{ width: "5em", height: "5em" }}
      />
    </div>
  ) : (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <h2 className="main-title">Experience Builder</h2>
          </div>
        </div>

        <div className={"statContainer"} style={{ paddingRight: 0 }}>
          <div className={clsx('stats-card', 'primary-bg')}>
            <div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <p className={clsx('stats-card__text-subtitle', 'x-card-subtitle')}>Session</p>
                    <WindowedSelect
                      name="author"
                      filterOption={createFilter( { ignoreAccents: false } )}
                      onChange={setSessionId}
                      styles={{
                        option: ( base ) => ( {
                          ...base,
                          color: "black"
                        } ),
                      }}
                      options={
                        sessions.length && sessions.map( ( obj ) => {
                          return (
                            { value: obj.id, label:  obj.name}
                          )
                        } )
                      }
                    />
                  {/* <select
                    id="dashboard-session-select"
                    className="custom-select"
                    onChange={(e) => setData({ ...data, sessionId: Number(e.target.value) })}
                  >
                    {sessions.length && sessions.map((s, index) => {
                      return (
                        <option key={`option-item-${s.id}`} value={s.id}>
                          {s.name}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
                <div className="form-group">
                  <p className={clsx('stats-card__text-subtitle', 'x-card-subtitle')}>Expiry Date (Optional)</p>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid
                      container
                      justify="space-around"
                      style={{
                        width: "100%",
                      }}
                      className="date-picker"
                    >
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy/MM/DD"
                        value={data.expiryDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{
                          width: "100%",
                        }}
                        className="date-input"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>

                <div className="form-group">
                  <p className={clsx('stats-card__text-subtitle', 'x-card-subtitle')}>Description</p>
                  <textarea
                    type="text"
                    className="custom-desc"
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                    value={data.description}
                    placeholder="Enter description..."
                  >
                  </textarea>
                </div>

                <div className="form-group">
                  <p className={clsx('stats-card__text-subtitle', 'x-card-subtitle')}>Domain (Optional)</p>
                  <input
                    type="text"
                    className="custom-url"
                    onChange={(e) => setData({ ...data, url: e.target.value })}
                    value={data.url}
                    placeholder="Enter URL..."
                  >
                  </input>
                </div>

                <FormGroup style={{ width: "fitContent" }}>
                  <FormControlLabel
                    control={
                      <PurpleSwitch
                        checked={data.status === 'deactive' ? false : true}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setData({ ...data, status: 'active' })
                          }
                          else {
                            setData({ ...data, status: 'deactive' })
                          }
                        }}
                        name="checkedA"
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                    style={{ width: "fitContent", color: "white" }}
                  />
                </FormGroup>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-12 mb-3 text-left">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={"statContainer"} style={{ paddingRight: 0, marginTop: '40px' }}>
          <div className={clsx('stats-card', 'primary-bg')}>
            <div>
              {pages !== null && (
                <div className="indicator">
                  <ArrowLeftIcon onClick={handleLeft} className="svg-icon" />
                  <p>Page {curPage}/{pages}</p>
                  <ArrowRightIcon onClick={handleRight} className="svg-icon" />
                </div>
              )}

              {response.length > 0 &&
                (
                  <div className="search-field">
                    <div className="search">
                      <SearchIcon className="search-svg" />
                      <input placeholder="Search" type="search" value={query} onChange={(e) => setQuery(e.target.value)} />
                    </div>
                  </div>
                )
              }

              {!loading ? (
                <table className="created-table">
                  <tr>
                    <th><p>Session Name</p></th>
                    <th className="little"><p>Create Date</p></th>
                    <th className="little"><p>Expiry Date</p></th>
                    <th className="little"><p>Owner</p></th>
                    <th className="little"><p>Status</p></th>
                    <th className="url"><p>Url</p></th>
                    <th className="little"><p>Edit</p></th>
                    <th><p>Generate Embed Code</p></th>
                  </tr>

                  {response.length > 0 && expResults.length > 0 ? expResults.slice(left, right).map(res => (
                    <tr id={res.embedSession.id}>
                      <td><p className="session-name">{res.embedSession.name}</p></td>
                      {res.created ? (
                        <td className="date little">{new Date(res.created).getFullYear()}-{new Date(res.created).getMonth()+1}-{new Date(res.created).getDate()}</td>
                      ) : <td className="date little">----</td>}
                      {res.expiry ? (
                        <td className="date little">{new Date(res.expiry).getFullYear()}-{new Date(res.expiry).getMonth()+1}-{new Date(res.expiry).getDate()}</td>
                      ) : <td className="date little">----</td>}
                      <td className="owner little">{res.embedUser.firstName}</td>
                      <td className="status little">
                        <div className="status-div">
                          {res.status === 'active' ? (
                            <img style={{ width: '20px' }} src="https://img.icons8.com/color/48/000000/checked--v1.png" />
                          ) : (
                            <img style={{ width: '20px' }} src="https://img.icons8.com/flat-round/64/000000/delete-sign.png" />
                          )}
                        </div>
                      </td>
                      <td className="url">
                        {res.domain !== '' ? res.domain : '----'}
                      </td>
                      <td className="edit little">
                        <div className="edit-div" onClick={() => handleEdit(res)}>
                          <img style={{ width: '30px' }} src="https://img.icons8.com/plasticine/100/000000/edit-property.png" />
                        </div>
                      </td>
                      <td><div className="generate-div" onClick={() => handleClickOpen(res)}>Generate</div></td>
                    </tr>
                  )) : <p className="no-res">No Result</p>}

                </table>
              ) : <div className="progress-bar"><CircularProgress color="inherit" /></div>}
            </div>
          </div>
        </div>

        {open && (
          <EmbedModal
            setOpen={setOpen}
            selected={selected} />
        )}

        {edit && (
          <EditDetailsModal
            setEdit={setEdit}
            allSessions={sessions}
            selected={selected} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// export default connect(mapStateToProps, actions)(withRouter(SessionCurator));
