import {FETCH_PRODUCTS_NEW} from '../actions/types';

export default function(state = [], action) {
   //console.log(action);
   switch (action.type) {
      case FETCH_PRODUCTS_NEW:
         return action.payload || [];
      default:
         return state;
   }
}
