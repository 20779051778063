import { Config, API, APIAxios } from "../APIAndConfig";
import axios from "axios";
import {
  FETCH_LIKED_SESSIONS,
  FETCH_OWNER_SESSION_FOR_USER,
  FETCH_RECENTLY_WATCH,
  FETCH_USER_OWNERS_SESSION_HISTORY,
  FETCH_USER_OWNERS_SESSION_HISTORY_YEARS,
  USER_CURRENT_SESSION_HISTORY
} from "./types";
import { Firebase } from "../firebase";
import set from "date-fns/set";
import { getOfflineSession, getOfflineSessionMedia } from "../helpers/offlineSession";


export const fetchSessionDetailById = (sessionId) => async dispatch => {
  const downloadedSession = await getOfflineSession(sessionId)

  if (downloadedSession && downloadedSession.downloadState === 'FINISHED') {
    const downloadedSessionMedia = await getOfflineSessionMedia(sessionId)
    downloadedSession.personal_images = downloadedSessionMedia[sessionId]
    // setting the downloaded flag here to true to cover already downloaded session
    downloadedSession.downloaded = true

    dispatch({ type: USER_CURRENT_SESSION_HISTORY, payload: downloadedSession });

    return downloadedSession
  }

  const session = await API.getSessionById(sessionId);
  const personalImages = await API.getMedia({
    purpose: "session",
    variant: "thumb",
    user: "current",
    session: sessionId,
  });

  const data = session.payload.data;
  if (data) {
    data.personal_images = personalImages.payload.data;
  }
  dispatch({ type: USER_CURRENT_SESSION_HISTORY, payload: data });
  return data;
};
/**
 * Get session detail for embed
 * @param {*} userId
 * @param {*} sessionId
 */

export const startUserWatchSession = (
  auth,
  sessionId,
  survey
) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  return await axios.post(
    `${Config.APIUrl}/api/user_start_session/`,
    {
      user_id: auth.id,
      session_id: sessionId,
      survey_id: survey.id
    },
    {
      headers: header
    }
  ).then((results) => {
    dispatch({ type: USER_CURRENT_SESSION_HISTORY, payload: results.data });
    return results.data;
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
        await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });


};

export const endUserWatchSession = (
  auth,
  sessionId,
  history,
  finishSession,
  finishTime
) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  return await axios.post(
    `${Config.APIUrl}/api/user_end_session/`,
    {
      user_id: auth.id,
      session_id: sessionId,
      history_id: history.id,
      finish_session: finishSession,
      finishTime: finishTime || null
    },
    {
      headers: header
    }
  ).then((results) => {
    return results.data;
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

  //dispatch({ type: USER_CURRENT_SESSION_HISTORY, payload: results.data });
  // return results.data;
};

export const fetchRecentlyWatchSession = (auth, limit) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/user_watch_session/${auth.id}?limit=${limit}`,
    { headers: header }
  ).then((results) => {
    dispatch({ type: FETCH_RECENTLY_WATCH, payload: results.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
};

export const fetchOwnerSessionForUser = auth => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(`${Config.APIUrl}/api/user_owners_sessions/${auth.id}`, {
    headers: header
  }).then((results) => {
    dispatch({ type: FETCH_OWNER_SESSION_FOR_USER, payload: results.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
};

/***********************
 * Get all user onwer sessions id and get the history of the queried session ids
 */
export const fetchUserOwnersSessionHistory = auth => async dispatch => {
  Firebase.Auth.onAuthStateChanged(async authUser => {
    const token = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await authUser.getIdToken())
    };
    await axios.get(
      `${Config.APIUrl}/api/user_owners_session_history/${auth.id}`,
      { headers: token }
    ).then((results) => {
      dispatch({
        type: FETCH_USER_OWNERS_SESSION_HISTORY,
        payload: results.data
      });
    }).catch(async (err) => {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
            window.location.reload()
          }
          break;
        case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
          window.location.href = "/error"
          break;
        default:
          console.log("Error", err);
          break;
      }
    });
  });
};

/**
 * Get session watched history years
 */
export const fetchUserOwnersSessionHistoryYears = auth => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/user_owners_session_history_years/${auth.id}`,
    { headers: header }
  ).then((results) => {
    dispatch({
      type: FETCH_USER_OWNERS_SESSION_HISTORY_YEARS,
      payload: results.data
    });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
};

export const editSessionDetails = (
  userId,
  sessionId,
  sessionName,
  imageList
) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  return await axios.post(
    `${Config.APIUrl}/api/session/${sessionId}`,
    {
      user_id: userId,
      name: sessionName,
      media_id_list: imageList
    },
    { headers: header }
  ).then((results) => {
    return results.data;
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
  //dispatch({ type: FETCH_OWNER_SESSION_FOR_USER, payload: results.data });

};

export const getLikedSessions = (userId) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  return await axios.get(
    `${Config.APIUrl}/get_favorite_sessions?userId=${userId}`,
    { headers: header }
  ).then((results) => {
    dispatch({ type: FETCH_LIKED_SESSIONS, payload: results.data.data });

  return results.data;
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
};
