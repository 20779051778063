import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Favorites extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null
      };
   }

   componentDidMount() {
      this.props.resetError();
   }

   componentWillUnmount() {
      this.props.resetError();
   }

   onOpenNav(e) {
      document.getElementById('sidebar').classList.add('sidebarOpen');
   }

   render() {
      return (
         <div className="mainWidth">
            <button
               className="btn d-md-none"
               onClick={this.onOpenNav.bind(this)}
            >
                <FontAwesomeIcon icon={faBars} />
            </button>
            <p className="text-secondary m-0 p-3">Favorites</p>
            <hr className="m-0" />
            <div className="row m-0 p-3">
               <div className="col-12" />
            </div>
         </div>
      );
   }
}

function mapStateToProps(state) {
   return { auth: state.auth };
}

export default connect(
   mapStateToProps,
   actions
)(Favorites);
