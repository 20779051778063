import React, { useState } from 'react';
import './editDetails.css';
import { faAngry, faFrown, faLaugh, faMeh } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrownOpen, faSmile } from "@fortawesome/free-solid-svg-icons";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Config, API, APIAxios } from "../../APIAndConfig";
import { Firebase } from "../../firebase";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
   MuiPickersUtilsProvider,
   KeyboardTimePicker,
   KeyboardDatePicker,
} from "@material-ui/pickers";
import { set } from 'lodash';

const PurpleSwitch = withStyles({
   switchBase: {
      color: red[900],
      "&$checked": {
         color: "#1b5e20",
      },
      "&$checked + $track": {
         backgroundColor: "#2e7d32",
      },
   },
   checked: {},
   track: {},
})(Switch);


const EditDetailsModal = (props) => {
   const { setEdit, allSessions, selected } = props

   const [data, setData] = useState(
      {
         sessionId: selected.embedSession.id,
         status: selected.status,
         expiryDate: selected.expiry ? new Date(selected.expiry).getTime() : null,
         description: selected.description,
         url: selected.domain
      }
   )

   const handleDateChange = (date) => {
      let timeStamp = new Date(date._d).getTime()
      setData({ ...data, expiryDate: timeStamp })
   };

   const handleSubmit = async (e) => {
      e.preventDefault()
      if (data.sessionId !== null && data.status !== '') {

         const token = await Firebase.Auth.currentUser.getIdToken();

         const header = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + token
         };

         const results = await axios.post(
            `${Config.APIUrl}/editUserEmbed/${selected.id}`,
            data,
            {
               headers: header
            }
         ).then(res => window.location.reload()).catch(async err => {
            switch (err.response.data) {
               case "SESSION_EXPIRED":
                 const token = await Firebase.Auth.currentUser?.getIdToken();
                 if (token) {
                  await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                  window.location.reload()
              }
                 break;
               case "BAD_CONNECTION":
                  case "UNCAUGHT_ERROR":
               case "DATABASE_LIMIT_EXCEEDED":
                 localStorage.setItem('service_unavailable_status', "failed");
                 await Firebase.Auth.signOut();
                 window.location.href = "/error"
                 break;
               default:
                 console.log("Error", err);
                 break;
             }
         });
      }
   }

   return (
      <div className="editModal">
         <div className="d-flex align-items-center justify-content-center h-100">
            <div className="editModalContent pp-animate-bottom shadow">
               <p className="modalClose text-right"
                  onClick={() => setEdit(false)}>
                  &times;
               </p>
               <div className="py-4">
                  <p className="h4 m-0 px-0 text-dark" style={{ textAlign: 'center' }}>Edit Details</p>
               </div>

               <div className="edit-field">

                  <label>Select Session</label>
                  <select
                     id="dashboard-session-select"
                     className="custom-select"
                     onChange={(e) => setData({ ...data, sessionId: Number(e.target.value) })}
                  >
                     {allSessions.length && allSessions.map((s, index) => {
                        return (
                           <option key={`option-item-${s.id}`} value={s.id}>
                              {s.name}
                           </option>
                        );
                     })}
                  </select>

                  <label className="exp-date">Select Expiry Date</label>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                     <Grid
                        container
                        justify="space-around"
                        style={{
                           width: "100%",
                        }}
                        className="date-picker"
                     >
                        <KeyboardDatePicker
                           margin="normal"
                           id="date-picker-dialog"
                           format="yyyy/MM/DD"
                           value={data.expiryDate}
                           onChange={handleDateChange}
                           KeyboardButtonProps={{
                              "aria-label": "change date",
                           }}
                           style={{
                              width: "100%",
                           }}
                           className="date-input"
                        />
                     </Grid>
                  </MuiPickersUtilsProvider>

                  <label className="description">Description</label>
                  <textarea
                     type="text"
                     className="custom-desc"
                     onChange={(e) => setData({ ...data, description: e.target.value })}
                     value={data.description}
                     placeholder="Enter description..."
                  >
                  </textarea>

                  <label className="domain">Domain</label>
                  <input
                     type="text"
                     className="custom-url"
                     onChange={(e) => setData({ ...data, url: e.target.value })}
                     value={data.url}
                     placeholder="Enter URL..."
                  >
                  </input>

                  <FormGroup style={{ width: "fitContent" }}>
                     <FormControlLabel
                        control={
                           <PurpleSwitch
                              checked={data.status === 'deactive' ? false : true}
                              onChange={(e) => {
                                 if (e.target.checked === true) {
                                    setData({ ...data, status: 'active' })
                                 }
                                 else {
                                    setData({ ...data, status: 'deactive' })
                                 }
                              }}
                              name="checkedA"
                           />
                        }
                        label="Status"
                        labelPlacement="start"
                        style={{ width: "fitContent", color: "white" }}
                     />
                  </FormGroup>
               </div>

               <div className="form-group">
                  <div className="row">
                     <div className="col-md-12 mb-3 text-left">
                        <button className="btn btn-primary" onClick={handleSubmit}>
                           Update
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default EditDetailsModal;
