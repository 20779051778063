import React from 'react';
import './survey.css';
import { faAngry, faFrown, faLaugh, faMeh, faTired } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrownOpen, faSmile } from "@fortawesome/free-solid-svg-icons";
import { ImSleepy } from "react-icons/im";

const SurveyBefore = ({ onBeforeModalClose, onSelect, onContinue, onSkip }) => {
   return (
      <div id="beforeModal" className="surveyModal newSurveyModal">
         <div className="d-flex align-items-center justify-content-center h-100">
            <div className="surveyModalContent pp-animate-bottom bg-white shadow">
               <p
                  className="modalClose text-right m-0"
                  onClick={onBeforeModalClose}
               >
                  &times;
               </p>
               <div className="py-4">
                  <p className="h4 m-0 px-2" style={{ color: '#200E32', fontWeight: '300' }}>How are you feeling now?</p>
                  <p className="fontRegular m-0 px-2" style={{ color: '#30374D', fontWeight: '300' }}>
                     Recording your emotions at the...
                  </p>
               </div>
               <div className="row m-0">
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="angry"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faAngry} className="ppEmoji ppRed" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">Angry</p>
                  </div>
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="meh"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faMeh} className="ppEmoji ppGray" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">Neutral</p>
                  </div>
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="happy"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faSmile} className="ppEmoji ppGreen" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">Happy</p>
                  </div>
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="tired"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                        <ImSleepy className="ppEmoji ppPink" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">Tired</p>
                  </div>
               </div>
               <div className="row m-0 pb-4">
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="powerful"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faLaugh} className="ppEmoji ppPink" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">
                        Powerful
                     </p>
                  </div>
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="sad"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faFrown} className="ppEmoji ppBlue" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">Sad</p>
                  </div>
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="anxious"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faFrownOpen} className="ppEmoji ppOrange" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">
                        Anxious
                     </p>
                  </div>
                  <div className="col-3 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded"
                        name="stressed"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faTired} className="ppEmoji ppBlue" />
                        </p>
                     </div>
                     <p className="text-dark text-center m-0">Stressed</p>
                  </div>
               </div>
               <div className="pb-4" align="center">
                  <button className="btn btn-primary" id="continue-btn" onClick={onContinue}>
                     <p>Let's see if we can improve that!</p>
                  </button>
                  <button className="btn btn-primary" id="prime-btn" onClick={onContinue}>
                     <p>Let's get your positive prime on the way.</p>
                  </button>
               </div>
               <div className="pb-4" align="center">
                  <a href="#" onClick={onSkip} 
                  style={{color: '#30374D', fontSize: '18px'}}>
                     Skip
                  </a>
               </div>
               {/* <center>
                        <button
                           id="addBtn"
                           className="btn btn-primary"
                           style={{ margin: '10px 0px' }}
                           type="submit"
                        >
                           Ok
                        </button>
                     </center> */}
            </div>
         </div>
      </div>
   );
};

export default SurveyBefore;
