import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Firebase} from'../firebase';
import {faBars, faPlus, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class LoginHeader extends Component {
   async logOut() {
      const res = await Firebase.Auth.signOut();
   }

   render() {
      return (
         <div className="mainWidth position-relative">
            <nav
               className="navbar position-absolute p-2"
               style={{ top: '0', right: '0' }}
            >
               <span className="nav-text text-muted d-none d-md-block ml-auto mr-3">
                  Credits:
               </span>
               <div
                  className="input-group input-group-sm nav-item"
                  style={{ width: 'auto' }}
               >
                  <div className="input-group-prepend">
                     <div className="input-group-text bg-transparent text-secondary px-4 bg-white">
                        {this.props.auth.credits}
                     </div>
                  </div>
                  <div key="header-payment" className="input-group-append">
                     <Link to="/topup" className="btn btn-primary">
                        <FontAwesomeIcon icon={faPlus} />
                     </Link>
                  </div>
               </div>
               <div key="header-logout" className="nav-item dropdown">
                  <a
                     className="nav-link pr-0"
                     href="#"
                     id="navbarDropdown"
                     role="button"
                     data-toggle="dropdown"
                     aria-haspopup="true"
                     aria-expanded="false"
                  >
                     <FontAwesomeIcon icon={faUserCircle} className="text-muted" />
                  </a>
                  <div
                     className="dropdown-menu dropdown-menu-right"
                     aria-labelledby="navbarDropdown"
                  >
                     <a
                        href="/"
                        onClick={this.logOut.bind(this)}
                        className="dropdown-item"
                     >
                        Log&nbsp;out
                     </a>
                  </div>
               </div>
            </nav>
         </div>
      );
   }
}

function mapStateToProps(state) {
   return { auth: state.auth };
}

export default connect(mapStateToProps)(LoginHeader);
