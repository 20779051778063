import {combineReducers} from "redux";
import {reducer as reduxForm} from "redux-form";
import authReducer from "./authReducer";
import surveysReducer from "./surveysReducer";
import headerModeReducer from "./headerModeReducer";
import imageReducer from "./imageReducer";
import categoryReducer from "./categoryReducer";
import userImagesReducer from "./userImagesReducer";
import selectedImagesReducer from "./selectedImagesReducer";
import selectedCategoriesReducer from "./selectedCategoriesReducer";
import userSessionsReducer from "./userSessionsReducer";
import selectedCurrentSessionReducer from "./selectedCurrentSessionReducer";
import mediaSessionReducer from "./mediaSessionReducer";
import mediaEmbedSessionReducer from "./mediaEmbedSessionReducer";
import productReducer from "./productReducer";
import productNewReducer from "./productNewReducer";
import productFeaturedReducer from "./productFeaturedReducer";
import errorReducer from "./errorReducer";
import productDetailReducer from "./productDetailReducer";
import userClientsReducer from "./userClientsReducer";
import userOwnersReducer from "./userOwnersReducer";
import userWatchReducer from "./userWatchReducer";
import recentlyWatchReducer from "./recentlyWatchReducer";
import userOwnersSessionHistoryReducer from "./userOwnersSessionHistoryReducer";
import userOwnersSessionHistoryYearsReducer from "./userOwnersSessionHistoryYearsReducer";
import purchasesReducer from "./purchasesReducer";
import ownerSessionForUserReducer from "./ownerSessionForUserReducer";
import sessionSurveyReducer from "./sessionSurveyReducer";
import userRefereeReducer from "./userRefereeReducer";
import defaultImageReducer from "./defaultImageReducer";
import defaultEmbedImageReducer from "./defaultEmbedImageReducer";
import userEmbedReducer from "./userEmbedReducer";
import likedSessions from "./likedSessionsReducer"
import networkStatusReducer from "./networkStatusReducer"

export default combineReducers({
  auth: authReducer,
  form: reduxForm,
  surveys: surveysReducer,
  mode: headerModeReducer,
  images: imageReducer,
  defaultImages: defaultImageReducer,
  defaultEmbedImages: defaultEmbedImageReducer,
  userEmbed: userEmbedReducer,
  categories: categoryReducer,
  userUploadedImages: userImagesReducer,
  userSelectedImages: selectedImagesReducer,
  userSelectedCategories: selectedCategoriesReducer,
  userSessions: userSessionsReducer,
  userSelectedCurrentSession: selectedCurrentSessionReducer,
  userClients: userClientsReducer,
  userOwners: userOwnersReducer,
  userOwnersSessionHistory: userOwnersSessionHistoryReducer,
  userOwnersSessionHistoryYears: userOwnersSessionHistoryYearsReducer,
  userReferee: userRefereeReducer,
  userCurrentWatchSession: userWatchReducer,
  recentlyWatchSession: recentlyWatchReducer,
  ownerSessionForUser: ownerSessionForUserReducer,
  mediaSession: mediaSessionReducer,
  mediaEmbedSession: mediaEmbedSessionReducer,
  productDetail: productDetailReducer,
  products: productReducer,
  productsNew: productNewReducer,
  productsFeatured: productFeaturedReducer,
  purchasedSessions: purchasesReducer,
  sessionSurvey: sessionSurveyReducer,
  error: errorReducer,
  likedSessions: likedSessions,
  networkStatus: networkStatusReducer
});
