import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import _ from "lodash";
import { Button, Card, Dropdown, Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import { API, APIAxios, Config } from "../../APIAndConfig";
import { Firebase } from "../../firebase";
import axios from "axios";
import moment from "moment";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { BsThreeDotsVertical } from "react-icons/bs";
import set from "date-fns/set";
import ReactTable from 'react-table-6';

function Invites(props) {

    const [info, setInfo] = useState([
        { label: 'Invitation 1', email: "", firstName: "", timeStamp: null, signedUp: null, lastLogin: null, used: false, allowed: false, clicked: false, resendClicked: false, deleteClicked: false, id_used: null, incomingTS: null },
        { label: "Invitation 2", email: "", firstName: "", timeStamp: null, signedUp: null, lastLogin: null, used: false, allowed: false, clicked: false, resendClicked: false, deleteClicked: false, id_used: null, incomingTS: null },
        { label: "Invitation 3", email: "", firstName: "", timeStamp: null, signedUp: null, lastLogin: null, used: false, allowed: false, clicked: false, resendClicked: false, deleteClicked: false, id_used: null, incomingTS: null },
        { label: "Invitation 4", email: "", firstName: "", timeStamp: null, signedUp: null, lastLogin: null, used: false, allowed: false, clicked: false, resendClicked: false, deleteClicked: false, id_used: null, incomingTS: null }
    ])
    const [info2, setInfo2] = useState([])
    const [isProcessing, setisProcessing] = useState(false)
    const [isResendProcessing, setisResendProcessing] = useState(false)
    const [currentUser, setCurrentUser] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [modalProps, setModalProps] = useState({
        header: '',
        message: ''
    })
    const [warningModal, setWarningModal] = useState({
        state: false,
        object: {},
        index: ''
    })

    const [sendInviteDeatils, setSendInviteDeatils] = useState({
        firstname: '',
        email: ''
    })
    const [inviteCount, setInviteCount] = useState(-1);
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <BsThreeDotsVertical size={20} ></BsThreeDotsVertical>
        </a>
    ));

    const getAllInvites = async (id) => {
        setInviteCount(-1);
        const token = await Firebase.Auth.currentUser.getIdToken();
        const header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        };
        try {
            const res = await axios.get(`${Config.APIUrl}/getInvites?senderId=${id}`, {
                headers: header,
            });
            if (res.status === 200 && res.data.data) {
                let responce = res.data.data
                let infoData = [...info]
                responce.map((obj) => {
                    let indexOrder = Number(obj.inviteOrder.slice(-1)) - 1
                    // console.log("indexorder",indexOrder);
                    infoData[indexOrder].email = obj.emailId
                    infoData[indexOrder].firstName = obj.firstName
                    infoData[indexOrder].timeStamp = moment(obj.timestamp).format('DD-MM-YYYY hh:mm A')
                    infoData[indexOrder].signedUp = obj.userId ? 'Yes' : 'No'
                    infoData[indexOrder].lastLogin = obj.lastlogin ? moment(obj.lastlogin).format('DD-MM-YYYY hh:mm A') : '-'
                    infoData[indexOrder].id_used = obj.id
                    infoData[indexOrder].incomingTS = obj.timestamp
                    infoData[indexOrder].used = true
                    infoData[indexOrder].allowed = false
                })
                setInfo(infoData)
                let displayCount = 0;
                let finalInvites = [];
                infoData.forEach((data) => {
                    if (data.email !== "") {
                        console.log("here",data);
                        displayCount += 1
                        finalInvites.push(data);
                    }
                })
                setInfo2(finalInvites);
                // console.log("SetInfocount", displayCount);
                setInviteCount(displayCount)
            }
        } catch (err) {
            switch (err.response?.data) {
                case "SESSION_EXPIRED":
                    const token = await Firebase.Auth.currentUser?.getIdToken();
                    if (token) {
                        await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                        window.location.reload()
                    }
                    break;
                case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
                case "DATABASE_LIMIT_EXCEEDED":
                    localStorage.setItem('service_unavailable_status', "failed");
                    await Firebase.Auth.signOut();
                    window.location.href = "/error"
                    break;
                default:
                    console.log("Error", err);
                    break;
            }
        }
    }

    useEffect(() => {
        setCurrentUser(props.auth)
        getAllInvites(props.auth.id)
    }, []);

    const handleChange = (e, index) => {
        let data = [...info]
        let inviteOrder = 'invite' + (index + 1)

        console.log(data[index].email.length);
        console.log(data[index].firstName.length);

        data[index] = {
            ...data[index],
            [e.target.name]: e.target.value,
            allowed: data[index].email.length > 0 && data[index].firstName.length > 0 ? inviteOrder : false
        }
        setInfo(data)

    }



    const handleChange2 = (index) => {
        // console.log("In handlechange2", index);
        let data = [...info]
        let inviteOrder = 'invite' + (index + 1)
        let x = data[index];
        x.allowed = inviteOrder || false
        let displayCount = 0;
        info.forEach((data) => {
            if (data.email !== "") {
                displayCount += 1
            }
        })
        // console.log("displayCount:", displayCount);
        setInviteCount(displayCount)
    }

    const resendInvite = async (e, obj, index) => {
        console.log(obj);
        console.log(currentUser);
        console.log("Resend Clicked");

        setisResendProcessing(true)
        let infoData = [...info]
        infoData[index].resendClicked = true
        setInfo(infoData)

        let payload = {
            user: currentUser,
            invites: obj
        }

        console.log("Payload is : ", payload);

        const token = await Firebase.Auth.currentUser.getIdToken();
        const header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        };
        try {
            const res = await axios.post(`${Config.APIUrl}/inviteMail?action=resend`, payload, {
                headers: header,
            });
            if (res.status === 200) {
                console.log("Done", res);
                // infoData[index].used = true
                infoData[index].allowed = false
                infoData[index].timeStamp = moment().format('DD-MM-YYYY')
                infoData[index].signedUp = "No"
                infoData[index].lastLogin = '-'
                infoData[index].resendClicked = false
                setInfo(infoData)
                setisResendProcessing(false)
                setShowModal(true)
                setModalProps({
                    header: "Invitation Sent",
                    message: infoData[index].label + " is re-sent successfully"
                })
                // alert(infoData[index].label + " is re-sent successfully")
            }
            else {
                setShowModal(true)
                setModalProps({
                    header: "Invitation Failed",
                    message: "Failed to send invitation"
                })
                // alert("Failed to send invitation")
            }
        } catch (err) {
            switch (err.response.data) {
                case "SESSION_EXPIRED":
                    const token = await Firebase.Auth.currentUser?.getIdToken();
                    if (token) {
                        await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                        window.location.reload()
                    }
                    break;
                case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
                case "DATABASE_LIMIT_EXCEEDED":
                    localStorage.setItem('service_unavailable_status', "failed");
                    await Firebase.Auth.signOut();
                    window.location.href = "/error"
                    break;
                default:
                    console.log("Error", err);
                    break;
            }
        }

    }

    const deleteInvite = async (obj, index) => {
        // console.log("Retract Clicked");
        console.log(obj);
        let payload = {
            id: obj.id_used
        }

        setisResendProcessing(true)
        setWarningModal({
            state: false,
            object: '',
            index: ''
        })
        let infoData = [...info]
        infoData[index].deleteInvite = true
        setInfo(infoData)

        // console.log("Payload is : ", payload);

        const token = await Firebase.Auth.currentUser.getIdToken();
        const header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        };
        try {
            const res = await axios.post(`${Config.APIUrl}/retractInvite`, payload, {
                headers: header,
            });
            if (res.status === 200) {
                console.log("Done", res);

                infoData[index].email = ""
                infoData[index].firstName = ""
                infoData[index].used = false
                infoData[index].clicked = false
                infoData[index].resendClicked = false
                infoData[index].id_used = null
                infoData[index].incomingTS = null
                infoData[index].allowed = false
                infoData[index].timeStamp = ''
                infoData[index].signedUp = ''
                infoData[index].lastLogin = null
                infoData[index].deleteClicked = false
                setInfo(infoData)
                setisResendProcessing(false)
                setShowModal(true)
                setModalProps({
                    header: "Invitation Retract",
                    message: infoData[index].label + " retract successful"
                })
                getAllInvites(props.auth.id)
                // alert(infoData[index].label + " is re-sent successfully")
            }
            else {
                setShowModal(true)
                setModalProps({
                    header: "Invitation Failed",
                    message: "Failed to send invitation"
                })
                // alert("Failed to send invitation")
            }
        } catch (err) {
            switch (err.response.data) {
                case "SESSION_EXPIRED":
                    const token = await Firebase.Auth.currentUser?.getIdToken();
                    if (token) {
                        await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                        window.location.reload()
                    }
                    break;
                case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
                case "DATABASE_LIMIT_EXCEEDED":
                    localStorage.setItem('service_unavailable_status', "failed");
                    await Firebase.Auth.signOut();
                    window.location.href = "/error"
                    break;
                default:
                    console.log("Error", err);
                    break;
            }
        }
    }

    // const renderInvitationFields = (obj, index) => {
    //     return (
    //         <>
    //             <div className="col-md-12 mt-4">

    //                 <div className={clsx('setting-card', 'card')}>
    //                     <div className={clsx('card-header', 'row')}>
    //                         <div className="col-10 col-lg-3">
    //                             <p className={clsx('setting-card-header mb-0 p-4')}>
    //                                 {obj.label}
    //                             </p>
    //                         </div>
    //                         <div className="col-lg-2 d-none d-lg-flex">
    //                             <p className={clsx('setting-card-header mb-0 p-4')}>
    //                                 First Name
    //                             </p>
    //                         </div>
    //                         <div className="col-lg-2 d-none d-lg-flex">
    //                             <p className={clsx('setting-card-header mb-0 p-4')}>
    //                                 Sent Time
    //                             </p>
    //                         </div>
    //                         <div className="col-lg-2 d-none d-lg-flex">
    //                             <p className={clsx('setting-card-header mb-0 p-4')}>
    //                                 Signed Up
    //                             </p>
    //                         </div>
    //                         <div className="col-lg-2 d-none d-lg-flex">
    //                             <p className={clsx('setting-card-header mb-0 p-4')}>
    //                                 Last Login
    //                             </p>
    //                         </div>
    //                         <div className="col-lg-1 d-none d-lg-flex">
    //                             <p className={clsx('setting-card-header mb-0 p-4')}>
    //                                 Action
    //                             </p>
    //                         </div>
    //                         <div className="col-2 d-lg-none d-flex">
    //                             <span>
    //                                 <Dropdown className="h-100 d-flex justify-content-center align-items-center" drop='down'>
    //                                     <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
    //                                     <Dropdown.Menu>
    //                                         <Dropdown.Item disabled={
    //                                             !info[index].allowed || info[index].clicked || info[index].signedUp === 'Yes' ? true : false
    //                                         }
    //                                             onClick={() => submitInvites(index)}>
    //                                             Send Invite
    //                                         </Dropdown.Item>
    //                                         <Dropdown.Item disabled={
    //                                             !info[index].used || info[index].resendClicked || info[index].signedUp === 'Yes' ? true : false
    //                                         }
    //                                             onClick={(e) => resendInvite(e, obj, index)}
    //                                         >
    //                                             Resend Invite
    //                                         </Dropdown.Item>
    //                                         <Dropdown.Item
    //                                             disabled={info[index].signedUp === 'Yes' || info[index].deleteInvite || !info[index].used ? true : false}
    //                                             onClick={(e) => deleteInvite(e, obj, index)}
    //                                         >
    //                                             Retract Invite
    //                                         </Dropdown.Item>
    //                                     </Dropdown.Menu>
    //                                 </Dropdown>
    //                             </span>
    //                         </div>
    //                     </div>
    //                     <div className="card-body row">
    //                         <div className="col-12 col-lg-3 form-group">
    //                             <input
    //                                 type="email"
    //                                 className={clsx('form-control')}
    //                                 name="email"
    //                                 value={obj.email}
    //                                 onChange={(e) => handleChange(e, index)}
    //                                 placeholder="Email"
    //                                 disabled={obj.used}
    //                             />
    //                         </div>
    //                         <div className="col-12 col-lg-2 form-group">
    //                             <input
    //                                 type="name"
    //                                 className={clsx('form-control')}
    //                                 name="firstName"
    //                                 value={obj.firstName}
    //                                 onChange={(e) => handleChange(e, index)}
    //                                 placeholder="First Name"
    //                                 disabled={obj.used}
    //                             />
    //                         </div>
    //                         <div className="col-12 col-lg-2 form-group">
    //                             <input
    //                                 type="text"
    //                                 className={clsx('form-control')}
    //                                 name="timeStamp"
    //                                 value={obj.timeStamp}
    //                                 onChange={(e) => handleChange(e, index)}
    //                                 disabled
    //                                 placeholder="Time"
    //                             />
    //                         </div>
    //                         <div className="col-12 col-lg-2 form-group">
    //                             <input
    //                                 type="text"
    //                                 className={clsx('form-control')}
    //                                 name="signedUp"
    //                                 value={obj.signedUp}
    //                                 onChange={(e) => handleChange(e, index)}
    //                                 disabled
    //                                 placeholder="Sign Up"
    //                             />
    //                         </div>
    //                         <div className="col-12 col-lg-2 form-group">
    //                             <input
    //                                 type="text"
    //                                 className={clsx('form-control')}
    //                                 name="lastLogin"
    //                                 value={obj.lastLogin}
    //                                 onChange={(e) => handleChange(e, index)}
    //                                 disabled
    //                                 placeholder="Last Login"
    //                             />
    //                         </div>
    //                         <div className="col-12 d-none d-lg-block col-lg-1 form-group">
    //                             <span>
    //                                 <Dropdown className="h-100 d-flex justify-content-center align-items-center" drop='up'>
    //                                     <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
    //                                     <Dropdown.Menu>
    //                                         <Dropdown.Item disabled={
    //                                             !info[index].allowed || info[index].clicked || info[index].signedUp === 'Yes' ? true : false
    //                                         }
    //                                             onClick={() => submitInvites(index)}>
    //                                             Send Invite
    //                                         </Dropdown.Item>
    //                                         <Dropdown.Item disabled={
    //                                             !info[index].used || info[index].resendClicked || info[index].signedUp === 'Yes' ? true : false
    //                                         }
    //                                             onClick={(e) => resendInvite(e, obj, index)}
    //                                         >
    //                                             Resend Invite
    //                                         </Dropdown.Item>
    //                                         <Dropdown.Item
    //                                             disabled={info[index].signedUp === 'Yes' || info[index].deleteInvite || !info[index].used ? true : false}
    //                                             onClick={(e) => {
    //                                                 setWarningModal({
    //                                                     state: true,
    //                                                     object: obj,
    //                                                     index: index
    //                                                 })
    //                                                 // deleteInvite(e, obj, index)
    //                                             }}
    //                                         >Retract Invite</Dropdown.Item>
    //                                     </Dropdown.Menu>
    //                                 </Dropdown>
    //                             </span>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 {/* <div className={clsx('form-group mb-5 mt-3 col-12')}>
    //                 <button className={clsx('btn btn-lg setting-button col-12 col-md-2 mt-2 float-md-right')}
    //                     disabled={
    //                         !info[index].used || info[index].resendClicked || info[index].signedUp === 'Yes' ? true : false
    //                     }
    //                     onClick={(e) => resendInvite(e, obj, index)}
    //                 > {isResendProcessing && info[index].resendClicked ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Resend Invite</span>}</button>
    //                 <button className={clsx('btn btn-lg setting-button col-12 col-md-2 mt-2')}
    //                     disabled={
    //                         !info[index].allowed || info[index].clicked || info[index].signedUp === 'Yes' ? true : false
    //                     }
    //                     onClick={() => submitInvites(index)}>
    //                     {isProcessing && info[index].clicked ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Send Invite</span>}</button>
    //             </div> */}
    //             </div>
    //         </>
    //     )
    // }

    // const submitInvites = async (index) => {

    //     if (info[index].firstName === "" || info[index].email === "") {
    //         return
    //     }

    //     setisProcessing(true)
    //     let infoData = [...info]
    //     infoData[index].clicked = true
    //     setInfo(infoData)

    //     let payload = {
    //         user: currentUser,
    //         invites: infoData[index]
    //     }

    //     console.log("Payload is : ", payload);

    //     const token = await Firebase.Auth.currentUser.getIdToken();
    //     const header = {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + token,
    //     };
    //     try {
    //         const res = await axios.post(`${Config.APIUrl}/inviteMail`, payload, {
    //             headers: header,
    //         });
    //         if (res.status === 200) {
    //             console.log("Done", res);
    //             if (res.data.message === "USER_ALREADY_REGISTERED") {
    //                 setisProcessing(false)
    //                 infoData[index].clicked = false
    //                 setShowModal(true)
    //                 setModalProps({
    //                     header: "User Already Present",
    //                     message: "User is already signed up to Positive Prime"
    //                 })
    //                 // alert("User is already signed up to Positive Prime")
    //             }
    //             else if (res.data.message === "USER_ALREADY_PRESENT_IN_INVITE") {
    //                 setisProcessing(false)
    //                 infoData[index].clicked = false
    //                 setShowModal(true)
    //                 setModalProps({
    //                     header: "Invitation Present",
    //                     message: "User is already invited please try another user"
    //                 })
    //                 // alert("User is already invited please try another user")
    //             }
    //             else {

    //                 console.log("In else");

    //                 infoData[index].used = true
    //                 infoData[index].allowed = false
    //                 infoData[index].timeStamp = moment().format('DD-MM-YYYY hh:mm A')
    //                 infoData[index].signedUp = "No"
    //                 infoData[index].lastLogin = '-'
    //                 infoData[index].incomingTS = moment().toISOString()
    //                 infoData[index].clicked = false
    //                 setInfo(infoData)
    //                 setisProcessing(false)
    //                 setShowModal(true)
    //                 setModalProps({
    //                     header: "Invitation Sent",
    //                     message: infoData[index].label + " is sent successfully"
    //                 })
    //                 getAllInvites(payload.user.id)
    //                 // alert(infoData[index].label + " is sent successfully")
    //             }
    //         }
    //         else {
    //             setShowModal(true)
    //             setModalProps({
    //                 header: "Invitation Failed",
    //                 message: "Failed to send invitation"
    //             })
    //             // alert("Failed to send invitation")
    //         }
    //     } catch (err) {
    //         switch (err.response.data) {
    //             case "SESSION_EXPIRED":
    //                 const token = await Firebase.Auth.currentUser?.getIdToken();
    //                 if (token) {
    //                     await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
    //                     window.location.reload()
    //                 }
    //                 break;
    //             case "BAD_CONNECTION":
    //             case "UNCAUGHT_ERROR":
    //             case "DATABASE_LIMIT_EXCEEDED":
    //                 localStorage.setItem('service_unavailable_status', "failed");
    //                 await Firebase.Auth.signOut();
    //                 window.location.href = "/error"
    //                 break;
    //             default:
    //                 console.log("Error", err);
    //                 break;
    //         }
    //     }
    // }
    const submitInvitesButton = async (index) => {
        // console.log("theinfoData[index]-->",info[index]);
        if (info[index].firstName === "" || info[index].email === "") {
            let infoData = [...info]
            let x = infoData[index];
            x.firstName = sendInviteDeatils.firstname
            x.email = sendInviteDeatils.email
            setInfo(infoData)
        }

        setisProcessing(true)
        let infoData = [...info]
        infoData[index].clicked = true
        setInfo(infoData)

        let payload = {
            user: currentUser,
            invites: infoData[index]
        }

        console.log("Payload is : ", payload);

        const token = await Firebase.Auth.currentUser.getIdToken();
        const header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        };
        try {
            const res = await axios.post(`${Config.APIUrl}/inviteMail`, payload, {
                headers: header,
            });
            if (res.status === 200) {
                console.log("Done", res);
                if (res.data.message === "USER_ALREADY_REGISTERED") {
                    setisProcessing(false)
                    infoData[index].clicked = false
                    setShowModal(true)
                    setModalProps({
                        header: "User Already Present",
                        message: "User is already signed up to Positive Prime"
                    })
                    // alert("User is already signed up to Positive Prime")
                }
                else if (res.data.message === "USER_ALREADY_PRESENT_IN_INVITE") {
                    setisProcessing(false)
                    infoData[index].clicked = false
                    setShowModal(true)
                    setModalProps({
                        header: "Invitation Present",
                        message: "User is already invited please try another user"
                    })
                    // alert("User is already invited please try another user")
                }
                else {

                    console.log("In else");

                    infoData[index].used = true
                    infoData[index].allowed = false
                    infoData[index].timeStamp = moment().format('DD-MM-YYYY hh:mm A')
                    infoData[index].signedUp = "No"
                    infoData[index].lastLogin = '-'
                    infoData[index].incomingTS = moment().toISOString()
                    infoData[index].clicked = false
                    setInfo(infoData)
                    setisProcessing(false)
                    setShowModal(true)
                    setModalProps({
                        header: "Invitation Sent",
                        message: infoData[index].label + " is sent successfully"
                    })
                    // console.log("theinfoData[index]:",infoData[index]);
                    getAllInvites(payload.user.id)
                    // alert(infoData[index].label + " is sent successfully")
                }
            }
            else {
                setShowModal(true)
                setModalProps({
                    header: "Invitation Failed",
                    message: "Failed to send invitation"
                })
                // alert("Failed to send invitation")
            }
        } catch (err) {
            switch (err.response.data) {
                case "SESSION_EXPIRED":
                    const token = await Firebase.Auth.currentUser?.getIdToken();
                    if (token) {
                        await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                        window.location.reload()
                    }
                    break;
                case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
                case "DATABASE_LIMIT_EXCEEDED":
                    localStorage.setItem('service_unavailable_status', "failed");
                    await Firebase.Auth.signOut();
                    window.location.href = "/error"
                    break;
                default:
                    console.log("Error", err);
                    break;
            }
        }

        setSendInviteDeatils({
            email: '',
            firstname: ''
        })
    }


    const onModalClose = () => {
        setShowModal(false)
    }

    // console.log("info---->",info);
    return (
        <div className="row justify-content-center">
            <div className="col-12 mb-5">
                <h2 className="main-title m-0 p-3">
                    Send Invitation
                </h2>

                {/* send invite form */}
                {
                    inviteCount===4?<><center>You have now reached your invitation limit</center></>: <div className="d-flex container justify-content-center">
                    <div className={clsx('setting-card', 'card', 'send-invite-button')}>

                        <div className="row px-5 form-group pt-4">
                            <input
                                type="email"
                                className={clsx('form-control')}
                                name="email"
                                value={sendInviteDeatils.email}
                                onChange={(e) => {
                                    setSendInviteDeatils({
                                        ...sendInviteDeatils,
                                        email: e.target.value
                                    })
                                }}
                                placeholder="Email"
                            // disabled={obj.used}
                            />
                        </div>

                        <div className="row px-5 form-group">
                            <input
                                type="name"
                                className={clsx('form-control')}
                                name="firstName"
                                value={sendInviteDeatils.firstname}
                                onChange={(e) => {
                                    setSendInviteDeatils({
                                        ...sendInviteDeatils,
                                        firstname: e.target.value
                                    })
                                }}
                                placeholder="First Name"
                            // disabled={obj.used}
                            />
                        </div>
                        <div className="row px-5 form-group">
                            <button className="btn btn-primary btn-block p-auto m-auto"
                                onClick={() => {
                                    let newIndex = null;
                                    newIndex = info.findIndex((item) => {
                                        console.log("index", item);

                                        return item.email == "";

                                    })
                                    if (newIndex !== null) {
                                        // console.log("tyheaccumulator, item, index, array",newIndex);
                                        handleChange2(newIndex);
                                        if (info[newIndex].allowed) {
                                            submitInvitesButton(newIndex)
                                        }
                                    } else {
                                        console.log("You cannot send invitaiton")
                                    }

                                }}

                                disabled={sendInviteDeatils?.firstname && sendInviteDeatils?.email ? false : true}>
                                Send Invite
                            </button>
                        </div>


                    </div>
                </div>
                }
               
                {/* end send invite */}
            </div>

            {/* Invitaiton Table */}
            <Card border="dark" className={clsx('card-blur', 'invite-table-width')}>
                <Card.Header className="text-white font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>
                    <div>
                        <span>Invitations</span>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    {
                        inviteCount!==-1?<ReactTable
                        data={info2}
                        columns={[{
                            Header: 'Invitation Email',
                            accessor: 'email',
                            headerClassName: 'p-3 text-light',
                            className: 'text-center'
                        }, {
                            Header: 'First Name',
                            headerClassName: 'p-3 text-light',
                            accessor: 'firstName',
                            className: 'text-center'
                        }, {
                            Header: 'Sent',
                            headerClassName: 'p-3 text-light',
                            accessor: 'timeStamp',
                            className: 'text-center'
                        },
                        {
                            Header: 'Signed Up',
                            accessor: 'signedUp',
                            headerClassName: 'p-3 text-light',
                            className: 'text-center'
                        },
                        {
                            Header: 'Last Login ',
                            accessor: 'lastLogin',
                            headerClassName: 'p-3 text-light',
                            className: 'text-center'
                        },
                        {
                            Header: 'Action',
                            headerClassName: 'p-3 text-light',
                            className: 'text-center',
                            Cell: (data) => {
                                // console.log("resend data check->1",data); 
                                // console.log("resend data check-->index",data.index);
                                // console.log("resend data check--> data.original",data.original);                                  
                                return (
                                    <div className="d-flex">
                                        {/* <button className="btn btn-primary btn-block p-auto m-auto" style={{fontSize:"0.7rem"}} onClick={() => {}} >
                                        Resend Invite
                                    </button> */}
                                        <button className="btn btn-primary btn-block p-auto m-auto" style={{ fontSize: "0.7rem" }} onClick={(e) => { resendInvite(e, data.original, data.index) }} >
                                            Resend Invite
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-primary btn-block p-auto m-auto" style={{ fontSize: "0.7rem" }}
                                            onClick={(e) => {
                                                //deleteInvite(e, data.original,data.index)
                                                setWarningModal({
                                                    state: true,
                                                    object: data.original,
                                                    index: data.index
                                                })
                                            }} >
                                            Retract Invite
                                        </button>
                                    </div>
                                )
                            }
                        }
                        ]}
                        defaultPageSize={inviteCount}
                        noDataText='No Data Found'
                        showPagination={false}
                    />: <center><Spinner animation="border" size="sm" className="p-2" /></center>
                    }
                    

                </Card.Body>
            </Card>
            {/* End invitation table */}
            {/* <div className="col-12" >
                {info.map((obj, index) => {
                    return renderInvitationFields(obj, index)
                })
                }
            </div> */}
            <Modal
                show={showModal}
            >
                <ModalHeader>{modalProps.header}</ModalHeader>
                <ModalBody>
                    {modalProps.message}
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={onModalClose}
                    >
                        Close
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                show={warningModal.state}
            >
                <ModalHeader>Retract Invite</ModalHeader>
                <ModalBody>
                    Confirm you are retracting this invite from this user - this will disable the invitation link that was previously sent
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            setWarningModal({
                                state: false,
                                object: {},
                                index: ''
                            })
                        }}
                    >
                        Close
                    </button>
                    <button
                        className="btn x-btn x-primary "
                        type="button"
                        onClick={() => deleteInvite(warningModal.object, warningModal.index)}
                    >
                        Delete
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        userClients: state.userClients
    };
}


export default connect(mapStateToProps, actions)(withRouter(Invites));
