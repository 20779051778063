import React, { Component } from "react";
import {
    Col, Row, Card, Spinner, Alert, Table, Container, DropdownButton,
    Dropdown,
    Modal,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { API, Config } from "../../APIAndConfig";
import ReactTable from 'react-table-6'


export class InviteReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteReportData: []
        }
    }

    async componentDidMount() {
        await this.inviteReport()
    }

    async inviteReport() {
        await API.getInviteReport()
            .then((res) => {
                if (res.payload && res.payload.data) {
                    console.log("Invite Reports",res.payload.data);
                    this.setState({ inviteReportData : res.payload.data })
                }
            })
    }
    render() {
        if (this.state.inviteReportData.length === 0)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
           
            <div>
                <h2 className="main-title m-0 p-3">Invite Report</h2>
                <hr className="m-0" />    
                <Card className="card-blur my-5">
                    <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Invite Report</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                        defaultPageSize = {10}
                        data={this.state.inviteReportData}
                            columns={[
                                {
                                    id: "friend",
                                    Header: 'Friend',
                                    accessor: 'Friend',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'user_id',
                                    Header: 'userID',
                                    accessor: 'userID',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'timestamp',
                                    Header: 'Timestamp',
                                    accessor: 'timestamp',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'first_name',
                                    Header: 'First Name',
                                    accessor: 'first_name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'last_name',
                                    Header: 'Last Name',
                                    accessor: 'last_name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'email',
                                    Header: 'Email',
                                    accessor: 'Email',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'inviteOrder',
                                    Header: 'Invite Order',
                                    accessor: 'inviteOrder',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'status',
                                    Header: 'Status',
                                    accessor: 'status',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'Role',
                                    Header: 'Role',
                                    accessor: 'Role',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                            ]}
                        />
                    </Card.Body>
                </Card>
                    
                    
                </div>
        )
    }

}


export default InviteReport