import {USER_CURRENT_SESSION_HISTORY} from '../actions/types';

export default function(state = null, action) {
   //console.log(action);
   switch (action.type) {
      case USER_CURRENT_SESSION_HISTORY:
         return action.payload;
      default:
         return state;
   }
}
