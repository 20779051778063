export const FETCH_USER = "fetch_user";
export const FETCH_SURVEYS = "fetch_surveys";
export const FETCH_CATEGORIES = "fetch_categories";

export const MODE_DEFAULT = "mode_default";
export const MODE_LOGIN = "mode_login";
export const MODE_SIGNUP = "mode_signup";

export const FETCH_IMAGE = "fetch_image"; // TODO: remove this since we are using it for UNSPLASH
export const FETCH_DEFAULT_IMAGES = "fetch_default_images";
export const FETCH_DEFAULT_EMBED_IMAGES = "fetch_default_embed_images";
export const FETCH_USER_IMAGES = "fetch_user_images";
export const FETCH_USER_SESSIONS = "fetch_user_sessions";
export const FETCH_CURRENT_SESSION = "fetch_current_session";
export const FETCH_CURRENT_EMBED_SESSION = "fetch_current_embed_session";
export const FETCH_USER_CLIENTS = "fetch_user_clients";
export const FETCH_USER_OWNERS = "fetch_user_owners"; // No better word. Found on https://www.thesaurus.com/browse/client
export const FETCH_USER_REFEREE = "fetch_user_referee";
export const FETCH_USER_EMBED = "fetch_user_embed";

export const FETCH_PRODUCT = "fetch_product";
export const FETCH_PRODUCTS = "fetch_products";
export const FETCH_PRODUCTS_FEATURED = "fetch_products_featured";
export const FETCH_PRODUCTS_NEW = "fetch_products_new";
export const FETCH_PURCHASED_SESSIONS = "fetch_purchased_sessions";
export const FETCH_OWNER_SESSION_FOR_USER = "fetch_owner_session_for_user";

export const USER_SELECTED_IMAGES = "user_selected_images";
export const USER_SELECTED_CATEGORIES = "user_selected_categories";
export const USER_SELECTED_CURRENT_SESSION = "user_selected_current_session";
export const FETCH_USER_OWNERS_SESSION_HISTORY = "fetch_user_owners_session_history";
export const FETCH_USER_OWNERS_SESSION_HISTORY_YEARS = "fetch_user_owners_session_history_years";

export const USER_CURRENT_SESSION_HISTORY = "user_current_session_history";
export const FETCH_RECENTLY_WATCH = "fetch_recently_watch";
export const USER_CURRENT_SESSION_SURVEY = "user_current_session_survey";
export const ERROR_MESSAGE = "error_message";

export const FETCH_LIKED_SESSIONS = "liked_sessions"

export const FETCH_NETWORK_STATUS = "fetch_network_status";
