import React, { Component } from 'react';
import { Firebase } from '../../firebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import * as actions from '../../actions';
// import map from 'lodash/map';
// import _ from 'lodash';
import '../../scss/Settings.css';
import Loader from '../common/Loader';
import { makeAssetsUrl, makePlayerImageUrl } from "../../APIAndConfig";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import moment from 'moment';
import { API } from "../../APIAndConfig";

class Settings extends Component {
   constructor(props) {
      super(props);
      this.state = { file: null, imagePreviewUrl: null, error: null };

      this.userDataChange = {
         // firstName: null,
         // lastName: null,
         // title: null,
         // address1: null,
         // address2: null,
         // city: null,
         // state: null,
         // postalCode: null,
         // country: null,
         // phone: null,
         // dob_dd: null,
         // dob_mm: null,
         // dob_yy: null,
         // gender: null
      };
   }

   componentDidMount() {
      this.props.resetError();
   }

   componentWillUnmount() {
      this.props.resetError();
   }

   onOpenNav(e) {
      document.getElementById('sidebar').classList.add('sidebarOpen');
   }

   onSave(e) {
      e.preventDefault();
      this.props.saveUserProfile(this.props.auth.id, this.userDataChange);
      alert('Change has been saved');
   }

   onSubmit() {
   }
   onInputFieldChange(e) {
      this.userDataChange[e.target.name] = e.target.value;
   }


   onModalOpen(e) {
      e.preventDefault();
      const modal = document.getElementById('confirmationModal');
      modal.style.display = 'block';
   }
   confirmationModalClose(e) {
      e.preventDefault()
      const modal = document.getElementById('confirmationModal');
      if (modal)
         modal.style.display = 'none';
   }

   onOpenAddImage(e) {
      e.preventDefault();
      const modal = document.getElementById('userModalContainer');
      if (modal)
         modal.style.display = 'block';
   }

   onClose(e) {
      e.preventDefault();
      const modal = document.getElementById('userModalContainer');
      if (modal)
         modal.style.display = 'none';

      this.setState({ file: null, imagePreviewUrl: null, error: null });

      const file = document.getElementById('userInputFile')
      if (file)
         file.value = '';
   }

   onFileChange(e) {
      e.preventDefault();
      //this.setState({ file: event.target.files[0] });

      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
         //console.log('Setting states for file and imagePreviewUrl', this.state);
         this.setState({
            file: file,
            imagePreviewUrl: reader.result
         });
      };
      reader.readAsDataURL(file);

   }

   onSubmitUpload(e) {
      e.preventDefault();
      const modal = document.getElementById('userModalContainer');
      const modalLoader = document.getElementById('modalLoader');
      const imagePreview = document.getElementById('userImagePreview');

      if (imagePreview)
         imagePreview.style.display = 'none';

      if (modalLoader)
         modalLoader.style.display = 'block';

      const inputElem = document.getElementById('userInputFile');

      //console.log('onSubmitUpload - uploading file object:', this.state.file);
      // TODO: move the modal, modalLoader, and imagePreview out of the submitImage
      if (this.state.file) {
         this.props.submitProfileImage(
            this.props.auth,
            this.state.file,
            modal,
            this,
            imagePreview,
            modalLoader,
            inputElem
         );
      }
   }

   async onCancelSubscription(e) {
      e.preventDefault();

      await this.props.cancelSubscription(this.props.auth);

      alert('Your subscription has been cancelled');
   }

   async onCancelProSubscription(e) {
      e.preventDefault();

      await this.props.cancelProSubscription(this.props.auth);

      alert('Your membership subscription has been cancelled');
   }
   async disableAccount(e) {
      e.preventDefault();

      let result = await API.cancelAccount()
      if (result.successful) {
         await Firebase.Auth.signOut();
      } else {
         alert('Please try again!');
      }


   }


   renderRoleArea() {
      if (!this.props.auth) {
         return <div />;
      }
      return (
         <div className="p-4 bg-white shadow mt-4">
            <div className="d-flex fontRegular">
               <p className="flex-grow-1 mb-4 fontBold">
                  Role:
                  </p>
               <p className="text-right" />
            </div>
            <div className="row m-0 pl-0 pr-1 py-1">
               <div className="row m-0 pl-0 pr-1 py-1">
                  <input disabled defaultValue={this.props.auth.role.label} />
               </div>
            </div>
         </div>
      );
   }

   renderSubscriptionArea() {
      if (!this.props.auth || !this.props.auth.isSubscribed) {
         return <div />;
      }
      return (
         <div className="p-3 flex-grow-1 flex-lg-grow-0">
            <div className="p-4 bg-white shadow">
               <div className="d-flex fontRegular">
                  <p className="flex-grow-1 mb-4 fontBold">
                     Credit Subscription Information
                  </p>
                  <p className="text-right" />
               </div>
               <div>
                  <div className="row m-0 pl-0 pr-1 py-1">
                     <p>
                        Your subscription for 60 Credits a week is currently
                        active.
                     </p>
                  </div>
                  <div className="row m-0 pl-0 pr-1 py-1">
                     <button
                        className="btn btn-primary btn-block"
                        onClick={this.onCancelSubscription.bind(this)}
                     >
                        Cancel Subscription
                     </button>
                  </div>
               </div>
            </div>
         </div>
      );
   }
   renderSubscription() {
      if (!this.props.auth || (this.props.auth.subscriptionType == null && this.props.auth.role.id !== 2 && this.props.auth.role.id !== 4)) {
         return <div />;
      }
      let renewal
      // console.log("object", this.props.auth.subscription.plan.nickname)
      if (this.props.auth.subscription) {
         renewal = moment.unix(this.props.auth.subscription.current_period_end).format("YYYY-DD-MM ");
      } else {
         renewal = "Not Available"
      }
      let membershipType
      switch (this.props.auth.subscription?.plan.nickname) {
         case 'premium-test':
            membershipType = 'Your $1.00/month Premium';
            break
         case 'premium-legacy':
            membershipType = 'Your $7.99/month Premium ';
            break
         case 'pro-yearly':
            membershipType = 'Your $99/year Premium';
            break
         case 'pro-monthly':
            membershipType = 'Your $9.99/month Premium ';
            break
         case 'signature-monthly':
            membershipType = 'Your $19.99/month Signature ';
            break
         case 'signature-yearly':
            membershipType = 'Your $199.99/year Signature';
            break
         case 'signature-test':
            membershipType = 'Your $1.00/month Signature';
            break
         case 'vip-yearly-special':
            membershipType = 'Your $359.00/year VIP Concierge';
            break
         case 'vip-yearly':
            membershipType = 'Your $499.00/year VIP Concierge';
            break
         case 'vip-test':
            membershipType = 'Your $1.00/year VIP Concierge';
            break
         case 'vip-discount':
            membershipType = 'Your $159.00/year VIP Concierge';
            break
         case 'leadership-test':
            membershipType = 'Your $1.00/month Leadership';
            break
         case 'leadership-legacy':
            membershipType = 'Your $60.00/month Leadership';
            break
         case 'leadership-monthly':
            membershipType = 'Your $19.99/month Leadership';
            break
         case 'leadership-full':
            membershipType = 'Your $997.00 Leadership';
            break
         case 'leadership-59mo':
            membershipType = 'Your $59.00/month Leadership';
            break
         case 'legacy-premium-yearly':
            membershipType = 'Your $99.00/year Legacy';
            break
         case 'legacy-vip-invalid':
            membershipType = 'Your $159.00/year Legacy';
            break
         case 'legacy-monthly-credit':
            membershipType = 'Your $7.99/month Legacy';
            break
         case 'legacy-balance-for-professional':
            membershipType = 'Your $60.00/month Legacy';
            break
         default:
            membershipType = "No"


      }

      return (
         <div className="p-3 flex-grow-1 flex-lg-grow-0">
            <div className="p-4 bg-white shadow">
               <div className="d-flex fontRegular">
                  <p className="flex-grow-1 mb-4 fontBold">
                     Membership Subscription Information
                  </p>
                  <p className="text-right" />
               </div>
               <div>
                  <div className="row m-0 pl-0 pr-1 py-1">
                     <p>
                        {membershipType} membership subscription is
                        currently active.
                     </p>
                  </div>
                  <div className="row m-0 pl-0 pr-1 py-1">
                     <p>
                        Renewal Date : {renewal}
                     </p>
                  </div>
                  <div className="row m-0 pl-0 pr-1 py-1">
                     <button
                        className="btn btn-primary btn-block"
                        onClick={this.onModalOpen.bind(this)}
                     >
                        Cancel Account
                     </button>
                  </div>
               </div>
            </div>
         </div>
      );
   }
   renderMembershipSubscriptionArea() {
      if (
         !this.props.auth ||
         !this.props.auth.isSubscribed_membership_type ||
         this.props.auth.isSubscribed_membership_type === 'none'
      ) {
         return <div />;
      }

      let membershipType = '$9.99/month Premium';
      if (this.props.auth.role.id === 5) {
         membershipType = '$19.99/month Signature';
      } else if (this.props.auth.isSubscribed_membership_type === 'pro_yearly') {
         membershipType = '$99.00/year Premium';
      } else if (this.props.auth.isSubscribed_membership_type === 'vip_yearly') {
         membershipType = '$159.00/year VIP Concierge';
      }

      return (
         <div className="p-3 flex-grow-1 flex-lg-grow-0">
            <div className="p-4 bg-white shadow">
               <div className="d-flex fontRegular">
                  <p className="flex-grow-1 mb-4 fontBold">
                     Membership Subscription Information
                  </p>
                  <p className="text-right" />
               </div>
               <div>
                  <div className="row m-0 pl-0 pr-1 py-1">
                     <p>
                        Your {membershipType} membership subscription is
                        currently active.
                     </p>
                  </div>
                  <div className="row m-0 pl-0 pr-1 py-1">
                     <button
                        className="btn btn-primary btn-block"
                        onClick={this.onCancelProSubscription.bind(this)}
                     >
                        Cancel Membership Subscription
                     </button>
                  </div>
               </div>
            </div>
         </div>
      );
   }

   renderDescriptionArea() {
      if (!this.props.auth) {
         return <div />;
      }


      const defaultImg = makeAssetsUrl('user_default.png');
      let imageProfileSrc = this.props.auth ? this.props.auth.profileImageLargeUrl ||
         this.props.auth.profileImageUrl || defaultImg : defaultImg;

      return (
         <div className="col-12 col-lg-6 col-xl-4 p-0">
            <div className="p-3">
               <div className="bg-white shadow">
                  <div
                     className="position-relative"
                     onClick={this.onOpenAddImage.bind(this)}
                     style={{ cursor: 'pointer' }}
                  >
                     <img
                        alt="profile-pic"
                        className="w-100"
                        src={imageProfileSrc}
                     />
                  </div>
                  <div className="p-4 fontSmall">
                     <div className="d-flex fontRegular">
                        <p className="flex-grow-1 mb-4 fontBold">About</p>
                        <p className="text-primary text-right">
                           <FontAwesomeIcon icon={faEdit} />
                        </p>
                     </div>
                     <p>{this.props.auth.description}</p>
                  </div>
               </div>
            </div>
         </div>
      );
   }

   confirmationModal(e) {
      return (
         <div id="confirmationModal" className="modal modalContainer" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                     <h5 className="modal-title">Cancel Account</h5>

                  </div>
                  <div className="modal-body">
                     <p>Are you sure you wish to cancel your account?</p>
                  </div>
                  <div className="modal-footer">
                     <button type="button" className="btn btn-primary" onClick={this.disableAccount.bind(this)}>Yes</button>
                     <button type="button" className="btn btn-secondary" onClick={this.confirmationModalClose.bind(this)}>No</button>
                  </div>
               </div>
            </div>
         </div>
      );

   }


   renderModal() {
      let { file, imagePreviewUrl } = this.state;
      let $imagePreview = null;

      if (file) {
         $imagePreview = (
            <div
               style={{
                  width: 'fit-content',
                  display: 'inline-block'
               }}
            >
               <img alt={file.name} src={imagePreviewUrl} />
            </div>
         );
      }

      return (
         <div id="userModalContainer" className="modalContainer ">
            <div className="uploadModalContent pp-animate-bottom">
               <span className="close" onClick={this.onClose.bind(this)}>
                  &times;
               </span>
               <p>Select a profile picture from your computer</p>
               <input
                  id="userInputFile"
                  onChange={this.onFileChange.bind(this)}
                  type="file"
                  accept="image/*"
               />
               <div id="userImagePreview" className="imgPreview">
                  {$imagePreview}
               </div>
               <Loader />
               {/* {this.renderError()} */}
               <button
                  className="btn btn-primary"
                  style={{ margin: '10px 0px' }}
                  type="submit"
                  onClick={this.onSubmitUpload.bind(this)}
               >
                  Update Now
               </button>
            </div>
         </div>
      );
   }

   render() {
      if (!this.props.auth) {
         return <div />;
      }

      // if (this.props.auth.sex) {
      //    document.getElementById('genderSelectBox').value = this.props.auth.sex;
      // }

      return (
         <div className="mainWidth">
            <div className="d-flex">
               <button
                  className="btn bg-transparent d-md-none"
                  onClick={this.onOpenNav.bind(this)}
               >
                  <FontAwesomeIcon icon={faBars} />
               </button>
               <p className="text-secondary m-0 px-0 py-3 px-md-3">Settings</p>
            </div>
            <hr className="m-0" />
            <div className="row m-0 p-3">
               {this.renderDescriptionArea()}
               <div className="col-12 col-lg-6 col-xl-8 p-0">
                  <div className="d-flex flex-wrap">
                     <div className="p-3 flex-grow-1 flex-lg-grow-0">
                        <div className="p-4 bg-white shadow">
                           <div className="d-flex fontRegular">
                              <p className="flex-grow-1 mb-4 fontBold">
                                 Contact Information
                              </p>
                              <p className="text-right">
                                 <FontAwesomeIcon icon={faEdit} />
                              </p>
                           </div>
                           <form
                              onSubmit={this.props.handleSubmit(
                                 this.onSubmit.bind(this)
                              )}
                           >
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="name"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Name
                                 </label>
                                 <input
                                    type="text"
                                    name="firstName"
                                    className="col form-control form-control-sm"
                                    placeholder="First name"
                                    defaultValue={this.props.auth.firstName}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="name"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                 </label>
                                 <input
                                    type="text"
                                    name="lastName"
                                    className="col form-control form-control-sm"
                                    defaultValue={this.props.auth.lastName}
                                    placeholder="Last name"
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="title"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Title
                                 </label>
                                 <input
                                    type="text"
                                    name="title"
                                    className="col form-control form-control-sm"
                                    placeholder="Author / CEO"
                                    defaultValue={this.props.auth.title}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="inputAddress1"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Address1
                                 </label>
                                 <input
                                    type="text"
                                    name="address1"
                                    className="col form-control form-control-sm"
                                    placeholder="Street Address"
                                    defaultValue={this.props.auth.address1}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="inputAddress2"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Address2
                                 </label>
                                 <input
                                    type="text"
                                    name="address2"
                                    className="col form-control form-control-sm"
                                    placeholder="Apt, Suite, etc"
                                    defaultValue={this.props.auth.address2}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="city"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    City
                                 </label>
                                 <input
                                    type="text"
                                    name="city"
                                    className="col form-control form-control-sm"
                                    placeholder="City"
                                    defaultValue={this.props.auth.city}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="state"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    State
                                 </label>
                                 <input
                                    type="text"
                                    name="state"
                                    className="col form-control form-control-sm"
                                    placeholder="State / Province"
                                    defaultValue={this.props.auth.state}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>

                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="postalCode"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Postal Code
                                 </label>
                                 <input
                                    type="text"
                                    name="postalCode"
                                    className="col form-control form-control-sm"
                                    placeholder="Postal Code"
                                    defaultValue={this.props.auth.postalCode}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>

                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="country"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Country
                                 </label>
                                 <input
                                    type="text"
                                    name="country"
                                    className="col form-control form-control-sm"
                                    placeholder="Country"
                                    defaultValue={this.props.auth.country}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>

                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="inputEmail"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Email
                                 </label>
                                 <p className="col form-control-plaintext form-control-sm">
                                    {this.props.auth.email}
                                 </p>
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="inputPhone"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Phone
                                 </label>
                                 <input
                                    type="text"
                                    name="phone"
                                    className="col form-control form-control-sm"
                                    placeholder="Phone"
                                    defaultValue={this.props.auth.phone}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="inputAddress2"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    DOB
                                 </label>
                                 <input
                                    type="text"
                                    name="dob_mm"
                                    className="col form-control form-control-sm customInput"
                                    placeholder="MM"
                                    defaultValue={this.props.auth.dob_mm}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                                 <input
                                    type="text"
                                    name="dob_dd"
                                    className="col form-control form-control-sm mx-2 customInput"
                                    placeholder="DD"
                                    defaultValue={this.props.auth.dob_dd}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                                 <input
                                    type="text"
                                    name="dob_yy"
                                    className="col form-control form-control-sm customInputYear"
                                    placeholder="YYYY"
                                    defaultValue={this.props.auth.dob_yy}
                                    onChange={this.onInputFieldChange.bind(
                                       this
                                    )}
                                 />
                              </div>
                              <div className="row m-0 m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="gender"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Gender
                                 </label>
                                 <div className="col p-0">
                                    <select
                                       id="genderSelectBox"
                                       name="gender"
                                       className="custom-select custom-select-sm"
                                       onChange={this.onInputFieldChange.bind(
                                          this
                                       )}
                                       defaultValue={this.props.auth.sex}
                                    >
                                       <option>Decline To State</option>
                                       <option>Male</option>
                                       <option>Female</option>
                                       <option>Other</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="row m-0 pl-0 pr-1 py-1">
                                 <label
                                    htmlFor="subscribed"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                 >
                                    Subscribed
                                 </label>
                                 <p className="col form-control-plaintext form-control-sm">
                                    {this.props.auth.isSubscribed ? 'YES' : 'NO'}
                                 </p>
                              </div>

                              <div className="text-right pl-0 pr-1 py-1 mt-3">
                                 <button
                                    id="saveProfileChange"
                                    className="btn btn-sm btn-primary"
                                    onClick={this.onSave.bind(this)}
                                 >
                                    Save changes
                                 </button>
                              </div>
                              {this.renderModal()}
                              {this.confirmationModal()}
                           </form>
                        </div>
                     </div>
                     {this.renderSubscriptionArea()}
                     {this.renderSubscription()}
                     {this.renderMembershipSubscriptionArea()}
                  </div>
               </div>
            </div>
            <br />
            <br />
            <br />
            <br />
         </div>
      );
   }
}

function mapStateToProps(state) {
   return { auth: state.auth };
}

Settings = connect(
   mapStateToProps,
   actions
)(withRouter(Settings));

export default reduxForm({
   form: 'settingsForm', // a unique identifier for this form
   destroyOnUnmount: false // keep values around
})(Settings);
