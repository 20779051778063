import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as actions from "../actions";
import { Firebase } from "../firebase";
import { makeAssetsUrl, makeProfileImageUrl } from "../APIAndConfig";
import { get } from "lodash";
import {
  faUser,
  faUsers,
  faPlayCircle,
  faStore,
  faPlusCircle,
  faCog,
  faComments,
  faAtom,
  faAddressBook,
  faChartLine,
  faGift,
  faTimes,
  faSchool,
  faDollarSign,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Card, Button } from "react-bootstrap";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedSessionId: -1, selectedSessionTitle: "" };
  }

  componentDidMount() {
    Firebase.Auth.onAuthStateChanged((authUser) => {
      if (authUser) {
      } else {
      }
    });
    this.refreshActive();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.refreshActive();
  }

  refreshActive() {
    const sidebar = document.getElementById("sidebar");
    if (!sidebar) return;

    const links = sidebar.getElementsByClassName("nav-link");
    let link = null;
    let testHref = document.location.href.split("?")[0];

    for (link of links)
      if (link.href === testHref && !link.classList.contains("navActive"))
        return this.toggleActive({ target: link });
  }

  async logOut() {
    const res = await Firebase.Auth.signOut();
    //console.log('Logging out', res);
  }

  toggleActive(e) {
    const currentElement = e.target;

    // console.log('toggleActive', currentElement.attributes.href.value);
    // if (currentElement.attributes.href.value == '/ledger') {
    //   console.log("object")
    //   this.props.history.push('/ledger')

    // }


    if (currentElement.classList.contains("disable")) {
      return;
    }

    const element = document.getElementById("sidebar");
    const activeLi = element.getElementsByClassName("navActive");
    if (activeLi && activeLi.length > 0) {
      activeLi[0].classList.remove("navActive");
    }

    currentElement.classList.toggle("navActive");
  }

  onCloseNav(e) {
    document.getElementById("sidebar").classList.remove("sidebarOpen");
  }

  renderName() {
    if (this.props.auth) {
      return this.props.auth.firstName + " " + this.props.auth.lastName;
    }
    return "";
  }

  renderAnalyticsLink(force) {
    const roleId = get(this.props.auth, "role.id", 2);
    if (!force && [3, 5, 6].indexOf(roleId) < 0) return null;

    return (
      <Link
        to="/v1/analytics"
        className="nav-link text-white"
        onClick={this.toggleActive.bind(this)}
      >
        <FontAwesomeIcon icon={faChartLine} className="ppNavIcon" /> Analytics
      </Link>
    );
  }
  renderDisplayName() {
    if (this.props.auth) {
      return (
        this.props.auth.firstName +
        " " +
        this.props.auth.lastName
      ).trim();
    }
    return "";
  }

  renderProArea() {
    const roleId = get(this.props.auth, "role.id", 0);
    if ([1, 4].indexOf(roleId) < 0) return;

    return (
      <div>
        <p
          className="mt-3 mr-3 ml-3 mb-0"
          style={{ color: "rgba(255, 255, 255, 0.5)" }}
        >
          Coaches
        </p>
        <nav className="nav flex-column">
          <Link
            to="/v1/session/category"
            className="nav-link text-white"
            onClick={this.toggleActive.bind(this)}
          >
            <i className="fas fa-plus-circle ppNavIcon" />
                  Create a session
               </Link>

          <Link
            to="/v1/users"
            className="nav-link text-white"
            onClick={this.toggleActive.bind(this)}
          >
            <FontAwesomeIcon icon={faAddressBook} className="ppNavIcon" />
            Client Management
          </Link>

          {this.renderAnalyticsLink(true)}

          {/* <Link
                  to="/v1/userDetails"
                  className="nav-link text-white"
                  onClick={this.toggleActive.bind(this)}
               >
                  <i className="fas fa-address-book ppNavIcon" />
                  User Details
               </Link> */}
        </nav>
      </div>
    );
  }

  renderAdminArea() {
    const roleId = get(this.props.auth, "role.id", 0);
    if (roleId !== 1) return;

    return (
      <div>
        <p
          className="mt-3 mr-3 ml-3 mb-0"
          style={{ color: "rgba(255, 255, 255, 0.5)" }}
        >
          Admin
        </p>
        <nav className="nav flex-column">
          <Link
            to="/v1/ledger"
            className="nav-link text-white"
            onClick={this.toggleActive.bind(this)}
          >
            <FontAwesomeIcon icon={faDollarSign} className="ppNavIcon" />
            Sales Ledger
          </Link>
          {/* <Link
                         to="/v1/usermanagement"
                         className="nav-link text-white"
                         onClick={this.toggleActive.bind(this)}
                    >
                         <FontAwesomeIcon icon={faUsersCog} className="ppNavIcon" />
                    User Management
                    </Link> */}
        </nav>
        <nav className="nav flex-column">
          <Link
            to="/v1/payoutledger"
            className="nav-link text-white"
            onClick={this.toggleActive.bind(this)}
          >
            <FontAwesomeIcon icon={faDollarSign} className="ppNavIcon" />
            Payout Ledger
          </Link>
        </nav>
        <nav className="nav flex-column">
          <Link
            to="/v1/admindash"
            className="nav-link text-white"
            onClick={this.toggleActive.bind(this)}
          >
            <FontAwesomeIcon icon={faUser} className="ppNavIcon" />
           Admin Dashboard
          </Link>
        </nav>
      </div>
    );
  }

  renderRole() {
    switch (this.props.auth.role.id) {
      case 1:
        return "Administrator";
      case 2:
        return "Standard User";
      case 3:
        return "Premium Member";
      case 4:
        return "Coach";
      case 5:
        return "Signature Member";
      case 6:
        return "VIP Concierge Member";
      default:
        return "";
    }
  }

  render() {
    if (!this.props.auth || this.props.auth === undefined) {
      return <div />;
    }

    // if(this.props.auth.orientation_status === null || this.props.location.pathname === "/orientations"){
    //   return <div />;
    // }

    let imageProfileSrc = makeAssetsUrl("user_default.png");

    if (this.props.auth.profileImageUrl) {
      imageProfileSrc = this.props.auth.profileImageUrl;
    }

    return (
      <div
        id="sidebar"
        className="sidebarWidth bg-gradient-primary h-100 shadow"
        style={{ zIndex: 1 }}
      >
        <div id="sidebarContent">
          <nav className="navbar navbar-dark">
            <span className="navbar-brand">Positive Prime</span>
            <button
              className="btn d-md-none bg-transparent text-white pr-0"
              onClick={this.onCloseNav.bind(this)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </nav>
          <div id="portrait" className="py-5" align="center">
            <div className="portraitInner">
              <img
                alt="profile-pic"
                className="rounded-circle ppImg imageContainer"
                src={imageProfileSrc}
              />
            </div>
            <p className="text-white mt-3 mb-0">{this.renderDisplayName()}</p>
            <p className="text-white m-0" style={{ opacity: 0.5 }}>
              <small>{this.props.auth.title}</small>
            </p>
            <p className="text-white m-0">
              <small>{this.renderRole()}</small>
            </p>
          </div>
          <nav className="nav flex-column">
            <a
              href="https://www.positiveprime.com/tutorial/"
              className="nav-link text-white"
              target="_blank"
            >
              <FontAwesomeIcon icon={faSchool} />
              Getting Started
            </a>

            <Link
              to="/v1/dashboard"
              className="nav-link text-white navActive"
              onClick={this.toggleActive.bind(this)}
            >
              <FontAwesomeIcon icon={faUser} /> Dashboard
            </Link>
            {/* <Link
                     to="/v1/freeUserDashboard"
                     className="nav-link text-white"
                     onClick={this.toggleActive.bind(this)}
                  >
                     <i className="fas fa-user ppNavIcon" /> Free User Dashboard
                  </Link> */}
            <Link
              to="/v1/sessions"
              className="nav-link text-white"
              onClick={this.toggleActive.bind(this)}
            >
              <FontAwesomeIcon icon={faPlayCircle} />
              My Sessions
            </Link>
            {/* <Link
                     to="/v1/freeUserSessions"
                     className="nav-link text-white"
                     onClick={this.toggleActive.bind(this)}
                  >
                     <i className="fas fa-play-circle ppNavIcon" /> Free User
                     Sessions
                  </Link> */}
            <Link
              to="/v1/shop"
              className="nav-link text-white"
              onClick={this.toggleActive.bind(this)}
            >
              <FontAwesomeIcon icon={faStore} />
              Shop Sessions
            </Link>
            {/* <Link
                     to="/v1/shopSearchResults"
                     className="nav-link text-white"
                     onClick={this.toggleActive.bind(this)}
                  >
                     <i className="fas fa-store ppNavIcon" /> Shop Search
                     Results
                  </Link> */}
            {/* <Link
              to="/v1/topup"
              className="nav-link text-white"
              onClick={this.toggleActive.bind(this)}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              Add Credits
            </Link> */}

            <Accordion defaultActiveKey="0">
              <Accordion.Toggle as={Card.Body} className="p-0" eventKey="1">
                <a
                  className="nav-link text-white"
                  onClick={this.toggleActive.bind(this)}
                >
                  <FontAwesomeIcon icon={faUsers} />
                  Referrals
                </a>
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey="1"
                style={{ backgroundColor: "#49518d" }}
              >
                <Link
                  to="/v1/referral"
                  className="nav-link text-white"
                  onClick={this.toggleActive.bind(this)}
                >
                  <div className="pl-4">
                    <FontAwesomeIcon icon={faUsers} className="mr-2" />
                    Referrals / Affiliate
                  </div>
                </Link>
              </Accordion.Collapse>

              <Accordion.Collapse
                eventKey="1"
                style={{ backgroundColor: "#49518d" }}
              >
                <Link
                  to="/v1/referralGraphs"
                  className="nav-link text-white"
                  onClick={this.toggleActive.bind(this)}
                >
                  <div className="pl-4">
                    <FontAwesomeIcon icon={faUsers} className="mr-2" />
                    Referral Analytics
                  </div>
                </Link>
              </Accordion.Collapse>
            </Accordion>

            <Link
              to="/v1/settings"
              className="nav-link text-white"
              onClick={this.toggleActive.bind(this)}
            >
              <FontAwesomeIcon icon={faCog} />
              Settings
            </Link>
            {/* <Link
              to="/v1/orientation"
              className="nav-link text-white"
              onClick={this.toggleActive.bind(this)}
            >
              <FontAwesomeIcon icon={faCog} />
              Orientation
            </Link> */}
            <a
              href="https://www.positiveprime.com/the-science/"
              className="nav-link text-white"
              target="_blank"
            >
              <FontAwesomeIcon icon={faAtom} />
              The Science
            </a>
            <a
              href="https://www.positiveprime.com/what-people-are-saying/"
              className="nav-link text-white"
              target="_blank"
            >
              <FontAwesomeIcon icon={faComments} />
              Feedback &amp; Reviews
            </a>
            <a
              href="https://www.positiveprime.com/resources/"
              className="nav-link text-white"
              target="_blank"
            >
              <FontAwesomeIcon icon={faAddressBook} />
              Resources
            </a>
            {/* <Link
                     to="/v1/api/logout"
                     className="nav-link text-white"
                     onClick={this.logOut.bind(this)}
                  >
                     <i className="fas fa-cog ppNavIcon" /> Logout
                  </Link> */}
            {/* <Link
                     to="/v1/help"
                     className="nav-link text-white"
                     onClick={this.toggleActive.bind(this)}
                  >
                     <i className="fas fa-question ppNavIcon" /> Help
                  </Link> */}
            {/* <Link
                     to="/v1/sandbox"
                     className="nav-link text-white"
                     onClick={this.toggleActive.bind(this)}
                  >
                     <i className="fas fa-question ppNavIcon" /> Sandbox
                  </Link> */}
            {this.renderAnalyticsLink()}

            <Link
              to="/v1/upgradeAccount"
              className="nav-link text-white"
              onClick={this.toggleActive.bind(this)}
            >
              <FontAwesomeIcon icon={faGift} className="ppNavIcon" />
              Upgrade Account
            </Link>
          </nav>
          {this.renderProArea()}
          {this.renderAdminArea()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, actions)(withRouter(SideBar));
