import { Config, API, APIAxios } from "../APIAndConfig";
import axios from "axios";
import {
  FETCH_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FEATURED,
  FETCH_PRODUCTS_NEW,
  FETCH_PURCHASED_SESSIONS,
  FETCH_USER
} from "./types";
import { Firebase } from "../firebase";
import set from "date-fns/set";

export const fetchProducts = () => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(`${Config.APIUrl}/api/products`, { headers: header })
  .then((products) => {
    dispatch({ type: FETCH_PRODUCTS, payload: products.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
        await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });
};

export const fetchNewReleaseProducts = () => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/products?new_release=true&featured=false`,
    { headers: header }
  ).then((products) => {
    dispatch({ type: FETCH_PRODUCTS_NEW, payload: products.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

};

export const fetchFeaturedProducts = limit => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/products?featured=true&limit=${limit}`,
    { headers: header }
  ).then((products) => {
    dispatch({ type: FETCH_PRODUCTS_FEATURED, payload: products.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

};

export const fetchProductByProductId = (auth, productId) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/product/${productId}`,
    { headers: header }
  ).then((product) => {
    dispatch({ type: FETCH_PRODUCT, payload: product.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

};

export const purchaseProduct = (auth, product) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.post(
    `${Config.APIUrl}/api/product_purchase/`,
    {
      user_id: auth.id,
      product_id: product.id
    },
    { headers: header }
  ).then((results) => {
    dispatch({ type: FETCH_USER, payload: results.data.user });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

  //console.log(results.data);

  // dispatch({ type: FETCH_USER, payload: results.data.user });
  //dispatch({ type: FETCH_PRODUCT, payload: results.data.productDetails });
};

export const fetchPurchasesByUserId = userId => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/user_purchases/${userId}`,
    { headers: header }
  ).then((results) => {
    dispatch({ type: FETCH_PURCHASED_SESSIONS, payload: results.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  })
  //console.log('fetchPurchasesByUserId', results.data);
  // dispatch({ type: FETCH_PURCHASED_SESSIONS, payload: results.data });
};

export const fetchProductsBySearch = searchKeyword => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/products?search_query=${searchKeyword}`,
    { headers: header }
  ).then((products) => {
    dispatch({ type: FETCH_PRODUCTS, payload: products.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
      }
        break;
      case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });


};
