import * as React from 'react';
import clsx from 'clsx';
import SessionCard from '../cards/SessionCard';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { connect } from "react-redux";
import daysToWeeks from 'date-fns/esm/fp/daysToWeeks/index.js';
import { API } from '../../APIAndConfig';
import { getAllJSDocTags } from 'typescript';
import { Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeart
} from "@fortawesome/free-solid-svg-icons";

class CoachingSessions extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            data: [],
            orderedData: [],
            loader: true,
            currentCount: 3,
            prevDisabled: true,
            nextDisabled: false,
            coaching_start: this.props.auth.coaching_start,
            messageModal: {
                state: false,
                header: '',
                message: ''
            },
            isProcessing: false,
            likedSession: false,
            nextSession: true,
            likedSessionIds: []
        }
    }

    async componentDidMount() {

        let hours = momenttz().tz( 'America/New_York' ).diff( momenttz( this.props.auth.coaching_start ).tz( 'America/New_York' ).startOf( 'day' ), 'h' )
        var totalDays = parseInt( hours / 24 ) + 1
        await this.getData( totalDays )
        this.getLikedSessions();
        var timer;
        var compareDate = momenttz().tz( 'America/New_York' ).endOf( 'day' ).valueOf();
        timer = setInterval( function () {
            timeBetweenDates( compareDate );
        }, 1000 );

        function timeBetweenDates( toDate ) {
            var dateEntered = toDate;
            var now = new Date();
            var difference = dateEntered - now.getTime();
            if ( difference <= 0 ) {
                clearInterval( timer );
            } else {
                var seconds = Math.floor( difference / 1000 );
                var minutes = Math.floor( seconds / 60 );
                var hours = Math.floor( minutes / 60 );
                hours %= 60;
                minutes %= 60;
                seconds %= 60;
                if ( document.getElementById( "countdown" ) ) {
                    document.getElementById( "countdown" ).innerHTML = ( "00".substring( 0, 2 - hours.toString().length ) + hours.toString() ) + " Hours " + ( "00".substring( 0, 2 - minutes.toString().length ) + minutes.toString() ) + " Minutes"
                }
            }
        }
    }

    async getData( days ) {

        let payload = days && days >= 1 ? days : 1
        await API.getCoachingSessions( payload).then(
            res => {
                this.setState( { loader: false } )
                if ( res.successful ) {
                    let reversedData = [ ...res.payload.data ].reverse()
                    let splicedData = [ ...res.payload.data ].reverse().splice( 0, 3 )
                    this.setState( {
                        data: reversedData,
                        orderedData: splicedData,
                        nextDisabled: res.payload.data.length > 3 ? false : true
                    } )
                } else {
                    console.log( "Failed to get sessions" )
                }
            }
        )
    }

    updateData( currentCount ) {
        let data = [ ...this.state.data ]
        this.setState( {
            currentCount: currentCount,
            // prevDisabled: currentCount > -1 ? false : true,
            nextDisabled: true, //currentCount >= this.state.data.length ? true : false,
            orderedData: data.splice( 0, this.state.data.length ),
            nextSession : true
        } )
    }

    async getLikedSessions() {
        await API.getlikeSession()
            .then( ( res ) => {
                if ( res.successful ) {
                    let likedData = [ ...res.payload.data ].reverse()
                    let ids = [ ...res.payload.data ].filter(obj => obj.userId === this.props.auth.id && obj.sessionType === "coaching_pearl").map( obj => obj.sessionId )
                    this.setState( {
                        likedSession: likedData,
                        likedSessionIds: ids
                    } )
                    console.log( this.allLiked );
                }
                else {
                    console.log( "Failed to get sessions" )
                }
            } )
    }
    async updateCoachingStart() {
        this.setState( { isProcessing: true } )
        await API.updateCoachingStatus( {
            userId: this.props.auth.id
        } ).then( ( res ) => {
            this.setState( { isProcessing: false } )
            if ( res.successful ) {
                this.setState( {
                    messageModal: {
                        state: true,
                        header: 'Success',
                        message: 'Freedom plan started successfully'
                    },
                    coaching_start: true
                } )
            } else {
                this.setState( {
                    messageModal: {
                        state: true,
                        header: 'Failed',
                        message: 'Failed to start freedom plan please try again'
                    },
                } )
            }
        } )
    }

    renderMessageModal() {
        return (
            <Modal show={this.state.messageModal.state} onHide={() => { }}>
                <Modal.Header> {this.state.messageModal.header}</Modal.Header>
                <Modal.Body>
                    {this.state.messageModal.message}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            this.setState( {
                                messageModal: {
                                    header: '',
                                    message: '',
                                    state: false
                                }
                            } )
                        }}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    getAlllikes() {
        let data = [ ...this.state.likedSession ]
        this.setState({
            orderedData : data,
            nextSession : false
        })
    }

    render() {
        return (
            this.state.loader ?
                <div className={"text-center mt-4"}>
                    <Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} />
                </div > :
                <div className={clsx( 'container', 'wellness-collection' )}>
                    <div>
                        <div className="d-flex align-items-center justify-items-center">
                            <h2 className="main-title m-0 p-3">Coaching Pearls</h2>
                        </div>
                    </div>
                    <div>
                        <img
                            src='assets/images/Hero_Image.jpeg'
                            alt={'image'}
                            className={'sessionCardContainer__imgContainer-img sessionCard-rounded pl-2 pr-2'}
                            style={{ height: '100%' }}
                        />
                    </div>
                    {
                        this.state.coaching_start ?
                        <div className="row d-flex justify-content-end mt-3">
                            <div className="col-2">
                                <button
                                    className="btn text-white w-100 x-secondary float-right"
                                    onClick={this.getAlllikes.bind( this )}
                                    disabled={!this.state.likedSession}
                                >
                                    <FontAwesomeIcon
                                        icon={faHeart}
                                        className="favorite_button far fa-heart hidden"
                                        color="white" />
                                </button>
                            </div>
                            <div className="col-2">
                                <button
                                    className="btn text-white w-100 x-secondary float-right"
                                    onClick={() => { this.updateData( ( this.state.currentCount + 4 ) ) }}
                                >
                                    View All
                                </button>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {
                        !this.state.coaching_start ?
                            <>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <button
                                        className="btn x-btn x-secondary float-right m-5"
                                        onClick={() => { this.updateCoachingStart() }}
                                    >
                                        {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Click To Start Your Freedom Coaching Program</span>}
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div className={'wellness-collection__main mt-2'}>
                                    <div className={'row'}>
                                        {
                                         this.state.nextSession ? (
                                            <div className="col-lg-3 col-sm-12 mt-4 justify-content-center align-items-center d-flex flex-column h-100">
                                                <div className="container sessionCardContainer">
                                                    <div style={{
                                                        height: '177px', borderRadius: '20px', background: '#4a3862'
                                                    }} className="d-flex justify-content-center align-items-center" >
                                                        <div style={{ fontSize: "20px", fontWeight: "bolder" }}>Next Video Appears In</div>
                                                    </div>
                                                </div>
                                                <p className="font-weight-bolder" id="countdown"></p>
                                            </div> ) : ""
                                        }
                                        {
                                            this.state.orderedData.map( ( obj, index ) => {
                                                return (
                                                    <div className="col-lg-3 col-sm-12 mt-4 d-flex flex-column" onClick={() => {
                                                        this.props.history.push( { 
                                                            pathname: "/play_coaching_session", 
                                                            state: { 
                                                                session_object: obj,
                                                                data: this.state.data,
                                                                likedSessions: this.state.likedSessionIds
                                                            }})
                                                    }}>
                                                        <img
                                                            src={process.env.REACT_APP_S3_URL_ZIP + obj.thumbnail}
                                                            alt={'image'}
                                                            className={'sessionCardContainer__imgContainer-img sessionCard-rounded pl-2 pr-2'}
                                                            style={{ height: '177px' }}
                                                        />
                                                        <p className="font-weight-bolder text-center">Day {obj.day} : {obj.name}</p>
                                                    </div>
                                                )
                                            } )}

                                    </div>
                                </div>
                            </>
                    }
                    {this.renderMessageModal()}
                </div>
        );
    };


};


function mapStateToProps( state ) {
    return {
        auth: state.auth,
    };
}

export default connect( mapStateToProps )( CoachingSessions );