import {USER_SELECTED_IMAGES} from '../actions/types';

export default function(state = [], action) {
   //console.log(action);
   switch (action.type) {
      case USER_SELECTED_IMAGES:
         return action.payload || [];
      default:
         return state;
   }
}
