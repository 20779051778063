import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

function EditImageCard(props) {
  return (
    <div key={props.id} name={props.id} className={clsx('edit-image-card, m-2')}>
      <div className={'edit-image-card__controls'} >
        <input
          type="checkbox"
          name={props.id}
          defaultChecked={props.checked}
          id={`image-input-${props.id}`}
          className="_edit_select_Img_Checkbox"
          onChange={props.onCheckboxChange}
        />
        <label for={`image-input-${props.id}`} className="_edit_select_Img_Checkbox__label" >
          <img
            id={`image-${props.id}`}
            alt={props.name}
            src={props.image}
            className={clsx('edit-image-card__image')}
          />
        </label>

        <span className="_edit_select_Img_Delete" onClick={props.onDelete}><FontAwesomeIcon icon={faTrashAlt} name={props.id} color={'#fff'} /></span>
      </div>
    </div>
  )
}

export default EditImageCard;