import { extend } from "lodash";
import React, { Component } from "react";
import clsx from 'clsx';
import ReactTable from "react-table-6";
import moment from 'moment'
import { Media_URL } from '../../APIAndConfig.ts';
import { Spinner } from "react-bootstrap";
import { API } from "../../APIAndConfig";
import { connect } from "react-redux";
import { ROUTES } from "../../Routers/DashboardRouter";
import { CATEGORY_ROUTES } from "../../views/Category";

class RecentSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loader: true
        }
    }

    async componentDidMount() {
        await API.getRecentSessions(this.props.auth.id).then(
            async (res) => {
                if (res.payload && res.payload.data) {
                    let payload = res.payload.data;
                    console.log(payload);
                    await payload.map((obj) => {
                        obj.view_time = moment(obj.end_time).diff(moment(obj.start_time), 'seconds')
                    })

                    this.setState({
                        data: payload,
                        loader: false
                    })
                }
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    onSessionClick(rowInfo) {
        const { original } = rowInfo;
        console.log(original)
        // this.props.history.push(`/images/${original.id}?runtime=${original.duration}`);
        if (original.category !== null && original.category !== undefined && original.category !== 'private' ) {
            this.props.history.push(ROUTES.CATEGORY + '/' + original.category + CATEGORY_ROUTES.SESSION + '/' + original.id);
        } else {
            this.props.history.push(ROUTES.CATEGORY + '/' + 'private' + CATEGORY_ROUTES.SESSION + '/' + original.id);
        }

    }

    render() {
        if (this.state.loader)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
            <div>
                <div className={'wellness-collection__header'}>
                    <h2 className={clsx('wellness-collection__header-title', 'main-title')}>
                        Session History
                    </h2>
                </div>
                <div>
                    {
                        this.state.data.length > 0 ?
                            <ReactTable
                                data={this.state.data}
                                columns={[
                                    {
                                        id: "thumbnail",
                                        // Header: 'Thumbnail',
                                        accessor: (d) => {
                                            return (
                                                <div style={{ width: '100%', minWidth: '350px' }}>
                                                    {
                                                        !d.thumbnail && !d.placeholder_image ?
                                                            <div style={{
                                                                minHeight: '250px', borderRadius: '20px', background: '#4a3862', maxWidth: '300px',
                                                                width: "100%", minWidth: '255px', border: '0'
                                                            }} className="d-flex justify-content-center align-items-center ml-auto mr-auto" >
                                                                <img
                                                                    src={'https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png'}
                                                                    className="sessionCardContainer__imgContainer-img sessionCard-rounded h-100 p-3"
                                                                />
                                                            </div>
                                                            :
                                                            <img
                                                                src={d.thumbnail ? process.env.REACT_APP_CDN + '/' + d.thumbnail : Media_URL + '/' + d.placeholder_image}
                                                                style={{ width: '100%', border: "0", minWidth: '255px', maxWidth: '300px' }}
                                                                className="sessionCardContainer__imgContainer-img sessionCard-rounded"
                                                            />

                                                    }
                                                    <p>{d.name}</p>
                                                </div>
                                            )
                                        },
                                        className: 'text-center',
                                        minWidth: 350,
                                        maxWidth: 450
                                    },
                                    {
                                        id: "start_time",
                                        // Header: 'Start Time',
                                        accessor: (d) => {
                                            return <p><span>Start Time : </span><br /><span>{d.start_time}</span></p>
                                        },
                                        className: 'text-left d-flex justify-content-center align-items-center',
                                        width: this.getColumnWidth(this.state.data, 'start_time', 'Start Time'),
                                    },
                                    {
                                        id: "end_time",
                                        // Header: 'End Time',
                                        accessor: (d) => {
                                            return <p><span>End Time : </span><br /><span>{d.end_time}</span></p>
                                        },
                                        className: 'text-left d-flex justify-content-center align-items-center',
                                        width: this.getColumnWidth(this.state.data, 'end_time', 'End Time')
                                    },
                                    {
                                        id: "view_time",
                                        // Header: 'View Time',
                                        accessor: (d) => {
                                            return <p><span>View Time : </span><br /><span>{d.view_time + " sec"}</span></p>
                                        },
                                        className: 'text-left d-flex justify-content-center align-items-center',
                                        width: this.getColumnWidth(this.state.data, 'view_time', 'View Time')
                                    },
                                    {
                                        id: "before_emotion",
                                        // Header: 'Before Emotion',
                                        accessor: (d) => {
                                            return <p><span>Before Emotion : </span><br /><span>{d.before_emotion}</span></p>
                                        },
                                        className: 'text-left d-flex justify-content-center align-items-center',
                                        width: this.getColumnWidth(this.state.data, 'before_emotion', 'Before Emotion')
                                    },
                                    {
                                        id: "after_emotion",
                                        // Header: 'After Emotion',
                                        accessor: (d) => {
                                            return <p><span>After Emotion : </span><br /><span>{d.after_emotion}</span></p>
                                        },
                                        className: 'text-left d-flex justify-content-center align-items-center',
                                        width: this.getColumnWidth(this.state.data, 'after_emotion', 'After Emotion')
                                    }
                                ]}
                                defaultPageSize={10}
                                showPagination={this.state.data.length > 10 ? true : false}
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        return {
                                            onClick: (e) => {
                                                this.onSessionClick(rowInfo)
                                            }
                                        }
                                    } else {
                                        return {}
                                    }
                                }}
                                style={{ border : '0px' }}
                                getTheadProps={() => { return { style: { boxShadow: 'none' }}}}
                                getTrGroupProps={() => { return { style: { border : '0px' }}}}
                                getTdProps={() => { return { style: { border : '0px' }}}}
                                getPaginationProps={() => { return { style: { boxShadow: 'none', borderTop: '0px' }}}}
                            /> :
                            <div className="text-center mt-5 pt-5" style={{ fontSize: '30px' }} >No sessions watched</div>
                    }

                </div>
            </div>
        )
    }

    getColumnWidth(rows, accessor, headerText) {
        const maxWidth = 400
        const magicSpacing = 10
        const cellLength = Math.max(
            ...rows.map((row) => (`${row[accessor]}` || '').length),
            headerText.length,
        )
        return Math.min(maxWidth, cellLength * magicSpacing)
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(RecentSessions);