import { Col, Row, Form, Button, Spinner, Alert } from "react-bootstrap";
import Loader from "../common/Loader";
import React, { ChangeEvent } from "react";
import { IntroWrap } from "./IntroWrapV2";
import { Link } from "react-router-dom";
import { Firebase } from "../../firebase";
import { set } from "lodash";
import { cookies } from "../../index";
import { API } from "../../APIAndConfig";
import '../../scss/loginV2.css'


export interface LoginState {
    isProcessing: boolean
    email: string;
    emailIsValid: boolean | null;
    emailFeedback: string | null;
    password: string;
    passwordIsValid: boolean | null;
    passwordFeedback: string | null;
    otherError: string | null;
}

export class LoginV2 extends React.Component<any, LoginState> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: cookies.get("email"),
            password: '',
            isProcessing: false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null
        }
    }

    async handleLogin() {

        let state: Partial<LoginState> = {
            isProcessing: this.state.email && this.state.password ? true : false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null
        }
        if (!this.state.email) {
            state.emailIsValid = false;
            state.emailFeedback = "Please enter an email address";
        }
        if (!this.state.password) {
            state.passwordIsValid = false;
            state.passwordFeedback = "Please enter your password";
        }
        await this.setState(state as object);
        if (!this.state.email || !this.state.password)
            return;

        try {
            const result = await Firebase.Auth.signInWithEmailAndPassword(this.state.email, this.state.password)
            await API.updateLastLogin(this.state.email);
            // this.props.history.push('/v2/dashboard')
            this.props.history.push('/dashboard')
        } catch (error) {
            switch (error.code) {
                case "auth/user-not-found":
                    state.emailIsValid = false;
                    state.emailFeedback = "This email address is not registered"
                    break;

                case "auth/wrong-password":
                    state.passwordIsValid = false;
                    state.passwordFeedback = "Incorrect password";

                    break;

                default:
                    console.error(error.code);
                    state.otherError = error.message || "Unknown Error";
            }
            state.isProcessing = false;
        } finally {
            await this.setState(state as object);
        }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        const state = {};
        set(state, event.target.name, event.target.value);
        this.setState(state);
    }

    render() {
        return (
            <IntroWrap processing={this.state.isProcessing}>
                <div className="loginForm">
                    {this.renderError()}

                    <div className="form-group">
                        <label htmlFor="email" className="custom-input-label">Email address</label>
                        <input
                            type="email"
                            className={"form-control custom-input " + this.getClassName(this.state.emailIsValid)}
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange.bind(this)}
                            disabled={this.state.isProcessing}
                        />
                        <span className="errorMessage">
                            {this.state.emailFeedback}
                        </span>
                    </div>
                    <div className="small text-right float-right">
                        <Link className="link-muted text-warning" to="/forgetPassword" onClick={this.handleForgotPassword.bind(this)}>Forgot Password?</Link>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password" className="custom-input-label">Password</label>
                        <input
                            type="password"
                            name="password"
                            className={"form-control custom-input " + this.getClassName(this.state.passwordIsValid)}
                            value={this.state.password}
                            onChange={this.handleChange.bind(this)}
                            disabled={this.state.isProcessing}
                        />
                        <span className="errorMessage">
                            {this.state.passwordFeedback}
                        </span>
                    </div>

                    <div className="form-group d-flex justify-content-between align-items-center mb-4">

                    </div>

                    <button className="btn btn-primary btn-block py-2 custom-button" type="submit" onClick={this.handleLogin.bind(this)}>Login</button>

                    <div className="my-5 text-center text-success">
                        <span className="bottom-text mb-2 d-inline float-left">Don’t have an account?
                        <Link className="ml-1 d-inline bottom-text text-warning" to="/signup7">Sign Up</Link>
                        </span>
                        <span>
                            <Link className="d-inline float-right mb-2 bottom-text" to="/signup7">Need Help ?</Link>
                        </span>


                    </div>

                </div>
            </IntroWrap>
        );
    }

    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
    handleForgotPassword() {
        cookies.set("email", this.state.email);
    }
    getClassName(valid: boolean | null | undefined) {

        if (valid === null || valid == undefined) {
            return undefined;
        }

        return "is-" + (valid ? "valid" : "invalid");
    }
}

export default LoginV2;
