import React, { Component } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
    StripeProvider,
    Elements,
} from 'react-stripe-elements';
import { getStripe } from "../../stripe";
import ReactPixel from 'react-facebook-pixel';

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },

            },
            invalid: {
                color: '#c23d4b',
            },
        },
    };
};

class _SplitFieldsForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: '',
            count: 0
        }
    }

    componentDidMount() {
        ReactPixel.init(process.env.REACT_APP_FB_KEY);
        ReactPixel.track('AddToCart', {
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });
    }

    handleChange = ({ error }) => {
        if (error) {
            this.setState({ errorMessage: error.message });
        }
    };

    handleSubmit = async (evt) => {
        evt.preventDefault();
        if (this.props.stripe) {
            this.setState({ errorMessage: "" });
            await this.props.stripe.createToken()
                .then(this.props.handleResult);

        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    onFocus() {
        this.setState({
            count : this.state.count + 1
        }, () => {
            if (this.state.count === 1) {
                ReactPixel.track('InitiateCheckout', {
                    eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
                });
            }
        })
    }

    render() {
        return (
            <form id="credit-card" className="mt-5 mb-5" onSubmit={this.handleSubmit.bind(this)}>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row justify-content-center">
                            <div className="col-md-5 p-0">
                                <h2 className="title p-4">Billing Information</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 justify-content-center align-items-center card shadow pt-4  ">
                        <div className="card-head">
                            {/* <div className="error errorMessage form-group" role="alert">
                                {this.props.message}
                            </div> */}
                        </div>
                        <div className="card-body w-100">
                            <div className="form-group">
                                <CardNumberElement
                                    {...createOptions()}
                                    onFocus={this.onFocus.bind(this)}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-6 form-group">
                                    <CardExpiryElement
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />

                                </div>
                                <div className="col-6 form-group">
                                    <CardCVCElement
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="error errorMessage form-group" role="alert">
                                {this.state.errorMessage}
                            </div>

                            <div className="form-group">
                                <p className="text-left billingText">
                                    If you do not provide us your personal information we may not
                                    be able to provide our Services to you. See our
                  <a href="#">
                                        <u> Privacy Policy</u>
                                    </a>
                                </p>
                            </div>

                            <button
                                type="submit"
                                className="btn btn-block  myButton"
                                onClick={this.handleSubmit}
                                disabled={!this.props.status || this.props.processing}
                            >
                                Save
              </button>
                            <br />
                        </div>
                    </div>
                </div>
            </form>

        );
    }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

export default class CheckoutForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: ""
        }
        this.handleResult = this.handleResult.bind(this)
    }

    componentDidMount() {
        ReactPixel.init(process.env.REACT_APP_FB_KEY);
    }

    handleResult(e) {
        if (e.error) {
            this.setState({ errorMessage: e.error.message })
        } else {

            ReactPixel.track('Purchase', {
                currency: 'USD',
                value: 0.00,
                eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
            });

            this.setState({ errorMessage: "" })
            this.props.handler(e.token.id)
        }
    }
    render() {
        return (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Elements>
                    <SplitFieldsForm handleResult={this.handleResult} status={this.props.status} message={this.state.errorMessage} processing={this.props.processing} />
                </Elements>
            </StripeProvider>
        );
    }
}
