import React, { useRef } from "react";
import clsx from "clsx";
import SessionCardButton from "./SessionCardButton";

function SessionCard(props) {
  const imgRef = useRef(null);
  return (
    <div
      className={clsx(
        "container",
        "sessionCardContainer mb-4",
        props.direction === "play" ? "pr-4 pl-4" : ""
      )}
    >
      <div
        className={clsx("container", "sessionCardContainer__imgContainer")}
        onClick={props.onClick}
      >
        {props.img ===
        "https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png" ? (
          <div
            style={{
              height: "177px",
              borderRadius: "20px",
              background: "#4a3862",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              ref={imgRef}
              src={props.img}
              alt={props.title + "-image"}
              className={
                "sessionCardContainer__imgContainer-img sessionCard-rounded pl-2 pr-2"
              }
              style={{ height: "55px", width: "230px" }}
            />
          </div>
        ) : (
          <img
            ref={imgRef}
            style={{ height: "177px" }}
            src={props.img}
            alt={props.title + "-image"}
            className={
              "sessionCardContainer__imgContainer-img sessionCard-rounded"
            }
          />
        )}
      </div>
      <div className={clsx("container", "sessionCardContainer__textContainer")}>
        <p
          className={clsx(
            "sessionCardContainer__textContainer-title",
            "x-card-title"
          )}
        >
          {props.title}
        </p>
        {props.author ? (
          <p
            className={clsx(
              "sessionCardContainer__textContainer-subtitle",
              "x-card-subtitle"
            )}
          >
            {props.author}
          </p>
        ) : (
          <></>
        )}
        {props.categoryId === "top10" ? (
          <p
            className={clsx(
              "sessionCardContainer__textContainer-subtitle",
              "x-card-subtitle"
            )}
          >
            <span style={{ fontWeight: "bold" }}>
              {parseInt(props.viewCount).toLocaleString("en")}
            </span>{" "}
            <span style={{ fontWeight: 400 }}> views</span>
          </p>
        ) : (
          <></>
        )}
        <SessionCardButton id={props.id} />
      </div>
    </div>
  );
}

export default SessionCard;
