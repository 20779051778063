import {FETCH_DEFAULT_EMBED_IMAGES} from "../actions/types";

export default function(state = null, action) {
  //console.log(action);
  switch (action.type) {
    case FETCH_DEFAULT_EMBED_IMAGES:
      return action.payload || null;
    default:
      return state;
  }
}
