import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { APIAxios, Config, API, makePlayerImageUrl, makeLegacyCDNUrl } from "../../APIAndConfig";
import axios from "axios";
import { Firebase } from '../../firebase';
import SessionCard from '../../components/cards/SessionCard';
import { ROUTES } from '../../Routers/DashboardRouter';
import { CATEGORY_ROUTES } from '../Category';
import { useParams } from 'react-router';
import { Media_URL } from '../../APIAndConfig.ts';
import { set } from 'lodash';
import { useSelector } from 'react-redux';

function Collection(props) {
    const categoryId = useParams().categoryId;

    const handleSessionCardClick = (id, session) => {
        if (categoryId === 'top10') {
            props.history.push(ROUTES.CATEGORY + '/' + session.category + CATEGORY_ROUTES.SESSION + '/' + id);
        } else {
            props.history.push(ROUTES.CATEGORY + '/' + categoryId + CATEGORY_ROUTES.SESSION + '/' + id);   
        }
    }

    const categoryName = props.categories.find((value) => value.id == categoryId).name;
    const [sessions, setSessions] = useState([]);

    const auth = useSelector(state => state.auth)
    const networkStatus = useSelector(state => state.networkStatus)

    const fetchSessions = async () => {
        const token = await Firebase.Auth.currentUser.getIdToken();

        const header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        };
        try {
            const res = await axios.get(
                `${Config.APIUrl}/category/` + categoryId,
                { headers: header }
            );
            if (res.data.status === 200) {
                const result = res.data.data.map(d => ({
                    ...d,
                    removing: false,
                    downloadPercentage: 0,
                    cancellingDownload: false,
                }))
                setSessions(result);
                console.log("res", res)
            }
        } catch (err) {
            switch (err.response.data) {
                case "SESSION_EXPIRED":
                    const token = await Firebase.Auth.currentUser?.getIdToken();
                    if (token) {
                        await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                        window.location.reload()
                    }
                    break;
                case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
                case "DATABASE_LIMIT_EXCEEDED":
                    localStorage.setItem('service_unavailable_status', "failed");
                    await Firebase.Auth.signOut();
                    window.location.href = "/error"
                    break;
                default:
                    console.log("Error Occured While fetching category data ", err);
                    break;
            }
        }

        // setProduct(res.data.product)
        // setScreenshots(res.data.screen_shot_list)
        // setSessions(res.data)
    }

    const fetchTopSessions = async () => {
        const token = await Firebase.Auth.currentUser.getIdToken();

        const header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        };
        try {
            const res = await axios.get(
                `${Config.APIUrl}/gettopSessions`,
                { headers: header }
            );
            if (res.data.status === 200) {
                const result = res.data.data
                setSessions(result);
                console.log("res", res)
            }
        } catch (err) {
            switch (err.response.data) {
                case "SESSION_EXPIRED":
                    const token = await Firebase.Auth.currentUser?.getIdToken();
                    if (token) {
                        await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                        window.location.reload()
                    }
                    break;
                case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
                case "DATABASE_LIMIT_EXCEEDED":
                    localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
                    window.location.href = "/error"
                    break;
                default:
                    console.log("Error Occured While fetching category data ", err);
                    break;
            }
        }
    }

    useEffect(() => {
        if (!networkStatus.connected) {
            return false
        }

        if( categoryId === 'top10' ) {
            fetchTopSessions();
        }
        else {
            fetchSessions();
        }
    }, [categoryId]);    

    return (
        <div className={clsx('container', 'wellness-collection')}>
            <div className={'wellness-collection__header'}>
                <h2 className={clsx('wellness-collection__header-title', 'main-title')}>
                    {categoryName}
                </h2>
            </div>
            <div className={'wellness-collection__main'}>
                <div className={'row'}>
                    {sessions.map((session) => {
                        return (
                            <div className={clsx('col-lg-3', 'col-sm-12')} key={session.id}>
                                <SessionCard
                                    onClick={() => handleSessionCardClick(session.id, session)}
                                    id={session.id}
                                    title={session.name}
                                    author={session.author?session.author.fullName:""}
                                    img={
                                        session.thumbnail ? process.env.REACT_APP_CDN + '/' + session.thumbnail : 
                                        session.images[0] && session.images[0].media_url ? Media_URL + '/' + session.images[0].media_url : 
                                        'https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png'
                                    }
                                    categoryId={categoryId}
                                    viewCount={ session.sessionCount ? session.sessionCount : undefined }
                                />
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}
export default Collection;