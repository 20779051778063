import React from 'react';
import Loader from '../common/Loader';
import './image.css';

export default props => {
   let $defaultElem = (
      <Loader
         size="large"
         style={{
            display: 'block',
            position: 'absolute',
            top: 160,
            left: 320
         }}
      />
   );

   if (props.imageData) {
      // $defaultElem = (
      //    <img
      //       alt={props.name}
      //       src={process.env.REACT_APP_S3_URL + '/' + props.imageData.media_url}
      //    />
      // );
      $defaultElem = '';
   }
   return (
      <div id="modalImagePlayerImage">
         {/* <div className="modal-image-title-detail white">
               {props.imageIndex + '. ' + props.imageData.photo_tags[0].title}
            </div> */}
         {$defaultElem}
         <img alt={props.name} img-idx={0} />

         <img alt={props.name} img-idx={1} className="pp-img-disable" />

         <img alt={props.name} img-idx={2} className="pp-img-disable" />

         <img alt={props.name} img-idx={3} className="pp-img-disable" />

         <img alt={props.name} img-idx={4} className="pp-img-disable" />
      </div>
   );
};
