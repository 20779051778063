import React from "react";
import "react-table-6/react-table.css";
import _ from "lodash";
import { LedgerComponent } from "./ledgerComponent";

export class PaymentLedgerV2 extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="">
        <h2 className="main-title m-0 p-3">Sales Ledger</h2>
        <hr className="m-0" />
        <LedgerComponent props={this.props} isLedger={true}/>
      </div>
    );
  }
}
