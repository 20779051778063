import { Config, API, APIAxios } from "../APIAndConfig";
import axios from "axios";
import { Firebase } from "../firebase";
import {
    ERROR_MESSAGE,
    FETCH_CATEGORIES,
    FETCH_CURRENT_EMBED_SESSION,
    FETCH_CURRENT_SESSION,
    FETCH_IMAGE,
    FETCH_NETWORK_STATUS,
    FETCH_SURVEYS,
    FETCH_USER,
    FETCH_USER_IMAGES,
    FETCH_USER_SESSIONS,
    USER_SELECTED_CATEGORIES,
    USER_SELECTED_CURRENT_SESSION,
    USER_SELECTED_IMAGES
} from "./types";
import set from "date-fns/set";
import { getOfflineSession, getOfflineSessionImages } from "../helpers/offlineSession";
import { readAsBase64 } from "../helpers";

export * from "./BillingAction";
export * from "./ImageActions";
export * from "./ProductActions";
export * from "./SessionActions";
export * from "./SurveyActions";
export * from "./UserActions";
export * from "./UploadActions";

// after refactoring to take advantage of ES 2017 and async/await
export const fetchUser = token => async dispatch => {
    if (!token && Firebase.Auth.currentUser)
        token = await Firebase.Auth.currentUser.getIdToken();
    const response = await API.getUser()
    const payload = response.successful ? response.payload.user : null;

    // side-effect: store image base64 to local storage
    if (!!payload && payload.profileImageUrl) {
        const base64Image = await readAsBase64(payload.profileImageUrl)
        if (base64Image) 
            localStorage.setItem('user_profile_image_url', base64Image)
    }
    
    dispatch({ type: FETCH_USER, payload: payload })
};
export const setUser = user => async dispatch => {

    dispatch({ type: FETCH_USER, payload: user });
}

export const submitSurvey = (values, history) => async dispatch => {
    const fbtoken = await Firebase.Auth.currentUser.getIdToken();

    const header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + fbtoken
    };
    await APIAxios.post(Config.APIUrl + "/api/surveys", values, { headers: header }).then((res) => {
        history.push("/surveys");
        dispatch({ type: FETCH_USER, payload: res.data });
    }).catch(async (err) => {
        switch (err.response.data) {
            case "SESSION_EXPIRED":
                const token = await Firebase.Auth.currentUser?.getIdToken();
                if (token) {
                    await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                    window.location.reload()
                }
                break;
            case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
            case "DATABASE_LIMIT_EXCEEDED":
                localStorage.setItem('service_unavailable_status', "failed");
                await Firebase.Auth.signOut();
                window.location.href = "/error"
                break;
            default:
                console.log("Error", err);
                break;
        }
    });
};

export const fetchSurveys = auth => async dispatch => {
    const result = await API.getSurveys();

    if (result.successful)
        dispatch({ type: FETCH_SURVEYS, payload: result.payload });
};

export const setMode = mode => dispatch => {
    dispatch({ type: mode, payload: mode });
};

const UPSPLASH_API_KEY =
    "1a8e11424325d8dfec66b82748507709d9faa393983dfdaa35ade910e2730f21";
const UPSPLASH_ROOT_URL = `https://api.unsplash.com/search/photos?client_id=${UPSPLASH_API_KEY}&query=mountain`;

export const fetchImages = term => async dispatch => {
    const fbtoken = await Firebase.Auth.currentUser.getIdToken();

    const header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + fbtoken
    };
    const url = `${UPSPLASH_ROOT_URL}&query=${term}`;

    return await axios.get(url, { headers: header }).then((request) => {
        return { type: FETCH_IMAGE, payload: request };
    }).catch(async (err) => {
        switch (err.response.data) {
            case "SESSION_EXPIRED":
                const token = await Firebase.Auth.currentUser?.getIdToken();
                if (token) {
                    await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                    window.location.reload()
                }
                break;
            case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
            case "DATABASE_LIMIT_EXCEEDED":
                localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
                window.location.href = "/error"
                break;
            default:
                console.log("Error", err);
                break;
        }
    });

    //console.log('Request', request);
};


// Note: This is used to submitSessionImages.
export const submitImage = (
    auth,
    files,
    modal,
    target,
    imagePreview,
    modalLoader,
    inputElem,
    successCount
) => async dispatch => {
    try {
        await API.uploadFiles(
            files, 
            auth.id, 
            successCount, 
            "img", 
            "session",
            null
        );
        const userUploads = await API.getMedia({
            purpose: "session",
            variant: "thumb",
            user: auth.id
        });

        // Tell all components listening that there are new images.
        dispatch({
            type: FETCH_USER_IMAGES,
            payload: userUploads.payload.data
        });

        if (modal)
            modal.style.display = "none";
        if (imagePreview)
            imagePreview.style.display = "block";
        if (modalLoader)
            modalLoader.style.display = "none";
        inputElem.value = "";
        target.setState({
            imagePreviewUrl: null,
            file: null,
            files: [],
            uploadImgPreviewList: []
        });

    } catch (error) {

        if (modalLoader)
            modalLoader.style.display = "none";
        if (inputElem)
            inputElem.value = "";
        dispatch({ type: ERROR_MESSAGE, payload: error.message });
    }
};

export const submitProfileImage = (
    auth,
    file,
    modal,
    target,
    imagePreview,
    modalLoader,
    inputElem
) => async dispatch => {

    try {

        const upload = await API.uploadFile(file, {
            purpose: "profile",
            type: "img",
            user: auth.id,
            mime: file.type,
            filename: file.name,
        });

        // Finally get updated user
        const user = await API.getUser();
        dispatch({ type: FETCH_USER, payload: user.payload.user });

        if (modal)
            modal.style.display = "none";
        if (imagePreview)
            imagePreview.style.display = "block";
        if (modalLoader)
            modalLoader.style.display = "none";
        if (inputElem)
            inputElem.value = "";
        target.setState({
            imagePreviewUrl: null,
            file: null,
            error: null
        });
    } catch (error) {
        //console.log(error);
        if (modalLoader)
            modalLoader.style.display = "none";
        if (inputElem)
            inputElem.value = "";

        dispatch({ type: ERROR_MESSAGE, payload: error.message });
    }
};

export const fetchUserUploadedImages = () => async dispatch => {

    const media = await API.getMedia({
        purpose: "session",
        variant: "thumb",
        user: "current"
    });

    console.log("object", media)
    dispatch({
        type: FETCH_USER_IMAGES,
        payload: media.payload.data
    });
    return media.payload.data;
};


// export const fetchUserSession = (auth, role) => async dispatch => {
//   let param = auth.id;

//   if (role !== "undefined") {
//     param = `${auth.id}/${role}`;
//   }

//   const sessions = await axios.get(`${Config.APIUrl}/api/user_session/${param}`);

export const fetchUserSession = auth => async dispatch => {
    const fbtoken = await Firebase.Auth.currentUser.getIdToken();

    const header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + fbtoken
    };

    await axios.get(`${Config.APIUrl}/api/user_session/${auth.id}`, {
        headers: header
    }).then((sessions) => {
        if (sessions.data.length > 0) {
            dispatch({ type: FETCH_USER_SESSIONS, payload: sessions.data });
        }
    }).catch(async (err) => {
        switch (err.response.data) {
            case "SESSION_EXPIRED":
                const token = await Firebase.Auth.currentUser?.getIdToken();
                if (token) {
                    await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                    window.location.reload()
                }
                break;
            case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
            case "DATABASE_LIMIT_EXCEEDED":
                localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
                window.location.href = "/error"
                break;
            default:
                console.log("Error", err);
                break;
        }
    })
    //console.log('fetchUserSession return', sessions);
    // if (sessions.data.length > 0) {
    //     dispatch({ type: FETCH_USER_SESSIONS, payload: sessions.data });
    // }
};

export const setSelectedSession = (id, title) => dispatch => {
    let newObject = { id: id, title: title };
    dispatch({
        type: USER_SELECTED_CURRENT_SESSION,
        payload: newObject
    });
};

export const fetchImageSessionById = (auth, sessionId) => async dispatch => {
    const downloadedSession = await getOfflineSession(sessionId)
    if (downloadedSession && downloadedSession.downloadState === 'FINISHED') {
        const imagesWithoutMediaUrl = await getOfflineSessionImages(sessionId);
        dispatch({ type: FETCH_CURRENT_SESSION, payload: imagesWithoutMediaUrl });

        return false
    }

    const fbtoken = await Firebase.Auth.currentUser.getIdToken();

    const header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + fbtoken
    };

    await axios.get(
        `${Config.APIUrl}/api/media_session/${sessionId}?user_id=${auth.id}`,
        { headers: header }
    ).then((session) => {
        dispatch({ type: FETCH_CURRENT_SESSION, payload: session.data });
    }).catch(async (err) => {
        switch (err.response.data) {
            case "SESSION_EXPIRED":
                const token = await Firebase.Auth.currentUser?.getIdToken();
                if (token) {
                    await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                    window.location.reload()
                }
                break;
            case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
            case "DATABASE_LIMIT_EXCEEDED":
                localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
                window.location.href = "/error"
                break;
            default:
                console.log("Error", err);
                break;
        }
    });

};

export const resetMediaSessions = () => dispatch => {
    dispatch({ type: FETCH_CURRENT_SESSION, payload: [] });
    dispatch({ type: USER_SELECTED_CURRENT_SESSION, payload: null });
};

export const resetError = () => dispatch => {
    dispatch({
        type: ERROR_MESSAGE,
        payload: null
    });
};

export const deleteImage = (media_id) => async dispatch => {


    const deleted = await API.deleteMedia(media_id);

    if (deleted.error) {
        dispatch({
            type: ERROR_MESSAGE,
            payload: deleted.error
        });
        return;
    }
    const images = await fetchUserUploadedImages()(dispatch);
    return images;
};

export const deleteMultipleImages = (media_id, id) => async dispatch => {

    let ids_string = media_id.toString()
    let payload = { "idString": ids_string, userId: id }
    const deleted = await API.deleteMultipleMedia(payload);

    if (deleted.error) {
        dispatch({
            type: ERROR_MESSAGE,
            payload: deleted.error
        });
        return;
    }
    const images = await fetchUserUploadedImages()(dispatch);
    return images;
};

export const setNetworkStatus = payload => dispatch => {
    dispatch({ type: FETCH_NETWORK_STATUS, payload });
};