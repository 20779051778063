import React, { Component } from "react";
import clsx from 'clsx';
import { FiCheckCircle } from "react-icons/fi";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { BiImageAdd } from "react-icons/bi";
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleLine } from "react-icons/ri";
import { API } from "../../APIAndConfig";
import SessionImages from "../cards/SessionImages";
import { Modal, Spinner } from "react-bootstrap";
import Pagination from "react-js-pagination";
import SimpleDropZone from "../../v2Sessions/simpleDropzone";

class SessionImagesDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteStatus: "view",
            noImages: 0,
            session_images: [],
            selectedDeleteImages: null,
            processingImages: false,
            imagesPerPage: 30,
            currentImages: [],
            selectedImage: "",
            showMaxImage: false,
            currentPage: 1,
            deleteConfirmationModal: false,
            isProcessing: false,
            showUploadImageModal: false,
            completedCount: []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.session) {
            if ((prevProps.session && prevProps.session.id) !== this.props.session.id) {
                this.setState({
                    deleteStatus: "view",
                    noImages: 0,
                    session_images: [],
                    selectedDeleteImages: null,
                    processingImages: false,
                    imagesPerPage: 30,
                    currentImages: [],
                    selectedImage: "",
                    showMaxImage: false,
                    currentPage: 1,
                    deleteConfirmationModal: false,
                    isProcessing: false,
                    showUploadImageModal: false,
                    completedCount: []
                })
            }
        }
    }

    getSessionImages = async () => {
        this.setState({ processingImages: true })
        await API.getSessionImages(this.props.session.id).then(
            res => {
                if (res.successful && res.payload) {
                    const indexOfLastImage = 1 * this.state.imagesPerPage;
                    const indexOfFirstImage = indexOfLastImage - this.state.imagesPerPage;
                    this.setState({
                        currentImages: res.payload.data.slice(indexOfFirstImage, indexOfLastImage),
                        session_images: res.payload.data,
                        selectedDeleteImages: null,
                        deleteStatus: "view",
                        isProcessing: false,
                        processingImages: false,
                        currentPage: 1,
                    })
                    if (res.payload.data.length === 0) {
                        this.setState({ noImages: 1 })
                    }
                }
            }
        )
    }

    onImageClick = (media_url) => {
        this.setState({
            selectedImage: media_url,
            showMaxImage: true
        })
    }

    onCheck = (id, event) => {
        let data = this.state.selectedDeleteImages ? [...this.state.selectedDeleteImages] : []
        if (event.target.checked) {
            data.push(Number(id))
            this.setState({
                selectedDeleteImages: data
            })
        }
        else {
            const index = data.indexOf(Number(id));
            if (index > -1) {
                data.splice(index, 1);
                if (data.length === 0) {
                    this.setState({
                        selectedDeleteImages: null
                    })
                } else {
                    this.setState({
                        selectedDeleteImages: data
                    })
                }
            }
        }
    }

    renderSessionCoreImages = () => {
        return this.state.currentImages.map(item => {

            let checkImage = false;
            if (this.state.selectedDeleteImages && this.state.selectedDeleteImages.includes(item.id)) {
                checkImage = true
            }

            return (
                <SessionImages
                    image={item.media_url}
                    id={item.id}
                    onClickImage={this.onImageClick.bind(this)}
                    status={this.state.deleteStatus}
                    onCheck={this.onCheck.bind(this)}
                    checked={checkImage}
                />
            )
        })
    }

    handlePageNumberClick = (pageNumber) => {
        const indexOfLastImage = Number(pageNumber) * this.state.imagesPerPage;
        const indexOfFirstImage = indexOfLastImage - this.state.imagesPerPage;
        this.setState({
            currentImages: this.state.session_images ? this.state.session_images.slice(indexOfFirstImage, indexOfLastImage) : [],
            currentPage: Number(pageNumber)
        })
    }

    renderPageNumbers = () => {
        return (
            <div>
                <Pagination
                    itemClass="session_images_pagination_item"
                    linkClass="session_images_pagination_link"
                    activeLinkClass="session_images_pagination_selectedLink"
                    prevPageText='<Prev'
                    nextPageText='Next >'
                    hideFirstLastPages
                    className="session_images_pagination"
                    activePage={this.state.currentPage}
                    itemsCountPerPage={this.state.imagesPerPage}
                    totalItemsCount={this.state.session_images.length}
                    pageRangeDisplayed={ window.innerWidth < 450 ? 3 : 10}
                    onChange={this.handlePageNumberClick.bind(this)}
                />
            </div>
        );
    }

    successCount(count) {
        let data = [...this.state.completedCount]
        data.push(count)
        this.setState({
            completedCount: data
        })
    }

    onSubmitUploadImageModal = async (files) => {
        if (files.length === 0) {

        } else {
            if (files && files.length > 0) {
                let data = await API.uploadFiles(
                    files,
                    null,
                    this.successCount.bind(this),
                    'img',
                    'session',
                    this.props.session.id
                )

                this.setState({
                    completedCount: [],
                    showUploadImageModal: false
                })
                this.getSessionImages()
            }
        }
    }

    deleteImages = async () => {
        await API.deleteMultipleMedia({
            idString: this.state.selectedDeleteImages.toString()
        }).then((res) => {
            if (res.successful) {
                this.setState({
                    isProcessing: false,
                    deleteConfirmationModal: false
                })
                this.getSessionImages()
            }
        })
    }

    renderChildList() {
        if (this.props.session.childSessions[0] && this.props.session.childSessions[0].child_session_ids) {
            let arr = this.props.session.childSessions[0].child_session_ids.split(",")
            return (
                <ul className="col-12 pl-5 pr-5 ml-5">
                {
                    arr.map((item, index) => {
                        let name = this.props.allSession.filter( obj => obj.id === Number(item))[0] ? this.props.allSession.filter( obj => obj.id === Number(item) )[0].name : item
                        return <li key={index} >{ name }</li>
                    })
                }
            </ul>
            )
        } else {
            return <div>No child sessions associated with the session</div>
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className={clsx('col-12 my-4')}>
                        <div className="card card-blur">
                            <div className="card-header d-flex align-items-center row">
                                <div className="col-md-auto col-12">
                                    Image Review
                                </div>
                                <div className="ml-auto col-md-auto col-12">
                                    {
                                        this.state.deleteStatus === "view" ?
                                            <button className={clsx('btn', 'x-secondary', 'col-md-auto', 'col-12', 'mt-md-0', 'mt-2')} style={{ borderRadius: "10px" }} 
                                            disabled={!this.props.session || this.state.noImages !== 0 || this.state.session_images.length === 0 || this.props.session.type === "mega"}
                                                onClick={() => {
                                                    this.setState({
                                                        deleteStatus: "delete"
                                                    })
                                                }}
                                            >
                                                <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                    <FiCheckCircle size={24} color="white" className="pr-2" /> Select Images
                                                </span></button> :
                                            <button className={clsx('btn', 'x-secondary', 'col-md-auto', 'col-12', 'mt-md-0', 'mt-2')} style={{ borderRadius: "10px" }} 
                                            disabled={!this.props.session || this.state.noImages !== 0 || this.state.session_images.length === 0 || this.props.session.type === "mega"}
                                                onClick={() => {
                                                    this.setState({
                                                        deleteStatus: "view",
                                                        selectedDeleteImages: null
                                                    })
                                                }}
                                            >
                                                <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                    <FaEye size={24} color="white" className="pr-2" /> View Images
                                                </span></button>
                                    }

                                    <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }} 
                                    disabled={!this.state.selectedDeleteImages || this.props.session.type === "mega"}
                                        onClick={() => {
                                            this.setState({
                                                deleteConfirmationModal: true
                                            })
                                        }}
                                    >
                                        <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                            <FaTrashAlt size={22} color="white" />
                                        </span></button>

                                    {
                                        this.props.session && this.state.session_images.length > 0 && this.state.deleteStatus === "delete" ?
                                            <>
                                                {
                                                    this.state.selectedDeleteImages && this.state.selectedDeleteImages.length === this.state.session_images.length ?
                                                        <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedDeleteImages : null
                                                                })
                                                            }}
                                                        >
                                                            <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                                <RiCheckboxMultipleBlankLine size={24} color="white" />
                                                            </span>
                                                        </button>
                                                        :
                                                        <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedDeleteImages : this.state.session_images.map(obj => obj.id)
                                                                })
                                                            }}
                                                        >
                                                            <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                                <RiCheckboxMultipleLine size={24} color="white" />
                                                            </span>
                                                        </button>
                                                }
                                            </> : <></>
                                    }

                                    <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }} 
                                    disabled={!this.props.session || this.props.session.type === "mega"}
                                        onClick={() => {
                                            this.setState({ showUploadImageModal: true })
                                        }}
                                    >
                                        <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                            <BiImageAdd size={24} color="white" />
                                        </span></button>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="form-group row m-0">
                                            {
                                                this.props.session === null ?
                                                    <div className="col-12 p-5"><h3 className="text-center"> Please select a session</h3></div>
                                                    : this.state.session_images.length > 0 ?
                                                        <>
                                                            <div className="font-weight-bolder pl-3">Total number of images  : {this.state.session_images.length}</div>
                                                            {
                                                                this.state.deleteStatus === "delete" ?
                                                                    <h6 className="w-100 text-center" style={{ fontSize: "25px", fontWeight: "bolder" }}>Select images to delete</h6>
                                                                    : <></>
                                                            }
                                                            <div className={clsx('ps-Modal__selectImages-container', 'justify-content-center')}>
                                                                {this.renderSessionCoreImages()}
                                                            </div>
                                                            <div className="my-4 p-4 d-flex justify-content-center w-100">
                                                                {this.renderPageNumbers()}
                                                            </div>
                                                        </>
                                                        :
                                                        this.state.noImages === 0 ?
                                                            <div className="col-12 p-5 w-100 d-flex justify-content-center align-items-center">
                                                                <button className={clsx('x-btn', 'btn', 'x-secondary')} disabled={!this.props.session || this.state.processingImages} onClick={() => {
                                                                    this.getSessionImages()
                                                                }} ><span className="p-2">Click to Load Images</span></button>
                                                            </div>
                                                            :
                                                            this.props.session.childSessions ?
                                                                <>
                                                                    <div className="col-12 p-5"><h4 className="text-center">Sorry - you cannot view the individual images of a supermix session. SuperMixes consist of individual sessions presented as a single session. To modify an image, you must modify the child / included session</h4></div>
                                                                    <div className="col-12 pl-5 pr-5"><h6>The sessions associated with this SuperMix are</h6></div>
                                                                    <div>
                                                                        {this.renderChildList()}
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className="col-12 p-5"><h3 className="text-center">No core images found for the session</h3></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showMaxImage} onHide={() => { this.setState({ showMaxImage: false }) }} dialogClassName="max_size_modal" centered>
                    <Modal.Header closeButton className="show_images_header"></Modal.Header>
                    <Modal.Body className="d-flex align-items-center justify-content-center">
                        <img
                            src={this.state.selectedImage}
                            style={{
                                maxHeight: "1080px",
                                maxWidth: "1920px"
                            }}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showUploadImageModal} onHide={() => { this.setState({ showUploadImageModal: true }) }}>
                    <Modal.Header>
                        <div className='w-100'>
                            <label>Upload Images</label>
                            <button
                                className="x-secondary"
                                style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                onClick={() => { this.setState({ showUploadImageModal: false }) }}
                            >X</button>
                        </div>
                    </Modal.Header>
                    <SimpleDropZone
                        onUpload={this.onSubmitUploadImageModal.bind(this)}
                        completedCount={this.state.completedCount.length}
                        acceptTypes="image/jpg, image/gif, image/bmp, image/jpeg, image/tiff, image/png, .heic"
                        message="Click Here To Select Images From Your Device"
                        inputType="Image"
                        maxFiles={50}
                    />
                </Modal>
                <Modal show={this.state.deleteConfirmationModal} onHide={() => { this.setState({ deleteConfirmationModal: true }) }}>
                    <Modal.Header>Delete Images</Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete images ?
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn x-btn x-secondary "
                            type="button"
                            onClick={() => {
                                this.setState({ deleteConfirmationModal: false })
                            }}
                        >
                            Close
                        </button>
                        <button
                            className="btn x-btn x-primary "
                            type="button"
                            disabled={this.state.isProcessing}
                            onClick={() => {
                                this.setState({ isProcessing: true })
                                this.deleteImages()
                            }}
                        >
                            {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Delete</span>}
                        </button>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default SessionImagesDisplay;
