import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { App } from "@capacitor/app";

import {
  endUserWatchSession,
  fetchDefaultImages,
  fetchImages,
  fetchImageSessionById,
  fetchSessionDetailById,
  fetchUser,
  fetchUserSession,
  resetMediaSessions,
  startUserWatchSession,
  surveyAfterSession,
  surveyBeforeSession,
} from "../../../actions/index";
import ImageDetail from "./ImageDetail";
import ModalImageDetail from "./ModalImageDetail";
import SurveyBefore from "../surveys/SurveyBefore";
import SurveyAfter from "../surveys/SurveyAfter";

import { withRouter } from "react-router-dom";
import "../../../scss/favorite_botton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faBars,
  faCompress,
  faExpand,
  faPause,
  faPlay,
  faVolumeMute,
  faVolumeUp,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import "./image.css";
import { Firebase } from "../../../firebase";
import {
  API,
  makeBucketUrl,
  makeLegacyCDNUrl,
  makePlayerImageUrl,
} from "../../../APIAndConfig";
import { FiHeart } from "react-icons/fi";
import moment from "moment";
import LoaderImage from "../../../assets/logo-loading.gif";
import { ProgressBar } from "react-bootstrap";
import NetworkSpeed from "network-speed";
import {
  removeOfflineUserFavorite,
  setOfflineUserFavorites,
  getOfflineSession,
  getOfflineImageData,
  setOfflineStartUserWatchSession,
  setOfflineSurveyBeforeSession,
  setOfflineStartUserWatchSessionIds,
  setSessionPauseTime,
  setOfflineSurveyAfterSession,
} from "../../../helpers/offlineSession";
const testNetworkSpeed = new NetworkSpeed();
const turtleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    width="30"
    height="30"
    viewBox="0 0 48 48"
    enable-background="new 0 0 48 48"
  >
    <g>
      <path
        fill="#8BC34A"
        d="M26.646,31.354c-2.209,0-4-1.791-4-4s1.794-3.877,4-4C33,23,37.322,21.996,38.711,15.638   c0.936-4.284,4.762-3.676,6.936-3.284c2.174,0.396,1.33,2.542,0.936,4.716C44.858,26.548,40,31,26.646,31.354z"
      />
      <g>
        <path
          fill="#8BC34A"
          d="M7.469,27.303c-0.314,0.821-0.677,1.444-1.104,2.1c-0.424,0.638-0.911,1.232-1.461,1.756    C3.815,32.213,2.395,32.924,1,33c1.281-0.578,2.169-1.61,2.707-2.745c0.272-0.57,0.471-1.169,0.608-1.778    c0.133-0.593,0.229-1.266,0.216-1.779c-0.021-0.85,0.649-1.556,1.499-1.577c0.85-0.021,1.556,0.649,1.577,1.498    c0.005,0.205-0.03,0.401-0.098,0.582L7.469,27.303z"
        />
      </g>
    </g>
    <path
      fill="#C0CA33"
      d="M6,30c0,1.656,1.343,3,3,3h22c1.656,0,3-1.178,3.25-3H6z"
    />
    <g>
      <circle fill="#558B2F" cx="42" cy="15" r="2" />
      <path
        fill="none"
        stroke="#558B2F"
        stroke-width="4"
        stroke-linecap="round"
        d="M24,28c0,0,9,0,9,1c0,3-3,7-3,7"
      />
      <path
        fill="none"
        stroke="#558B2F"
        stroke-width="4"
        stroke-linecap="round"
        d="M9,28l-2,4c0,0,1,4,8,4"
      />
    </g>
    <path
      fill="#795548"
      d="M24,30l0.699-0.232c4.401-1.86,8.092-4.779,10.619-8.376C33.34,14.803,27.233,10,20,10  c-8.836,0-16,7.164-16,16v1l6,3H24z"
    />
    <path
      fill="#8D6E63"
      d="M20,10c-7.376,0-13.571,5-15.421,11.789L9,24l6,1h6l5-2l4-3l3.131-3.131C30.242,12.719,25.441,10,20,10z"
    />
    <path
      fill="#5D4037"
      d="M32.523,16.063l-2.467,2.467l-4.887-5.7l0.749-1.685c-0.618-0.247-1.252-0.465-1.907-0.634l-0.827,1.86  l-4.462,3.569l-5.243-0.874l-2.369-2.369c-0.56,0.375-1.087,0.793-1.595,1.234l2.266,2.266l-3.23,6.461l-3.678-1.84  c-0.219,0.639-0.413,1.289-0.55,1.962l4.229,2.114C8.6,24.918,8.651,24.914,8.7,24.93c0.047,0.016,0.086,0.049,0.135,0.057l6,1  C14.89,25.995,14.945,26,15,26h6c0.126,0,0.252-0.023,0.372-0.071l5-2c0.082-0.033,0.158-0.076,0.229-0.129l4-3  c0.02-0.015,0.029-0.036,0.047-0.052c0.019-0.017,0.043-0.023,0.061-0.041l2.978-2.978C33.332,17.148,32.943,16.593,32.523,16.063z   M15.083,24L10.5,23.236l3.064-6.129l4.625,0.771L19.719,24H15.083z M25.506,22.121l-3.813,1.525l-1.566-6.267l3.744-2.995  l4.676,5.456L25.506,22.121z"
    />
    <path
      fill="#4E342E"
      d="M28.321,25.761l-1.084-2.438l2.517-1.888l2.383,3.572c0.503-0.464,0.978-0.953,1.434-1.455l-2.283-3.425  l2.397-2.397c-0.353-0.581-0.741-1.137-1.161-1.667l-3.18,3.18l-3.837,2.877L20.807,24h-5.724l-5.769-0.961l-4.439-2.22  c-0.219,0.64-0.412,1.289-0.55,1.961l3.511,1.756l-0.974,3.895l1.961,0.98l0.948-4.269L14,25.847V30h2v-4h4.105l0.444,4h2.013  l-0.484-4.354l3.398-1.359l1.858,4.18c0.591-0.33,1.167-0.677,1.724-1.047L28.321,25.761z"
    />
    <circle cx="42.646" cy="14.354" r="1" />
  </svg>
);

library.add(faVolumeMute);

const START_PLAYER = "START_PLAYER";
const PAUSE_PLAYER = "PAUSE_PLAYER";
const END_PLAYER = "END_PLAYER";
const FASTER_PLAYER = "FASTER_PLAYER";
const SLOWER_PLAYER = "SLOWER_PLAYER";
const LOAD_PLAYER = "LOAD_PLAYER";
const AUDIO_PLAY = "AUDIO_PLAY";
const AUDIO_PAUSE = "AUDIO_PAUSE";
const BUFFER_SECONDS = 20;
const BUFFER_BATCH_SIZE = 20;
//const timerInterval = 1000;
//const timerIntervalSet = [250, 500, 1000, 2000, 3000, 4000, 5000, 6000];

// TODO: 1. Add check the toggle button since it forces the thing to play again.
//       2. Add logic to reset the internal component state.
//       3. Add logic to update the timerIntervalSet to insert in between...
//       4. Merge logic to randomize images and splice in the text images.

class ImagePlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageData: null,
      index: 0,
      timer: 0,
      action: START_PLAYER,
      audioAction: AUDIO_PLAY,
      networkSpeedInterval: null,
      timerInterval: 0,
      timerIntervalIdx: -1,
      timerIntervalSet: [],
      imageDataArray: null,
      timerArray: [],
      originalIdxArray: [],
      loaded: false,
      currentEmotion: "skip",
      token: null,
      speedLabel: "",
      userNameCount: 1,
      submittedEndTime: false,
      nextLocation: null,
      userWatchHistory: null,
      completedSession: false,
      defaultAudioUrl: "/default/music/default_music_3.mp3",
      progressBar: 0,
      beforeSurveyClosed: false,
      favorite: props.favorite || false,
      buffering: false,
      bufferLoaded: 0,
      bufferTotal: props.mediaSession.length,
      pauseTime: {
        startTime: "",
        time: 0,
      },
      selectedTime: "Time",
      defaultTimeMS: {
        threeMin: 179000,
        fiveMin: 299000,
        tenMin: 599000,
        fifteenMin: 899000,
        thirtyMin: 1800000,
        sixtyMin: 3602000,
      },
      slowNetwork: false,
      currentEmotionCondition: false,
      tokenInterval: null,
      totalMins: 0,
      isPersonalised: false,
    };

    this.nextImageToBuffer = 0;
    this.processingCount = 0;

    //Bind this to the functions
    this.nextProperty = this.nextProperty.bind(this);
    this.startAnimation = this.startAnimation.bind(this);
    this.stopAnimation = this.stopAnimation.bind(this);
    this.fasterAnimation = this.fasterAnimation.bind(this);
    this.slowerAnimation = this.slowerAnimation.bind(this);
    this.audioChange = this.audioChange.bind(this);
    this.openModalImage = this.openModalImage.bind(this);
    this.toggleBtn = this.toggleBtn.bind(this);
    this.toggleDisabled = this.toggleDisabled.bind(this);
    this.printRemainingTime = this.printRemainingTime.bind(this);

    this.startImagePlayer = this.startImagePlayer.bind(this);
    this.setupTimerIntervalSet = this.setupTimerIntervalSet.bind(this);
    this.setupDefaultArray = this.setupDefaultArray.bind(this);
    this.onRouteChange = this.onRouteChange.bind(this);
    this.onClickAfterSurvey = this.onClickAfterSurvey.bind(this);
    this.addImageProcess = this.addImageProcess.bind(this);
    this.addAudioProcess = this.addAudioProcess.bind(this);
    this.incrementLoadedCount = this.incrementLoadedCount.bind(this);
    this.stopBuffering = this.stopBuffering.bind(this);

    App.addListener("appStateChange", ({ isActive }) => {
      if (!isActive) {
        this.stopAnimation();
        this.setState({
          pauseTime: {
            ...this.state.pauseTime,
            startTime: moment().toISOString(),
          },
        });
      }
    });
  }

  async getNetworkDownloadSpeed() {
    const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
    const fileSizeInBytes = 500000;
    const speed = await testNetworkSpeed.checkDownloadSpeed(
      baseUrl,
      fileSizeInBytes
    );
    console.log(speed);
    if (parseFloat(speed.mbps) < 5) {
      this.setState({ slowNetwork: true });
    } else {
      this.setState({ slowNetwork: false });
    }
  }

  async componentDidMount() {
    this.unblock = this.props.history.block((targetLocation) => {
      this.targetLocation = targetLocation;
      this.onRouteChange();
      return false;
    });

    let session_id = parseInt(this.props.sessionId);

    if (isNaN(session_id)) {
      this.props.history.push("/notFound");
      return;
    }

    // Check if session is available offline
    let offlineSession = await getOfflineSession(session_id);

    if (!offlineSession || offlineSession.downloadState !== "FINISHED") {
      this.state.networkSpeedInterval = setInterval(() => {
        this.getNetworkDownloadSpeed();
        // const speed = await testNetworkSpeed.checkDownloadSpeed(LoaderImage, 137236);
        // console.log(speed);
      }, 5000);
    }

    // preload the loader image
    const loaderImg = new Image();
    loaderImg.src = LoaderImage;
    window[LoaderImage] = loaderImg;

    Firebase.Auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const token = await authUser.getIdToken();
        this.setState({ token });
        if (!this.props.auth) {
          return;
        }

        if (!this.state.loaded) {
          this.state.loaded = true;

          // doesn't need to skip as inside the function have a side-effect
          // of using the downloaded session if any
          let data = await this.props.fetchSessionDetailById(
            this.props.sessionId
          );

          let audioPlayer = document.getElementById("audioPlayer");

          if (audioPlayer) {
            let source = document.createElement("source");
            source.id = "audioSource";
            source.type = "audio/mpeg";
            source.src = data.downloaded
              ? data.audio64
              : makeLegacyCDNUrl("b/" + data.defaultAudioURL);

            audioPlayer.appendChild(source);

            audioPlayer.src = data.downloaded
              ? data.audio64
              : makeLegacyCDNUrl("b/" + data.defaultAudioURL);
            audioPlayer.preload = "auto";
          }

          this.state.defaultAudioUrl = data.defaultAudioURL;

          // doesn't need to skip as inside the function have a side-effect
          // of using the downloaded session if any
          await this.props.fetchImageSessionById(
            this.props.auth,
            this.props.sessionId
          );

          // doesn't need to skip as inside the function have a side-effect
          // of using the downloaded session if any
          await this.props.fetchDefaultImages();

          // Doesn't get called on component mount, only component update
          this.openBeforeSurveyModal();
          await this.startImagePlayer();

          // Only start playing if the before survey is closed
          if (this.state.beforeSurveyClosed === true) {
            this.startAnimation();
          } else this.stopAnimation();
        }
      } else {
        this.props.history.push("/login");
      }
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.slowNetwork !== this.state.slowNetwork) {
      if (this.state.slowNetwork) {
        this.slowerAnimation();
      } else {
        this.fasterAnimation();
      }
    }
    if (this.state.loaded) {
      return;
    }

    if (
      (this.props.auth &&
        this.props.mediaSession.length <= 0 &&
        this.props.defaultImages) ||
      !this.props.networkStatus.connected
    ) {
      this.state.loaded = true;

      // doesn't need to skip as inside the function have a side-effect
      // of using the downloaded session if any
      let data = await this.props.fetchSessionDetailById(this.props.sessionId);

      let audioPlayer = document.getElementById("audioPlayer");
      if (audioPlayer) {
        let source = document.createElement("source");
        source.id = "audioSource";
        source.src = data.downloaded
          ? data.audio64
          : makeLegacyCDNUrl("b/" + data.defaultAudioURL);
        audioPlayer.src = data.downloaded
          ? data.audio64
          : makeLegacyCDNUrl("b/" + data.defaultAudioURL);
        audioPlayer.preload = "auto";
        audioPlayer.appendChild(source);
      }

      this.state.defaultAudioUrl = data.defaultAudioURL;

      // doesn't need to skip as inside the function have a side-effect
      // of using the downloaded session if any
      await this.props.fetchImageSessionById(
        this.props.auth,
        this.props.sessionId
      );

      // doesn't need to skip as inside the function have a side-effect
      // of using the downloaded session if any
      await this.props.fetchDefaultImages();

      this.openBeforeSurveyModal();
      await this.startImagePlayer();

      console.log(this.state.beforeSurveyClosed);

      // Only start playing if the before survey is closed
      if (this.state.beforeSurveyClosed === true) this.startAnimation();
      else this.stopAnimation();
    }
  }

  componentWillUnmount() {
    let audioPlayer = document.getElementById("audioPlayer");
    if (audioPlayer && !audioPlayer.paused) {
      audioPlayer.remove();
    }

    if (this.unblock) {
      this.unblock();
      this.props.history.push(this.targetLocation);
    }
  }

  setupDefaultArray() {
    if (this.state.timerIntervalSet && this.state.timerIntervalSet.length > 0) {
      return;
    }

    let initalInterval = 3000; // milliseconds
    this.state.timerIntervalSet.unshift({
      interval: initalInterval,
      label: "1x",
    });

    // 2x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.5,
      label: "2x",
    });

    // 3x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.3333333333,
      label: "3x",
    });

    // 4x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.25,
      label: "4x",
    });

    // 5x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.2,
      label: "5x",
    });

    // 6x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.1666666667,
      label: "6x",
    });

    // 7x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.14285714,
      label: "7x",
    });

    // 8x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.125,
      label: "8x",
    });

    // 9x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.11111111111,
      label: "9x",
    });

    // 10x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.0009,
      label: "Max",
    });
  }

  setupTimerIntervalSet(totalNumberOfImgs) {
    if (totalNumberOfImgs > 0) {
      // Main timer start
      let runtime = 60; // this.getRuntime();
      this.setupDefaultArray();

      let seconds = runtime * 60;
      let interval = seconds / totalNumberOfImgs;
      interval *= 1000;

      const { timerIntervalSet } = this.state;

      for (let i = timerIntervalSet.length - 1; i >= 0; --i) {
        if (timerIntervalSet[i].interval <= interval) {
          this.state.timerIntervalSet[i].interval = interval;
          this.state.timerIntervalIdx = i;
          this.state.timerInterval = interval;

          return;
        } else if (i === 0 && timerIntervalSet[i].interval > interval) {
          this.state.timerIntervalSet[i].interval = interval;
          this.state.timerIntervalIdx = i;
          this.state.timerInterval = interval;
        }
      }

      this.toggleDisabled();
    }
  }

  async onRouteChange() {
    if (this.state.timer !== 0) {
      clearTimeout(this.state.timer);
      this.state.timer = 0;
    }

    this.stopAnimation();
    this.props.resetMediaSessions();

    if (this.state.userWatchHistory && this.props.auth) {
      if (this.state.submittedEndTime === false) {
        let history = {};
        if (this.props.networkStatus.connected) {
          history = await this.props.endUserWatchSession(
            this.props.auth,
            this.props.sessionId,
            this.state.userWatchHistory,
            false,
            moment()
              .subtract("seconds", this.state.pauseTime.time / 1000)
              .toISOString()
          );
        }

        if (history.earnCredits) {
          //          alert(
          //            `Congratulations! You earn a bonus of ${history.earnCredits} credits for this session!`
          //          );

          if (this.state.token) {
            await this.props.fetchUser(this.state.token);
          }
        }
      }
    }

    let survey = document.getElementById("ppAfterSurvey");
    if (survey) {
      survey.click();
    }
  }

  getRuntime() {
    let runtime = this.props.runtime; //new URLSearchParams(this.props.location.search).get("runtime");

    runtime = parseInt(runtime);
    if (isNaN(runtime)) {
      // Default to 15 minutes
      // runtime = 15;
      runtime = 15;
    } else if (runtime < 3) {
      // Min runtime is 3 minutes
      runtime = 3;
    } else if (runtime > 15) {
      // Max runtime is 3 minutes
      runtime = 15;
    }

    return runtime;
  }

  imagesToBuffer(totalImages) {
    let runtimeSec = this.getRuntime() * 60;
    let imagesPerSec = totalImages / runtimeSec;
    let imagesBuffer = Math.round(imagesPerSec * BUFFER_SECONDS);

    return imagesBuffer;
  }

  async getMediaUrl(imageArray, index) {
    let mediaUrl = await getOfflineImageData(
      imageArray[this.nextImageToBuffer].id
    );
    if (Object.keys(mediaUrl).length === 0) {
      mediaUrl = imageArray[index].media_url;
    }
    return mediaUrl;
  }

  bufferNextImage() {
    if (this.nextImageToBuffer === null) return;

    this.setState(async (state) => {
      const imageArray = state.imageDataArray;

      if (imageArray[this.nextImageToBuffer].image_element) {
        this.nextImageToBuffer++;
        this.incrementLoadedCount();
        this.stopBuffering();
        return {};
      }

      let img = document.createElement("img");
      let mediaUrl = await this.getMediaUrl(imageArray, this.nextImageToBuffer);
      img.src = makePlayerImageUrl(mediaUrl);
      if (img.src.startsWith("data")) {
        this.processingCount--;
        this.incrementLoadedCount();
        this.stopBuffering();
      } else {
        img.onload = () => {
          this.processingCount--;
          this.incrementLoadedCount();
          this.stopBuffering();
        };
      }
      imageArray[this.nextImageToBuffer].image_element = img;
      this.processingCount++;
      if (this.state.bufferLoaded - (this.state.index + 1) <= 1) {
        this.stopAnimation();
        this.state.action = LOAD_PLAYER;
        this.setState({ buffering: true });
      }

      if (this.nextImageToBuffer < imageArray.length - 1)
        this.nextImageToBuffer++;
      else this.nextImageToBuffer = null;

      return { imageDataArray: imageArray };
    });
  }

  incrementLoadedCount() {
    this.setState((prevState, props) => ({
      bufferLoaded: prevState.bufferLoaded + 1,
    }));
  }

  stopBuffering() {
    const frontlog = this.state.bufferLoaded - (this.state.index + 1);
    if (frontlog >= BUFFER_BATCH_SIZE && this.state.action == LOAD_PLAYER) {
      this.setState({ buffering: false });
      this.startAnimation();
    }
  }

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        this.incrementLoadedCount();
        resolve(img);
      };
      img.onerror = () => {
        reject(img);
      };
      img.src = src;
    });
  }

  addAudioProcess(src) {
    return new Promise((resolve, reject) => {
      let audioPlayer = document.getElementById("audioPlayer");

      if (audioPlayer) {
        audioPlayer.onload = () => {
          resolve(audioPlayer);
        };
        audioPlayer.onerror = reject;
        audioPlayer.src = src;
      }
    });
  }

  async startImagePlayer() {
    let imageArray = this.props.mediaSession;

    // Add in the beginning images if it exist
    for (let i = this.props.defaultImages.length - 1; i >= 0; --i) {
      const defaultImage = this.props.defaultImages[i];

      if (defaultImage.default_image_type_id === 1) {
        imageArray.unshift(defaultImage);
      }
    }

    // Add in the ending images if it exist
    for (let i = 0; i < this.props.defaultImages.length; ++i) {
      const defaultImage = this.props.defaultImages[i];

      if (defaultImage.default_image_type_id === 2) {
        imageArray.push(defaultImage);
      }
    }

    // Loading the images first
    let imageArrayLength = imageArray.length;
    let bufferLength = this.imagesToBuffer(imageArrayLength);

    // Buffer initial set of images before play
    let promises = [];
    let batch = [];
    var that = this;
    for (
      this.nextImageToBuffer;
      this.nextImageToBuffer < bufferLength;
      ++this.nextImageToBuffer
    ) {
      batch.push(this.nextImageToBuffer);

      promises.push(
        this.addImageProcess(
          makePlayerImageUrl(
            await this.getMediaUrl(imageArray, this.nextImageToBuffer)
          )
        ).catch((e) => {
          console.error(e.message);
        })
      );

      if (
        batch.length === BUFFER_BATCH_SIZE ||
        this.nextImageToBuffer === bufferLength - 1
      ) {
        await Promise.all(promises)
          .then(function (response) {
            for (var key in response) {
              that.setState({
                progressBar: (batch[key] / (bufferLength - 1)) * 100,
              });
              imageArray[batch[key]].image_element = response[key];
            }
          })
          .catch((e) => console.log(`Error in promises ${e}`));

        promises.length = 0;
        batch.length = 0;
      }
    }

    // Add in the user name images...
    let canvas = document.getElementById("ppCanvas");
    if (canvas) {
      let dataURL = canvas.toDataURL("image/jpeg");
      let img = document.createElement("img");
      img.src = dataURL;
      let userNameImg = {
        media_url: dataURL,
        name: "userName.jpeg",
        auto_generated: true,
        image_element: img,
      };

      if (imageArrayLength < 21) {
        // Add user image in the middle
        let middle = Math.floor(imageArrayLength * 0.5);
        imageArray.splice(middle, 0, userNameImg);
      } else if (imageArrayLength < 101) {
        // Add user image every 20 images
        let i = 20;
        while (i < imageArrayLength) {
          imageArray.splice(i, 0, userNameImg);
          i += 20;
        }
      } else {
        // Add user image every 50 images
        let i = 50;
        while (i < imageArrayLength) {
          imageArray.splice(i, 0, userNameImg);
          i += 50;
        }
      }
    }

    this.setupTimerIntervalSet(imageArray.length);

    let label = this.state.timerIntervalSet[this.state.timerIntervalIdx].label;
    this.setState({
      action: START_PLAYER,
      imageData: imageArray[0],
      imageDataArray: imageArray,
      speedLabel: label,
      bufferTotal: imageArray.length,
    });

    let imgContainer = document.getElementById("mainImagePlayerImage");
    let modalImgContainer = document.getElementById("modalImagePlayerImage");
    let children = imgContainer.getElementsByTagName("img");
    let childrenModal = modalImgContainer.getElementsByTagName("img");

    if (imgContainer) {
      for (let i = 0; i < children.length; ++i) {
        let child = children[i];
        let childModal = childrenModal[i];

        if (child.hasAttribute("img-idx")) {
          let value = parseInt(child.getAttribute("img-idx"));
          if (value < imageArray.length) {
            // Update previous elements!
            if (imageArray[value].auto_generated) {
              if (!imageArray[value].image_element) {
                let mediaUrl = await this.getMediaUrl(imageArray, value);
                child.src = mediaUrl;
                childModal.src = mediaUrl;
              } else {
                imageArray[value].image_element.setAttribute(
                  "img-idx",
                  child.getAttribute("img-idx")
                );
                let disable = child.classList.contains("pp-img-disable");

                if (disable) {
                  imageArray[value].image_element.classList.add(
                    "pp-img-disable"
                  );
                }

                child.parentNode.replaceChild(
                  imageArray[value].image_element,
                  child
                );

                childModal.parentNode.replaceChild(
                  imageArray[value].image_element.cloneNode(true),
                  childModal
                );
              }
            } else {
              if (!imageArray[value].image_element) {
                let mediaUrl = makePlayerImageUrl(
                  await this.getMediaUrl(imageArray, value)
                );
                child.src = mediaUrl;
                childModal.src = mediaUrl;
              } else {
                imageArray[value].image_element.setAttribute(
                  "img-idx",
                  child.getAttribute("img-idx")
                );
                let disable = child.classList.contains("pp-img-disable");

                if (disable) {
                  imageArray[value].image_element.classList.add(
                    "pp-img-disable"
                  );
                }

                child.parentNode.replaceChild(
                  imageArray[value].image_element,
                  child
                );

                childModal.parentNode.replaceChild(
                  imageArray[value].image_element.cloneNode(true),
                  childModal
                );
              }
            }
          }
        }
      }
    }
  }

  // TODO: Use the carousel way instead
  async nextProperty(event) {
    const imageArray = this.state.imageDataArray;
    if (!this.state.imageData) {
      // STOP ANIMATION HERE...
      this.state.action = END_PLAYER;
      this.setState({
        imageData: imageArray[0],
        action: END_PLAYER,
      });
      this.stopAnimation();
      this.state.completedSession = true;

      if (this.state.userWatchHistory) {
        this.state.submittedEndTime = true;

        let history = await this.props.endUserWatchSession(
          this.props.auth,
          this.props.sessionId,
          this.state.userWatchHistory,
          true,
          moment()
            .subtract("seconds", this.state.pauseTime.time / 1000)
            .toISOString()
        );

        if (history.earnCredits) {
          //          alert(
          //            `Congratulations! You earn a bonus of ${history.earnCredits} credits for this session!`
          //          );

          if (this.state.token) {
            await this.props.fetchUser(this.state.token);
          }
        }
      }
      this.state.currentEmotionCondition && this.openAfterSurveyModal();
      return;
    } else {
      let newIndex = 0;
      if (this.state.index < imageArray.length - 1) {
        if (
          this.state.action !== FASTER_PLAYER &&
          this.state.action !== SLOWER_PLAYER
        ) {
          // Going to show next image so continue buffer
          this.bufferNextImage();

          newIndex = this.state.index + 1;

          let imgContainer = document.getElementById("mainImagePlayerImage");

          let modalImgContainer = document.getElementById(
            "modalImagePlayerImage"
          );

          let ppIdx = 0;
          if (newIndex > 0) {
            ppIdx = newIndex % 5;
          }

          if (!this.state.tokenInterval) {
            const isPersonalised = this.props.mediaSession.some(
              (f) => f.user_id === this.props.auth.id
            );

            this.state.tokenInterval = setInterval(() => {
              if (
                this.state.action !== END_PLAYER &&
                this.state.action !== PAUSE_PLAYER &&
                this.state.totalMins < 180
              ) {
                this.state.totalMins++;
                this.setState({
                  totalMins: this.state.totalMins,
                });
              } else if (this.state.totalMins >= 180) {
                clearInterval(this.state.tokenInterval);
                API.addTokenPlayerSession(this.props.auth.id, {
                  isPersonalised,
                });
              }
            }, 1000);
          }

          let children = imgContainer.getElementsByTagName("img");
          let childrenModal = modalImgContainer.getElementsByTagName("img");

          for (let i = 0; i < children.length; ++i) {
            let child = children[i];
            let childModal = childrenModal[i];

            child.classList.add("pp-img-disable");
            childModal.classList.add("pp-img-disable");

            if (child.hasAttribute("img-idx")) {
              let nextIdx = this.state.index + 5;
              let value = parseInt(child.getAttribute("img-idx"));

              let previous = ppIdx - 1;

              if (previous === -1) previous = 4;

              if (
                value === previous &&
                nextIdx < this.state.imageDataArray.length
              ) {
                if (this.state.imageDataArray[nextIdx].auto_generated) {
                  if (!this.state.imageDataArray[nextIdx].image_element) {
                    child.src = await this.getMediaUrl(
                      this.state.imageDataArray,
                      nextIdx
                    );
                    childModal.src = await this.getMediaUrl(
                      this.state.imageDataArray,
                      nextIdx
                    );
                  } else {
                    this.state.imageDataArray[
                      nextIdx
                    ].image_element.setAttribute(
                      "img-idx",
                      child.getAttribute("img-idx")
                    );

                    let disable = child.classList.contains("pp-img-disable");
                    if (disable) {
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.classList.add("pp-img-disable");
                    }

                    child.parentNode.replaceChild(
                      this.state.imageDataArray[nextIdx].image_element,
                      child
                    );

                    childModal.parentNode.replaceChild(
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.cloneNode(true),
                      childModal
                    );
                  }
                } else {
                  if (!this.state.imageDataArray[nextIdx].image_element) {
                    // Update previous elements!
                    child.src = makePlayerImageUrl(
                      await this.getMediaUrl(this.state.imageDataArray, nextIdx)
                    );
                    childModal.src = makePlayerImageUrl(
                      await this.getMediaUrl(this.state.imageDataArray, nextIdx)
                    );
                  } else {
                    this.state.imageDataArray[
                      nextIdx
                    ].image_element.setAttribute(
                      "img-idx",
                      child.getAttribute("img-idx")
                    );

                    let disable = child.classList.contains("pp-img-disable");
                    if (disable) {
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.classList.add("pp-img-disable");
                    }

                    child.parentNode.replaceChild(
                      this.state.imageDataArray[nextIdx].image_element,
                      child
                    );

                    childModal.parentNode.replaceChild(
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.cloneNode(true),
                      childModal
                    );
                  }
                }
              }

              if (value === ppIdx) {
                child.classList.remove("pp-img-disable");
                childModal.classList.remove("pp-img-disable");
              }
            }
          }
        } else {
          newIndex = this.state.index;
          this.state.action = PAUSE_PLAYER;
        }
      } else {
        this.state.action = END_PLAYER;
        this.setState({
          imageData: imageArray[0],
          action: END_PLAYER,
        });
        this.stopAnimation();
        this.state.completedSession = true;

        if (this.state.userWatchHistory) {
          this.state.submittedEndTime = true;
          let history = await this.props.endUserWatchSession(
            this.props.auth,
            this.props.sessionId,
            this.state.userWatchHistory,
            true,
            moment()
              .subtract("seconds", this.state.pauseTime.time / 1000)
              .toISOString()
          );

          if (history.earnCredits) {
            //            alert(
            //              `Congratulations! You earn a bonus of ${history.earnCredits} credits for this!`
            //            );

            if (this.state.token) {
              await this.props.fetchUser(this.state.token);
            }
          }
        }
        this.state.currentEmotionCondition && this.openAfterSurveyModal();

        return;
      }

      this.setState({ imageData: imageArray[newIndex], index: newIndex });
    }
  }

  stopAnimation() {
    if (this.state.action !== END_PLAYER) {
      this.state.action = PAUSE_PLAYER;
    }

    // NOTE: Funkie way of clearing the timer for now
    // TODO: Remove this after adding in a structure to keep track of timer creation.
    // for (let i = 0; i < 1000; i++) {console.log('clearing', i);
    //   clearInterval(i);
    // }

    if (this.state.timer !== 0) {
      clearTimeout(this.state.timer);
      this.state.timer = 0;
    }

    let audioPlayer = document.getElementById("audioPlayer");
    if (audioPlayer && !audioPlayer.paused) {
      audioPlayer.pause();
    }

    let playBtns = document.querySelectorAll(".fa-play");
    playBtns.forEach(function (element) {
      element.classList.remove("hidden");
    });

    let pauseBtns = document.querySelectorAll(".fa-pause");
    pauseBtns.forEach(function (element) {
      element.classList.add("hidden");
    });
  }

  async startAnimation() {
    const updateImage = async () => {
      const startTime = new Date();
      await this.nextProperty();
      const endTime = new Date();
      const durationInMs = endTime - startTime;
      if (this.state.timer !== 0)
        this.state.timer = setTimeout(
          updateImage,
          Math.max(200, this.state.timerInterval - durationInMs)
        );
    };

    if (this.props.defaultImages) {
      if (this.state.imageDataArray === null) return;

      //document.querySelector(".next-click").click();
      await this.nextProperty();
      this.state.action = START_PLAYER;
      let l_timerInterval = this.state.timerInterval;
      this.state.timer = setTimeout(updateImage, l_timerInterval);

      let audioPlayer = document.getElementById("audioPlayer");
      if (
        audioPlayer &&
        audioPlayer.paused &&
        this.state.audioAction === AUDIO_PLAY
      ) {
        const playPromise = audioPlayer.play();
        if (playPromise !== null) {
          playPromise.catch((error) => {
            audioPlayer.play();
          });
        }
      }

      let playBtns = document.querySelectorAll(".fa-play");
      playBtns.forEach(function (element) {
        element.classList.add("hidden");
      });

      let pauseBtns = document.querySelectorAll(".fa-pause");
      pauseBtns.forEach(function (element) {
        element.classList.remove("hidden");
      });
    }
    // Don't start player if image array isn't set
  }

  slowerAnimation() {
    if (this.state.action === END_PLAYER) {
      this.stopAnimation();
      return;
    }

    if (this.state.action === PAUSE_PLAYER) {
      return;
    }

    this.setState({
      selectedTime: "Time",
    });
    if (this.state.timerIntervalIdx < this.state.timerIntervalSet.length - 1) {
      this.state.timerIntervalIdx++;
      this.state.timerInterval =
        this.state.timerIntervalSet[this.state.timerIntervalIdx].interval;

      let label =
        this.state.timerIntervalSet[this.state.timerIntervalIdx].label;
      this.setState({ speedLabel: label });
    }
    this.toggleDisabled();

    if (this.state.action === START_PLAYER) {
      this.state.action = SLOWER_PLAYER;
      this.stopAnimation();
      this.startAnimation();
    }
  }

  fasterAnimation() {
    if (this.state.action === END_PLAYER) {
      this.stopAnimation();
      return;
    }

    if (this.state.action === PAUSE_PLAYER) {
      return;
    }

    let checkTime =
      180000 -
      (moment().diff(this.state.userWatchHistory?.start_time, "seconds") *
        1000 -
        this.state.pauseTime.time);
    this.setState({
      selectedTime: "Time",
    });

    if (this.state.timerIntervalIdx !== 0) {
      this.state.timerIntervalIdx--;
      let count = this.state.imageDataArray.length - 1 - this.state.index;
      let timeRemainMS =
        count *
        this.state.timerIntervalSet[this.state.timerIntervalIdx].interval;

      this.state.timerInterval =
        timeRemainMS >= checkTime
          ? this.state.timerIntervalSet[this.state.timerIntervalIdx].interval
          : checkTime / count;
      let label =
        this.state.timerIntervalSet[this.state.timerIntervalIdx].label;
      this.setState({
        speedLabel: label,
      });
    }

    this.toggleDisabled();

    if (this.state.action === START_PLAYER) {
      this.state.action = FASTER_PLAYER;
      this.stopAnimation();
      this.startAnimation();
    }
  }

  audioChange() {
    let volumeUpBtns = document.querySelectorAll(".fa-volume-up");
    let volumeMuteBtns = document.querySelectorAll(".fa-volume-mute");
    let audioPlayer = document.getElementById("audioPlayer");

    if (volumeUpBtns[0].classList.contains("hidden")) {
      this.setState({ audioAction: AUDIO_PLAY });
      volumeUpBtns.forEach(function (element) {
        element.classList.remove("hidden");
      });

      volumeMuteBtns.forEach(function (element) {
        element.classList.add("hidden");
      });

      if (
        audioPlayer &&
        audioPlayer.paused &&
        this.state.action === START_PLAYER
      ) {
        const playPromise = audioPlayer.play();
        if (playPromise !== null) {
          playPromise.catch((error) => {
            audioPlayer.play();
          });
        }
      }
    } else {
      this.setState({ audioAction: AUDIO_PAUSE });
      volumeUpBtns.forEach(function (element) {
        element.classList.add("hidden");
      });

      volumeMuteBtns.forEach(function (element) {
        element.classList.remove("hidden");
      });

      if (audioPlayer && !audioPlayer.paused) {
        audioPlayer.pause();
      }
    }
  }

  openModalImage() {
    if (this.state.timer !== 0) {
      let audioPlayer = document.getElementById("audioPlayer");
      if (
        audioPlayer &&
        audioPlayer.paused &&
        this.state.audioAction === AUDIO_PLAY
      ) {
        const playPromise = audioPlayer.play();
        if (playPromise !== null) {
          playPromise.catch((error) => {
            audioPlayer.play();
          });
        }
      }
    }

    let modalImgPlayer = document.getElementById("modalImagePlayer");
    modalImgPlayer.style.display = "block";
    let mainImgPlayer = document.getElementById("mainImagePlayer");
    mainImgPlayer.style.display = "none";
  }

  closeModalImage() {
    let modalImgPlayer = document.getElementById("modalImagePlayer");
    modalImgPlayer.style.display = "none";
    let mainImgPlayer = document.getElementById("mainImagePlayer");
    mainImgPlayer.style.display = "block";
  }

  onDefaultSelect(e) {
    let count = this.state.imageDataArray.length - 1 - this.state.index;
    let timeMS = this.state.defaultTimeMS[e.target.value];

    this.state.timerInterval = timeMS / count;
    this.state.selectedTime = "NO_TIME";

    if (this.state.action === START_PLAYER) {
      this.state.action = FASTER_PLAYER;
      this.stopAnimation();
      this.startAnimation();
    }
  }

  async toggleBtn() {
    if (this.state.action === LOAD_PLAYER) {
      return;
    }

    if (
      this.state.action === PAUSE_PLAYER ||
      this.state.action === END_PLAYER
    ) {
      this.startAnimation();
      let tempTime = this.state.pauseTime.startTime;
      this.setState({
        pauseTime: {
          startTime: "",
          time:
            this.state.pauseTime.time +
            moment().diff(tempTime, "seconds") * 1000,
        },
      });

      await setSessionPauseTime({
        sessionId: +this.props.sessionId,
        pauseTime: this.state.pauseTime,
      });
    } else {
      this.stopAnimation();
      this.setState({
        pauseTime: {
          ...this.state.pauseTime,
          startTime: moment().toISOString(),
        },
      });

      await setSessionPauseTime({
        sessionId: this.props.sessionId,
        pauseTime: this.state.pauseTime,
      });
    }
  }

  toggleDisabled() {
    const downBtns = document.querySelectorAll(".fa-angle-double-down");
    if (
      this.state.timerIntervalIdx ===
      this.state.timerIntervalSet.length - 1
    ) {
      downBtns.forEach(function (element) {
        element.classList.add("disabled");
      });
    } else {
      downBtns.forEach(function (element) {
        element.classList.remove("disabled");
      });
    }

    const upBtns = document.querySelectorAll(".fa-angle-double-up");
    if (this.state.timerIntervalIdx === 0) {
      upBtns.forEach(function (element) {
        element.classList.add("disabled");
      });
    } else {
      upBtns.forEach(function (element) {
        element.classList.remove("disabled");
      });
    }
  }

  printRemainingTime() {
    const {
      timerIntervalSet,
      timerIntervalIdx,
      imageDataArray,
      index,
      timerInterval,
    } = this.state;

    if (
      !timerIntervalSet ||
      timerIntervalSet.length <= 0 ||
      !imageDataArray ||
      imageDataArray.length <= 0
    ) {
      return;
    }

    let count = imageDataArray.length - 1 - index;
    let timeRemainMS = count * timerInterval;

    return this.formatMS(timeRemainMS);
  }

  formatMS(ms) {
    // 1- Convert to seconds:
    let seconds = ms / 1000;
    // 2- Extract hours:
    let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    let minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;

    return (
      this.formatNumber(hours) +
      ":" +
      this.formatNumber(minutes) +
      ":" +
      this.formatNumber(Math.ceil(seconds))
    );
  }

  formatNumber(num) {
    let format = num;
    if (num < 10) {
      format = `0${num}`;
    }

    return format;
  }

  /* Begin Survey Helper logic */

  openBeforeSurveyModal() {
    const modal = document.getElementById("beforeModal");
    modal.style.display = "block";
    this.clearSurveyActiveState();
    this.setState({ beforeSurveyClosed: false });
  }

  openAfterSurveyModal() {
    const modal = document.getElementById("afterModal");
    modal.style.display = "block";
    this.clearSurveyActiveState();
  }

  onClickAfterSurvey(e) {
    e.preventDefault();
    this.openAfterSurveyModal();
  }

  async onBeforeModalClose(e) {
    e.preventDefault();

    if (!this.props.networkStatus.connected) {
      await setOfflineSurveyBeforeSession({
        sessionId: +this.props.sessionId,
        currentEmotion: this.state.currentEmotion,
      });

      await setOfflineStartUserWatchSession({
        sessionId: +this.props.sessionId,
      });

      await setOfflineStartUserWatchSessionIds(this.props.sessionId);
    }

    let survey = {};
    if (this.props.networkStatus.connected) {
      survey = await this.props.surveyBeforeSession(
        this.props.auth.id,
        this.props.sessionId,
        this.state.currentEmotion
      );
    }

    const modal = document.getElementById("beforeModal");
    modal.style.display = "none";

    // reset the currentEmotion to skip
    this.state.currentEmotion = "skip";
    this.state.currentEmotionCondition = true;
    // Don't use startImagePlayer(survey). Just send survey details and only start if images are ready

    // Send to server starting watch session
    let userWatchHistory = { start_time: 0 };
    if (this.props.networkStatus.connected) {
      userWatchHistory = await this.props.startUserWatchSession(
        this.props.auth,
        this.props.sessionId,
        survey
      );
    }

    if (!!Object.keys(userWatchHistory).length)
      this.setState({ userWatchHistory: userWatchHistory });

    // Only if the player is loaded and is in paused state then start playing
    if (this.state.action == PAUSE_PLAYER && this.state.imageDataArray !== null)
      this.startAnimation();

    this.setState({ beforeSurveyClosed: true });
  }

  async onAfterModalClose(e) {
    e.preventDefault();

    if (!this.props.networkStatus.connected) {
      await setOfflineSurveyAfterSession({
        sessionId: +this.props.sessionId,
        currentEmotion: this.state.currentEmotion,
      });
    }

    if (this.props.sessionSurvey) {
      await this.props.surveyAfterSession(
        this.props.auth.id,
        this.props.sessionId,
        this.props.sessionSurvey.id,
        this.state.currentEmotion
      );
    }

    // if (this.state.completedSession === true) {
    //   this.props.history.push("/dashboard");
    // }

    //Close Modal
    const modal = document.getElementById("afterModal");
    modal.style.display = "none";

    this.state.currentEmotion = "skip";
    this.state.currentEmotionCondition = false;

    if (this.unblock) {
      this.unblock();
      this.props.history.push(this.targetLocation);
    }
  }

  clearSurveyActiveState() {
    const collection = document.getElementsByClassName("ppEmojiButton");
    for (let i = 0; i < collection.length; ++i) {
      const element = collection[i];

      if (element.classList.contains("ppEmoActive")) {
        element.classList.remove("ppEmoActive");
      }
    }
  }

  onSelectEmotion(e) {
    e.preventDefault();
    let mainTarget = e.target;

    let value = e.target.getAttribute("name");
    if (e.target.nodeName === "path") {
      value =
        e.target.parentElement.parentElement.parentElement.getAttribute("name");

      mainTarget = e.target.parentElement.parentElement.parentElement;
    }

    if (e.target.nodeName === "svg") {
      value = e.target.parentElement.parentElement.getAttribute("name");
      mainTarget = e.target.parentElement.parentElement;
    }

    if (e.target.nodeName === "p") {
      value = e.target.parentElement.getAttribute("name");
      mainTarget = e.target.parentElement;
    }

    this.state.currentEmotion = value;

    this.clearSurveyActiveState();
    mainTarget.classList.add("ppEmoActive");
  }

  onBeforeSurveyContinue(e) {
    // Send data to server
    e.preventDefault();

    this.onBeforeModalClose(e);
  }

  onAfterSurveyDone(e) {
    // Send data to server
    e.preventDefault();

    this.onAfterModalClose(e);
  }
  /* End Survey Helper logic */

  onOpenNav(e) {
    document.getElementById("sidebar").classList.add("sidebarOpen");
  }

  /* Begin Name Template */
  calculateY(text, maxWidth, lineHeight) {
    let numOfCharacters = maxWidth / lineHeight; // number of character on 1 line
    numOfCharacters *= 2;
    return (text.length / numOfCharacters) * lineHeight;
  }

  wrapText(context, text, x, y, maxWidth, lineHeight) {
    let words = text.split(" "),
      line = "",
      lineCount = 0,
      i,
      test,
      metrics;

    for (i = 0; i < words.length; i++) {
      test = words[i];
      metrics = context.measureText(test);
      while (metrics.width > maxWidth) {
        // Determine how much of the word will fit
        test = test.substring(0, test.length - 1);
        metrics = context.measureText(test);
      }
      if (words[i] != test) {
        words.splice(i + 1, 0, words[i].substr(test.length));
        words[i] = test;
      }

      test = line + words[i] + " ";
      metrics = context.measureText(test);

      if (metrics.width > maxWidth && i > 0) {
        context.fillText(line, x, y);
        line = words[i] + " ";
        y += lineHeight;
        lineCount++;
      } else {
        line = test;
      }
    }

    context.fillText(line, x, y);
  }

  onTemplate1Load(e) {
    e.preventDefault();
    let fullName = this.props.auth?.firstName + " " + this.props.auth?.lastName;

    let canvas = document.getElementById("ppCanvas");
    let context = canvas.getContext("2d");

    canvas.width = e.target.naturalWidth;
    canvas.height = e.target.naturalHeight;

    let x = canvas.width * 0.5;

    let endY = this.calculateY(fullName, canvas.width - 30, 150);
    let y = canvas.height * 0.5 - endY * 0.5;

    context.drawImage(e.target, 0, 0, canvas.width, canvas.height);

    context.font = "bolder 60pt Helvetica Neue";

    context.fillStyle = "#3D5478";
    context.textAlign = "center";

    this.wrapText(context, fullName, x, y, canvas.width - 30, 150);
  }

  isCanvasBlank(cnv) {
    const blank = document.createElement("canvas");
    blank.width = cnv.width;
    blank.height = cnv.height;
    return cnv.toDataURL() === blank.toDataURL();
  }

  renderTemplateImages() {
    if (document.getElementById("ppCanvas")) {
      const blank = this.isCanvasBlank(document.getElementById("ppCanvas"));
      if (blank) {
        const fullpath = makeLegacyCDNUrl(
          "/b/default/sessions/background_3_default.jpeg?t=" +
            new Date().getTime()
        );
        return (
          <img
            crossOrigin="anonymous"
            id="pp-img-template-1"
            src={fullpath}
            style={{ display: "none" }}
            className="pp-stmt-img-card"
            onLoad={this.onTemplate1Load.bind(this)}
          />
        );
      } else {
        return;
      }
    }
  }

  /* End Name Template */

  render() {
    if (this.props.networkStatus.connected) {
      if (!this.props.auth || !this.props.mediaSession) {
        return <div />;
      }
    }

    let session_id = parseInt(this.props.sessionId);

    // Don't render the rest of the page if the session_id is not a number.
    if (isNaN(session_id) || !this.props.mediaSession) {
      return <div />;
    }

    return (
      <div className="">
        {/* <div className="d-flex">
          <button
            className="btn bg-transparent d-md-none"
            onClick={this.onOpenNav.bind(this)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <p className="text-secondary m-0 px-0 py-3 px-md-3">Image Player</p>
        </div> */}
        <div id="mainImagePlayer" className="image-player">
          <div>
            <div className="next-click" onClick={this.nextProperty} />
            <div
              id="ppAfterSurvey"
              onClick={
                this.state.currentEmotionCondition
                  ? this.onClickAfterSurvey
                  : undefined
              }
            />
          </div>

          <canvas id="ppCanvas" style={{ display: "none" }} />
          <div>{this.renderTemplateImages()}</div>

          <div className="d-flex justify-content-center">
            <div className=" shadow v2ImagePlayer">
              <div className="image-container position-relative">
                <div class="imageDetailBox">
                  <ImageDetail
                    imageData={this.state.imageData}
                    imageIndex={
                      this.state.originalIdxArray[this.state.index] + 1
                    }
                    progressBar={this.state.progressBar}
                  />
                </div>
                {this.state.buffering && (
                  <img
                    src={LoaderImage}
                    size="large"
                    style={{
                      display: "block",
                      position: "absolute",
                      top: "calc(50% - 50px)",
                      left: "calc(50% - 50px)",
                      width: "100px",
                      height: "100px",
                      background: "transparent",
                    }}
                  />
                )}
                <ProgressBar className={"playProgress position-relative"}>
                  <ProgressBar
                    className={
                      (this.state.slowNetwork ? "bg-danger" : "bg-primary") +
                      " position-absolute mt-0 h-100"
                    }
                    max={this.state.bufferTotal}
                    now={this.state.index + 1}
                  />
                  <ProgressBar
                    className={"bg-secondary mt-0"}
                    max={this.state.bufferTotal}
                    now={this.state.bufferLoaded}
                  />
                </ProgressBar>
                <div
                  id="imagePlayerControls"
                  className="player-control d-flex align-items-center fluidFont"
                >
                  <span
                    id="playBtn"
                    className="md-font fluidFont"
                    onClick={this.toggleBtn}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      className="hidden"
                      color="white"
                    />
                    <FontAwesomeIcon icon={faPause} color="white" />
                  </span>

                  <div
                    id="slowBtn"
                    className="d-flex align-items-center sm-font fluidFont"
                    onClick={!this.state.slowNetwork && this.slowerAnimation}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Slower"
                    style={
                      this.state.slowNetwork
                        ? { opacity: 0.5, cursor: "not-allowed" }
                        : null
                    }
                  >
                    <p className="text-white m-0">slower</p>
                    <FontAwesomeIcon
                      icon={faAngleDoubleDown}
                      className="playerArrow"
                      color="white"
                    />
                  </div>

                  <div
                    id="fastBtn"
                    className="d-flex align-items-center sm-font fluidFont"
                    onClick={!this.state.slowNetwork && this.fasterAnimation}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Faster"
                    style={
                      this.state.slowNetwork
                        ? { opacity: 0.5, cursor: "not-allowed" }
                        : null
                    }
                  >
                    <p className="text-white m-0">faster</p>
                    <FontAwesomeIcon
                      icon={faAngleDoubleUp}
                      className="playerArrow"
                      color="white"
                    />
                  </div>

                  {this.state.slowNetwork && (
                    <div
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Your internet speed is slow."
                    >
                      {turtleIcon}
                    </div>
                  )}

                  <span
                    id="speedLabel"
                    className="sm-font fluidFont"
                    style={{ fontWeight: "bold" }}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Speed of Animation"
                  >
                    <div className="white-text">{this.state.speedLabel}</div>
                  </span>

                  <span>
                    <select
                      name="default_time"
                      className="sm-font fluidFont"
                      style={{
                        background: "transparent",
                        color: "white",
                        border: "0px",
                        fontWeight: "bold",
                      }}
                      onChange={this.onDefaultSelect.bind(this)}
                      defaultValue="Time"
                    >
                      <option className="text-dark" value="Time" disabled>
                        Time
                      </option>
                      <option
                        value="threeMin"
                        className="text-dark"
                        disabled={this.state.slowNetwork}
                      >
                        3 min
                      </option>
                      <option
                        value="fiveMin"
                        className="text-dark"
                        disabled={this.state.slowNetwork}
                      >
                        5 min
                      </option>
                      <option
                        value="tenMin"
                        className="text-dark"
                        disabled={this.state.slowNetwork}
                      >
                        10 min
                      </option>
                      <option value="fifteenMin" className="text-dark">
                        15 min
                      </option>
                      <option value="thirtyMin" className="text-dark">
                        30 min
                      </option>
                      <option value="sixtyMin" className="text-dark">
                        60 min
                      </option>
                    </select>
                  </span>

                  <span
                    id="timeRemainLabel"
                    className="ml-auto mr-auto sm-font fluidFont"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Time Remaining"
                  >
                    <div className="white-text">
                      {this.printRemainingTime()}
                    </div>
                  </span>

                  <span
                    id="favorite_button"
                    className="ml-auto md-font fluidFont"
                  >
                    {this.state.favorite ? (
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="favorite_button far fa-heart hidden"
                        color="red"
                        onClick={async () => {
                          this.setState({ favorite: !this.state.favorite });
                          let value = "delete";
                          let payload = {
                            userId: this.props.auth.id,
                            sessionId: this.props.sessionId,
                          };

                          await API.favoriteSession(value, payload)
                            .then((res) => {
                              console.log("Successfully favorite added");
                            })
                            .catch((err) => {
                              console.log("Failed");
                            });

                          await removeOfflineUserFavorite(
                            payload.userId,
                            payload.sessionId
                          );
                        }}
                      />
                    ) : (
                      <FiHeart
                        className="favorite_button_hollow svg-inline--fa"
                        color="white"
                        onClick={async () => {
                          this.setState({ favorite: !this.state.favorite });
                          let value = "save";
                          let payload = {
                            userId: this.props.auth.id,
                            sessionId: this.props.sessionId,
                          };

                          await API.favoriteSession(value, payload)
                            .then((res) => {
                              console.log("Successfully favorite added");
                            })
                            .catch((err) => {
                              console.log("Failed");
                            });

                          await setOfflineUserFavorites(
                            payload.userId,
                            payload.sessionId
                          );
                        }}
                      />
                    )}
                  </span>

                  <span
                    id="audioBtn"
                    className="md-font fluidFont"
                    onClick={this.audioChange}
                  >
                    <FontAwesomeIcon
                      icon={faVolumeMute}
                      className="hidden"
                      color="white"
                    />
                    <FontAwesomeIcon icon={faVolumeUp} color="white" />
                  </span>

                  <span
                    id="expandBtn"
                    className="md-font fluidFont"
                    onClick={this.openModalImage}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Expand Player"
                  >
                    <FontAwesomeIcon icon={faExpand} color="white" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {this.state.slowNetwork && (
            <div className="mt-1">
              <p className="mb-0">
                NOTE: If you see our little turtle friend Poppy she is just
                letting you know that your internet speed could do with a boost.
              </p>
              <p>
                Poppy's got your back and will optimise your speed options, so
                you can have the best Session possible.
              </p>
            </div>
          )}
        </div>

        <div
          id="modalImagePlayer"
          style={{
            width: "100%",
            position: "fixed",
            height: "100%",
          }}
        >
          <div id="modalImagePlayerContent">
            <ModalImageDetail
              imageData={this.state.imageData}
              imageIndex={this.state.originalIdxArray[this.state.index] + 1}
            />
            {this.state.buffering && (
              <img
                src={LoaderImage}
                size="large"
                style={{
                  display: "block",
                  position: "absolute",
                  top: "calc(50% - 50px)",
                  left: "calc(50% - 50px)",
                  width: "100px",
                  height: "100px",
                  background: "transparent",
                }}
              />
            )}
            <ProgressBar
              className={"playProgress position-absolute w-100"}
              style={{ bottom: 0 }}
            >
              <ProgressBar
                className={
                  (this.state.slowNetwork ? "bg-danger" : "bg-primary") +
                  " position-absolute mt-0 h-100"
                }
                max={this.state.bufferTotal}
                now={this.state.index + 1}
              />
              <ProgressBar
                className={"bg-secondary mt-0"}
                max={this.state.bufferTotal}
                now={this.state.bufferLoaded}
              />
            </ProgressBar>
            <div
              id="modalImagePlayerControls"
              className="modal-player-control d-flex align-items-center p-2"
            >
              <span
                id="modalPlayBtn"
                className="md-font p-2"
                onClick={this.toggleBtn}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  className="hidden"
                  color="white"
                />
                <FontAwesomeIcon icon={faPause} color="white" />
              </span>

              <span
                id="modalSlowBtn"
                className="sm-font d-flex align-items-center p-2"
                onClick={!this.state.slowNetwork && this.slowerAnimation}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Slower"
                style={
                  this.state.slowNetwork
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : null
                }
              >
                <p className="text-white m-0">slower</p>
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faAngleDoubleDown}
                  color="white"
                />
              </span>

              <span
                id="modalFastBtn"
                className="sm-font d-flex align-items-center p-2"
                onClick={!this.state.slowNetwork && this.fasterAnimation}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Faster"
                style={
                  this.state.slowNetwork
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : null
                }
              >
                <p className="text-white m-0">faster</p>
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faAngleDoubleUp}
                  color="white"
                />
              </span>

              {this.state.slowNetwork && (
                <div
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Your internet speed is slow."
                >
                  {turtleIcon}
                </div>
              )}

              <span
                id="speedLabel"
                className="sm-font p-2"
                style={{ fontWeight: "bold" }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Speed of Animation"
              >
                <div className="white-text">{this.state.speedLabel}</div>
              </span>

              <span>
                <select
                  name="default_time"
                  className="sm-font p-2"
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "0px",
                    fontWeight: "bold",
                  }}
                  onChange={this.onDefaultSelect.bind(this)}
                  defaultValue="Time"
                >
                  <option className="text-dark" disabled>
                    Time
                  </option>
                  <option
                    value="threeMin"
                    className="text-dark"
                    disabled={this.state.slowNetwork}
                  >
                    3 min
                  </option>
                  <option
                    value="fiveMin"
                    className="text-dark"
                    disabled={this.state.slowNetwork}
                  >
                    5 min
                  </option>
                  <option
                    value="tenMin"
                    className="text-dark"
                    disabled={this.state.slowNetwork}
                  >
                    10 min
                  </option>
                  <option value="fifteenMin" className="text-dark">
                    15 min
                  </option>
                  <option value="thirtyMin" className="text-dark">
                    30 min
                  </option>
                  <option value="sixtyMin" className="text-dark">
                    60 min
                  </option>
                </select>
              </span>

              <span
                id="timeRemainLabel"
                className="sm-font ml-auto mr-md-5 pr-md-4"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Time Remaining"
              >
                <div className="white-text">{this.printRemainingTime()}</div>
              </span>

              <span id="favorite_button" className="md-font p-2 ml-auto">
                {this.state.favorite ? (
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="favorite_button far fa-heart hidden"
                    color="red"
                    onClick={async () => {
                      this.setState({ favorite: !this.state.favorite });
                      let value = "delete";
                      let payload = {
                        userId: this.props.auth.id,
                        sessionId: this.props.sessionId,
                      };

                      await API.favoriteSession(value, payload)
                        .then((res) => {
                          console.log("Successfully favorite added");
                        })
                        .catch((err) => {
                          console.log("Failed");
                        });

                      await removeOfflineUserFavorite(
                        payload.userId,
                        payload.sessionId
                      );
                    }}
                  />
                ) : (
                  <FiHeart
                    className="favorite_button_hollow svg-inline--fa"
                    color="white"
                    onClick={async () => {
                      this.setState({ favorite: !this.state.favorite });
                      let value = "save";
                      let payload = {
                        userId: this.props.auth.id,
                        sessionId: this.props.sessionId,
                      };

                      await API.favoriteSession(value, payload)
                        .then((res) => {
                          console.log("Successfully favorite added");
                        })
                        .catch((err) => {
                          console.log("Failed");
                        });

                      await setOfflineUserFavorites(
                        payload.userId,
                        payload.sessionId
                      );
                    }}
                  />
                )}
              </span>

              <span
                id="modalAudioBtn"
                className="md-font p-2"
                onClick={this.audioChange}
              >
                <FontAwesomeIcon
                  icon={faVolumeMute}
                  className="hidden"
                  color="white"
                />
                <FontAwesomeIcon icon={faVolumeUp} color="white" />
              </span>

              <span
                id="compressBtn"
                className="md-font p-2"
                onClick={this.closeModalImage}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Exit Fullscreen"
              >
                {" "}
                <FontAwesomeIcon icon={faCompress} color="white" />
              </span>
            </div>
          </div>
          <span className="modal-close cursor" onClick={this.closeModalImage}>
            &times;
          </span>
        </div>

        <div id="audioContent">
          <audio id="audioPlayer" loop preload="auto" autoPlay={false}>
            <p>If you're reading this, audio isn't supported.</p>
          </audio>
        </div>

        <SurveyBefore
          onBeforeModalClose={this.onBeforeModalClose.bind(this)}
          onSelect={this.onSelectEmotion.bind(this)}
          onContinue={this.onBeforeSurveyContinue.bind(this)}
          onSkip={this.onBeforeModalClose.bind(this)}
        />

        {this.state.currentEmotionCondition && (
          <SurveyAfter
            onAfterModalClose={this.onAfterModalClose.bind(this)}
            onSelect={this.onSelectEmotion.bind(this)}
            onDone={this.onAfterSurveyDone.bind(this)}
            onSkip={this.onAfterModalClose.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUser,
      fetchImages,
      fetchDefaultImages,
      fetchUserSession,
      fetchImageSessionById,
      resetMediaSessions,
      startUserWatchSession,
      endUserWatchSession,
      surveyBeforeSession,
      surveyAfterSession,
      fetchSessionDetailById,
    },
    dispatch
  );
}

function mapStateToProps({
  auth,
  images,
  authFirebase,
  userSelectedCurrentSession,
  mediaSession,
  userCurrentWatchSession,
  sessionSurvey,
  defaultImages,
  networkStatus,
}) {
  return {
    auth,
    images,
    authFirebase,
    userSelectedCurrentSession,
    mediaSession,
    userCurrentWatchSession,
    sessionSurvey,
    defaultImages,
    networkStatus,
  }; // ES6 - identical to { images: images }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ImagePlayer));
