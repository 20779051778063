import {Col, Row, Form, Button, Alert} from "react-bootstrap";
import Loader from "../common/Loader";
import React, {ChangeEvent} from "react";
import {IntroWrap} from "./IntroWrap";
import {Link} from "react-router-dom";
import {FormInput} from "../FormInput";
import {cookies} from "../../index";
import {Firebase} from "../../firebase";

export interface ForgetPasswordState {
    isProcessing: boolean
    email: string;
    emailIsValid: boolean | null;
    emailFeedback: string | null;
    otherError: string | null;
}
export class ForgetPassword extends React.Component <any, ForgetPasswordState> {


    constructor(props: any) {
        super(props);
        this.state = {
            isProcessing: false,
            email: cookies.get("email"),
            emailFeedback: null,
            emailIsValid: null,
            otherError: null
        }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.id === "email")
            this.setState({
                email: event.target.value,
                emailIsValid: null,
                emailFeedback: null
            })
    }
    async handleReset() {
        await this.setState({isProcessing: true});
        cookies.set("email", this.state.email);
        try {
            const result = await Firebase.Auth.sendPasswordResetEmail(this.state.email);
            await this.setState({
                isProcessing: false,
                emailIsValid: true,
                emailFeedback: "An email has been sent to this address"
            });
        } catch (err) {
            let message: string = err.message;
            if (err.code === "auth/user-not-found")
                message = "This email address is not registered";

            await this.setState({
                isProcessing: false,
                emailIsValid: false,
                emailFeedback: message
            });
        }

    }
    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
    render() {
        return (
            <IntroWrap
                processing={this.state.isProcessing}
                heading="Forgotten your password?"
                text="Enter your email below to receive a link to reset your password"
            >

                <div className="loginForm">
                    {this.renderError()}

                    <FormInput
                        name="email"
                        type="email"
                        label="Email Address"
                        placeholder="Your email address"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        valid={this.state.emailIsValid}
                        feedback={this.state.emailFeedback}
                        disabled={this.state.isProcessing}
                    />
                    <Button disabled={this.state.emailIsValid || this.state.isProcessing} variant="primary" block className="py-2" onClick={this.handleReset.bind(this)}>Reset Password</Button>

                    <p className="text-center mt-5">Not in the right place?</p>
                    <Row>
                        <Col md={6} className="p-2">
                            <Link className="btn btn-secondary btn-block" to="/login">&lt; Back to Login</Link>
                        </Col>
                        <Col md={6} className="p-2">
                            <Link className="btn btn-success btn-block" to="/signup">Sign Up</Link>
                        </Col>
                    </Row>
                </div>
            </IntroWrap>
        );
    }
}
export default ForgetPassword;
