import {USER_SELECTED_CURRENT_SESSION} from '../actions/types';

export default function(state = null, action) {
   //console.log(action);
   switch (action.type) {
      case USER_SELECTED_CURRENT_SESSION:
         return action.payload || [];
      default:
         return state;
   }
}
