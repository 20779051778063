import React, { Component, useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import "./MyStyle.css";
import { cookies } from "../../index";
import { set, get } from "lodash";
import { Firebase } from "../../firebase";
import { API} from "../../APIAndConfig";
import validator from "validator";
import { Spinner, Alert, Row, Col } from "react-bootstrap";
import publicIp from "public-ip";
import ReactPixel from 'react-facebook-pixel';
import '../../scss/sign_up_b.scss'
import { FaCheck } from "react-icons/fa";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

class RegistrationPromo extends Component {
    constructor() {
        super();
        const referralCookie = cookies.get("referralCode");
        this.reference = React.createRef();
        this.state = {
            signUp: true,
            isProcessing: false,
            referralCode: '',
            referralCodeCookie: referralCookie,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            setEmailErrorMessage: "",
            setFirstNameErrorMessage: "",
            setLastNameErrorMessage: "",
            setPasswordErrorMessage: "",
            token: null,
            signupStatus: false,
            checkRefferal: false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null,
            chkbox: true,
            showCardInfo: false,
            selectedPlan: 'monthly'

        };
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const referralCode = params.get('referralCode');
        const ref = params.get('ref');
        const setDaysParam = params.get('days');
        const setamountParam = params.get('amount');
        const setPeriodParam = params.get('period');

        this.setState({days: setDaysParam, amount: setamountParam, period: setPeriodParam})

        ReactPixel.init(process.env.REACT_APP_FB_KEY);
        ReactPixel.track('PageView', {
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });
        ReactPixel.track('AddToCart', {
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });
        if (Firebase.Analytics) {
            Firebase.Analytics.logEvent("page_view");
        }

        if (ref || referralCode) {
            this.setState({ referralCode: ref || referralCode, checkRefferal: true })
        }
    }

    handleChange(event) {
        const state = {};
        set(state, event.target.name, event.target.value);
        this.setState(state);
        switch (event.target.name) {
            case "cardNumber":
                this.validateCreditCard(event.target.value);
                break;
            case "firstName":
                this.validateFirstName(event.target.value);
                break;
            case "lastName":
                this.validateLastName(event.target.value);
                break;
            case "email":
                this.validateEmail(event.target.value);
                break;
            case "password":
                this.validatePassword(event.target.value);
                break;
            case "date":
                this.validateDate(event.target.value);
                break;
            case "cvv":
                this.validateCvv(event.target.value);
                break;
            default:
                break;
        }
    }

    validateFirstName = (value) => {
        let state = {};
        if (validator.isEmpty(value)) {
            set(state, "setFirstNameErrorMessage", "Enter First Name!");
            this.setState(state);
        } else {
            set(state, "setFirstNameErrorMessage", "");
            this.setState(state);
        }
    };

    validateLastName = (value) => {
        let state = {};
        if (validator.isEmpty(value)) {
            set(state, "setLastNameErrorMessage", "Enter Last Name!");
            this.setState(state);
        } else {
            set(state, "setLastNameErrorMessage", "");
            this.setState(state);
        }
    };

    validateEmail = (value) => {
        let state = {};        
        if (validator.isEmpty(value)) {
            set(state, "setEmailErrorMessage", "Enter Email");
            this.setState(state);
        } else if (!validator.isEmail(value)) {
            set(state, "setEmailErrorMessage", "Enter Valid Email");
            this.setState(state);
        } else {
            set(state, "setEmailErrorMessage", "");
            this.setState(state);
        }
    };

    validatePassword = (value) => {
        let state = {};
        let totalChar = 0;
        if (validator.isEmpty(value)) {
            set(state, "setPasswordErrorMessage", "Enter Password!");
            this.setState(state);
        } else if (
            value.length < 6
        ) {
            set(state, "setPasswordErrorMessage", "Enter a Valid Password");
            this.setState(state);
        } else {
            set(state, "setPasswordErrorMessage", "");
            this.setState(state);
        }
    };

    handleSubmit = async () => {
        ReactPixel.track('Lead', {
            content_name: 'Sign Up',
            content_category: 'Meditation > wellbeing',
            content_ids: ['1234'],
            content_type: 'product',
            value: this.state.selectedPlan === 'monthly' ? 19.00 : 199.99,
            currency: 'USD',
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });

        if( Firebase.Analytics )
            Firebase.Analytics.logEvent('complete_order')

        this.setState({ isProcessing: true });
        const params = {
            email: this.state.email || "",
            firstName: this.state.firstName || "",
            lastName: this.state.lastName || "",
            password: this.state.password || "",
            confirmPassword: this.state.password || "",
            referralCode: this.state.referralCode || "epic",
        };

        let result = await API.createUser(params);
        if (result.successful && result.status === 201) {
        
        await API.day365ChallengeUser(params);
        await Firebase.Auth.signInWithEmailAndPassword(
            this.state.email || "",
            this.state.password || ""
        );

        this.props.history.push({pathname : "/dashboard", state: {
            path : "from_login",
            infusion : true,
            referralCode : this.state.referralCode,
            emailPermissionStatus: this.state.chkbox
        }})
        cookies.remove("referralCode");
        } else {
            let state = {
                isProcessing: false,
                firstNameIsValid: null,
                firstNameFeedback: null,
                lastNameIsValid: null,
                lastNameFeedback: null,
                emailIsValid: null,
                emailFeedback: null,
                passwordIsValid: null,
                passwordFeedback: null,
                confirmPasswordIsValid: null,
                confirmPasswordFeedback: null,
                referralCodeIsValid: null,
                referralCodeFeedback: null,
            };

            let error;
            let errors = get(result.payload, "errors", []);
            for (error of errors) {
                set(state, `${error.param}IsValid`, false);
                set(state, `${error.param}Feedback`, error.detail);
            }
            await this.setState(state);
        }

    };

    PerkHeader(props) {
        return (
            <div className="d-flex mt-2">
                <p>{props.children}</p>
            </div>
        )
    }

    Perk(props) {
        return (
            <div className="d-flex">
                <p className="mr-3"><FaCheck color="#4b3861" size={12} /></p>
                <p className="text-black" style={{ fontSize: '12px' }}>{props.children}</p>
            </div>
        )
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-custom  navbar-expand navbar-dark">
                    <a className="navbar-brand" href="#">
                        <img
                            src="/assets/images/Logo1.0.png"
                            height="30"
                            className="d-inline-block align-top"
                            alt=""
                        />
                    </a>
                </nav>
                <div style={{ background: "#6A62F9" }}>
                    <div className="container" style={{ fontSize : "20px" }}>
                        <Row className="bg-white main_row m-0">
                            <Col className="p-3 col-12 col-sm-7 bg-white">
                                <div className="row p-0 bg-white">
                                    <div className="p-0">
                                        <h2 className="title p-4" style={{ color: '#4b3861' }}>Try Positive Prime FREE for { this.state.days } Days</h2>
                                    </div>
                                </div>
                                <Row className="ml-1 mr-1">
                                    <Col className="col-8 font-weight-bold">ITEM</Col>
                                    <Col className="col-4 col d-flex justify-content-end font-weight-bold">$ USD</Col>
                                </Row>
                                <hr className="mr-1 mt-2 ml-2" />
                                <Row className="ml-1 mr-1 mt-2">
                                    <Col className="col-8">Positive Prime Free Trial</Col>
                                    <Col className="col-4 col d-flex justify-content-end">$ 0.00</Col>
                                </Row>

                                <div className="form-group mt-4 mb-4">
                                    <label className="container-checkbox">Yes! I want to Positive Prime My Mind!
                                    <input type="checkbox" checked />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div className="outer_div_box1 mt-5">
                                    <p className="outer_div_box_heading1">WHATS INCLUDED</p>
                                    <div className="pl-4 pr-4 pt-3 pb-3 text-justify">
                                        <p className="text-center" style={{ fontSize: '20px' }}>Membership includes ALL of the following:</p>
                                        <this.Perk><b className="bold_perk_text">Access to all 100+ Positive Prime Sessions</b> created by world class coaches, including Lisa Barnett, Dana Wilde and Joe Vitale</this.Perk>
                                        <this.Perk><b className="bold_perk_text">New Sessions added weekly</b> on topics like finding wealth, beating anxiety and discovering daily happiness</this.Perk>
                                        <this.Perk><b className="bold_perk_text">Personalise your experience</b> by uploading &amp; integrating your own photos into your Sessions</this.Perk>
                                        <this.PerkHeader><b className="bold_perk_text">Exclusive Extras</b></this.PerkHeader>
                                        <this.Perk><b className="bold_perk_text">A Brand New SuperMix Session</b> added every month - SuperMixes combine multiple sessions for supercharging visual hypnosis and discovering a deeper state of Positive Priming.</this.Perk>
                                        <this.Perk>Positive Prime Exclusive <b className="bold_perk_text">7 Magnificent Days of Think and Grow Rich Mini-Course</b></this.Perk>
                                        <this.Perk><b className="bold_perk_text">6x Positive Prime Bonus Gifts</b> from our community of coaches to empower you to have a happier and healthier life both at home and in the workplace. And more to come!</this.Perk>
                                        <this.Perk><b className="bold_perk_text">Bonus Session Support E-Guides:</b> including empowerment Flash Cards</this.Perk>
                                        <this.Perk>For your Family, Friends and Clients: <b className="bold_perk_text">Gift a full (equal) subscription</b> to Positive Prime by inviting up to 4 other people</this.Perk>
                                        <this.PerkHeader><b className="bold_perk_text">Support, Education &amp; Guidance</b></this.PerkHeader>
                                        <this.Perk>Monthly group mentoring calls with founder and creator of Positive Prime - Kim Serafini</this.Perk>
                                        <this.Perk>Weekly micro-lessons via email - Sharing wisdom, inspiration and the most effective ways to get the best out of your Positive Prime Sessions.</this.Perk>
                                        <this.Perk>100s of exceptional resources to support your journey and much more :)</this.Perk>
                                    </div>
                                </div>


                                <div className="outer_div_box mt-5">
                                    <p className="outer_div_box_heading">WHAT OUR MEMBERS SAY</p>
                                    <div className="mt-5" ></div>
                                    <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                        <div className="d-flex justify-content-center"><ImQuotesLeft color="red" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                        <p><span>Helps me get off to a great start to my day and/or gives me a wonderful pick-up or mental and physical boost.  Overall, it has helped me to achieve a more positive focus and outlook, opening me up to new and wonderful opportunities and minimizing negative impact.</span><br /><br /><span className="font-weight-bold">Michael Sepate</span></p>
                                        <div className="d-flex justify-content-center align-self-end mb-5"><ImQuotesRight color="red" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                    </div>
                                    <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                        <div className="d-flex justify-content-center"><ImQuotesLeft color="#6b65f8" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                        <p><span>I use it to set a positive tone for my mornings and as a pick me up during the day.  The days I skip doing it are not nearly as productive and calm as the days I do it.</span><br /><br /><span className="font-weight-bold">Vicki Ibaugh</span></p>
                                        <div className="d-flex justify-content-center align-self-end mb-5"><ImQuotesRight color="#6b65f8" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                    </div>
                                </div>

                            </Col>

                            <Col className="col-sm-5 col-12 p-0">
                                <div className="card" style={{ border: "none", background: "#f2f2f2" }}>
                                    {
                                        this.renderForm()
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex pt-4 pb-3 main_row m-sm-0 mt-n1 mr-0 ml-0 mb-0 justify-content-end bg-white">
                            <Col className="col-sm-7 col-12">
                                <table className="table-borderless">
                                    <tbody>
                                        <tr className="d-flex">
                                            <td className="float-text">
                                                <BsCheckCircle color="#4b3861" size={27} />
                                            </td>
                                            <td>
                                                <p className="text-left pl-3 moneyBack orderGuaranteeText">
                                                    <span className="orderGuarantee">
                                                        <b style={{ color: "#4b3861" }}>30-Day Money Back Guarantee</b>
                                                    </span>
                                                    <br />
                                                    Available if you are dissatisfied with one of our products and email us within 365 days of date of subscription. See{" "}
                                                    <a href="https://www.positiveprime.com/privacy-policy/" target="_blank">
                                                        <u> Terms & Conditions{" "} </u>
                                                    </a>
                                                    for details
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="row justify-content-center">
                                    <img
                                        className="mr-4"
                                        src="/assets/images/norton.png"
                                        height="40px"
                                        alt="norton"
                                    />
                                    <img
                                        className="ml-4"
                                        src="/assets/images/bbb.png"
                                        height="40px"
                                        alt="bbb"
                                    />
                                </div>
                            </Col>
                            <Col className="d-flex flex-column col-sm-5 col-12 mt-5 mt-sm-0 align-items-center justify-content-center">
                                <div>TOTAL : </div>
                                <h2>$0.00</h2>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }

    renderForm() {
        return (
            <div className="card-body">
                <div className="text-center">
                    <p className="font-weight-bold" >Confirmation</p>
                    <hr />
                    <p style={{
                        fontWeight: 'bold',
                        fontSize: '22px',
                        color: '#6b65f8'
                        }} >
                            * FREE for { this.state.days } Days *
                    </p>
                    <p style={{ color: '#6b65f8' }} >
                        <span>Then, if you like it,</span><br />
                        <span>pay only </span><br />
                        <span>${ this.state.amount } / { this.state.period } </span><br /><br />
                        <span className="font-weight-bold">Upgrade Anytime!</span></p>
                    <p className="font-weight-bold">And, you will always have access to your GIFT Session (or Sessions) AND<br />
                        <span style={{ color: '#6b65f8' }}>Kim Serafini&lsquo;s  &ldquo;Being Happier&rdquo;</span>
                        <br /> Session
                    </p>
                </div>
                <hr />
                <Row className="shadow bg-white" style={{ border: "0.5px solid #e0e0e4" }}>
                    <Col className= "d-flex align-items-center"
                        style={{ height: "70px" }}>
                        <div style={{ marginRight: "10px" }} >
                            <h6 style={{ fontSize: "18px", fontWeight: 'bold' }}>1.</h6>
                        </div>
                        <div style={{ paddingTop: "10px" }} >
                            <h6 style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }} >Info</h6>
                            <p style={{ fontSize: '8px' }} >Personal Details</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className="p-0">
                        <div className="shadow arrow-left"></div>
                    </Col>
                </Row>
                
                {
                    this.personalDetails()
                }
                <div className="d-flex align-items-center justify-content-center">
                    <img
                        src="/assets/images/satisfaction.png"
                        height="120"
                        className="d-inline-block align-top"
                        alt=""
                    />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <img
                        src="/assets/images/payment.png"
                        height="100"
                        className="d-inline-block align-top"
                        alt=""
                    />
                </div>
            </div >
        );
    }

    personalDetails() {
        return (
            <form className="pt-2 pl-2 pr-2 pb-5">
                <div className="form-group">
                    <label className="font-weight-bold">First Name</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.firstNameIsValid)}
                        name="firstName"
                        label="First Name"
                        placeholder="Jane"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.firstNameFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}

                    />
                    <span className="errorMessage">
                        {this.state.firstNameFeedback}
                        {this.state.setFirstNameErrorMessage}
                    </span>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Last Name</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.lastNameIsValid)}
                        name="lastName"
                        label="Last Name"
                        placeholder="Smith"
                        value={this.state.lastName}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.lastNameFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />
                    <span className="errorMessage">
                        {this.state.lastNameFeedback}
                        {this.state.setLastNameErrorMessage}
                    </span>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Email Address</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.emailIsValid)}
                        name="email"
                        type="email"
                        label="Your email address"
                        placeholder="jane.smith@example.com"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.emailFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />

                    <span className="errorMessage">
                        {/* {this.state.emailFeedback} */}
                        {this.state.setEmailErrorMessage}
                    </span>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Password</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.passwordIsValid)}
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.passwordFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />
                    <span className="errorMessage">
                        {this.state.passwordFeedback}
                        {this.state.setPasswordErrorMessage}
                    </span>
                </div>
                {!this.state.checkRefferal ? <div className="form-group">
                    <label className="font-weight-bold">Referral Code</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.referralCodeIsValid)}
                        name="referralCode"
                        type="text"
                        label="Referral Code (Optional)"
                        placeholder="Referral Code"
                        value={this.state.referralCode}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.referralCodeFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />
                    <span className="errorMessage">
                        {this.state.referralCodeFeedback}
                    </span>
                </div> : <></>}
                <div className="form-group billingText">
                    <p>
                        If you do not provide us your personal information we may not be
                                        able to provide our Services to you. See our{" "}
                        <a href="https://www.positiveprime.com/privacy-policy/" target="_blank">
                            Privacy Policy
                                </a>
                                    .
                                </p>
                </div>
                <div className="form-group">
                        <label className="container-checkbox">Yes! I want to make sure I’m always being kept informed and up to date with the latest offerings from Positive Prime via email and other electronic communications. You can unsubscribe from our communications at any time simply by contacting us.
                            <input type="checkbox" defaultChecked={this.state.chkbox} onChange={() => this.setState({ chkbox: !this.state.chkbox })} />
                            <span class="checkmark"></span>
                        </label>
                </div>
                <div className="form-group">
                    <button
                        type="submit"
                        className="btn btn-block signup-button-css shadow"
                        style={{ background: "#11142f" }}
                        disabled={
                            !(
                                !this.state.setFirstNameErrorMessage &&
                                !this.state.setLastNameErrorMessage &&
                                !this.state.setEmailErrorMessage &&
                                !this.state.setPasswordErrorMessage &&
                                this.state.firstName &&
                                this.state.lastName &&
                                this.state.email &&
                                this.state.password
                            ) || this.state.isProcessing
                        }
                        onClick={this.createUser}
                    >
                        {this.state.isProcessing ? <Spinner animation="border" size="sm" /> : <span>SignUp</span>}
                    </button>
                </div>
            </form>
        )
    }

    createUser = async (e) => {
        e.preventDefault();
        await this.setState({ isProcessing: true });
        let agent = navigator.userAgent
        let Ip = await publicIp.v4({
            fallbackUrls: ["https://ifconfig.co/ip"]
        })
        let user = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            referral_code: this.state.referralCode || "epic",
            ipaddress: Ip,
            user_agent: agent,
            http_referrer: document.referrer,
            url: window.location.href

        }
        await API.createUserAbandoned(user)
        .then((res)=>{
            console.log("User Abundendand Response: ",res);
            if(res.status ===201){
                this.setState({ signupStatus: true, showCardInfo: true, form_show_check : false, isProcessing: false })
            }else{
                if(res.status === 409){
                    this.setState({
                        "setEmailErrorMessage": "Email Already Exists"
                    });
                }
                this.setState({ isProcessing: false });
            }
        })
        this.handleSubmit();
    }

    getClassName(valid) {

        if (valid === null || valid == undefined) {
            return undefined;
        }

        return "is-" + (valid ? "valid" : "invalid");
    }

    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
}
export default RegistrationPromo;
