import {Col, Form} from "react-bootstrap";
import React, {ChangeEvent} from "react";

export interface FormInputProps {
    label: string,
    name: string,
    disabled: boolean,
    placeholder: string,
    type: string,
    value?: string
    valid: boolean | null,
    feedback: string | null,
    onChange: (event: ChangeEvent<HTMLInputElement>) => any
}


export interface FormInputState {
    value: string
}
export class FormInput extends React.Component<FormInputProps, FormInputState> {

    static defaultProps = {
        label: null,
        type: 'text',
        disabled: false,
        valid: null,
        feedback: null
    }

    constructor(props: FormInputProps) {
        super(props);
    }

    handleChange() {

    }

    render() {
        return (
            <Form.Group className="mb-4">
                <Form.Label htmlFor={this.props.name}>{this.props.label || this.props.name}</Form.Label>
                <Form.Control
                    className={this.getClassName()}
                    disabled={this.props.disabled}
                    id={this.props.name}
                    name={this.props.name}
                    type={this.props.type}
                    placeholder={this.props.placeholder || this.props.name}
                    value={this.props.value || ''}
                    onChange={this.props.onChange}  />
                {this.renderFeedback()}
            </Form.Group>
        )
    }

    getClassName() {
        if (this.props.valid === null)
            return undefined;

        return "is-" + (this.props.valid ? "valid" : "invalid");
    }

    renderFeedback() {
        if (this.props.valid === null)
            return;

        if (!this.props.feedback)
            return;

        const className = (this.props.valid ? "valid" : "invalid") + "-feedback";
        return (
            <div className={className}>{this.props.feedback}</div>
        );
    }
}
