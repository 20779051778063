import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Accordion, Card, Button } from "react-bootstrap";
function SidebarLink(props) {
  return (
    props.child ? SiderbarAccordion(props) :
    <>
    {(() => {

      switch(props.type){ 
        case "OUTER_ROUTE_FREERESOURCES" :
          return (<Link
            to="/#"
            onClick={() => {
              window.open('https://www.positiveprime.com/free-resources/');
            }}
            className={clsx("nav-link sidebar-link text-white", props.activeLink.startsWith(props.link) && "navActiveLink")}
          >
            <img src={props.icon} alt={props.label} className={'sidebar-link__icon'} /> <span className={'sidebar-link__label'}>{props.label}</span>
          </Link>)
         
        case "OUTER_ROUTE":
           return(<Link
                to="/#"
                onClick={() => {
                  window.open('https://www.positiveprime.com/bonus-7-days-trial/');
                }}
                className={clsx("nav-link sidebar-link text-white", props.activeLink.startsWith(props.link) && "navActiveLink")}
              >
                <img src={props.icon} alt={props.label} className={'sidebar-link__icon'} /> <span className={'sidebar-link__label'}>{props.label}</span>
              </Link>)
        
        case "OUTER_ROUTE_STORIES" :
          return (<Link
            to="/#"
            onClick={() => {
              window.open('https://www.positiveprime.com/stories-2/');
            }}
            className={clsx("nav-link sidebar-link text-white", props.activeLink.startsWith(props.link) && "navActiveLink")}
          >
            <img src={props.icon} alt={props.label} className={'sidebar-link__icon'} /> <span className={'sidebar-link__label'}>{props.label}</span>
          </Link>)

        default:
          return (
            <Link
              to={props.link}
              onClick={() => {
                props.onClick()
                reload(props)
              }}
              className={clsx("nav-link sidebar-link text-white", props.activeLink.startsWith(props.link) && "navActiveLink")}
            >
              <img src={props.icon} alt={props.label} className={'sidebar-link__icon'} /> <span className={'sidebar-link__label'}>{props.label}</span>
            </Link>
          )
          
      }
        
        

    }) ()}
    </>
    
  )
}

function SiderbarAccordion(props) {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Toggle as={Card.Body} className="p-0" eventKey="1">
        <Link
          to={props?.activeLink}
          onClick={() => {
            props.onClick()
            reload(props)
          }}
          className={clsx("nav-link sidebar-link text-white", props?.activeLink.startsWith(props?.link) && "navActiveLink")}
        >
          <img src={props?.icon} alt={props?.label} className={'sidebar-link__icon'} /> <span className={'sidebar-link__label'}>{props?.label}</span>
        </Link>
      </Accordion.Toggle>
      {
        props.child.map((element, index) => {
          return (<Accordion.Collapse
            eventKey="1"
            className="pl-3"
            key={index}
          >
            <Link
              onClick={() => {
                props.onClick()
                reload(props)
              }}
              to={element.link}
              className={clsx("nav-link sidebar-link text-white", !props.activeLink.localeCompare(element.link) && "navActiveLink")}
            >
              <img src={element.icon} alt={element.label} className={'sidebar-link__icon'} /> <span className={'sidebar-link__label'}>{element.label}</span>
            </Link>

          </Accordion.Collapse>)
        })
      }

    </Accordion >
  )
}
function reload(props) {
  if (props.link == props.activeLink) {
    window.location.reload()
  }
}


export default SidebarLink;