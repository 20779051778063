// import external modules
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { subscriptionPlans } from "../components/CoachingSessions/CoachingPlans";

const CoachingRoute = ({ user, ...rest }) => {

    if (user.role.id === 1 || user.role.id === 4) {
        return <Route {...rest} />
    }
    else if (subscriptionPlans.includes(user.subscriptionType) ) {
        return <Route {...rest} />
    } else {
        return <Redirect to="/404" />
    }
};

export default CoachingRoute;
