import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom'
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux'
import { Config, API, APIAxios } from "../../APIAndConfig";
import ImagePlayer from '../../components/players/ImagePlayer/ImagePlayer';
import { ROUTES } from '../../Routers/DashboardRouter';
import { Media_URL } from '../../APIAndConfig.ts';
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import { fetchOwnerSessionForUser, fetchPurchasesByUserId, fetchUserSession, } from "../../actions";
import { Modal, Spinner } from 'react-bootstrap';
import WindowedSelect from 'react-windowed-select';
import { createFilter } from 'react-select';
import SessionImages from '../cards/SessionImages';
import { FaEye, FaTrashAlt } from 'react-icons/fa';
import { HiGift } from 'react-icons/hi';
import { BiImageAdd } from 'react-icons/bi';
import { FiCheckCircle } from 'react-icons/fi';
import SessionImagesDisplay from './SessionImagesDisplay';
import ReactTable from 'react-table-6';
import SessionMusicDisplay from './SessionMusicDisplay';
import { CATEGORY_ROUTES } from '../../views/Category';
import moment from 'moment';
import AddPersonalizedImages from './AddPersonalizedImages';

var dataX = []
export const SessionCurator = (props) => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const [session, setSession] = useState(null)
    const [sessions, setSessions] = useState(null)
    const [cover, setCover] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [isProcessing, setisProcessing] = useState(false);
    const [isUploading, setisUploading] = useState(false);
    const [files, setFiles] = useState([])
    const [preview, setPreview] = useState([])
    const [images, setImages] = useState([])
    const fileUploader = useRef(null);
    const [permittedUsers, setPermittedUsers] = useState(null)
    const [showUsers, setShowUsers] = useState(false)
    const [users, setUsers] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [messageModal, setMessageModal] = useState({
        heading: "",
        message: "",
        status: false
    })
    const [allUsers, setAllUsers] = useState(null)
    const [updateAudio, setUpdateAudio] = useState()
    const [favorites, setFavorites] = useState("Processing")
    const [exportDetails, setExportDetails] = useState(null)
    const [exportDetailsCheck, setExportDetailsCheck] = useState(false)
    const [sessionplay, setSessionPlay] = useState(false)
    const [exportModalConfirmation, setExportModalConfirmation] = useState(false)
    const [editSession, setEditSession] = useState({
        status: false,
        session_id: "",
        session_name: "",
        session_status: "",
        session_visibility: "",
        description: "" 
    })
    const [confirmationModal, setConfirmationModal] = useState({
        heading: "",
        message: "",
        status: false
    })

    const renderSelectSession = () => {
        return (
            <WindowedSelect
                name="sessions"
                filterOption={createFilter({ ignoreAccents: false })}
                onChange={(e) => {
                    console.log(e.value);
                    setExportDetails(null)
                    setExportDetailsCheck(false)
                    getUserFavorites(e.value.id)
                    getExportDetails(e.value.id)
                    setSession(e.value)
                    setPermittedUsers(null)
                    setUsers(null)
                    setSelectedUser(null)
                }}
                options={
                    sessions?.map((obj) => {
                        return (
                            { value: obj, label: obj.status === 'disabled' ? obj.name + `\t( Disabled )` : obj.name }
                        )
                    })
                }
                value={{
                    value: session ? session : "", label: session ? session.name : "Select"
                }}
                styles={{
                    option: (base) => ({
                        ...base,
                        backgroundColor: 'transparent',
                        color: 'black',
                    }),
                }}
            />
        );
    }
    const renderSessions = async () => {
        let payload = await API.getAllSessions()
        setSessions(payload.payload?.data || [])

        if (props.location.state && props.location.state.message === "create_session") {
            setSession(payload.payload.data.filter(obj => Number(obj.id) === Number(props.location.state.session_id))[0])
            setExportDetails(null)
            setExportDetailsCheck(false)
            getUserFavorites(props.location.state.session_id)
            getExportDetails(props.location.state.session_id)
            setPermittedUsers(null)
            setUsers(null)
            setSelectedUser(null)
            props.history.replace(props.location.pathname, null);
        }

    }

    const getUserFavorites = async (sessionId) => {
        await API.getUserFavorites(state.auth.id)
            .then((res) => {
                let favorite = res.payload.data.find((obj) => obj.sessionId === Number(sessionId))
                setFavorites(favorite ? true : false)
            }).catch((err) => {
                console.log("Error in fetch favorites", err);
            })
    }

    const getExportDetails = async (sessionId) => {
        await API.getGeneratedZipsById(sessionId)
            .then((res) => {
                console.log(res.payload.data);
                if (res.payload.data.length > 0) {
                    setExportDetails(res.payload.data)
                }
                setExportDetailsCheck(true)
            }).catch((err) => {
                console.log("Error in fetch favorites", err);
            })
    }

    const renderSessionImages = () => {
        return images.length > 0 ? images.map((image, index) =>
            <div className="col-3 justify-content-center" key={index}>

                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="cover" id="inlineRadio1" onChange={(e) => { setCover(image) }} value={image}
                        checked={cover == image} />
                    <label className="form-check-label" htmlFor="inlineRadio1"> Cover</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="thumbnail" id="inlineRadio2" onChange={(e) => { setThumbnail(image) }} value={image}
                        checked={thumbnail == image} />
                    <label className="form-check-label" htmlFor="inlineRadio2"> Thumbnail</label>
                </div>
                <div className="form-group h-100 ">
                    <img className="img-thumbnail" src={process.env.REACT_APP_CDN + '/' + image}></img></div>
            </div>
        ) : <div className="col-12"><h3 className="text-center"> No Images Available</h3></div>

    }
    const saveSession = async () => {
        console.log("object", cover, thumbnail)
        setisProcessing(true)
        const update = await API.updateSession(session.id, { coverphoto: cover, thumbnail: thumbnail })
        let index = sessions.indexOf(session)
        if (index > -1) {
            let arr = sessions
            arr[index] = update.payload?.data[0]
            setSessions(arr)
        }
        setisProcessing(false)
        console.log("update", update)
    }
    const onFileChange = async (e) => {
        e.preventDefault();
        setisUploading(true)
        if (e.target.files.length > 0) {

            //this.setState({ file: event.target.files[0] });
            console.log("onFileChange - event object:", e.target.files);
            let upload = await API.uploadFiles(
                e.target.files,
                null,
                (count) => { },
                'img',
                'thumbnail',
                session.id
            )
            console.log("object", upload)
            const userUploads = await API.getMedia({
                purpose: "thumbnail",
                session: session.id
            });
            console.log("object1", userUploads)
            getImages()
            setisUploading(false)
            // let fileList = [];
            // let readerList = [];
            // for (let i = 0; i < e.target.files.length; ++i) {
            //     let reader = new FileReader();
            //     let file = e.target.files[i];

            //     reader.onloadend = () => {
            //         fileList.push(file);
            //         readerList.push(reader.result);
            //         //console.log('Setting states for file and imagePreviewUrl', this.state);
            //         setFiles([fileList])
            //         setPreview(readerList)

            //     };
            //     reader.readAsDataURL(file);
            // }

            // setFiles([fileList])
            // setPreview(readerList)

            //console.log("onFileChange state", this.state);
        }

    }
    const handleClick = async (e) => {


        fileUploader.current.click();
    }
    const getImages = async () => {
        if (session) {
            const userUploads = await API.getMedia({
                purpose: "thumbnail",
                variant: "original",
                session: session.id
            });
            let covers = userUploads.payload.data.map((image) => {
                return image.object.url;
            })
            setImages(covers)
        }
    }
    const getChildUsers = async () => {
        await API.getChildUsers(session.author.id).then(
            res => {
                if (res.successful && res.payload) {
                    setPermittedUsers(res.payload.data)
                    let childUsersIds = res.payload.data.map(obj => obj.id)
                    setUsers(allUsers.filter(obj => !childUsersIds.includes(obj.userId)))
                }
            }
        )
    }
    const getAllUsers = async (childUsers) => {
        await API.getUsers().then(
            res => {
                if (res.successful && res.payload) {
                    setAllUsers(res.payload.data)
                }
            }
        )
    }

    const giftSession = async () => {
        console.log(selectedUser);
        console.log(session);
        setisProcessing(true)
        let payload = {
            parent: session.author.id,
            child: selectedUser.userId
        }

        await API.giftSession(payload).then(
            res => {
                if (res.successful) {
                    setMessageModal({
                        heading: "Success",
                        message: "Session gifted successfully",
                        status: true
                    })
                    setisProcessing(false)
                    setShowUsers(false)
                    getChildUsers()
                }
                else {
                    setMessageModal({
                        heading: "Failed",
                        message: "Failed to gift session to user",
                        status: true
                    })
                    setisProcessing(false)
                    setShowUsers(false)
                }
            }
        )

    }
    const removeGift = async (item) => {
        setisProcessing(true)
        await API.deleteGift(item.relationship_id)
            .then((res) => {
                if (res.successful) {
                    setMessageModal({
                        heading: "Success",
                        message: "Gift removed successfully",
                        status: true
                    })
                    setisProcessing(false)
                    getChildUsers()
                }
                else {
                    setMessageModal({
                        heading: "Failed",
                        message: "Failed to remove Gift",
                        status: true
                    })
                    setisProcessing(false)
                }
            })
    }

    const displayMessageModal = (heading, message, status) => {
        setMessageModal({
            heading: heading,
            message: message,
            status: status
        })
    }

    const saveGeneratedZips = async () => {
        let payload = {
            userId: state.auth.id,
            sessionId: session.id,
            thumbnailUrl: session.thumbnail,
            session_type: session.type,
            sessionName: session.name,
            placeholderUrl: session.images[0] && session.images[0].media_url ? session.images[0].media_url : null
        }

        await API.saveGeneratedZips(payload).then((res) => {
            if (res.successful) {
                setExportDetailsCheck(false)
                getExportDetails(session.id)
                if (res.payload.message === "ALREADY_PRESENT") {
                    setMessageModal({
                        heading: "Successfull",
                        message: "The zip for the current session is already initiated please try another session",
                        status: true
                    })
                } else {
                    setMessageModal({
                        heading: "Successfull",
                        message: "This export request has now been queued, it will be available shortly",
                        status: true
                    })
                }
            }
            else {
                setMessageModal({
                    heading: "Failed",
                    message: "Failed to initiate zip creation please try again",
                    status: true
                })
            }
        })

    }

    const renderLatestExport = () => {
        if (!exportDetails) {
            return <></>
        }
        return (
            <div className="row">
                <div className="col-12 my-4">
                    <div className="card card-blur">
                        <div className="card-header d-flex align-items-center">
                            Latest Export Information
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>Session Id : </span>
                                    <span>{exportDetails ? exportDetails[0].session_id : ""}</span>
                                </div>
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>Session Name : </span>
                                    <span>{exportDetails ? exportDetails[0].session_name : ""}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>Created At : </span>
                                    <span>{exportDetails ? moment(exportDetails[0].created_at).format("DD-MM-YYYY") : ""}</span>
                                </div>
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>User Id : </span>
                                    <span>{exportDetails ? exportDetails[0].user_id : ""}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>File Name : </span>
                                    <span>{exportDetails ? exportDetails[0].file_name : ""}</span>
                                </div>
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>File Status : </span>
                                    <span>{exportDetails ? exportDetails[0].status : ""}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>File Size : </span>
                                    <span>{exportDetails ? (Number(exportDetails[0].size) / 1000000).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : ""}</span>
                                </div>
                                <div className='col-6 font-weight-bolder my-1' style={{ fontSize: "17px" }}>
                                    <span>Total Images : </span>
                                    <span>{exportDetails ? exportDetails[0].total_images : ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const updateSessionInfo = async () => {
        await API.updateSessionInfo(editSession).then(
            res => {
                renderSessions()
                setConfirmationModal({
                    heading: "",
                    message: "",
                    status: false
                })

                setEditSession({
                    status: false,
                    session_id: "",
                    session_name: "",
                    session_status: "",
                    session_visibility: ""
                })

                setisProcessing(false)

                if (res.successful) {
                    setMessageModal({
                        heading: "Success",
                        message: "Seccessfully updated the session information",
                        status: true
                    })
                    let data = { ...session }
                    data.name = editSession.session_name
                    data.visibility = editSession.session_visibility
                    data.status = editSession.session_status
                    setSession(data)
                } else {
                    setMessageModal({
                        heading: "Failed",
                        message: "Failed to update the session information",
                        status: true
                    })
                }
            }
        )
    }

    useEffect(() => {
        renderSessions()
        getAllUsers()
    }, [])
    useEffect(() => {
        getImages()
        setCover(session?.coverphoto)
        setThumbnail(session?.thumbnail)
    }, [session])

    return (
        !sessions ? <div className={"text-center mt-4"}>< Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }
        } /></div > :
            <div className="container-fluid">
                <div className="row">
                    <div className={clsx('col-12 my -4')}>
                        <h2 className={clsx('main-title')}>
                            Session Curator
                        </h2>
                    </div>
                </div>
                <div className="row ">
                    <div className={clsx('col-12 my-4')}>
                        <label >Session</label>
                        {renderSelectSession()}
                    </div>
                </div>
                <div className="row">
                    <div className={clsx('col-12')}>
                        <button
                            className="btn x-secondary col-md-auto col-12 text-white ml-auto"
                            disabled={!session || !exportDetailsCheck}
                            onClick={() => {
                                if (!exportDetails) {
                                    saveGeneratedZips()
                                } else {
                                    setExportModalConfirmation(true)
                                }
                            }}
                        >Click here to generate session export</button>
                        <button
                            className="btn x-secondary col-md-auto col-12 text-white mt-md-0 mt-2 float-right"
                            disabled={!session}
                            onClick={() => {
                                console.log(session);
                                setEditSession({
                                    status: true,
                                    session_id: session.id,
                                    session_name: session.name,
                                    session_status: session.status,
                                    session_visibility: session.visibility,
                                    description:session?.description?session.description.short_description:""
                                })
                            }}
                        >Edit Session</button>
                    </div>
                </div>
                {renderLatestExport()}
                <div className="row">
                    <div className={clsx('col-12 my-4')}>
                        <div className="card card-blur">
                            <div className="card-header d-flex row">
                                <div className="col col-md-auto mb-md-0 mb-2 col-12">Edit Session</div>
                                <button
                                    className="btn x-secondary col-md-auto col-12 text-white ml-auto"
                                    disabled={!session}
                                    onClick={() => {
                                        setSessionPlay(true)
                                    }} >Play Session</button>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {images.length > 0 ? (<label>Selet Cover</label>) : <></>}
                                        <div className="form-group row">
                                            {session !== null ? (renderSessionImages()) : <div className="col-12"><h3 className="text-center"> Please select a session</h3></div>}

                                        </div>
                                    </div>
                                    {session ?
                                        <div className="col-md-12">
                                            <div className="form-group text-center">
                                                <input type={'file'} style={{ display: "none" }} id={'upload-new-img'} ref={fileUploader} onChange={onFileChange.bind(this)} multiple />
                                                <button className={clsx('x-btn', 'btn', 'x-secondary')} onClick={handleClick}  >
                                                    {isUploading ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2"> Upload New Images</span>}
                                                </button>
                                            </div>
                                        </div>
                                        : <></>}
                                    <div className="card-footer text-muted col-md-12">
                                        <button className={clsx('btn btn-lg setting-button')} disabled={!session} onClick={() => saveSession()}> {isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Save Session</span>}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SessionMusicDisplay
                    session={session}
                    displatMessage={displayMessageModal}
                />
                <SessionImagesDisplay
                    session={session}
                    allSession={sessions}
                />
                <div className="row">
                    <div className={clsx('col-12 my-4')}>
                        <div className="card card-blur">
                            <div className="card-header d-flex align-items-center">
                                <div>
                                    Viewing Permission Users
                                </div>
                                <div className="ml-auto">
                                    <button className="x-btn btn x-secondary d-flex justify-content-center align-items-center" disabled={!session || !users}
                                        onClick={() => { setShowUsers(true) }}
                                    >
                                        <span><HiGift size={24} /></span><span className="pl-2">Gift Session</span>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    !permittedUsers ?
                                        <div className="p-3 d-flex justify-content-center align-items-center">
                                            <button className="x-btn btn x-secondary" disabled={!session || !allUsers} onClick={getChildUsers} >View Permitted Users</button>
                                        </div>
                                        :
                                        <ReactTable
                                            filterable
                                            defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
                                            data={permittedUsers || []}
                                            style={{ background: "rgb(111 79 136 / 1)" }}
                                            columns={[
                                                {
                                                    id: "id",
                                                    Header: 'User Id',
                                                    accessor: 'child',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                },
                                                {
                                                    id: 'first_name',
                                                    Header: 'First Name',
                                                    accessor: 'first_name',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                },
                                                {
                                                    id: 'last_name',
                                                    Header: 'Last Name',
                                                    accessor: 'last_name',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                },
                                                {
                                                    id: 'delete',
                                                    Header: 'Remove',
                                                    accessor: (d) => {
                                                        return (
                                                            <button className={clsx('btn', 'x-secondary', 'ml-2')} style={{ borderRadius: "10px" }} onClick={() => removeGift(d)}>
                                                                <span className="d-flex justify-content-center align-items-center text-white pt-1 pb-1">
                                                                    <FaTrashAlt color="white" />
                                                                </span>
                                                            </button>
                                                        )
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                }
                                            ]}
                                            defaultPageSize={5}
                                            noDataText='No Data Found'
                                            getTrProps={(state, rowInfo, column, instance) => {
                                                if (typeof rowInfo !== "undefined") {
                                                    return {
                                                        style: {
                                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                                        },
                                                    }
                                                }
                                                else {
                                                    return {
                                                        style: {
                                                            background: 'transparent',
                                                            color: 'white'
                                                        },
                                                    }
                                                }
                                            }}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    session?.type === "mega" ? 
                        <AddPersonalizedImages
                            session={session}
                            allSession={sessions}
                            allUsers={allUsers}
                        />
                    : <></>
                }
                <Modal show={showUsers}>
                    <Modal.Header>Users</Modal.Header>
                    <Modal.Body>
                        <WindowedSelect
                            name="users"
                            filterOption={createFilter({ ignoreAccents: false })}
                            onChange={(e) => { setSelectedUser(e.value) }}
                            options={
                                users?.map((obj) => {
                                    return (
                                        { value: obj, label: `${obj.userId} ${obj.first_name} ${obj.last_name}` }
                                    )
                                })
                            }
                            styles={{
                                option: (base) => ({
                                    ...base,
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                }),
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn x-btn x-secondary "
                            type="button"
                            onClick={() => {
                                setShowUsers(false)
                                setSelectedUser(null)
                            }}
                        >
                            Close
                        </button>
                        <button
                            className="btn x-btn x-primary "
                            type="button"
                            onClick={giftSession}
                        >
                            {isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Gift</span>}
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={messageModal.status}>
                    <Modal.Header>{messageModal.heading}</Modal.Header>
                    <Modal.Body>{messageModal.message}</Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn x-btn x-primary "
                            type="button"
                            onClick={() => {
                                setMessageModal({
                                    heading: "",
                                    message: "",
                                    status: false
                                })
                                setSelectedUser(null)
                            }}
                        >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={sessionplay}>
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Body>
                        <p>* Note to view the session you will be redirected to our main system, any changes that are not saved on this page will be lost.</p>
                        <p>Do you still wish to continue?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn x-btn x-secondary "
                            type="button"
                            onClick={() => {
                                setSessionPlay(false)
                            }}
                        >
                            No
                        </button>
                        <button
                            className="btn x-btn x-primary "
                            type="button"
                            onClick={() => {
                                let category = session.category ? session.category : 'private'
                                props.history.push({
                                    pathname: ROUTES.CATEGORY + '/' + category + CATEGORY_ROUTES.PLAY + '/' + session.id,
                                    state: {
                                        duration: 15
                                    }
                                });
                            }}
                        >
                            Yes
                        </button>
                    </Modal.Footer>
                </Modal>
                {
                    exportDetails ?
                        <Modal show={exportModalConfirmation}>
                            <Modal.Header>Confirmation</Modal.Header>
                            <Modal.Body>
                                <p>This session already has an export generated, it was generated on {moment(exportDetails[0].created_at).format("DD-MM-YYYY")}</p>
                                <p>Do you still wish to create a new export?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn x-btn x-secondary "
                                    type="button"
                                    onClick={() => {
                                        setExportModalConfirmation(false)
                                    }}
                                >
                                    No
                                </button>
                                <button
                                    className="btn x-btn x-primary "
                                    type="button"
                                    onClick={() => {
                                        saveGeneratedZips()
                                        setExportModalConfirmation(false)
                                    }}
                                >
                                    Yes
                                </button>
                            </Modal.Footer>
                        </Modal> : <></>
                }
                {/* {
                    session ?
                        <Modal show={sessionplay} size="xl">
                            <Modal.Header>
                                <div className='w-100'>
                                    <label>Play</label>
                                    <button
                                        className="x-secondary"
                                        style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                        onClick={() => {
                                            setSessionPlay(false)
                                        }}
                                    >X</button>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <ImagePlayer
                                    sessionId={session.id}
                                    runtime={15}
                                    favorite={favorites}
                                />
                            </Modal.Body>
                        </Modal> : <></>
                } */}
                <Modal show={editSession.status} onHide={() => { }}>
                    <Modal.Header>Edit Session</Modal.Header>
                    <Modal.Body>
                        <div className={clsx('form-group')}>
                            <label>Session Name</label>
                            <input
                                type="text"
                                className={clsx('form-control', 'setting-input')}
                                name="session_name"
                                value={editSession.session_name}
                                onChange={(e) => {
                                    let data = { ...editSession }
                                    data.session_name = e.target.value
                                    setEditSession(data)
                                }}
                                placeholder="Session Name"
                            />
                        </div>
                        {/* start description field */}
                        <div className={clsx('form-group')}>
                            <label>Description</label>
                            <textarea
                                type="text"
                                className={clsx('form-control', 'description-height')}
                                name="description"
                                value={editSession.description}
                                onChange={(e) => {
                                    let data = { ...editSession }
                                    data.description = e.target.value
                                    setEditSession(data)
                                }}
                                placeholder="description"
                            />
                        </div>
                        {/* end description field */}
                        <div className={clsx('form-group')}>
                            <label>Session Status</label>
                            <div className="row">
                                <div className="col">
                                    <input id="enabled" type="radio" name="session_status" onClick={(e) => {
                                        let data = { ...editSession }
                                        data.session_status = 'enabled'
                                        setEditSession(data)
                                    }} value="enabled" checked={editSession.session_status === "enabled"}></input>
                                    <label for="enabled" className="ml-2">Enabled</label>
                                </div>
                                <div className="col">
                                    <input id="disabled" type="radio" name="session_status" onClick={(e) => {
                                        let data = { ...editSession }
                                        data.session_status = 'disabled'
                                        setEditSession(data)
                                    }} value="disabled" checked={editSession.session_status === "disabled"}></input>
                                    <label for="disabled" className="ml-2">Disabled</label>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('form-group')}>
                            <label>Session Visibility</label>
                            <div className="row">
                                <div className="col">
                                    <input id="open" type="radio" name="session_visibility" onClick={(e) => {
                                        let data = { ...editSession }
                                        data.session_visibility = 'open'
                                        setEditSession(data)
                                    }} value="open" checked={editSession.session_visibility === "open"}></input>
                                    <label for="open" className="ml-2">Open</label>
                                </div>
                                <div className="col">
                                    <input id="private" type="radio" name="session_visibility" onClick={(e) => {
                                        let data = { ...editSession }
                                        data.session_visibility = 'private'
                                        setEditSession(data)
                                    }} value="private" checked={editSession.session_visibility === "private"}></input>
                                    <label for="private" className="ml-2">Private</label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {
                        session ?
                            <Modal.Footer>
                                <button
                                    className="btn x-btn x-secondary "
                                    type="button"
                                    onClick={() => {
                                        setEditSession({
                                            status: false,
                                            session_id: "",
                                            session_name: "",
                                            session_status: "",
                                            session_visibility: ""
                                        })
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn x-btn x-primary "
                                    type="button"
                                    onClick={() => {
                                        if (editSession.session_name === session.name &&
                                            editSession.session_status === session.status &&
                                            editSession.session_visibility === session.visibility&&
                                            editSession.short_description === session.description
                                        ) {
                                            setMessageModal({
                                                heading: "Message",
                                                message: "No field changed",
                                                status: true
                                            })
                                        } else {
                                            if (editSession.session_name !== session.name) {
                                                setConfirmationModal({
                                                    status: true,
                                                    heading: "Confirmation",
                                                    message: "Beware : Renaming an active session will change the name across the entire system, do you wish to continue ?",
                                                })
                                            } else {
                                                setisProcessing(true)
                                                updateSessionInfo()
                                            }
                                        }
                                    }}
                                    disabled={!editSession.session_name || isProcessing}
                                >
                                    {isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Submit</span>}
                                </button>
                            </Modal.Footer>
                            : <></>
                    }
                    <Modal show={confirmationModal.status} onHide={() => { }}>
                        <Modal.Header>{confirmationModal.heading}</Modal.Header>
                        <Modal.Body>{confirmationModal.message}</Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn x-btn x-primary "
                                type="button"
                                onClick={() => {
                                    setConfirmationModal({
                                        heading: "",
                                        message: "",
                                        status: false
                                    })
                                }}
                                disabled={isProcessing}
                            >
                                No
                            </button>
                            <button
                                className="btn x-btn x-secondary "
                                type="button"
                                onClick={() => {
                                    setisProcessing(true)
                                    setEditSession({
                                        status: false,
                                        session_id: "",
                                        session_name: "",
                                        session_status: "",
                                        session_visibility: ""
                                    })
                                    updateSessionInfo()
                                }}
                                disabled={isProcessing}
                            >
                                {isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Yes</span>}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </Modal>
            </div >
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})



export default connect(mapStateToProps, actions)(withRouter(SessionCurator))
