import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions';
import Loader from '../common/Loader';
import { render } from "react-dom";
import { API } from "../../APIAndConfig";
import { Col, Modal, ModalBody, ModalFooter, Row, Spinner } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Firebase } from "../../firebase";
import validator from "validator";
import * as firebase from "firebase/app";
import validateEmails from "../../utils/validateEmails";
import moment from "moment";
import DeleteAccountButton from "../users/DeleteAccountButton";

function SettingV2(props) {
    console.log("props", props)
    const [isProcessing, setisProcessing] = useState(false);
    const [File, setFile] = useState(null);
    const [ImagePreviewUrl, setImagePreviewUrl] = useState('');
    const [Message, setMessage] = useState(null);
    const [inputField, setInputField] = useState({
        name: props.auth?.firstName + " " + props.auth?.lastName,
        email: props.auth?.email,
        feedback: '',
        currentPlan: props.auth.subscriptionType || '',
        cardNumber: '',
    })
    const [emailEditModal, setEmailEditModal] = useState(false)
    const [messageModal, setMessageModal] = useState({
        state: false,
        header: '',
        message: ''
    })
    const [passwordEditModal, setPasswordEditModal] = useState(false)
    const [updateObject, setUpdateObject] = useState({
        newEmail: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        emailError: false,
        newPasswordError: false,
        confirmPasswordError: false,
        oldPasswordError: false,
    })
    const [loading, setloading] = useState(true);
    const [subscriptionExpiry, setsubscriptionExpiry] = useState({
        trial_ending : '',
        current_ending : '',
        trial_status : ''
    });
    const [changePlanModal, setChangePlanModal] = useState(false)
    const [changePlan,setChangePlan] = useState(null)
    const [subscription,setsubscription] = useState([
        "signature-monthly",
        "pro-monthly",
        "signature7-trial7life",
        "signature-monthly-free10trial",
    ])
    const [invoiceStates, setInvoiceStates] = useState({
        loadInvoice: false,
        showInvoice: false,
    })
    const [invoice, setInvoice] = useState({
        currentPlan:"",
        newPlan:"",
        subTotal: "",
        total: ""
    })
    useEffect(() => {
        (async () => {
            console.log(props);
            // if (props.auth.stripeCustomerId) {
            //     await API.getCardDetails(props.auth.stripeCustomerId, props.auth.subscriptionMembership ? props.auth.subscriptionMembership : null ).then(
            //         (res) => {
            //             setInputField({
            //                 ...inputField,
            //                 cardNumber: res.payload.cards[0] ? 'XXXX XXXX XXXX ' + res.payload.cards[0].last4 : ""
            //             })
            //             if( res.payload.subscription ) {
            //                 console.log("res.payload.subscription----->",res.payload.subscription);
            //                 setsubscriptionExpiry({
            //                     trial_ending : res.payload.subscription.trial_end ? moment( res.payload.subscription.trial_end * 1000).format('DD-MM-YYYY') : '-',
            //                     current_ending : res.payload.subscription.current_period_end ? moment( res.payload.subscription.current_period_end * 1000).format('DD-MM-YYYY') : '-',
            //                     trial_status : res.payload.subscription.trial_end ? moment( res.payload.subscription.trial_end * 1000).isAfter(moment()) : '-'
            //                 })
            //             }
            //             setloading(false)
            //         }
            //     ).catch((err) => {
            //         setloading(false)
            //         console.log("Failed to fetch cards");
            //     })
            // }
            if (props.auth.paypal_customer_id) {
                await API.getPaypalSubscriptionDetails(props.auth.subscriptionMembership)
                .then(
                    (res)=>{
                        // console.log("res.next_billing_date",res.payload.next_billing_date)
                        let billingDate = moment(res.payload.next_billing_date).format('DD-MM-YYYY')
                        
                        setsubscriptionExpiry({
                            trial_ending : res.payload.next_billing_date? billingDate : '-'
                        })
                        setloading(false)
                    }
                    
                ).catch((err) => {
                    setloading(false)
                    console.log("Failed to get paypal subscription id");
                })
            }else{
                await API.getCardDetails(props.auth.stripeCustomerId, props.auth.subscriptionMembership ? props.auth.subscriptionMembership : null ).then(
                    (res) => {
                        setInputField({
                            ...inputField,
                            cardNumber: res.payload.cards[0] ? 'XXXX XXXX XXXX ' + res.payload.cards[0].last4 : ""
                        })
                        if( res.payload.subscription ) {
                            setsubscriptionExpiry({
                                trial_ending : res.payload.subscription.trial_end ? moment( res.payload.subscription.trial_end * 1000).format('DD-MM-YYYY') : '-',
                                current_ending : res.payload.subscription.current_period_end ? moment( res.payload.subscription.current_period_end * 1000).format('DD-MM-YYYY') : '-',
                                trial_status : res.payload.subscription.trial_end ? moment( res.payload.subscription.trial_end * 1000).isAfter(moment()) : '-'
                            })
                        }
                        setloading(false)
                    }
                ).catch((err) => {
                    setloading(false)
                    console.log("Failed to fetch cards");
                })
            }
        })();
    }, []);

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    const submitButton = async (e) => {
        e.preventDefault();
        setisProcessing(true)
        await API.sendFeedback(inputField).then(
            res => {
                setMessage("Thank you for sharing your feedback.")
                setisProcessing(false)
            }
        ).catch(err => {
            setMessage("Please try again, facing some technical issue.")
            setisProcessing(false)
        })
        // alert(JSON.stringify(inputField))
    }

    const validateEmail = (event) => {
        let state = {};
        if (validator.isEmpty(event.target.value)) {
            setUpdateObject({
                ...updateObject,
                emailError: "Enter Email",
                newEmail: event.target.value
            })
        } else if (!validator.isEmail(event.target.value)) {
            setUpdateObject({
                ...updateObject,
                emailError: "Enter Valid Email",
                newEmail: event.target.value
            })
        } else {
            setUpdateObject({
                ...updateObject,
                emailError: false,
                newEmail: event.target.value
            })
        }
    };

    const validatePassword = (event, key) => {
        let state = {};
        let totalChar = 0;
        console.log(event.target.name);

        if (validator.isEmpty(event.target.value)) {
            let updateKey = key === 'password' ? 'newPasswordError' : 'confirmPasswordError'
            setUpdateObject({
                ...updateObject,
                [updateKey]: "Enter Password",
                [event.target.name]: event.target.value
            })
        } else if (
            event.target.value.length < 6
        ) {
            let updateKey = key === 'password' ? 'newPasswordError' : 'confirmPasswordError'
            setUpdateObject({
                ...updateObject,
                [updateKey]: "Enter a Valid Password",
                [event.target.name]: event.target.value
            })
        } else {
            let updateKey = key === 'password' ? 'newPasswordError' : 'confirmPasswordError'
            setUpdateObject({
                ...updateObject,
                [updateKey]: false,
                [event.target.name]: event.target.value
            })
        }

        console.log(updateObject);

    };

    const onFileChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            //console.log('Setting states for file and imagePreviewUrl', this.state);
            setFile(file)
            setImagePreviewUrl(reader.result)
        };
        reader.readAsDataURL(file);

    }

    const onSubmitUpload = (e) => {
        e.preventDefault();
        const modal = document.getElementById('userModalContainer');
        const modalLoader = document.getElementById('modalLoader');
        const imagePreview = document.getElementById('userImagePreview');

        if (imagePreview)
            imagePreview.style.display = 'none';

        if (modalLoader)
            modalLoader.style.display = 'block';

        const inputElem = document.getElementById('userInputFile');

        //console.log('onSubmitUpload - uploading file object:', this.state.file);
        // TODO: move the modal, modalLoader, and imagePreview out of the submitImage
        if (File) {
            props.submitProfileImage(
                props.auth,
                File,
                modal,
                this,
                imagePreview,
                modalLoader,
                inputElem
            );
        }
    }

    const onOpenAddImage = (e) => {
        e.preventDefault();
        const modal = document.getElementById('userModalContainer');
        if (modal)
            modal.style.display = 'block';
    }
    const onClose = (e) => {
        e.preventDefault();
        const modal = document.getElementById('userModalContainer');
        if (modal)
            modal.style.display = 'none';

        setFile(null)
        setImagePreviewUrl(null)

        const file = document.getElementById('userInputFile')
        if (file)
            file.value = '';
    }

    let renderModal = () => {
        let $imagePreview = null;

        if (File) {
            $imagePreview = (
                <div
                    style={{
                        width: 'fit-content',
                        display: 'inline-block'
                    }}
                >
                    <img alt={File.name} src={ImagePreviewUrl} />
                </div>
            );
        }

        return (
            <div id="userModalContainer" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-dark">Profile Upload</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group ">
                                <label className="text-dark">Select a profile picture from your computer</label>
                                <input
                                    id="userInputFile"
                                    onChange={onFileChange}
                                    type="file"
                                    accept="image/*"
                                />

                            </div>
                            <div id="userImagePreview" className="imgPreview form-group text-center">
                                {$imagePreview}
                            </div>

                            <Loader />
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn x-btn x-secondary "
                                type="button"
                                onClick={onClose}
                            >
                                Close
               </button>
                            <button
                                className="btn x-btn x-primary "
                                type="button"
                                onClick={onSubmitUpload}
                            >
                                Update Now
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let renderMessageModal = () => {
        return (
            <Modal show={messageModal.state} >
                <ModalHeader> {messageModal.header}</ModalHeader>
                <ModalBody>
                    {messageModal.message}
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            setMessageModal({
                                header: '',
                                message: '',
                                state: false
                            })
                        }}
                    >
                        Close
                    </button>
                </ModalFooter>
            </Modal>
        )
    }

    let renderEmailEditModal = () => {
        return (
            <Modal show={emailEditModal} >
                <ModalHeader>Change Email Address</ModalHeader>
                <ModalBody>
                    <div className={clsx('form-group')}>
                        <label>New Email Address</label>
                        <input
                            type="email"
                            className={clsx('form-control', 'setting-input')}
                            name="email"
                            value={updateObject.email}
                            onChange={(e) => {
                                validateEmail(e)
                            }}
                            placeholder="Email Address"
                        />
                        <span className="errorMessage">{updateObject.emailError ? updateObject.emailError : ""}</span>
                    </div>
                    <div className={clsx('form-group')}>
                        <label>Current Password</label>
                        <input
                            type="password"
                            className={clsx('form-control', 'setting-input')}
                            name="password"
                            value={updateObject.oldPassword}
                            onChange={(e) => {
                                setUpdateObject({
                                    ...updateObject,
                                    oldPassword: e.target.value
                                })
                            }}
                            placeholder="Password"
                        />
                        <span className="errorMessage">{updateObject.emailError ? updateObject.emailError : ""}</span>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            setEmailEditModal(false)
                            setUpdateObject({
                                newEmail: '',
                                oldPassword: '',
                                newPassword: '',
                                confirmPassword: '',
                                emailError: false,
                                newPasswordError: false,
                                confirmPasswordError: false,
                                oldPasswordError: false
                            })
                        }}
                    >
                        Close
                    </button>
                    <button
                        className="btn x-btn x-primary "
                        type="button"
                        disabled={updateObject.newEmail.length > 0 && updateObject.oldPassword.length > 0 && !updateObject.emailError ? false : true}
                        onClick={() => {
                            updateEmail()
                        }}
                    >
                        Update
                    </button>

                </ModalFooter>
            </Modal>
        )
    }

    let renderPasswordEditModal = () => {
        return (
            <Modal show={passwordEditModal} >
                <ModalHeader>Update Password</ModalHeader>
                <ModalBody>
                    <div className={clsx('form-group')}>
                        <label>Current Password</label>
                        <input
                            type="password"
                            className={clsx('form-control', 'setting-input')}
                            name="old_password"
                            value={updateObject.oldPassword}
                            onChange={(e) => {
                                setUpdateObject({
                                    ...updateObject,
                                    oldPassword: e.target.value
                                })
                            }}
                            placeholder="Current Password"
                        />
                        <span className="errorMessage">{updateObject.oldPasswordError ? updateObject.oldPasswordError : ""}</span>
                    </div>
                    <div className={clsx('form-group')}>
                        <label>New Password</label>
                        <input
                            type="password"
                            className={clsx('form-control', 'setting-input')}
                            name="newPassword"
                            value={updateObject.newPassword}
                            onChange={(e) => {
                                validatePassword(e, 'password')
                            }}
                            placeholder="New Password"
                        />
                        <span className="errorMessage">{updateObject.newPasswordError ? updateObject.newPasswordError : ""}</span>
                    </div>
                    <div className={clsx('form-group')}>
                        <label>Confirm New Password</label>
                        <input
                            type="password"
                            className={clsx('form-control', 'setting-input')}
                            name="confirmPassword"
                            value={updateObject.confirmPassword}
                            onChange={(e) => {
                                validatePassword(e, 'cpassword')
                            }}
                            placeholder="Confirm Password"
                        />
                        <span className="errorMessage">{updateObject.confirmPasswordError ? updateObject.confirmPasswordError : ""}</span>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            setPasswordEditModal(false)
                            setUpdateObject({
                                newEmail: '',
                                oldPassword: '',
                                newPassword: '',
                                confirmPassword: '',
                                emailError: false,
                                newPasswordError: false,
                                confirmPasswordError: false,
                                oldPasswordError: false
                            })
                        }}
                    >
                        Close
                    </button>
                    <button
                        className="btn x-btn x-primary "
                        type="button"
                        disabled={
                            updateObject.newPassword.length > 0 &&
                                updateObject.confirmPassword.length > 0 &&
                                updateObject.oldPassword.length > 0 &&
                                !updateObject.oldPasswordError &&
                                !updateObject.newPasswordError &&
                                !updateObject.confirmPasswordError
                                ? false : true}
                        onClick={updatePassword}
                    >
                        Update
                    </button>

                </ModalFooter>
            </Modal>
        )
    }

    let changeCurrentPlan = async() =>{
        let payload = {
            stripe_customer_id: props.auth.stripeCustomerId,
            subscription_membership_id: props.auth.subscriptionMembership,
            subscription_membership_type: changePlan,
        }
        if(!payload.subscription_membership_id || !payload.stripe_customer_id || !payload.subscription_membership_type){
            setMessageModal({
                state: true,
                header: 'Current Plan Error',
                message: "Make sure you before changing your current plan you must have current plan/subscription"
            })
        } 
        else{
            await API.prorationSubscriptionUpdate(payload)
            .then((res)=>{
                if (res.status === 200) {
                    setChangePlanModal(false);
                    setInvoiceStates({loadInvoice:false,showInvoice:false});
                    setInvoice({
                        currentPlan:"",
                        newPlan:"",
                        subTotal: "",
                        total: ""
                    });
                    setMessageModal({
                        state: true,
                        header: 'Success',
                        message: "Plan Change Successfully"
                    })                 
                }
                if (res.status === 502) {
                    setChangePlanModal(false);
                    setInvoiceStates({loadInvoice:false,showInvoice:false});
                    setInvoice({
                        currentPlan:"",
                        newPlan:"",
                        subTotal: "",
                        total: ""
                    });
                    setMessageModal({
                        state: true,
                        header: 'Error',
                        message: "Failed to change current plan"
                    })               
                }
            })
            .catch((err)=>{
                setChangePlanModal(false);
                setInvoiceStates({loadInvoice:false,showInvoice:false});
                setMessageModal({
                    state: true,
                    header: 'Error',
                    message: "Failed to change current plan"
                })
            })
        }   
    }

    let retriveUpcomingInvoice = async (event) =>{
        let selected_event = event.target.value;
        setChangePlan(selected_event);
        if(event.target.name === "subscribed_membership_type"){

            let payload = {
                stripe_customer_id: props.auth.stripeCustomerId,
                subscription_membership_id: props.auth.subscriptionMembership,
                subscription_membership_type: selected_event,
            }

            if(!payload.subscription_membership_id || !payload.stripe_customer_id || !payload.subscription_membership_type){
                setMessageModal({
                    state: true,
                    header: 'Current Plan Error',
                    message: "Make sure you before changing your current plan you must have current plan/subscription"
                })
            } else{
                setInvoiceStates({loadInvoice:true,showInvoice:false});
                await API.retriveUpcomingInvoice(payload)
                .then((res) => {
                    if (res.status === 200) {
                        setInvoiceStates({loadInvoice:false,showInvoice:true});
                        setInvoice({
                            currentPlan:props.auth.subscriptionType,
                            newPlan:selected_event,
                            subTotal:"$"+((res.payload.data.subtotal)/100).toFixed(2),
                            total:"$"+((res.payload.data.total)/100).toFixed(2),
                        })                        
                    }
                    if (res.status === 502){
                        setInvoiceStates({loadInvoice:false});
                        setMessageModal({
                            state: true,
                            header: 'Invoice Error',
                            message: "Failed to fetch invoice detail"
                        })
                    }
                })
            }
            
        }
    }
    let renderChangePlanModal = () => {
        return (
            <Modal show={changePlanModal} >
                <ModalHeader>Change Plan</ModalHeader>
                <ModalBody>
                    <div className={clsx('form-group')}>
                        <label>Current Plan</label>
                        <input
                            type="text"
                            className={clsx('form-control', 'setting-input')}
                            name="current_subscription"
                            value={inputField.currentPlan}
                            disabled
                        />
                    </div>
                    <div className={clsx('form-group')}>
                        <label>New Plan</label>
                        <select name="subscribed_membership_type" className="form-control" onChange={retriveUpcomingInvoice}>
                            <option disabled selected>Select Membership Type</option>
                            
                        {props.auth.subscriptionType === "signature-monthly" ?
                            <option value="signature-yearly" name="subscribed_membership_type">signature-yearly</option>
                        :<></>
                        }
                        {props.auth.subscriptionType === "pro-monthly" ?
                            <option value="pro-yearly" name="subscribed_membership_type">pro-yearly</option>
                        :<></>
                        }
                        {props.auth.subscriptionType === "signature7-trial7life" ?
                            <option value="signature7-trial7yearly" name="subscribed_membership_type">signature7-trial7yearly</option>
                        :<></>
                        }
                        {props.auth.subscriptionType === "signature-monthly-free10trial" ?
                            <option value="signature-yearly-free10trial" name="subscribed_membership_type">signature-yearly-free10trial</option>
                        :<></>
                        } 
                        </select>
                    </div>
                    {invoiceStates.loadInvoice ?
                    <p>Fetching Invoice. Please Wait.</p> 
                    :<></>   
                    }
                    {invoiceStates.showInvoice ? 
                        <div className={clsx('form-group')}>
                            <h5><strong><u>Invoice Detail</u></strong></h5>
                            <Row>
                            <Col md={6}>
                                <span className='font-weight-bold'>Current Plan: </span>
                            </Col>
                            <Col md={6}>
                                <span>{invoice.currentPlan}</span>
                            </Col>
                            <Col md={6}>
                                <span className='font-weight-bold'>New Plan: </span>
                            </Col>
                            <Col md={6}>
                                <span>{invoice.newPlan}</span>
                            </Col>
                            <Col md={6}>
                                <span className='font-weight-bold'>Subtotal:</span>
                            </Col>
                            <Col md={6}>
                                <span>{invoice.subTotal}</span>
                            </Col>
                            <Col md={6}>
                                <span className='font-weight-bold'>Total:</span>
                            </Col>
                            <Col md={6}>
                                <span>{invoice.total}</span>
                            </Col>
                            </Row>
                        </div>
                    : <></>}
                    
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            setChangePlanModal(false);
                            setInvoiceStates({loadInvoice:false,showInvoice:false});
                            setInvoice({
                                currentPlan:"",
                                newPlan:"",
                                subTotal: "",
                                total: ""
                            });
                        }}
                    >
                        Close
                    </button>
                    <button
                        className="btn x-btn x-primary "
                        type="button"
                        disabled={!invoiceStates.showInvoice}
                        onClick={() =>{
                            changeCurrentPlan();
                        }}
                    >
                        Update
                    </button>

                </ModalFooter>
            </Modal>
        )
    }
    const updatePassword = async () => {

        if (updateObject.newPassword === updateObject.confirmPassword) {
            await Firebase.Auth.currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(inputField.email, updateObject.oldPassword))
                .then(
                    async (res) => {
                        console.log(res);
                        await Firebase.Auth.currentUser.updatePassword(updateObject.newPassword)
                            .then((res) => {
                                setPasswordEditModal(false)
                                setUpdateObject({
                                    newEmail: '',
                                    oldPassword: '',
                                    newPassword: '',
                                    confirmPassword: '',
                                    emailError: false,
                                    newPasswordError: false,
                                    confirmPasswordError: false,
                                    oldPasswordError: false
                                })
                                setMessageModal({
                                    state: true,
                                    header: 'Password Updated',
                                    message: "Your password has now been updated successfully"
                                })
                                // alert("Password updated successfully")
                            }).catch(err => {
                                setMessageModal({
                                    state: true,
                                    header: 'Password Change Error',
                                    message: "Falied to update password"
                                })
                                // alert("Falied to update password")
                            })
                    }
                ).catch(
                    err => {
                        console.log(err);
                        setMessageModal({
                            state: true,
                            header: 'Password Change Error',
                            message: "Current password was incorrect"
                        })
                        // alert("Old password didnt match")
                    }
                )
        }
        else {
            setMessageModal({
                state: true,
                header: 'Password Change Error',
                message: "New password didnt match"
            })
            // alert("New Password and confirm are not same")
        }
    }

    const updateEmail = async () => {

        console.log(props.auth.email);
        if (props.auth.email === updateObject.newEmail) {
            setMessageModal({
                state: true,
                header: 'Email Change Error',
                message: "Please try new email to update"
            })
        }
        else {

            await Firebase.Auth.currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(inputField.email, updateObject.oldPassword))
                .then(
                    async (res) => {
                        await Firebase.Auth.currentUser.updateEmail(updateObject.newEmail)
                            .then(async (res) => {
                                console.log(res);
                                await API.updateEmail({ email: updateObject.newEmail, cust_id: props.auth.stripeCustomerId, id: props.auth.id })
                                    .then((res) => {
                                        if (res.payload.message === 'Failed to update stripe email') {
                                            setMessageModal({
                                                state: true,
                                                header: 'Email Change Error',
                                                message: "Failed to update Stripe email"
                                            })
                                            // alert("Failed to update Stripe email")
                                        } else if (res.payload.message === 'Failed to update db') {
                                            setMessageModal({
                                                state: true,
                                                header: 'Email Change Error',
                                                message: "Failed to update Database"
                                            })
                                            // alert("Failed to update Database")
                                        } else {
                                            setInputField({
                                                ...inputField,
                                                email: updateObject.newEmail
                                            })
                                            setEmailEditModal(false)
                                            setUpdateObject({
                                                newEmail: '',
                                                oldPassword: '',
                                                newPassword: '',
                                                confirmPassword: '',
                                                emailError: false,
                                                newPasswordError: false,
                                                confirmPasswordError: false,
                                                oldPasswordError: false
                                            })
                                            setMessageModal({
                                                state: true,
                                                header: 'Email Updated',
                                                message: "Your email has now been updated successfully"
                                            })
                                            // alert("Successfully updated email")
                                        }
                                    })
                                    .catch(() => {
                                        setMessageModal({
                                            state: true,
                                            header: 'Email Change Error',
                                            message: "Failed to update Database"
                                        })
                                        // alert("Failed to update Database")
                                    })
                            }).catch((err) => {
                                console.log("Error : ", err);
                                setMessageModal({
                                    state: true,
                                    header: 'Email Change Error',
                                    message: err.message
                                })
                                // alert(err.message)
                            })
                    }
                ).catch(
                    err => {
                        console.log(err);
                        setMessageModal({
                            state: true,
                            header: 'Email Change Error',
                            message: 'The password is invalid'
                        })
                        // alert("Old password didnt match")
                    }
                )
        }
    }

    return (
    loading ?
    <div className={"text-center mt-4"}>
        <Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} />
    </div > :
    <div className="row">
        <div className="col-12">
            <h2 className={clsx('wellness-session__header-title', 'main-title')}>
                Settings
                </h2>
            <div className="row mt-5">

                <div className="col-md-4 p-4">
                    <div className={clsx('setting-card', 'card')}>
                        <div className={clsx('card-header')}>
                            <p className={clsx('setting-card-header mb-0 p-4')}>
                                Update Your Profile
                        </p>
                        </div>
                        <div className="card-body justify-content-center text-center">
                            <a href="" onClick={onOpenAddImage}>
                                <img className={clsx('setting-img', 'img-thumbnail rounded-circle p-0')} src={props.auth?.profileImageLargeUrl} />
                            </a>
                            <div className="text-center pt-4">
                                <h5> {props.auth?.firstName} {props.auth?.lastName}</h5>
                            </div>
                        </div>
                        <div className="card-body pt-0 d-flex">
                            <h6 className="col-10">Email : {inputField.email}</h6>
                            <span className="col-2 d-flex align-items-center justify-content-end" onClick={() => {
                                setEmailEditModal(true)
                            }} ><MdEdit color="white" size={18}></MdEdit></span>
                        </div>
                        <div className="card-body pt-0 d-flex">
                            <button
                                className="btn btn-lg setting-button w-100 text-white"
                                type="button"
                                onClick={() => {
                                    setPasswordEditModal(true)
                                }}
                            >
                                Update Password
                    </button>
                        </div>
                        {subscription.includes(inputField.currentPlan) ? 
                            <div className="card-body pt-0 d-flex">
                                <button
                                    className="btn btn-lg setting-button w-100 text-white"
                                    type="button"
                                    onClick={() => {
                                        setChangePlanModal(true)
                                    }}
                                >
                                    Change Plan
                                </button>
                            </div>
                        :<></>
                        }
                        <div className="card-body pt-0 d-flex">
                            <DeleteAccountButton className={'btn btn-lg setting-button w-100 text-white'} />
                        </div>
                    </div>
                </div>
                <div className="col-md-8 p-4">
                    <div className={clsx('setting-card', 'card')}>
                        <div className={clsx('card-header')}>
                            <p className={clsx('setting-card-header mb-0 p-4')}>
                                Account Information
                            </p>
                            {Message ? <p>{Message}</p> : <></>}
                        </div>
                        <div className="card-body">
                            <form>
                                {/* <div className={clsx('form-group')}>
                                    <label>Name</label>
                                    <input
                                        type="name"
                                        className={clsx('form-control', 'setting-input')}
                                        name="name"
                                        value={inputField.name}
                                        onChange={inputsHandler}
                                        disabled={true}
                                        placeholder="Name"
                                    />
                                </div>
                                <div className={clsx('form-group')}>
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className={clsx('form-control', 'setting-input')}
                                        name="email"
                                        value={inputField.email}
                                        onChange={inputsHandler}
                                        disabled={true}
                                        placeholder="Email Address"
                                    />
                                </div> */}
                                <div className={clsx('form-group')}>
                                    <label>Current Plan</label>
                                    <input
                                        type="text"
                                        className={clsx('form-control', 'setting-input')}
                                        name="current_subscription"
                                        value={!inputField.currentPlan || inputField.currentPlan === "NULL"  ? '-' : inputField.currentPlan }
                                        onChange={inputsHandler}
                                        disabled={true}
                                        placeholder="Current Plan"
                                    />
                                </div>
                                <div className={clsx('form-group')}>
                                    <label>Card Number</label>
                                    <input
                                        type="text"
                                        className={clsx('form-control', 'setting-input')}
                                        name="card_number"
                                        value={inputField.cardNumber ? inputField.cardNumber : '-'}
                                        onChange={inputsHandler}
                                        disabled={true}
                                        placeholder="Card Number"
                                    />
                                </div>

                                <div className={clsx('form-group')}>
                                    <label>Payment Type</label>
                                    <input
                                        type="text"
                                        className={clsx('form-control', 'setting-input')}
                                        name="payment_type"
                                        value={ props.auth.paypal_customer_id ? 'Paypal' : 'Credit Card' }
                                        disabled={true}
                                        placeholder="Payment Type"
                                    />
                                </div>

                                <div className={clsx('form-group')}>
                                    <label>{ subscriptionExpiry.trial_status ? 'Trial Period Ending Date' : 'Expiry / Plan Renewal Date' }</label>
                                    <input
                                        type="text"
                                        className={clsx('form-control', 'setting-input')}
                                        name="expiry"
                                        // value={ subscriptionExpiry.trial_status ? subscriptionExpiry.trial_ending : subscriptionExpiry.current_ending ? subscriptionExpiry.current_ending : '' }
                                        value={ props.auth.paypal_customer_id?subscriptionExpiry.trial_ending:subscriptionExpiry.trial_status ? subscriptionExpiry.trial_ending : subscriptionExpiry.current_ending ? subscriptionExpiry.current_ending:'' }
                                        disabled={true}
                                        placeholder="Plan Expiry"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className={clsx('setting-card', 'card', 'mt-4')}>
                        <div className={clsx('card-header')}>
                            <p className={clsx('setting-card-header mb-0 p-4')}>
                                Feedback / Support Request
                            </p>
                            {Message ? <p>{Message}</p> : <></>}
                        </div>
                        <div className="card-body">
                            <form>
                                <div className={clsx('form-group')}>
                                    <textarea
                                        rows="6"
                                        type="feedback"
                                        className={clsx('form-control', 'setting-input')}
                                        name="feedback"
                                        value={inputField.feedback}
                                        onChange={inputsHandler}
                                        disabled={isProcessing}
                                        placeholder="Feedback / Support Request"
                                    />
                                </div>
                                <div className={clsx('form-group')}>
                                    <button className={clsx('btn btn-lg setting-button')} onClick={submitButton}> {isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Send Message</span>}</button>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
            {renderModal()}
            {renderEmailEditModal()}
            {renderPasswordEditModal()}
            {renderMessageModal()}
            {renderChangePlanModal()}
        </div>
    </div>)
};


function mapStateToProps(state) {
    return { auth: state.auth };
}

SettingV2 = connect(
    mapStateToProps,
    actions
)(withRouter(SettingV2));

export default SettingV2;
