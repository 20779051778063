import React, { Component } from "react";
import { connect } from "react-redux";

import { Firebase } from "../../firebase";
import { Link, withRouter } from "react-router-dom";
import * as actions from "../../actions";

import "react-virtualized/styles.css"; // only needs to be imported once
import faker from "faker";
import ReactTable from "react-table-6";
import Loader from "../common/Loader";

import "../../scss/users.css";
import { faBars, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const list = new Array(100).fill(true).map((value, index) => ({
    index: index,
    name: faker.name.findName(),
    email: faker.internet.email(),
    description: faker.name.jobTitle(),
    location: faker.address.city(),
}));

// Structure:
// {
// 	"id": 2,
// 	"uuid": "YcmvvSL3woVPSyh27A3uUxdb9NA2",
// 	"role.id": 2,
// 	"first_name": "Jordon",
// 	"last_name": "Pierce ",
// 	"email": "test5@test.com",
// 	"date_of_birth": null,
// 	"sex": null,
// 	"credits": 2620,
// 	"referral_code": null,
// 	"created": "2019-01-03T12:13:39.000Z",
// 	"modified": "2019-01-03T12:13:39.000Z"
// }

const columns = [
    {
        Header: () => <div className="text-left">Client Id</div>,
        accessor: "id",
        headerClassName: 'p-3 text-light',
        className: 'text-center'
    },
    {
        Header: () => <div className="text-left">First Name</div>,
        accessor: "first_name",
        headerClassName: 'p-3 text-light',
        className: 'text-center'
    },
    {
        Header: () => <div className="text-left">Last Name</div>,
        accessor: "last_name",
        headerClassName: 'p-3 text-light',
        className: 'text-center'
    },
    {
        Header: () => <div className="text-left">Email</div>,
        accessor: "email",
        headerClassName: 'p-3 text-light',
        className: 'text-center'
    },
    {
        Header: () => <div className="text-left">Description</div>,
        accessor: "description",
        headerClassName: 'p-3 text-light',
        className: 'text-center'
    },
];

class UsersV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            token: null,
        };
    }

    componentDidMount() {
        this.props.resetError();

        Firebase.Auth.onAuthStateChanged(async (authUser) => {
            if (authUser) {
                const token = await authUser.getIdToken();
                this.setState({ token });
                if (this.props.auth) {
                    await this.props.fetchUserClients(this.props.auth);
                    await this.props.fetchUserOwners(this.props.auth);
                } else {

                }
            } else {
                this.props.history.push("/login");
            }
        });
    }

    // When refreshing the page
    async componentDidUpdate() {
        if (this.props.auth && !this.props.userClients) {
            await this.props.fetchUserClients(this.props.auth);
            await this.props.fetchUserOwners(this.props.auth);
        }
    }

    componentWillUnmount() {
        this.props.resetError();
    }

    onOpenNav(e) {
        document.getElementById("sidebar").classList.add("sidebarOpen");
    }

    onModalClose(e) {
        e.preventDefault();
        const modal = document.getElementById("userModal");
        modal.style.display = "none";
    }

    onModalOpen(e) {
        e.preventDefault();
        const modal = document.getElementById("userModal");
        modal.style.display = "block";
    }

    async onSubmit(e) {
        e.preventDefault();
        const modal = document.getElementById("userModal");
        const modalLoader = document.getElementById("modalLoader");
        modalLoader.style.display = "block";

        const firstName = document.getElementsByName("first-name");
        const lastName = document.getElementsByName("last-name");
        const email = document.getElementsByName("email");

        if (
            firstName &&
            firstName.length > 0 &&
            lastName &&
            lastName.length > 0 &&
            email &&
            email.length > 0
        ) {

            await this.props
                .addClient(
                    this.props.auth,
                    firstName[0].value,
                    lastName[0].value,
                    email[0].value
                )
                .catch((err) => {
                    modal.style.display = "none";
                    modalLoader.style.display = "none";
                });

            firstName[0].value = "";
            lastName[0].value = "";
            email[0].value = "";
        }

        modal.style.display = "none";
        modalLoader.style.display = "none";
    }

    renderUserModal() {
        //const { product } = this.props.productDetail;
        //let display = `Get ${product.title} for ${product.cost} credits`;
        return (
            <div id="userModal" className="userModal">
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="userModalContent pp-animate-bottom p-4 shadow">
                        <p
                            className="modalClose text-right m-0"
                            onClick={this.onModalClose.bind(this)}
                        >
                            &times;
                        </p>
                        <center>
                            <p id="userMessage" className="">
                                Add a new user
                            </p>
                        </center>
                        <form onSubmit={this.onSubmit.bind(this)}>
                            <div className="form-group row m-0 pb-2">
                                <label
                                    htmlFor="first-name"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                >
                                    First
                                </label>
                                <input
                                    type="text"
                                    name="first-name"
                                    className="col form-control form-control-sm"
                                    placeholder="First Name"
                                //defaultValue={this.props.auth.firstName}
                                //onChange={this.onInputFieldChange.bind(this)}
                                />
                            </div>
                            <div className="form-group row m-0 pb-2">
                                <label
                                    htmlFor="last-name"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                >
                                    Last
                                </label>
                                <input
                                    type="text"
                                    name="last-name"
                                    className="col form-control form-control-sm"
                                    placeholder="Last Name"
                                //defaultValue={this.props.auth.firstName}
                                //onChange={this.onInputFieldChange.bind(this)}
                                />
                            </div>
                            <div className="form-group row m-0 pb-4">
                                <label
                                    htmlFor="email"
                                    className="col-3 col-form-label col-form-label-sm pl-0 text-secondary"
                                >
                                    Email
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    className="col form-control form-control-sm"
                                    placeholder="Email Address"
                                //defaultValue={this.props.auth.firstName}
                                //onChange={this.onInputFieldChange.bind(this)}
                                />
                            </div>
                            <Loader />
                            <center>
                                <button id="addBtn" className="btn btn-primary" type="submit">
                                    Add User
                                </button>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (!this.props.userClients) {
            return <div />;
        }
        return (
            <div className="">
                <div className="d-flex">
                    <button
                        className="btn bg-transparent d-md-none"
                        onClick={this.onOpenNav.bind(this)}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <h2 className="main-title m-0 px-0 py-3 px-md-3">
                        Client Management
                    </h2>
                </div>
                <hr className="m-0" />
                <div className="row m-0 p-3">
                    <div className="col-12">
                        <button
                            className="btn btn-primary"
                            style={{ marginBottom: "10px" }}
                            onClick={this.onModalOpen.bind(this)}
                        >
                            <FontAwesomeIcon icon={faUserPlus} /> Add Client
                        </button>
                        <ReactTable
                            filterable
                            data={this.props.userClients}
                            columns={columns}
                            defaultPageSize={10}
                            style={{ background : "rgb(111 79 136 / 1)" }}
                            noDataText={"No Clients Found"}
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />

                        <Link
                            to="/dashboard"
                            className="btn btn-primary"
                            style={{ margin: "10px 0px" }}
                        >
                            Go to Dashboard
                        </Link>
                    </div>
                </div>

                {this.renderUserModal()}

                {/* <p>React-Virtualized Table:</p>
            <div
               style={{ width: 1500, border: '1px solid rgba(0, 0, 0, 0.1)' }}
            >
               <PPTable list={list} />
            </div>
            <br />
            <br /> */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        userClients: state.userClients,
        userOwners: state.userOwners,
    };
}

export default connect(mapStateToProps, actions)(withRouter(UsersV2));
