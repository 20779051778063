import React, { Component } from "react";
import {
    Col, Row, Card, Spinner, Alert, Table, Container, DropdownButton,
    Dropdown,
    Modal,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { API, Config } from "../../APIAndConfig";
import ReactTable from 'react-table-6'


export class GeneralReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allTimeUsers: [],
            threeMonthsUsers: [],
            allTimeSignedupUsers: [],
            signedupActiveUsers: [],
            
        }
    }

    async componentDidMount() {
        await this.getAllTimeUsers()
        await this.threeMonthsUsers()
        await this.getAllTimeSignedupUsers()
        await this.getSignupedActiveUsers()

    }

    async getAllTimeUsers() {
        await API.getAllTimeUsers()
            .then((res) => {
                if (res.payload && res.payload.data) {
                    // console.log("All time users:",res.payload.data);
                    this.setState({ allTimeUsers : res.payload.data })
                }
            })
    }

    async threeMonthsUsers() {
        await API.getThreeMonthsUsers()
            .then((res) => {
                if (res.payload && res.payload.data) {
                    // console.log("Three months users:",res.payload.data);
                    this.setState({ threeMonthsUsers : res.payload.data })
                }
            })
    }

    async getAllTimeSignedupUsers() {
        await API.getAllTimeSignedupUsers()
            .then((res) => {
                if (res.payload && res.payload.data) {
                    // console.log("All time signuped users",res.payload.data);
                    this.setState({ allTimeSignedupUsers : res.payload.data })
                }
         })         
    }

    async getSignupedActiveUsers(){
        await API.getSignupedActiveUsers()
            .then((res) => {
                if (res.payload && res.payload.data) {
                    // console.log("Signuped Active users",res.payload.data);
                    this.setState({ signedupActiveUsers : res.payload.data })
                }
         })
    }

    render() {
        if (this.state.allTimeUsers.length === 0 || this.state.threeMonthsUsers.length === 0 || this.state.allTimeSignedupUsers.length === 0 || this.state.signedupActiveUsers.length === 0)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
           
            <div>
                <h2 className="main-title m-0 p-3">General Reports</h2>
                <hr className="m-0" /> 
                {/* first row    */}
                <div className="row">
                    <div className="col col-6">
                        <Card className="card-blur my-5">
                        <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>All Time Users</Card.Header>
                        <Card.Body className="p-0">
                            <ReactTable
                            showPagination = {false}
                            defaultPageSize = {7}
                            data={this.state.allTimeUsers}
                                columns={[
                                    {
                                        id: "total",
                                        Header: 'Total',
                                        accessor: 'total',
                                        headerClassName: 'p-3 text-light',
                                        className: 'text-center'
                                    },
                                    {
                                        id: 'role_id',
                                        Header: 'Role_id',
                                        accessor: 'role_id',
                                        headerClassName: 'p-3 text-light',
                                        className: 'text-center'
                                    },
                                    {
                                        id: 'role',
                                        Header: 'Role',
                                        accessor: 'role',
                                        headerClassName: 'p-3 text-light',
                                        className: 'text-center'
                                    },
                                ]}
                            />
                        </Card.Body>
                        </Card>
                    </div>
                    <div className="col col-6">
                        <Card className="card-blur my-5">
                            <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>User Activity Last 3 Months</Card.Header>
                            <Card.Body className="p-0">
                                <ReactTable
                                showPagination = {false}
                                defaultPageSize = {7}
                                data={this.state.threeMonthsUsers}
                                    columns={[
                                        {
                                            id: "total",
                                            Header: 'Total',
                                            accessor: 'total',
                                            headerClassName: 'p-3 text-light',
                                            className: 'text-center'
                                        },
                                        {
                                            id: 'role_id',
                                            Header: 'Role_id',
                                            accessor: 'role_id',
                                            headerClassName: 'p-3 text-light',
                                            className: 'text-center'
                                        },
                                        {
                                            id: 'role',
                                            Header: 'Role',
                                            accessor: 'role',
                                            headerClassName: 'p-3 text-light',
                                            className: 'text-center'
                                        },
                                    ]}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                {/* second row    */}
                <div class='row'>
                    <div class="col col-6">
                        <Card className="card-blur my-5">
                            <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Users Signed up Per Year</Card.Header>
                            <Card.Body className="p-0">
                                <ReactTable
                                defaultPageSize = {10}
                                data={this.state.allTimeSignedupUsers}
                                    columns={[
                                        {
                                            id: "users",
                                            Header: 'Users',
                                            accessor: 'users',
                                            headerClassName: 'p-3 text-light',
                                            className: 'text-center'
                                        },
                                        {
                                            id: 'SignedUp',
                                            Header: 'SignedUp',
                                            accessor: 'signedUp',
                                            headerClassName: 'p-3 text-light',
                                            className: 'text-center'
                                        }
                                    ]}
                                noDataText='No Data Found'
                                />
                            </Card.Body>
                        </Card>
                    </div>
                    <div class="col col-6">
                        <Card className="card-blur my-5">
                            <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Active Users From Each Signup Year (Last 3 Months Activity)</Card.Header>
                            <Card.Body className="p-0">
                            <ReactTable
                            defaultPageSize = {10}
                            data={this.state.signedupActiveUsers}
                                columns={[
                                    {
                                        id: "users",
                                        Header: 'Users',
                                        accessor: 'Users',
                                        headerClassName: 'p-3 text-light',
                                        className: 'text-center'
                                    },
                                    {
                                        id: 'SignedUp',
                                        Header: 'SignedUp',
                                        accessor: 'SignedUp',
                                        headerClassName: 'p-3 text-light',
                                        className: 'text-center'
                                    }
                                ]}
                            noDataText='No Data Found'
                            />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

}


export default GeneralReports