export const subscriptionPlans = [
    "vip-discount",
    "vip-yearly",
    "signature-monthly",
    "signature-yearly",
    "pro-monthly",
    "pro-yearly",
    "signature7-free7trial",
    "signature7-trial7life",
    "signature7-trial7yearly",
    "signature-monthly-free10trial",
    "signature-yearly-free10trial",
    "signature-monthly-notrial",
    "SignatureCoachingR",
    "SignatureCoaching",
    "Signature Coaching Recurring",
    "signature-promo-xmas-free10trial-monthly15",
    "signature-promo-xmas-free10trial-yearly150",
    "30DayChallengeAuto",
    "30DayChallengeToUpgrade",
    "signature-willpowerlegacy",
    "Sponsored",
    "Signature Monthly",
    "Signature Yearly",
    "Signature7 Free7Trial",
    "Signature7 Trial7Life",
    "Siganture7 Trial7Yearly",
    "Signature10 Monthly Free Trial",
    "Signature10 Yearly Free Trial",
    "Signature Monthly No Trial",
    "Signature Coaching One Time",
    "Signature Coaching Recurring",
    "VIP Discount",
    "VIP Yearly",
    "VIP Yearly Special"
]