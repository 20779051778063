import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "../scss/errorPage.scss"

export class ErrorPage extends Component {
    render() {
        let errosPaths = ['/signup', '/signup7', '/signupnm', '/signupbn', 'signupcp', '/signupbna', '/signup7b', '/signup7life', '/signup7yearly', '/inviteSignup','/login']
        
        return (
            <div className="error_div">
                <nav className="navbar navbar-custom  navbar-expand navbar-dark w-100 position-fixed">
                    <a className="navbar-brand" href="#">
                        <img
                            src="/assets/images/Logo1.0.png"
                            height="30"
                            className="d-inline-block align-top"
                            alt=""
                        />
                    </a>
                </nav>
                <div className="first_div">
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <div>
                                <img
                                    src="/assets/images/err1.png"
                                    style={{ width: '100%', border: "0" }}
                                    alt=""
                                />
                            </div>
                            <div>
                                <img
                                    src="/assets/images/err2.png"
                                    style={{ width: '100%', border: "0" }}
                                    alt=""
                                />
                            </div>
                            <div>
                                <img
                                    src="/assets/images/err1.png"
                                    style={{ width: '100%', border: "0" }}
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                    <div>
                        {errosPaths.includes(this.props.location.pathname) ?
                            <div>
                                <Row>
                                    <Col className="d-flex align-items-center justify-content-center flex-column mt-5 mb-5">
                                        <h2 className="text-center heading font-weight-bolder" >Hi {this.props.auth.firstName}</h2>
                                        <p className="text-center para font-weight-bolder">Warning : "You currently are logged in and have an active session.
                                            The page you are trying to access is not available to logged in users"</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex align-items-center justify-content-center flex-column">
                                        <a href="/">
                                            <button
                                                className="home_button"
                                            >
                                                Return to Dashboard
                                            </button>
                                        </a>
                                    </Col>
                                </Row>
                            </div> :
                            <div>
                                <Row>
                                    <Col className="d-flex align-items-center justify-content-center flex-column mt-5 mb-5">
                                        <h2 className="text-center heading" >Something is Wrong</h2>
                                        <p className="text-center para" >The page you are looking for was moved, removed, renamed or might never existed.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex align-items-center justify-content-center flex-column">
                                        <a href="/">
                                            <button
                                                className="home_button"
                                            >
                                                Return Home
                                            </button>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth}
}
export default connect(mapStateToProps)(ErrorPage)



