import React from "react";
import { DateRangePicker } from "react-dates";
import moment, { Moment } from "moment";
import 'react-dates/initialize';
import "../../scss/analytics.scss";
import { get, has, set } from "lodash";
import { SimpleDropdownButton } from "../common/SimpleDropdownButton";
import { AnalyticsDefaults } from "./AnalyticsDefaults";

export type Analyticscollate = "daily" | "weekly" | "monthly"
export type AnalyticsFilter = "all" | "authored" | "client";


export interface AnalyticsRange {
    label: string,
    startDate: Moment,
    endDate: Moment,
    collate: string,
}

export function getMomentAtMidday(date?: moment.Moment): moment.Moment {
    if (!date)
        date = moment();

    return date.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
}

export interface AnalyticsCurrentDateRange {
    collate: string,
    filter: string,
    range: string | null,
    start: string,
    end: string
}

export interface AnalyticsRangePickerProps {
    collation?: { [key: string]: string }
    filters?: { [key: string]: string }
    ranges?: { [key: string]: AnalyticsRange }
    defaultRange?: string,
    config?: Partial<AnalyticsCurrentDateRange>,
    onChange?: (current: AnalyticsCurrentDateRange) => void,
    stringFormat?: string;
}

export interface AnalyticsRangePickerState {
    startDate: moment.Moment | null,
    startDateId: string
    endDate: moment.Moment | null,
    endDateId: string,
    focusedInput: any,
    range: string | null,
    collate: string | null,
    filter: string | null
}

export class AnalyticsRangePicker extends React.Component<AnalyticsRangePickerProps, AnalyticsRangePickerState> {


    getInitialState(): AnalyticsRangePickerState {
        return {
            startDate: null,
            endDate: null,
            startDateId: "start",
            endDateId: "end",
            focusedInput: null,
            range: null,
            collate: null,
            filter: null
        }
    }

    constructor(props: any) {
        super(props);
        let state = this.getInitialState();

        if (props.config.filter)
            state.filter = props.config.filter;

        if (props.config.collate)
            state.collate = props.config.collate;

        if (props.config.range)
            state.range = props.config.range;

        if (props.config.start)
            state.startDate = getMomentAtMidday(moment(props.config.start));

        if (props.config.end)
            state.endDate = getMomentAtMidday(moment(props.config.end));


        this.state = state;
    }

    componentDidMount(): void {
        this.setDates({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            filter: this.state.filter,
            collate: this.state.collate,
            range: this.state.range,
        });
    }

    getAvailableFilters(): { [key: string]: string } {
        return this.props.filters || AnalyticsDefaults.filters;
    }
    getAvailableCollation(): { [key: string]: string } {
        return this.props.collation || AnalyticsDefaults.collation;
    }
    getAvailableRanges(): { [key: string]: AnalyticsRange } {
        return this.props.ranges || AnalyticsDefaults.ranges;
    }
    getActiveFilter(): string | null {
        return this.state.filter || Object.keys(this.getAvailableFilters())[0]
    }
    getActiveCollation(): string | null {
        return this.state.collate || Object.keys(this.getAvailableCollation())[0]
    }
    getActiveRange(): string | null {
        return this.state.range;
    }

    getRange(key?: string | null): AnalyticsRange | null {

        const ranges = this.getAvailableRanges();

        if (key && has(ranges, key))
            return get(ranges, key) as AnalyticsRange

        if (!key && this.props.defaultRange && has(ranges, this.props.defaultRange))
            return get(ranges, this.props.defaultRange) as AnalyticsRange

        return null;
    }

    getMomentAsString(moment: Moment | null) {
        if (!moment)
            return '';

        return moment.format(this.props.stringFormat || 'YYYY-MM-DD');
    }

    renderDatePicker() {
        return (
            <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId={this.state.startDateId} // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId={this.state.endDateId} // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => this.setDates({ startDate, endDate })} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                isOutsideRange={day => (false)}
                displayFormat={() => "Do MMMM YYYY"}
                minimumNights={0}
            />
        )
    }


    setDates(opts: {
        startDate?: moment.Moment | null,
        endDate?: moment.Moment | null,
        range?: string | null,
        collate?: string | null
        filter?: string | null
    }) {

        const handleStateChange = this.handleStateChange.bind(this);

        if (!opts.startDate && !opts.endDate && !opts.range) {
            return this.setState(function (prevState) {
                return {
                    collate: opts.collate || prevState.collate,
                    filter: opts.filter || prevState.filter
                }
            }, function () {
                handleStateChange();
            });
        }
        if (opts.startDate)
            opts.startDate = getMomentAtMidday(opts.startDate);

        if (opts.endDate)
            opts.endDate = getMomentAtMidday(opts.endDate);

        let range: AnalyticsRange | null = opts.range ? this.getRange(opts.range) : null;

        //TODO: reduce nesting
        if (range) {
            opts.startDate = range.startDate;
            opts.endDate = range.endDate;
        } else if (opts.startDate && opts.endDate) {
            opts.range = null;

            const ranges = this.getAvailableRanges();
            let key: string;
            let givenStart = opts.startDate.toISOString()
            let givenEnd = opts.endDate.toISOString()
            for (key of Object.keys(ranges)) {
                let testRange: AnalyticsRange | null = this.getRange(opts.range)
                if (testRange) {
                    let testStart = testRange.startDate.toISOString();
                    let testEnd = testRange.endDate.toISOString();
                    if (givenStart === testStart && givenEnd === testEnd) {
                        opts.range = key;
                        break;
                    }
                }
            }
        }

        return this.setState(function (prevState) {
            return {
                collate: opts.collate || prevState.collate,
                filter: opts.filter || prevState.filter,
                range: opts.range || null,
                startDate: opts.startDate || null,
                endDate: opts.endDate || null
            }
        }, function () {
            handleStateChange();
        });
    }

    handleStateChange() {
        if (this.props.onChange) {
            const payload: AnalyticsCurrentDateRange = {
                start: this.getMomentAsString(this.state.startDate),
                end: this.getMomentAsString(this.state.endDate),
                filter: this.getActiveFilter() || '',
                collate: this.getActiveCollation() || '',
                range: this.getActiveRange() || ''
            }
            this.props.onChange.apply(this, [payload])
        }
    }

    handleFilterDropdown(e: string) {
        this.setDates({
            filter: e
        })
    }

    renderFilterDropdown() {
        const filters = this.getAvailableFilters();
        if (Object.keys(filters).length <= 1)
            return null;

        return (
            <SimpleDropdownButton
                className="mx-3"
                id="btnAnalyticsFilterDropdown"
                active={this.state.filter}
                options={filters}
                onSelect={this.handleFilterDropdown.bind(this)}
            >render
            </SimpleDropdownButton>
        );
    }

    handleCollateDropdown(e: string) {
        this.setDates({
            collate: e
        })
    }

    renderCollateDropdown() {
        return (
            <SimpleDropdownButton
                className="mx-3"
                id="btnAnalyticsCollateDropdown"
                active={this.getActiveCollation()}
                options={this.getAvailableCollation()}
                onSelect={this.handleCollateDropdown.bind(this)}
            >
            </SimpleDropdownButton>
        );
    }

    renderPresetsDropdown() {

        const ranges = this.getAvailableRanges();
        let options: { [key: string]: string } = {}

        let key: string
        for (key of Object.keys(ranges))
            set(options, key, get(ranges, key).label);

        return (
            <SimpleDropdownButton
                className="mx-3"
                id="btnAnalyticsRangeDropdown"
                active={this.getActiveRange()}
                options={options}
                onSelect={this.handleRangeDropdown.bind(this)}
                nullVariant="outline-primary"
                nullTitle="Custom Range"
            >
            </SimpleDropdownButton>
        );

    }

    handleRangeDropdown(e: string) {
        this.setDates({
            range: e
        })
    }



    render() {
        return (
            <div className="analyticsRangePicker">
                {this.renderFilterDropdown()}
                {this.renderCollateDropdown()}
                {this.renderPresetsDropdown()}
                {this.renderDatePicker()}
            </div>
        )
    }
}
