import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

export class ServiceUnavailable extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (localStorage.getItem("service_unavailable_status") !== "failed") {
            this.props.history.push('/')
        }
        localStorage.removeItem('service_unavailable_status')
    }

    render() {
        return (
            <div className="service_unavailable_div">
                <nav className="navbar navbar-custom  navbar-expand navbar-dark w-100 position-fixed">
                    <a className="navbar-brand" href="#">
                        <img
                            src="/assets/images/Logo1.0.png"
                            height="30"
                            className="d-inline-block align-top"
                            alt=""
                        />
                    </a>
                </nav>
                <div className="service_unavailable_first_div">
                    <h1>Oh No! Something has gone wrong!</h1>
                    <p>The server is temporarily unable to service your request - our team are working on restoring services for you as soon as possible! Please try again shortly.</p>
                </div>
            </div>
        );
    }
}
export default ServiceUnavailable



