import { set } from 'lodash';
import moment from 'moment';
import React from 'react'
import { Container, Spinner, Card, Row, Col, Form, InputGroup } from 'react-bootstrap'
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css'
import { APIActions } from '../../api/APIActions';
import { API } from '../../APIAndConfig';
import { FormInput } from "../FormInput";
import { createFilter } from 'react-select';
import WindowedSelect from 'react-windowed-select';

interface stateinterface {
    tableData: any,
    userID: any,
    description: any,
    amount: any,
    userIdList: any,
    type: any,
    searchInput: string
    errAmount: any
    errDesp: any,
    amountMessage: any,
    descriptionMessage: any
    filteredData: any
}

export class PaymentLedger extends React.Component<any, stateinterface> {

    constructor(props: any) {
        super(props)

        this.state = {
            tableData: null,
            filteredData: [],
            userID: null,
            description: null,
            amount: null,
            errAmount: null,
            errDesp: null,
            userIdList: [],
            type: 'Generic (default)',
            amountMessage: null,
            descriptionMessage: null,
            searchInput: ""
        }

    }

    async componentWillMount() {
        const params = new URLSearchParams(this.props.location.search);
        const user = params.get('view_payout_user');
        const payoutdate = params.get('payoutdate');
        const userId = params.get('userId');
        const ledgerRecord: any = await API.getLedgerData(userId)
        // this.props.history.push('/ledger?color=blue')
        if (user && payoutdate) {
            let filteredData = ledgerRecord.payload.data.filter((element: any) => {
                if (element.userID == user && moment(element.payoutdate)
                    .local()
                    .format("YYYY-DD-MM") == payoutdate) {
                    return element
                }
            })
            this.setState({ filteredData })
        }
        this.setState({
            tableData: ledgerRecord.payload.data || [],
            userIdList: ledgerRecord.payload.userId || [],
            userID: ledgerRecord.payload.userId[0].id,
        })


    }


    getUserLedger = async (data: any) => {
        this.props.history.push('/ledger?userId=' + data.original.userID)
        this.setState({
            tableData: null,
            userIdList: [],
            userID: null,
        })
        const ledgerRecord: any = await API.getLedgerData(data.original.userID)
        this.setState({
            tableData: ledgerRecord.payload.data || [],
            userIdList: ledgerRecord.payload.userId || [],
            userID: ledgerRecord.payload.userId[0].id,
        })

    }

    globalSearch = () => {
        let { searchInput, tableData } = this.state;
        let filteredData = tableData.filter((value: any) => {
            return (
                value.amount.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.description.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.entrytimestamp
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.ledgerID
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.userID
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.refereeID
                    .toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.payoutID
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.payoutdate
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.paymentstatus
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
            );
        });
        this.setState({ filteredData });
    };
    searchChange = (event: any) => {
        this.setState({ searchInput: event.target.value }, () => {
            this.globalSearch();
        });
    };

    handleChange(e: any) {

        let state = { ...this.state }

        if (e.target.name === 'userid') {
            state.userID = e.target.value
        }
        else if (e.target.name === 'description') {
            state.description = e.target.value
        }
        else if (e.target.name === 'amount') {
            state.amount = e.target.value
        }
        else if (e.target.name === 'type') {
            state.type = e.target.value
        }
        this.setState(state)
    }


    setUser(e: any) {
        console.log("event", e)
        this.setState({ userID: e.value })
    }

    async handleClick(e: any) {
        e.preventDefault()

        let num = new RegExp('[-]?\d+(\.)?(\d+)?')

        if (this.state.amount !== null && this.state.amount.match(num) !== null) {
            this.setState({
                errAmount: false,
                amountMessage: 'amount can only be number'
            })
        }

        if (this.state.userID !== null && this.state.type != null && this.state.amount !== null && this.state.description !== null) {
            await API.postLedgerData({
                userID: this.state.userID,
                description: this.state.description,
                amount: this.state.amount,
                type: this.state.type
            }).then(
                (res: any) => {
                    if (res.payload.message === 'Successfully saved') {
                        alert('Saved Successfully')

                        let data = { ...this.state }
                        data.userID = data.userIdList[0].id
                        data.type = 'Generic (default)'
                        data.description = null
                        data.amount = null
                        data.errAmount = null
                        data.errDesp = null
                        data.amountMessage = null
                        data.descriptionMessage = null
                        this.setState(data)
                    }
                }
            ).catch(
                err => {
                }
            )
        }
        else {


            if (this.state.amount === null) {
                this.setState({
                    errAmount: false,
                    amountMessage: 'amount cannot be empty'
                })
            }

            if (this.state.description === null) {
                this.setState({
                    errDesp: false,
                    descriptionMessage: 'description cannot be empty'
                })
            }
        }

    }

    showLedger() {

        const columns = [{
            Header: 'Ledger Id',
            accessor: 'ledgerID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            id: "entrytimestamp",
            Header: 'Timestamp',
            headerClassName: 'bg-primary p-3 text-light',
            accessor: (d: any) => {
                return moment(d.entrytimestamp)
                    .local()
                    .format("YYYY-DD-MM")
            },
            className: 'text-center'
        },
        {
            Header: 'User Id',
            // accessor: 'userID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center',
            Cell: (data: any) => (
                <a href="#" onClick={() => this.getUserLedger(data)}>
                    {data.original.userID}
                </a>
                // <button className="btn btn-primary btn-block p-auto m-auto" onClick={() => this.setPayout(data)} >
                //     Payout
                // </button>

            )
        },
        {
            Header: 'Description',
            accessor: 'description',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Referee Id',
            accessor: 'refereeID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Status',
            accessor: 'paymentstatus',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Payout Date',
            id: 'payoutdate',
            accessor: (d: any) => {
                if (d.payoutdate !== 'NA') {
                    return moment(d.payoutdate)
                        .local()
                        .format("YYYY-DD-MM")
                } else { return d.payoutdate }
            },
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Payout Id',
            accessor: 'payoutID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }]

        if (!this.state.tableData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
            <div>
                <Card border="dark">
                    <Card.Header className="text-white bg-dark font-weight-bold "> <div ><span>Sales Ledger</span>
                        <input
                            style={{ float: "right" }}
                            name="searchInput"
                            value={this.state.searchInput || ""}
                            onChange={this.searchChange}
                            placeholder="Search"
                        /></div>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : this.state.tableData}
                            columns={columns}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'white' : '#f9f9f9',
                                            color: rowInfo.index % 2 === 0 ? '#000000' : '#000000'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'white',
                                            color: 'black'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }


    renderSelectUser() {
        return (
			<WindowedSelect
				name="userID"
				filterOption={createFilter({ ignoreAccents: false })}
				onChange={this.setUser.bind(this)}
				options={
                    this.state.userIdList.map((obj: any) => {
                        return (
                            { value: obj.id, label: obj.first_name + " " + obj.last_name + " - " + obj.id }
                        )
                    })
                }
			/>
        );
    }

    render() {


        return (
            <div className="mainWidth">
                <p className="text-secondary m-0 p-3">Sales Ledger</p>
                <hr className="m-0" />
                <Row className="ml-3 mr-3 mb-3 mt-5">
                    <Col>
                        {this.showLedger()}
                    </Col>
                </Row>

                <div className="row m-0">
                    <div className="col-12 pt-5 px-4 pb-0 px-lg-5">
                        <p className="h4 m-0">Add Sales Entry</p>
                    </div>
                </div>

                <Row className="mt-3 ml-3 mr-3 mb-5">
                    <Col>
                        <div className='bg-white shadow p-4 mb-5'>
                            <form>

                                <div className="form-group">
                                    <label htmlFor="inputSession">User ID</label>
                                    {this.renderSelectUser()}
                                </div>

                                <FormInput
                                    name="description"
                                    type="text"
                                    label="Description"
                                    placeholder="Description"
                                    value={this.state.description}
                                    valid={this.state.errDesp}
                                    feedback={this.state.descriptionMessage}
                                    onChange={(e) => this.handleChange(e)}
                                ></FormInput>

                                <div className="form-group">
                                    <label htmlFor="inputSession">Type</label>
                                    <select
                                        id="dashboard-session-select"
                                        value={this.state.type}
                                        onChange={this.handleChange.bind(this)}
                                        className="custom-select"
                                        name="type"
                                    >
                                        {['Generic (default)', 'Product Sale with Affiliates', 'Product Sale No Affiliates'].map((obj: any, value: any) => {
                                            return (
                                                <option key={`option-item-${value}`} value={obj}>
                                                    {obj}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <FormInput
                                    name="amount"
                                    type="text"
                                    label="Amount"
                                    placeholder="Amount"
                                    value={this.state.amount}
                                    valid={this.state.errAmount}
                                    feedback={this.state.amountMessage}
                                    onChange={(e) => this.handleChange(e)}
                                ></FormInput>

                                <div className='mt-4'>
                                    <button
                                        className="btn btn-primary btn-block p-auto m-auto"
                                        onClick={(e) => { this.handleClick(e) }}
                                    >
                                        Add Ledger
                                        </button>
                                </div>

                            </form>
                        </div>


                    </Col>
                </Row>
                <Row className='mb-5'></Row>

            </div>
        )
    }
}