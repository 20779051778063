import React, { Component } from "react";
import {
    Col, Row, Card, Spinner, Alert, Table, Container, DropdownButton,
    Dropdown,
} from "react-bootstrap";
import { ReferalDefaults } from '../../components/referalAnalysis/referalsDefaults'
// import { DateRangePicker } from 'react-dates'
import { API } from "../../APIAndConfig";
import { get } from "lodash";
import { Firebase } from "../../firebase";
import moment from 'moment'
import ReactTable from 'react-table-6'
import '../../scss/reactTableButton.scss'
var SelectedStartDate = moment();
var SelectedEndDate = moment();
export class AdminDashboardV2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchInput: '',
            filteredData: [],
            newUsersData: null,
            freeUserCount: [],
            paidUserCount: 0,
            paidUserSignupAmount: 0,
            affiliateUserCount: 0,
            affiliateUserDetails: [],
            pendingPayouts: [],
            startDate: SelectedStartDate,
            endDate: SelectedEndDate,
            titleDays: ReferalDefaults.ranges.today.label || "",
            customSelect: false,
            transactionData: []
        }
    }

    async componentDidMount() {
        this.getData()
    }
    async getData() {
        this.setState({
            newUsersData: null,
            freeUserCount: [],
            paidUserCount: 0,
            paidUserSignupAmount: 0,
            affiliateUserCount: 0,
            pendingPayouts: [],
            affiliateUserDetails: [],
            transactionData: null
        })
        const data = await API.getAdminDashboardRecords({ startDate: this.state.startDate.format("YYYY-MM-DD"), endDate: this.state.endDate.format("YYYY-MM-DD") });
        console.log("data", data)
        this.setState({
            newUsersData: get(data, 'payload.data.newUsersData' || []),
            freeUserCount: get(data, 'payload.data.freeUserCount' || []),
            paidUserCount: get(data, 'payload.data.paidUsers.paid_users' || 0),
            paidUserSignupAmount: get(data, 'payload.data.paidUsers.signup_amount' || 0),
            affiliateUserCount: get(data, 'payload.data.affiliateUserCount.affiliate_signups' || 0),
            pendingPayouts: get(data, 'payload.data.pendingPayouts' || []),
            affiliateUserDetails: get(data, 'payload.data.affiliateUserDetails' || []),
            transactionData: get(data, 'payload.data.transactionData' || []),
        })
    }
    async handleDays(object) {
        this.setState({
            titleDays: object + "",
            customSelect: false
        })
        await this.daysFilterChange(object)
        this.getData()
        console.log("object", this.state.startDate, this.state.endDate)
    }
    setDaysActive(obj) {
        return this.state.titleDays === obj ? true : false
    }


    async daysFilterChange(object) {

        if (object === "Today") {
            this.setState({
                startDate: moment(new Date()),
                endDate: moment(new Date())
            })
        }

        else if (object === "Yesterday") {
            this.setState({
                startDate: moment().subtract(1, "days"),
                endDate: moment().subtract(1, "days")
            })
        }

        else if (object === "Last 7 Days") {
            this.setState({
                startDate: moment().subtract(6, "days"),
                endDate: moment()
            })
        }

        else if (object === "Last 14 Days") {
            this.setState({
                startDate: moment().subtract(13, "days"),
                endDate: moment(new Date())
            })
        }

        else if (object === "Last 28 Days") {
            this.setState({
                startDate: moment().subtract(27, "days"),
                endDate: moment(new Date())
            })
        }

        else if (object === "This Week") {
            this.setState({
                startDate: moment().startOf('isoWeek').subtract(1, "days"),
                endDate: moment(new Date())
            })
        }

        else if (object === "Last Week") {
            this.setState({
                startDate: moment().subtract(1, 'weeks').startOf('isoWeek').subtract(1, 'days'),
                endDate: moment().subtract(1, 'weeks').endOf('isoWeek').subtract(1, 'days'),
            })
        }

        else if (object === "This Month") {
            this.setState({
                startDate: moment().startOf('month'),
                endDate: moment(new Date())
            })
        }

        else if (object === "Last Month") {
            this.setState({
                startDate: moment().subtract(1, "month").startOf('month'),
                endDate: moment().subtract(1, "month").endOf('month')
            })
        }

        else if (object === "This Year") {
            this.setState({
                startDate: moment().startOf('year'),
                endDate: moment().endOf('year')
            })
        }

        else if (object === "Last Year") {
            this.setState({
                startDate: moment().subtract(1, 'year').startOf('year'),
                endDate: moment().subtract(1, 'year').endOf('year')
            })
        }

    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] ?
                String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                false
        );
    }

    render() {

        // Firebase.Analytics.logEvent("AdminDashboard");

        return (
            <div className="">
                <h2 className="main-title m-0 p-3">Admin Dashboard</h2>
                <hr className="m-0" />
                <Row>
                    <Col xs={12}>
                        <Row className="pb-3">

                            <Col xs={2}>
                                <DropdownButton
                                    key="Days"
                                    title={`${this.state.titleDays}`}
                                    size="lg"
                                    className="ml-1"
                                    variant={this.state.customSelect ? "outline-primary" : "primary"}
                                    id="Referal codes"
                                >{
                                        (Object.keys(ReferalDefaults.ranges).map((obj, index) => (
                                            <Dropdown.Item key={index} eventKey={String(index)} onClick={() => { this.handleDays(ReferalDefaults.ranges[obj].label) }} active={this.setDaysActive(ReferalDefaults.ranges[obj].label)} >{`${ReferalDefaults.ranges[obj].label}`}</Dropdown.Item>
                                        ), []))
                                    }
                                </DropdownButton>
                            </Col>

                        </Row>
                        <Row className="text-center mb-5">
                            <Col>
                                <Card className="card-blur">
                                    <Card.Header className="text-white">Signups ({this.state.titleDays})</Card.Header>
                                    <Card.Body style={{ minHeight: 150 }}>
                                        <h5 className="text-white">{this.state.paidUserCount}  Paid Users : (${this.state.paidUserSignupAmount}) </h5>
                                        {/* <h4 className="text-info">{this.state.freeUserCount}  Free Users : ($0.00) </h4> */}
                                        {this.state.freeUserCount.map((element, index) => {
                                            return <h5 key={index} className="text-white">{element.label}: {element.user_count} </h5>
                                        })}
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col>
                                <Card className="card-blur">
                                    <Card.Header className=" text-white">Affiliate Signups ({this.state.titleDays}) </Card.Header>
                                    <Card.Body style={{ height: 150 }}>
                                        <div className="p-3">
                                            <h4 className="text-white">Affiliate Users : {this.state.affiliateUserCount} </h4>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col>
                                <Card className="card-blur">
                                    <Card.Header className="text-white">Forecasted Affiliate Payouts</Card.Header>
                                    <Card.Body style={{ minHeight: 150 }}>
                                        <Row className="p-3">
                                            <Col >
                                                {this.state.pendingPayouts?.length == 0 ? <h6 className="text-white">Payout Due : $(0.00) </h6> : <></>}
                                                {this.state.pendingPayouts.map((element, index) => {
                                                    return <h6 key={index} className="text-white">{moment(element.payoutdate)
                                                        .local()
                                                        .format("YYYY-DD-MM")} Payout Due : (${element.amount}) </h6>
                                                })}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col>{this.affiliateBreakdown()}</Col>
                        </Row>
                        <Row className="my-5">
                            <Col>{this.transaction()}</Col>
                        </Row>

                        <Row className="my-5">
                            <Col>{this.paidLedger()}</Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    searchChange = (event) => {
        this.setState({ searchInput: event.target.value }, () => {
            this.globalSearch();
        });
    };

    globalSearch = () => {
        let { searchInput, referralData } = this.state;
        let filteredData = get(referralData, 'payload.referrals').filter((value) => {
            return (
                value.emailHash.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.referred
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.userId.toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())

            );
        });
        this.setState({ filteredData });
    };

    paidLedger() {


        if (!this.state.newUsersData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
            <div>
                <Card className="card-blur">
                    <Card.Header className="text-white font-weight-bold " style={{ background : "rgb(111 79 136 / 1)" }}>User Signup Table</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            filterable
                            defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
                            data={this.state.newUsersData}
                            columns={[
                                {
                                    id: "created",
                                    Header: 'Date/Time',
                                    accessor: (d) => {
                                        return moment(d.created)
                                            .local()
                                            .format("YYYY-DD-MM")
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: "id",
                                    Header: 'User Id',
                                    accessor: 'id',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'name',
                                    Header: 'Full Name',
                                    accessor: (d) => {
                                        return d.first_name + " " + d.last_name
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: 165
                                },

                                {
                                    id: "email",
                                    Header: 'Email Address',
                                    accessor: 'email',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: this.getColumnWidth(this.state.newUsersData, 'email', 'Email Address'),
                                },
                                {
                                    id: 'payment',
                                    Header: 'Payment ',
                                    accessor: (d) => {
                                        if (d.invite_parent) {
                                            return 'Invited'
                                        }
                                        else if (d.subscription_membership_id !== null) {
                                            if(d.paypal_customer_id){
                                                return 'Paypal'
                                            }
                                            else{
                                                return 'Stripe'
                                            }
                                        } else {
                                            return 'Other'
                                        }
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    Header: 'Amount',
                                    accessor: 'amount',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },

                                {
                                    id: 'plan',
                                    Header: 'Plan',
                                    accessor: (d) => {
                                        if (d.subscribed_membership_type !== null) {
                                            return d.subscribed_membership_type
                                        } else {
                                            return 'Not Available'
                                        }
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: 180
                                },
                                {
                                    id: 'Tier1 Referral',
                                    Header: 'Tier1 Referral',
                                    accessor: (obj) => {
                                        if (obj.ref_1_firstName && obj.ref_1_lastName) {
                                            return obj.ref_1_firstName + " " + obj.ref_1_lastName
                                        } else {
                                            return ''
                                        }
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'Tier2 Referral',
                                    Header: 'Tier2 Referral',
                                    accessor: (obj) => {
                                        if (obj.ref_2_firstName && obj.ref_2_lastName) {
                                            return obj.ref_2_firstName + " " + obj.ref_2_lastName
                                        } else {
                                            return ''
                                        }
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'Tier3 Referral',
                                    Header: 'Tier3 Referral',
                                    accessor: (obj) => {
                                        if (obj.ref_3_firstName && obj.ref_3_lastName) {
                                            return obj.ref_3_firstName + " " + obj.ref_3_lastName
                                        } else {
                                            return ''
                                        }
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },

                            ]}
                            defaultPageSize={5}
                            style={{ background : "rgb(111 79 136 / 1)" }}
                            defaultSorted={[
                                {
                                  id: "created",
                                  desc: true
                                }
                              ]}
                            noDataText='No Data Found'
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }

    transaction() {


        if (!this.state.transactionData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
            <div>
                <Card className="card-blur">
                    <Card.Header className="text-white  font-weight-bold " style={{ background : "rgb(111 79 136 / 1)" }}>Latest Transactions (Updated Every Hour)</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            filterable
                            defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
                            data={this.state.transactionData}
                            style={{ background : "rgb(111 79 136 / 1)" }}
                            columns={[
                                {
                                    id: "created",
                                    Header: 'Date/Time',
                                    Cell: (row) => {
                                        return <p style={{ whiteSpace : 'pre-wrap' }} >{ moment(row.original.entrytimestamp).local().format("YYYY-DD-MM HH:MM") }</p>
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: "id",
                                    Header: 'User Id',
                                    accessor: 'userID',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'name',
                                    Header: 'Full Name',
                                    accessor: 'Name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: 165
                                },

                                {
                                    id: "email",
                                    Header: 'Email Address',
                                    accessor: 'email',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: this.getColumnWidth(this.state.transactionData, 'email', 'Email Address'),
                                },
                                {
                                    id: 'payment',
                                    Header: 'Payment ',
                                    accessor: (d) => {
                                        if (d.subscription_membership_id !== null) {
                                            return 'Stripe'
                                        } else {
                                            return 'Other'
                                        }
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    Header: 'Amount',
                                    accessor: 'amount',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },

                                {
                                    id: 'plan',
                                    Header: 'Plan',
                                    accessor: 'Plan',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: 240
                                },
                                {
                                    id: 'Tier1 Referral',
                                    Header: 'Tier1 Referral',
                                    accessor: 'referrername1',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: 150
                                },
                                {
                                    id: 'Tier2 Referral',
                                    Header: 'Tier2 Referral',
                                    accessor: 'referrername2',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: 150
                                },
                                {
                                    id: 'Tier3 Referral',
                                    Header: 'Tier3 Referral',
                                    accessor: 'referrername3',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',
                                    width: 150
                                },

                            ]}
                            defaultPageSize={5}
                            noDataText='No Data Found'
                            defaultSorted={[
                                {
                                  id: "created",
                                  desc: true
                                }
                              ]}
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }

    affiliateBreakdown() {


        if (!this.state.newUsersData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
            < div >
                <Card className="card-blur">
                    <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Signups Per Affiliate</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            data={this.state.affiliateUserDetails}
                            columns={[
                                {
                                    Header: 'Referrer',
                                    accessor: 'referrer',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {

                                    Header: 'Affiliate Name',
                                    accessor: "Affiliate Name",
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center',

                                },

                                {
                                    Header: 'Total',
                                    accessor: 'total',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },


                            ]}
                            defaultPageSize={5}
                            style={{ background : "rgb(111 79 136 / 1)" }}
                            noDataText='No Data Found'
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </ div>
        )
    }


    getColumnWidth(rows, accessor, headerText) {
        const maxWidth = 400
        const magicSpacing = 10
        const cellLength = Math.max(
            ...rows.map((row) => (`${row[accessor]}` || '').length),
            headerText.length,
        )
        return Math.min(maxWidth, cellLength * magicSpacing)
    }

}
export default AdminDashboardV2



