import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import { Drivers, Storage } from "@ionic/storage";

const KEY_SESSION = "offline_session_";
const KEY_SESSIONIMAGES = "offline_sessionimages_";
const KEY_SESSION_IMAGEDATA = "offline_imagedata_";
const KEY_USER_FAVORITES = "offline_user_favorites";
const KEY_START_USER_WATCH_SESSION = "offline_start_user_watch_session_";
const KEY_SURVEY_BEFORE_SESSION = "offline_survey_before_session_";
const KEY_SURVEY_AFTER_SESSION = "offline_survey_after_session_";
const KEY_USER_WATCH_SESSION_IDS = "offline_user_watch_session_ids";
const KEY_DEFAULT_REQUIRED_IMAGES = "offline_required_default_images";
const KEY_SESSION_DEFAULT_REQUIRED_IMAGES = "offline_required_default_imagedata_"
const KEY_SESSION_MEDIAS = "offline_media_";
const KEY_SESSION_PAUSE_TIME = "offline_session_pause_time_";
const DB_NAME = "offline_session";

let store = false;

export const DOWNLOAD_STATE = {
  NONE: "NONE",
  DOWNLOADING: "DOWNLOADING",
  PAUSED: "PAUSED",
  REMOVING: "REMOVING",
  FINISHED: "FINISHED",
};

export const createStore = async () => {
  store = new Storage({
    name: DB_NAME,
    driverOrder: [
      CordovaSQLiteDriver._driver,
      Drivers.IndexedDB,
      Drivers.LocalStorage,
    ],
  });

  await store.defineDriver(CordovaSQLiteDriver);
  await store.create();
};

export const createOfflineSession = async ({ session, images }) => {
  // added extra property for faster look up when removing an image
  session.imageLookup = {};

  images.forEach(({ id: imageId }) => {
    // used an array to preserve the order
    session.imageLookup[imageId] = true;
  });

  await setItems(KEY_SESSION + session.id, session);
  await setOfflineSessionImages(session.id, images);
};

export const setOfflineSession = async (session) => {
  if (!session) return;
  await setItems(KEY_SESSION + session.id, session);
};

export const getFinishedOfflineSessions = async () => {
  const keys = await store.keys();
  const result = [];
  for (const key of keys) {
    if (key && key.startsWith(KEY_SESSION)) {
      const session = await getItems(key);
      if (session.downloadState === DOWNLOAD_STATE.FINISHED) {
        result.push(session);
      }
    }
  }
  return result;
};

export const getOfflineSessions = async (notDownload) => {
  const keys = await store.keys();
  const result = [];
  for (const key of keys) {
    if (key && key.startsWith(KEY_SESSION)) {
      const session = await getItems(key);
      if (notDownload) {
        result.push(session);
      } else {
        if (
          session.downloadState === DOWNLOAD_STATE.FINISHED ||
          session.downloadState === DOWNLOAD_STATE.DOWNLOADING ||
          session.downloadState === DOWNLOAD_STATE.PAUSED
        ) {
          result.push(session);
        }
      }
    }
  }
  return result;
};

export const getRemovingSessions = async () => {
  const keys = await store.keys();
  const result = [];
  for (const key of keys) {
    if (key && key.startsWith(KEY_SESSION)) {
      const session = await getItems(key);
      if (session.downloadState === DOWNLOAD_STATE.REMOVING) {
        result.push(session);
      }
    }
  }
  return result;
};

export const getNonRemovingSessions = async () => {
  const keys = await store.keys();
  const result = [];
  for (const key of keys) {
    if (key && key.startsWith(KEY_SESSION)) {
      const session = await getItems(key);
      if (session.downloadState !== DOWNLOAD_STATE.REMOVING) {
        result.push(session);
      }
    }
  }
  return result;
};

export const getOfflineSession = async (sessionId) => {
  const session = await getItems(KEY_SESSION + sessionId);
  if (!session || !session.id) return null;
  return session;
};

export const setOfflineSessionImages = async (sessionId, images) => {
  if (!images) return;
  await setItems(KEY_SESSIONIMAGES + sessionId, images);
};

export const getOfflineSessionImages = async (id) => {
  let images = await getItems(KEY_SESSIONIMAGES + id);
  if (!images || !images.length || images.length === 0) return null;
  return shuffle(images);
};

export const setOfflineImageData = async (id, data) => {
  if (!data || !id) return;
  await setItems(KEY_SESSION_IMAGEDATA + id, data);
};

export const setOfflineSessionDefaultRequiredImages = async (id, data) => {
  await setItems(KEY_SESSION_DEFAULT_REQUIRED_IMAGES + id, data);
}

export const getOfflineSessionDefaultRequiredImage = async (id) => {
  const data = await getItems(KEY_SESSION_DEFAULT_REQUIRED_IMAGES + id);
  if (!data) return null;
  return data;
}

export const getOfflineImageData = async (id) => {
  const data = await getItems(KEY_SESSION_IMAGEDATA + id);
  if (!data) return null;
  return data;
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const getOfflineSessionImageIdsForSession = async (sessionId) => {
  const images = await getItems(KEY_SESSIONIMAGES + sessionId);
  if (!images || !images.length || images.length === 0) return [];
  const imageIds = images.map((x) => x.id);
  const keys = await store.keys();
  const result = [];
  for (const key of keys) {
    if (key && key.startsWith(KEY_SESSION_IMAGEDATA)) {
      const imageId = Number(key.replace(KEY_SESSION_IMAGEDATA, ""));
      if (imageIds.includes(imageId)) result.push(imageId);
    }
  }
  return result;
};

export const setOfflineSessionMedia = async (sessionId, medias) => {
  await setItems(KEY_SESSION_MEDIAS + sessionId, medias);
};

export const getOfflineSessionMedia = async (sessionId) => {
  return await getItems(KEY_SESSION_MEDIAS + sessionId);
};

export const removeOfflineSession = async (sessionId) => {
  // const session = await getItems(KEY_SESSION + sessionId);
  const images = await getItems(KEY_SESSIONIMAGES + sessionId);
  // await store.remove(KEY_SESSION + sessionId);
  // await store.remove(KEY_SESSIONIMAGES + sessionId);

  if (!images) return false;

  // variable to store images to be remove
  let unusedImages = images.map((x) => x.id);

  // get all to-be-removed sessions
  const sessions = await getNonRemovingSessions();

  // const sessions = await getNonRemovingSessions();

  // start iterating to filter out the images that are being use in removing sessions
  for (const session of sessions) {
    const { imageLookup } = session;
    if (!imageLookup) continue;
    unusedImages = unusedImages.filter((u) => !imageLookup[u]);
  }

  // delete images
  for (const unusedImage of unusedImages) {
    await store.remove(KEY_SESSION_IMAGEDATA + unusedImage);
  }
};

export const setOfflineUserFavorites = async (userId, sessionId) => {
  let favs = await getItems(KEY_USER_FAVORITES);

  const key = generateFavoriteSessionKey(userId, sessionId);
  favs[key] = true;

  await setItems(KEY_USER_FAVORITES, favs);
};

export const removeOfflineUserFavorite = async (userId, sessionId) => {
  let favs = await getItems(KEY_USER_FAVORITES);

  const key = generateFavoriteSessionKey(userId, sessionId);
  delete favs[key];

  await setItems(KEY_USER_FAVORITES, favs);
};

export const setOfflineDefaultRequiredImages = async (images) => {
  await setItems(KEY_DEFAULT_REQUIRED_IMAGES, images);
};

export const getOfflineDefaultRequiredImages = async () => {
  const result = await getItems(KEY_DEFAULT_REQUIRED_IMAGES);

  return result;
};

export const setOfflineStartUserWatchSessionIds = async (sessionId) => {
  const items = await getItems(KEY_USER_WATCH_SESSION_IDS);

  items[sessionId] = true;

  await setItems(KEY_USER_WATCH_SESSION_IDS, items);
};

export const getOfflineStartUserWatchSessionIds = async () => {
  const items = await getItems(KEY_USER_WATCH_SESSION_IDS);

  return items;
};

export const setOfflineStartUserWatchSession = async (payload) => {
  await setItems(KEY_START_USER_WATCH_SESSION + payload.sessionId, payload);
};

export const getOfflineStartUserWatchSession = async (sessionId) => {
  const result = await getItems(KEY_START_USER_WATCH_SESSION + sessionId);

  return result;
};

export const setOfflineSurveyBeforeSession = async (payload) => {
  await setItems(KEY_SURVEY_BEFORE_SESSION + payload.sessionId, payload);
};

export const setOfflineSurveyAfterSession = async (payload) => {
  await setItems(KEY_SURVEY_AFTER_SESSION + payload.sessionId, payload);
};

export const getOfflineSurveyAfterSession = async (sessionId) => {
  const result = await getItems(KEY_SURVEY_AFTER_SESSION + sessionId);

  return result;
};

export const getOfflineSurveyBeforeSession = async (sessionId) => {
  const result = await getItems(KEY_SURVEY_BEFORE_SESSION + sessionId);

  return result;
};

export const setSessionPauseTime = async (payload) => {
  await setItems(KEY_SESSION_PAUSE_TIME + payload.sessionId, payload);
};

export const getSessionPauseTime = async (sessionId) => {
  const result = await getItems(KEY_SESSION_PAUSE_TIME + sessionId);

  return result;
};

export const removeOfflineStartUserWatchSessionId = async (sessionId) => {
  await store.remove(KEY_SESSION_PAUSE_TIME + sessionId);
  await store.remove(KEY_START_USER_WATCH_SESSION + sessionId);
  await store.remove(KEY_SURVEY_BEFORE_SESSION + sessionId);
  await store.remove(KEY_SURVEY_AFTER_SESSION + sessionId);

  const ids = await getItems(KEY_USER_WATCH_SESSION_IDS);

  delete ids[sessionId];

  await setItems(KEY_USER_WATCH_SESSION_IDS, ids);
};

const generateFavoriteSessionKey = (userId, sessionId) =>
  `${userId}-${sessionId}`;

const setItems = async (key, val) => {
  await store.set(key, val);
};

const getItems = async (key) => {
  let items = (await store.get(key)) || {};

  return items;
};
