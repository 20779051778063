import {FETCH_RECENTLY_WATCH} from '../actions/types';

export default function(state = null, action) {
   //console.log(action);
   switch (action.type) {
      case FETCH_RECENTLY_WATCH:
         return action.payload || [];
      default:
         return state;
   }
}
