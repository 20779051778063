import React, { Component } from "react";
import { connect } from "react-redux";
import { Firebase } from "../../firebase";
// import { Link, withRouter } from 'react-router-dom';
// import { reduxForm } from 'redux-form';
import * as actions from "../../actions";
import PPTable2 from "../common/PPTable2";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import map from 'lodash/map';
// import _ from 'lodash';

const columnsPurchase = [
  {
    Header: () => <div className="text-left">Title</div>,
    accessor: "name"
  },
  {
    Header: () => <div className="text-left">Created By</div>,
    accessor: "author_full_name"
  },
  {
    Header: () => <div className="text-left">Description</div>,
    accessor: "short_description"
  },
  {
    Header: () => <div className="text-left">Category</div>,
    accessor: "category_name_list"
  }
];

const columnsUserSession = [
  {
    Header: () => <div className="text-left">Title</div>,
    accessor: "name"
  },
  {
    Header: () => <div className="text-left">Default Runtime (seconds)</div>,
    accessor: "duration"
  },
  // {
  //    Header: () => <div className="text-left">Name</div>,
  //    accessor: 'author_full_name'
  // },
  // {
  //    Header: () => <div className="text-left">Description</div>,
  //    accessor: 'short_description'
  // },
  {
    Header: () => <div className="text-left">Category</div>,
    accessor: "category_name_list"
  }
];

const columnsCoachSession = [
  {
    Header: () => <div className="text-left">Title</div>,
    accessor: "name"
  },
  {
    Header: () => <div className="text-left">Default Runtime (seconds)</div>,
    accessor: "duration"
  },
  {
    Header: () => <div className="text-left">Created By</div>,
    accessor: "author_full_name"
  },
  // {
  //    Header: () => <div className="text-left">Description</div>,
  //    accessor: 'short_description'
  // },
  {
    Header: () => <div className="text-left">Category</div>,
    accessor: "category_name_list"
  }
];

const columnsRecentlyWatchSession = [
  {
    Header: () => <div className="text-left">Title</div>,
    accessor: "name"
  },
  {
    Header: () => <div className="text-left">Created By</div>,
    accessor: "author_full_name"
  },
  {
    Header: () => <div className="text-left">Start Time (UTC)</div>,
    accessor: "start_time"
  },
  {
    Header: () => <div className="text-left">End Time (UTC)</div>,
    accessor: "end_time"
  },
  {
    Header: () => <div className="text-left">View Time (HH:MM:SS)</div>,
    accessor: "view_time"
  },

  {
    Header: () => <div className="text-left">Before Emotion</div>,
    accessor: "before_emotion"
  },
  {
    Header: () => <div className="text-left">After Emotion</div>,
    accessor: "after_emotion"
  }
];

class Sessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidMount() {
    this.props.resetError();
    Firebase.Auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        if (this.props.auth) {
          await this.props.fetchPurchasesByUserId(this.props.auth.id);


          await this.props.fetchUserSession(this.props.auth);


          await this.props.fetchOwnerSessionForUser(this.props.auth);

          await this.props.fetchRecentlyWatchSession(this.props.auth);
        }
      } else {
        this.props.history.push("/login");
      }
    });
  }

  async componentDidUpdate() {
    if (this.props.auth) {
      if (!this.props.purchasedSessions) {
        await this.props.fetchPurchasesByUserId(this.props.auth.id);


        await this.props.fetchUserSession(this.props.auth);


        await this.props.fetchOwnerSessionForUser(this.props.auth);
      }

      if (!this.props.recentlyWatchSession) {
        await this.props.fetchRecentlyWatchSession(this.props.auth);
      }
    }
  }

  onSessionClick(rowInfo) {
    //onsole.log('Passed in function!!!', rowInfo);
    const { original } = rowInfo;

    //this.props.setSelectedSession(parseInt(original.id), original.name);

    this.props.history.push(
      `/images/${original.id}?runtime=${original.duration}`
    );
  }

  componentWillUnmount() {
    this.props.resetError();
  }

  onOpenNav(e) {
    document.getElementById("sidebar").classList.add("sidebarOpen");
  }

  render() {
    if (!this.props.auth) {
      return <div />;
    }

    return (
      <div className="mainWidth h-100">
        <div className="d-flex flex-column h-100">
          <div>
            <div className="d-flex">
              <button
                className="btn bg-transparent d-md-none"
                onClick={this.onOpenNav.bind(this)}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
              <p className="text-secondary m-0 px-0 py-3 px-md-3">
                My Sessions
              </p>
            </div>
            <hr className="m-0" />
            <div className="row m-0 p-3">
              <div className="col-12" />
            </div>
          </div>
          <nav className="p-3 px-sm-5">
            <div className="nav nav-pills" id="nav-tab-session" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-purchase-tab"
                data-toggle="tab"
                href="#nav-purchase"
                role="tab"
                aria-controls="nav-purchase"
                aria-selected="true"
              >
                Purchased Sessions
              </a>
              <a
                className="nav-item nav-link"
                id="nav-created-session-tab"
                data-toggle="tab"
                href="#nav-created-session"
                role="tab"
                aria-controls="nav-created-session"
                aria-selected="false"
              >
                Created Sessions
              </a>
              <a
                className="nav-item nav-link"
                id="nav-coach-session-tab"
                data-toggle="tab"
                href="#nav-coach-session"
                role="tab"
                aria-controls="nav-coach-session"
                aria-selected="false"
              >
                Coach Sessions
              </a>
              <a
                className="nav-item nav-link"
                id="nav-recently-watch-session-tab"
                data-toggle="tab"
                href="#nav-recently-watch-session"
                role="tab"
                aria-controls="nav-recently-watch-session"
                aria-selected="false"
              >
                Recently Watched Sessions
              </a>
            </div>
          </nav>

          <div
            className="tab-content flex-grow-1 d-flex px-3 px-sm-5 pb-5"
            id="nav-tabContent"
          >
            <div
              className="tab-pane flex-grow-1 bg-white border table-responsive fade show active"
              id="nav-purchase"
              role="tabpanel"
              aria-labelledby="nav-purchase-tab"
            >
              <PPTable2
                list={
                  this.props.purchasedSessions
                    ? this.props.purchasedSessions
                    : []
                }
                columns={columnsPurchase}
                noDataText={"No sessions"}
                onSelectRow={this.onSessionClick.bind(this)}
              />
            </div>
            <div
              className="tab-pane flex-grow-1 bg-white border table-responsive fade"
              id="nav-created-session"
              role="tabpanel"
              aria-labelledby="nav-created-session-tab"
            >
              <PPTable2
                list={this.props.userSessions ? this.props.userSessions : []}
                columns={columnsUserSession}
                noDataText={"No sessions"}
                onSelectRow={this.onSessionClick.bind(this)}
              />
            </div>
            <div
              className="tab-pane flex-grow-1 bg-white border table-responsive fade"
              id="nav-coach-session"
              role="tabpanel"
              aria-labelledby="nav-coach-session-tab"
            >
              <PPTable2
                list={
                  this.props.ownerSessionForUser
                    ? this.props.ownerSessionForUser
                    : []
                }
                columns={columnsCoachSession}
                noDataText={"No sessions"}
                onSelectRow={this.onSessionClick.bind(this)}
              />
            </div>
            <div
              className="tab-pane flex-grow-1 bg-white border table-responsive fade"
              id="nav-recently-watch-session"
              role="tabpanel"
              aria-labelledby="nav-recently-watch-session-tab"
            >
              <PPTable2
                list={
                  this.props.recentlyWatchSession
                    ? this.props.recentlyWatchSession
                    : []
                }
                columns={columnsRecentlyWatchSession}
                noDataText={"No sessions"}
                onSelectRow={this.onSessionClick.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    purchasedSessions: state.purchasedSessions,
    userSessions: state.userSessions,
    ownerSessionForUser: state.ownerSessionForUser,
    recentlyWatchSession: state.recentlyWatchSession
  };
}

export default connect(mapStateToProps, actions)(Sessions);
