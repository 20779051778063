import React from "react";
import { TopUpProps, TopUpState } from "./TopUp";
import { Col, Jumbotron, Row, Card, Spinner, Alert, Table, Container } from "react-bootstrap";

import { Link } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as actions from "../../actions";
import { connect } from "react-redux";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../APIAndConfig";
import { get } from "lodash";
import moment from 'moment'
import ReactTable from 'react-table-6'

export interface AffiliateProps extends RouteComponentProps {
    auth: any;
}
export interface AffiliateState {
    referralData: any,
    payoutDate: any,
    runningBalance: number,
    payoutAmount: number,
    ledgerData: any,
    paidLedgerData: any,
    searchInput: string,
    filteredData: any
}
export class AffiliatesComponent extends React.Component<AffiliateProps, AffiliateState> {

    constructor(props: AffiliateProps) {
        super(props);
        this.state = {
            referralData: null,
            payoutDate: null,
            runningBalance: 0,
            payoutAmount: 0,
            ledgerData: null,
            paidLedgerData: null,
            searchInput: '',
            filteredData: []
        }
    }

    onOpenNav(e: React.MouseEvent<HTMLButtonElement>) {
        const sidebar = document.getElementById('sidebar')
        if (sidebar)
            sidebar.classList.add('sidebarOpen');
    }

    async componentDidMount(): Promise<void> {
        const data = await API.getUserReferrals();
        this.setState({
            referralData: data,
            payoutDate: get(data, 'payload.payoutDate.payoutdate', 0),
            runningBalance: get(data, 'payload.runningBalance', 0) || 0,
            payoutAmount: get(data, 'payload.payoutAmount.payoutAmount', 0) || 0,
            ledgerData: get(data, 'payload.ledgerData'),
            paidLedgerData: get(data, 'payload.paidLedger')
        })
    }

    render() {

        const boxTotalSignup = get(this.state.referralData, 'payload.totalSignups', 0) || 0
        return (
            <div className="mainWidth">
                <button
                    className="btn d-md-none"
                    onClick={this.onOpenNav.bind(this)}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <p className="text-secondary m-0 p-3">Referrals / Affiliates</p>
                <hr className="m-0" />

                <Container fluid className="p-5">
                    {boxTotalSignup > 0 ? <></> :
                        <Row className="mb-1">
                            <Col>
                                <Jumbotron className="bg-primary shadow text-center">
                                    <h1 className="mb-5 text-white">We love our new Affiliate program. We trust you will too! </h1>
                                    <h3 className="text-light">
                                        For more information, please visit: <a target="_blank" href="https://www.positiveprime.com/affiliates/">positiveprime.com/affiliates</a> <br />
                                        Now you can earn bonuses and commissions
                                    </h3>
                                </Jumbotron>
                            </Col>
                        </Row>}

                    {this.renderUpgradeWarning()}

                    <Row className="text-center mb-5">
                        <Col>
                            <Card border="info">
                                <Card.Header className="bg-info text-white">Your Unique Referral Link</Card.Header>
                                <Card.Body style={{ height: 150 }}>
                                    <h2 className="p-3">
                                        <a target="_blank" className="text-info" href={`https://pospri.me/${this.props.auth?.referralCode}`}>
                                            pospri.me/{this.props.auth?.referralCode}
                                        </a>
                                    </h2>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card border="success">
                                <Card.Header className="bg-success text-white">Account Information</Card.Header>
                                <Card.Body style={{ height: 150 }}>
                                    <div className="p-3">
                                        <h4 className="text-success">Current Balance : ${this.state.runningBalance} </h4>
                                        <h4 className="text-success"> Payouts ( Expected : {
                                            !this.state.payoutDate || this.state.payoutDate === null ? 'Not Available' : moment(this.state.payoutDate).format('DD MMM')} ) : ${this.state.payoutAmount}</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {this.renderAffiliateCounts()}

                    {/* <div style={{ textAlign: "center", marginTop: 50 }} >
                        <p>*Note* Data on this screen commences from 1st September 2020 for Stripe transactions only.</p>
                    </div> */}
                    <div style={{ textAlign: "center", marginTop: 50 }} >
                        <h3>Attention : Referral System is Currently Undergoing Maintenance - It will be back online shortly!</h3>
                    </div>


                    <Row className="my-5">
                        <Col>{this.renderLedgerData()}</Col>
                    </Row>
                    <Row className="my-5">
                        <Col>{this.paidLedger()}</Col>
                    </Row>

                </Container>
            </div>
        );
    }

    searchChange = (event: any) => {
        this.setState({ searchInput: event.target.value }, () => {
            this.globalSearch();
        });
    };

    globalSearch = () => {
        let { searchInput, referralData } = this.state;
        let filteredData = get(referralData, 'payload.referrals').filter((value: any) => {
            return (
                value.emailHash.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.referred
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                value.userId.toString()
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())

            );
        });
        this.setState({ filteredData });
    };

    paidLedger() {


        if (!this.state.paidLedgerData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
            <div>
                <Card border="dark">
                    <Card.Header className="text-white bg-dark font-weight-bold ">Payouts</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            data={this.state.paidLedgerData}
                            columns={[
                                {
                                    Header: 'User Id',
                                    accessor: 'id',
                                    headerClassName: 'bg-primary p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    Header: 'First Name',
                                    accessor: 'first_name',
                                    headerClassName: 'bg-primary p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    Header: 'Last Name',
                                    accessor: 'last_name',
                                    headerClassName: 'bg-primary p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: "email",
                                    Header: 'Email',
                                    accessor: 'email',
                                    headerClassName: 'bg-primary p-3 text-light',
                                    className: 'text-center',
                                    width: this.getColumnWidth(this.state.paidLedgerData, 'email', 'Email'),
                                },
                                {
                                    id: "entrystamp",
                                    Header: 'Payment Date',
                                    accessor: (d: any) => {
                                        return moment(d.entrytimestamp)
                                            .local()
                                            .format("YYYY-DD-MM")
                                    },
                                    headerClassName: 'bg-primary p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    Header: 'Amount',
                                    accessor: 'amount',
                                    headerClassName: 'bg-primary p-3 text-light',
                                    className: 'text-center'
                                },



                            ]}
                            defaultPageSize={5}
                            noDataText='No Data Found'
                            getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'white' : '#f9f9f9',
                                            color: rowInfo.index % 2 === 0 ? '#000000' : '#000000'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'white',
                                            color: 'black'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </div>
        )
    }


    renderLedgerData() {
        if (!this.state.ledgerData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        if (!this.state.referralData.successful)
            return <Alert variant="danger">Error fetching referral data</Alert>

        const total = get(this.state.referralData, 'payload.totals.all', 0);
        if (total < 1)
            return <p>No referrals yet</p>


        const columns = [{
            Header: 'Ledger Id',
            accessor: 'ledgerID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Timestamp',
            headerClassName: 'bg-primary p-3 text-light',
            accessor: 'entrytimestamp',
            className: 'text-center'
        },
        {
            Header: 'User Id',
            accessor: 'userID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Description',
            accessor: 'description',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Referee Id',
            accessor: 'refereeID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Status',
            accessor: 'paymentstatus',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Payout Date',
            accessor: 'payoutdate',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Payout Id',
            accessor: 'payoutID',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }]

        return (
            <div>
                <Card border="dark">
                    <Card.Header className="text-white bg-dark font-weight-bold ">Sales Ledger</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            data={this.state.ledgerData}
                            columns={columns}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'white' : '#f9f9f9',
                                            color: rowInfo.index % 2 === 0 ? '#000000' : '#000000'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'white',
                                            color: 'black'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>


                <Row className="my-5">
                    <Col>{this.renderAffiliateData()}</Col>
                </Row>

            </div>
        )

    }


    renderAffiliateCounts() {

        const totalSignup = get(this.state.referralData, 'payload.totalSignups', 0) || 0
        const totalEarning = get(this.state.referralData, 'payload.totalEarning', 0) || 0
        const tier1Count = get(this.state.referralData, 'payload.tier1.count', 0) || 0
        const tier1Sum = get(this.state.referralData, 'payload.tier1.tier1Earning', 0) || 0
        const tier2Count = get(this.state.referralData, 'payload.tier2.count', 0) || 0
        const tier2Sum = get(this.state.referralData, 'payload.tier2.tier2Earning', 0) || 0
        const tier3Count = get(this.state.referralData, 'payload.tier3.count', 0) || 0
        const tier3Sum = get(this.state.referralData, 'payload.tier3.tier3Earning', 0) || 0

        if (!this.state.referralData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        if (!this.state.referralData.successful)
            return <Alert variant="danger">Error fetching referral data</Alert>

        return (
            <Row>
                <Col>
                    <Card className="bg-gradient-primary text-white">
                        <Card.Body className="text-center mt-3 mb-3">
                            <h3>{totalSignup + " ( $" + totalEarning + " )"}</h3>
                            <h4>Total Signups</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="bg-gradient-success text-white">
                        <Card.Body className="text-center mt-3 mb-3">
                            <h3>{tier1Count + " ( $" + tier1Sum + " )"}</h3>
                            <h4>Tier 1</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="bg-gradient-warning text-white">
                        <Card.Body className="text-center mt-3 mb-3">
                            <h3>{tier2Count + " ( $" + tier2Sum + " )"}</h3>
                            <h4>Tier 2</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="bg-gradient-info text-white">
                        <Card.Body className="text-center mt-3 mb-3">
                            <h3>{tier3Count + " ( $" + tier3Sum + " )"}</h3>
                            <h4>Tier 3</h4>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }


    getColumnWidth(rows: any, accessor: any, headerText: any) {
        const maxWidth = 400
        const magicSpacing = 10
        const cellLength = Math.max(
            ...rows.map((row: any) => (`${row[accessor]}` || '').length),
            headerText.length,
        )
        return Math.min(maxWidth, cellLength * magicSpacing)
    }
    renderAffiliateData() {
        if (!this.state.referralData)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        if (!this.state.referralData.successful)
            return <Alert variant="danger">Error fetching referral data</Alert>

        const total = get(this.state.referralData, 'payload.totals.all', 0);
        // if (total < 1)
        //     return <p>No referrals yet</p>

        let rows: any = [];
        const columns = [{
            Header: 'Level',
            accessor: 'level',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Referral Date',
            headerClassName: 'bg-primary p-3 text-light',
            accessor: 'date',
            className: 'text-center'
        }, {
            Header: 'ID',
            headerClassName: 'bg-primary p-3 text-light',
            accessor: 'id',
            className: 'text-center'
        },
        {
            Header: 'Name',
            accessor: 'name',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        },
        {
            Header: 'Email MD5 Hash',
            accessor: 'email',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }]


        let row: any;
        let data
        if (this.state.filteredData.length > 0) {
            data = this.state.filteredData
        } else {
            data = get(this.state.referralData, 'payload.referrals', [])
        }
        for (row of data) {
            let level = get(row, 'userRole', '');
            let levelClass = level === "User" ? "text-muted" : undefined;
            let date = (get(row, 'referred') || '').split('T')[0];
            let id = get(row, 'userId');
            let name = get(row, 'name', '') || 0;
            let email = get(row, 'emailHash', '');

            // console.log(level + "\t" + date + "\t" + name + "\t" + email)
            let dataObj: any = {
                level: level,
                date: date,
                id: id,
                name: name,
                email: email
            }
            rows.push(dataObj)

            // rows.push(<tr>
            //     <td className={levelClass}>{level}</td>
            //     <td>{date}</td>
            //     <td>{name}</td>
            //     <td>{email}</td>
            // </tr>);
        }
        return (
            <div>
                <Card border="dark">
                    <Card.Header className="text-white bg-dark font-weight-bold ">
                        <div ><span>Current Referrals: &nbsp; {total}</span>
                            <input
                                style={{ float: "right" }}
                                name="searchInput"
                                value={this.state.searchInput || ""}
                                onChange={this.searchChange}
                                placeholder="Search"
                            /></div>
                    </Card.Header>
                    <Card.Body className="p-0">
                        {/* <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Level</th>
                                    <th>Referral Date</th>
                                    <th>Name</th>
                                    <th>Email MD5 Hash</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </Table> */}

                        <ReactTable
                            data={rows}
                            columns={columns}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'white' : '#f9f9f9',
                                            color: rowInfo.index % 2 === 0 ? '#000000' : '#000000'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'white',
                                            color: 'black'
                                        },
                                    }
                                }
                            }}
                        />

                    </Card.Body>
                </Card>
            </div>
        )
    }

    renderUpgradeWarning() {
        const roleId = get(this.props.auth, 'role.id', 0);
        if ([4, 5, 6, 1].indexOf(roleId) >= 0)
            return;

        return (
            <Row className="my-3">
                <Col>
                    <Alert variant="warning" className="p-3">
                        <Alert.Heading>You are NOT CURRENTLY ELIGIBLE for the Affiliate program.</Alert.Heading>
                        <p>
                            You must be a VIP or SIGNATURE member to take advantage. You will only earn commission on transactions and purchases made AFTER you've upgraded your account.
                        </p>
                        <Alert.Link><Link to="/upgradeAccount">Click here to upgrade your account now</Link></Alert.Link>
                    </Alert>
                </Col>
            </Row>
        )
    }
}
function mapStateToProps(state: any) {
    return { auth: state.auth, createdSessions: state.userSessions };
}
export const Affiliate = connect(mapStateToProps, actions)(withRouter(AffiliatesComponent));
