import React, { Component } from 'react'
import {
	Col,
	Row,
	Card,
	Spinner,
	Alert,
	Table,
	Container,
	DropdownButton,
	Dropdown,
	Modal,
	Tooltip,
	OverlayTrigger,
} from 'react-bootstrap'
import { ReferalDefaults } from '../../components/referalAnalysis/referalsDefaults'
// import { DateRangePicker } from 'react-dates'
import { API, Config } from '../../APIAndConfig'
import { get } from 'lodash'
import { Firebase } from '../../firebase'
import moment from 'moment'
import ReactTable from 'react-table-6'
import '../../scss/reactTableButton.scss'
import clsx from 'clsx'
import validator from 'validator'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { cookies } from '../../index'
import axios from 'axios'

export class UserManagement extends Component {
	constructor(props) {
		super(props)
		const referralCookie = cookies.get('referralCode')
		this.state = {
			users: [],
			editUser: false,
			addUser: false,
			currentUser: {},
			emailError: false,
			errorPassword: false,
			firstNameError: false,
			lastNameError: false,
			messageAlert: {
				state: false,
				header: '',
				message: '',
			},
			errors: {
				referralCodeError: false,
				referredByUserIdError: false,
			},
			subscription: [
				'vip-discount',
				'vip-yearly',
				'signature-monthly',
				'signature-yearly',
				'pro-monthly',
				'pro-yearly',
				'signature7-free7trial',
				'signature7-trial7life',
				'signature7-trial7yearly',
				'signature-monthly-free10trial',
				'signature-yearly-free10trial',
				'signature-monthly-notrial',
				'SignatureCoachingR',
				'SignatureCoaching',
			],

			newUser: {},
			isProcessing: false,
			infusionSoft: false,
			referredUsers: [],
			referredUsersModal: false,
			userHistory: {
				status: false,
				loading: true,
			},
		}
	}

	async componentDidMount() {
		await this.getAllUsers()
	}

	handleChange(e) {
		this.setState({
			currentUser: {
				...this.state.currentUser,
				[e.target.name]: e.target.value,
			},
		})
	}

	handleNewUserChange(e) {
		this.setState({
			newUser: {
				...this.state.newUser,
				[e.target.name]: e.target.value,
			},
		})
	}

	validateEmail(event) {
		if (validator.isEmpty(event.target.value)) {
			this.setState({
				emailError: 'Enter Email',
				currentUser: {
					...this.state.currentUser,
					email: event.target.value,
				},
			})
		} else if (!validator.isEmail(event.target.value)) {
			this.setState({
				emailError: 'Enter Valid Email',
				currentUser: {
					...this.state.currentUser,
					email: event.target.value,
				},
			})
		} else {
			this.setState({
				emailError: false,
				currentUser: {
					...this.state.currentUser,
					email: event.target.value,
				},
			})
		}
	}

	validatePassword = (event) => {
		if (event.target.value.length < 6 && event.target.value.length !== 0) {
			this.setState({
				errorPassword: 'Enter a Valid Password',
				currentUser: {
					...this.state.currentUser,
					newPassword: event.target.value,
				},
			})
		} else {
			this.setState({
				errorPassword: '',
				currentUser: {
					...this.state.currentUser,
					newPassword: event.target.value,
				},
			})
		}
	}

	validateNewUserEmail(event) {
		if (validator.isEmpty(event.target.value)) {
			this.setState({
				newUser: {
					...this.state.newUser,
					email: event.target.value,
					emailError: 'Enter Email',
				},
			})
		} else if (!validator.isEmail(event.target.value)) {
			this.setState({
				newUser: {
					...this.state.newUser,
					email: event.target.value,
					emailError: 'Enter Valid Email',
				},
			})
		} else {
			this.setState({
				newUser: {
					...this.state.newUser,
					email: event.target.value,
					emailError: false,
				},
			})
		}
	}

	validateNewUserPassword = (event) => {
		let str =
			'error' +
			(event.target.name === 'password' ? 'Password' : 'ConfirmPassword')

		if (event.target.value.length < 6 && event.target.value.length !== 0) {
			this.setState({
				newUser: {
					...this.state.newUser,
					[event.target.name]: event.target.value,
					[str]: 'Enter a Valid Password',
				},
			})
		} else {
			this.setState({
				newUser: {
					...this.state.newUser,
					[event.target.name]: event.target.value,
					[str]: '',
				},
			})
		}
	}

	async getReferredUsers(userId) {
		await API.getReferredUsers(userId).then((res) => {
			if (res.successful) {
				console.log(res.payload.data)
				this.setState({
					referredUsers: res.payload.data,
				})
			}
		})
	}

	async getUserHistory(payload) {
		await API.getUserHistory(payload).then((res) => {
			if (res.successful) {
				const watchColumns = [
					{
						id: 'session_name',
						Header: 'Session Name',
						accessor: (d) => {
							return (
								<OverlayTrigger
									placement="bottom"
									overlay={
										<Tooltip id="button-tooltip-2" className="mt-n1">
											ID : {d.id}, SessionID : {d.session_id}
										</Tooltip>
									}
								>
									{({ ref, ...triggerHandler }) => (
										<div
											{...triggerHandler}
											ref={ref}
											className="w-100"
											style={{ whiteSpace: 'pre-wrap' }}
										>
											{d.name}
										</div>
									)}
								</OverlayTrigger>
							)
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'start_time',
						Header: 'Start Time',
						accessor: (d) => {
							return moment(d.start_time).format('DD-MM-YYYY HH:mm:ss')
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'end_time',
						Header: 'End Time',
						accessor: (d) => {
							return moment(d.end_time).format('DD-MM-YYYY HH:mm:ss')
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'total_time',
						Header: 'Total Time',
						accessor: (d) => {
							return moment(d.end_time).diff(moment(d.start_time), 'seconds')
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
						width: 100,
					},
					{
						id: 'finish_session',
						Header: 'Finish session',
						accessor: (d) => {
							return d.finish_session === 1 ? 'Finished' : 'UnFinished'
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
						width: 120,
					},
				]

				const loginColumns = [
					{
						id: 'date',
						Header: 'Date',
						accessor: (d) => {
							return moment(d.created).format('DD-MM-YYYY HH:mm:ss')
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'user_id',
						Header: 'User ID',
						accessor: 'userID',
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'platform',
						Header: 'Platform',
						accessor: 'platform',
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'ip_address',
						Header: 'IP Address',
						accessor: 'IPaddress',
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
				]

				const purchaseColumns = [
					{
						id: 'date',
						Header: 'Date',
						accessor: (d) => {
							return moment(d.created * 1000).format('DD-MM-YYYY HH:mm:ss')
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'amount',
						Header: 'Amount',
						accessor: (d) => {
							return `$${d.amount / 100}`
						},
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'description',
						Header: 'Description',
						accessor: 'description',
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'payment_method',
						Header: 'Payment Method',
						accessor: 'payment_method',
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
					{
						id: 'status',
						Header: 'Status',
						accessor: 'status',
						headerClassName: 'p-3 text-light',
						className: 'text-center',
					},
				]

				this.setState({
					userHistory: {
						...this.state.userHistory,
						loading: false,
						watchHistory: res.payload.watchHistory,
						loginHistory: res.payload.loginHistory,
						purchaseHistory: res.payload.purchaseHistory,
						watchColumns: watchColumns,
						loginColumns: loginColumns,
						purchaseColumns: purchaseColumns,
						type: 'watch_history',
					},
				})
			} else {
				console.log(res)
				this.setState({
					userHistory: {
						status: false,
						loading: false,
					},
					messageAlert: {
						state: true,
						header: 'Failed',
						message: 'Failed to fetch user history please try again',
					},
				})
			}
		})
	}

	// async addSponsoredPlan(item){
	//     if(item.id === 2){
	//         this.setState({
	//             subscription: [...this.state.subscription,"Sponsored"]
	//         })
	//     }
	// }

	async getAllUsers() {
		await API.getUsers().then((res) => {
			if (res.payload && res.payload.data) {
				res.payload.data.map((item) => {
					item.checkEmail = item.email
					item.checkFirstName = item.first_name
					item.checkLastName = item.last_name
					item.checkRole = item.id
					item.checkReferralCode = item.referral_code
					item.checkReferredByUserId = item.referredByUserId
					item.checkDescription = item.description
					item.checkSubscribed_membership_type = item.subscribed_membership_type
					item.newPassword = ''
					item.action = (
						<div>
							<button
								className="btn x-secondary text-white"
								onClick={() => {
									this.getReferredUsers(item.userId)
									this.setState({
										editUser: true,
										currentUser: item,
									})
									// this.addSponsoredPlan(item)
								}}
							>
								Edit
							</button>
							<button
								className="btn x-secondary text-white ml-2"
								onClick={() => {
									let payload = {
										userId: item.userId,
										stripe_customer_id: item.stripe_customer_id,
									}
									this.getUserHistory(payload)
									this.setState({
										userHistory: {
											status: true,
											loading: true,
										},
									})
								}}
							>
								View Activity
							</button>
						</div>
					)
				})
				this.setState({ users: res.payload.data })
			}
		})
	}

	async handleSubmit() {
		if (
			this.state.currentUser.checkEmail === this.state.currentUser.email &&
			this.state.currentUser.checkFirstName ===
				this.state.currentUser.first_name &&
			this.state.currentUser.checkLastName ===
				this.state.currentUser.last_name &&
			this.state.currentUser.checkRole === this.state.currentUser.id &&
			this.state.currentUser.checkReferralCode ===
				this.state.currentUser.referral_code &&
			this.state.currentUser.checkReferredByUserId ===
				this.state.currentUser.referredByUserId &&
			this.state.currentUser.checkDescription ===
				this.state.currentUser.description &&
			this.state.currentUser.checkSubscribed_membership_type ===
				this.state.currentUser.subscribed_membership_type &&
			this.state.currentUser.newPassword === ''
		) {
			this.setState({
				messageAlert: {
					state: true,
					message: 'No changes detected to update please try updating fields',
					header: 'Update User Error',
				},
			})
		} else if (
			this.state.currentUser.checkReferredByUserId !==
				this.state.currentUser.referredByUserId &&
			this.state.currentUser.referredByUserId !== '' &&
			this.state.currentUser.referredByUserId !== null &&
			this.state.users.find(
				(obj) =>
					obj.userId === Number(this.state.currentUser.referredByUserId) &&
					Number(this.state.currentUser.referredByUserId) !==
						Number(this.state.currentUser.userId)
			) === undefined
		) {
			this.setState({
				errors: {
					...this.state.errors,
					referredByUserIdError: 'No such user available for referral',
				},
			})
		} else if (
			this.state.currentUser.checkReferralCode !==
				this.state.currentUser.referral_code &&
			this.state.users.find(
				(obj) =>
					obj.referral_code === this.state.currentUser.referral_code &&
					this.state.currentUser.referral_code !==
						this.state.currentUser.checkReferralCode
			) !== undefined
		) {
			this.setState({
				errors: {
					...this.state.errors,
					referralCodeError:
						'Referral code is already used please try different referral code',
				},
			})
		} else {
			this.setState({ isProcessing: true })
			let payload = {
				checkFirstName: this.state.currentUser.checkFirstName,
				first_name: this.state.currentUser.first_name,
				checkLastName: this.state.currentUser.checkLastName,
				last_name: this.state.currentUser.last_name,
				checkEmail: this.state.currentUser.checkEmail,
				email: this.state.currentUser.email,
				stripe_customer_id: this.state.currentUser.stripe_customer_id,
				subscription_membership_id:
					this.state.currentUser.subscription_membership_id,
				uuid: this.state.currentUser.uuid,
				role_id: Number(this.state.currentUser.id),
				checkRole: Number(this.state.currentUser.checkRole),
				user_id: this.state.currentUser.userId,
				inviteCheck: this.state.currentUser.invite_parent,
				description: this.state.currentUser.description,
				referral_code: this.state.currentUser.referral_code,
				checkReferralCode: this.state.currentUser.checkReferralCode,
				referredByUserId: this.state.currentUser.referredByUserId,
				checkReferredByUserId: this.state.currentUser.checkReferredByUserId,
				newPassword: this.state.currentUser.newPassword || null,
				checkSubscribed_membership_type:
					this.state.currentUser.checkSubscribed_membership_type,
				subscription_membership_type:
					this.state.currentUser.subscribed_membership_type,
			}

			await API.updateUserInfo(payload).then((res) => {
				if (res.status === 200) {
					this.setState({
						editUser: false,
						currentUser: {},
						messageAlert: {
							state: true,
							message: 'Sucessfully updated the user',
							header: 'Update Successful',
						},
						isProcessing: false,
					})
					this.getAllUsers()
				} else {
					this.setState({
						editUser: false,
						currentUser: {},
						messageAlert: {
							state: true,
							message: 'Failed to update user',
							header: 'Update Failed',
						},
						isProcessing: false,
					})
				}
			})
		}
	}

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id
		return row[id]
			? String(String(row[id]).toLowerCase()).startsWith(
					filter.value.toLowerCase()
			  )
			: false
	}

	async handleNewUserSubmit() {
		this.setState({ isProcessing: true })
		const params = {
			email: this.state.newUser.email || '',
			firstName: this.state.newUser.first_name || '',
			lastName: this.state.newUser.last_name || '',
			password: this.state.newUser.password || '',
			confirmPassword: this.state.newUser.confirm_password || '',
			referralCode: this.state.newUser.referral_code || 'epic',
		}

		await API.createUser(params).then((res) => {
			if (res.successful && res.status === 201) {
				if (this.state.infusionSoft) {
					let infusionsoftPayload = {
						user: {
							email: res.payload.user.email,
							firstName: res.payload.user.firstName,
							lastName: res.payload.user.lastName,
						},
						referralCode: params.referralCode || 'epic',
						invited: false,
					}
					API.registerUserInfusionsoft(infusionsoftPayload)
				}
				this.setState({
					newUser: {},
					isProcessing: false,
					addUser: false,
					messageAlert: {
						state: true,
						header: 'Successfull',
						message: 'User has been added successfully',
					},
				})
				cookies.remove('referralCode')
				this.getAllUsers()
			} else if (!res.successful) {
				if (res.payload && res.payload.errors) {
					this.setState({
						isProcessing: false,
						newUser: {
							...this.state.newUser,
							mainError: res.payload.errors[0].detail,
						},
					})
				} else {
					this.setState({
						isProcessing: false,
						newUser: {},
						addUser: false,
						messageAlert: {
							state: true,
							header: 'Failed',
							message: 'oops something went wrong please try later',
						},
					})
				}
			}
		})
	}

	renderReferredBySelect() {
		let referredByUsers = [...this.state.users]
		referredByUsers.splice(
			this.state.users.findIndex(
				(obj) => obj.userId === this.state.currentUser.userId
			),
			1
		)

		return (
			<select
				name="referredByUserId"
				className="form-control w-100"
				onChange={this.handleChange.bind(this)}
			>
				<option value="select" selected disabled>
					{this.state.currentUser.tier1
						? this.state.currentUser.tier1
						: 'Select'}
				</option>
				{referredByUsers.map((obj, index) => {
					return (
						<option
							value={obj.userId}
						>{`${obj.userId} - ${obj.first_name} ${obj.last_name}`}</option>
					)
				})}
			</select>
		)
	}

	users() {
		if (this.state.users.length === 0)
			return (
				<div className={'text-center'}>
					<Spinner
						animation={'grow'}
						variant={'primary'}
						style={{ width: '5em', height: '5em' }}
					/>
				</div>
			)

		return (
			<div>
				<Card className="card-blur">
					<Card.Header
						className="text-white  font-weight-bold "
						style={{ background: 'rgb(111 79 136 / 1)' }}
					>
						User Management
					</Card.Header>
					<Card.Body className="p-0">
						<ReactTable
							filterable
							data={this.state.users}
							defaultFilterMethod={(filter, row) =>
								this.filterCaseInsensitive(filter, row)
							}
							columns={[
								{
									id: 'userId',
									Header: 'User Id',
									accessor: 'userId',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'first_name',
									Header: 'First Name',
									accessor: 'first_name',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'last_name',
									Header: 'Last Name',
									accessor: 'last_name',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'email',
									Header: 'Email Address',
									accessor: 'email',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'subscribed_membership_type',
									Header: 'Membership Type',
									accessor: 'subscribed_membership_type',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'role',
									Header: 'Role',
									accessor: (d) => {
										return `( ${d.id} ) ${d.label}`
									},
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'action',
									Header: 'Action',
									accessor: 'action',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
									Filter: ({ filter, onChange }) => {
										return (
											<input
												type="text"
												value=""
												style={{ width: '100%' }}
												disabled
											/>
										)
									},
								},
							]}
							defaultPageSize={10}
							noDataText="No Data Found"
							style={{ background: 'rgb(111 79 136 / 1)' }}
							getTrProps={(state, rowInfo, column, instance) => {
								if (typeof rowInfo !== 'undefined') {
									return {
										style: {
											background:
												rowInfo.index % 2 === 0
													? 'transparent'
													: 'rgb(96 64 125 / 1)',
											color: rowInfo.index % 2 === 0 ? 'white' : 'white',
										},
									}
								} else {
									return {
										style: {
											background: 'transparent',
											color: 'white',
										},
									}
								}
							}}
						/>
					</Card.Body>
				</Card>

				<Modal show={this.state.editUser} onHide={() => {}} size="xl">
					<Modal.Header>Edit User</Modal.Header>
					<Modal.Body>
						<Row>
							<Col className="col-md-4 col-12">
								<div className={clsx('form-group')}>
									<label>User Id</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="userId"
										value={this.state.currentUser.userId}
										placeholder="User Id"
										disabled
									/>
								</div>
								<div className={clsx('form-group')}>
									<label>First Name</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="first_name"
										value={this.state.currentUser.first_name}
										onChange={this.handleChange.bind(this)}
										placeholder="Firstname"
									/>
									<span className="errorMessage">
										{this.state.firstNameError ? this.state.firstNameError : ''}
									</span>
								</div>
								<div className={clsx('form-group')}>
									<label>Last Name</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="last_name"
										value={this.state.currentUser.last_name}
										onChange={this.handleChange.bind(this)}
										placeholder="Lastname"
									/>
									<span className="errorMessage">
										{this.state.lastNameError ? this.state.lastNameError : ''}
									</span>
								</div>
								<div className={clsx('form-group')}>
									<label>Email</label>
									<input
										type="email"
										className={clsx('form-control', 'setting-input')}
										name="email"
										value={this.state.currentUser.email}
										onChange={this.validateEmail.bind(this)}
										placeholder="Email"
									/>
									<span className="errorMessage">
										{this.state.emailError ? this.state.emailError : ''}
									</span>
								</div>
								<div className={clsx('form-group')}>
									<label>New Password</label>
									<input
										type="password"
										className={clsx('form-control', 'setting-input')}
										name="password"
										value={this.state.currentUser.newPassword}
										onChange={this.validatePassword.bind(this)}
										placeholder="New Password"
									/>
									<span className="errorMessage">
										{this.state.errorPassword ? this.state.errorPassword : ''}
									</span>
								</div>
								<div className={clsx('form-group')}>
									<label>Membership Type</label>
									{this.state.currentUser.checkSubscribed_membership_type ? (
										<input
											type="text"
											className={clsx('form-control', 'setting-input')}
											name="subscribed_membership_type"
											value={
												this.state.currentUser.checkSubscribed_membership_type
											}
											onChange={this.handleChange.bind(this)}
											placeholder="Subscription membership type"
											disabled
										/>
									) : (
										<select
											name="subscribed_membership_type"
											className="form-control"
											onChange={this.handleChange.bind(this)}
										>
											<option disabled selected>
												Select Membership Type
											</option>
											{this.state.subscription.map((item, index) => (
												<option
													key={index}
													value={item}
													name="subscribed_membership_type"
												>
													{item}
												</option>
											))}
										</select>
									)}
								</div>
								<div className={clsx('form-group')}>
									<label>Role</label>
									<select
										name="id"
										className="form-control"
										onChange={this.handleChange.bind(this)}
									>
										<option
											value={1}
											selected={this.state.currentUser.id === 1}
										>
											Administrator
										</option>
										<option
											value={2}
											selected={this.state.currentUser.id === 2}
										>
											User
										</option>
										<option
											value={3}
											selected={this.state.currentUser.id === 3}
										>
											Pro
										</option>
										<option
											value={4}
											selected={this.state.currentUser.id === 4}
										>
											Coach
										</option>
										<option
											value={5}
											selected={this.state.currentUser.id === 5}
										>
											Signature Pro
										</option>
										<option
											value={6}
											selected={this.state.currentUser.id === 6}
										>
											VIP
										</option>
										<option
											value={7}
											selected={this.state.currentUser.id === 7}
										>
											User Cancelled
										</option>
									</select>
								</div>
								<div className={clsx('form-group')}>
									<label>Referral Count</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="referral_counts"
										value={this.state.currentUser.referral_counts}
										placeholder="Referral Count"
										disabled
									/>
								</div>
							</Col>
							<Col className="col-md-4 col-12">
								<div className={clsx('form-group')}>
									<label>Sessions Watched</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="session_count"
										value={this.state.currentUser.session_count}
										placeholder="Sessions Watched"
										disabled
									/>
								</div>
								<div className={clsx('form-group')}>
									<label>Referral Code</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="referral_code"
										onChange={this.handleChange.bind(this)}
										value={this.state.currentUser.referral_code}
										placeholder="Referral Code"
									/>
									<span className="errorMessage">
										{this.state.errors.referralCodeError
											? this.state.errors.referralCodeError
											: ''}
									</span>
								</div>
								<div className={clsx('form-group')}>
									<label>Created</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="created"
										value={moment(this.state.currentUser.created).format(
											'DD-MM-YYYY hh:mm A'
										)}
										placeholder="Created"
										disabled
									/>
								</div>
								<div className={clsx('form-group')}>
									<label>Last Login</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="lastLogin"
										value={
											this.state.currentUser.lastLogin
												? moment(this.state.currentUser.lastLogin).format(
														'DD-MM-YYYY hh:mm A'
												  )
												: '-'
										}
										placeholder="Last Login"
										disabled
									/>
								</div>
								<div className={clsx('form-group')}>
									<label>Referred by</label>
									{
										// !this.state.currentUser.tier1 || this.state.currentUser.referredByUserId === 3028 ?
										this.renderReferredBySelect()
										// :
										// <input
										//     type="text"
										//     className={clsx('form-control', 'setting-input')}
										//     name="referredByUserId"
										//     value={this.state.currentUser.tier1}
										//     onChange={this.handleChange.bind(this)}
										//     placeholder="Referred By"
										//     disabled
										// />
									}
									<span className="errorMessage">
										{this.state.errors.referredByUserIdError
											? this.state.errors.referredByUserIdError
											: ''}
									</span>
								</div>
								<div className="form-group d-flex flex-column">
									<label>Profile Image</label>
									<img
										className="rounded-circle p-0 ml-auto mr-auto"
										height="100"
										width="100"
										src={
											this.state.currentUser.profile_pic_url
												? this.state.currentUser.profile_pic_url
												: '/assets/images/profile_logo.svg'
										}
									/>
								</div>
								<div className={clsx('form-group')}>
									<label>Description</label>
									<textarea
										rows="4"
										type="text"
										className={clsx('form-control', 'setting-input')}
										value={this.state.currentUser.description}
										onChange={this.handleChange.bind(this)}
										name="description"
										placeholder="User Description"
									/>
								</div>
							</Col>
							<Col className="col-md-4 col-12">
								<div className={clsx('form-group')}>
									<label>Tier 1 Referral</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="session_count"
										value={this.state.currentUser.tier1}
										placeholder="Tier 1 Referral"
										disabled
									/>
								</div>
								<div className={clsx('form-group')}>
									<label>Tier 2 Referral</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="session_count"
										value={this.state.currentUser.tier2}
										placeholder="Tier 2 Referral"
										disabled
									/>
								</div>
								<div className={clsx('form-group')}>
									<label>Tier 3 Referral</label>
									<input
										type="text"
										className={clsx('form-control', 'setting-input')}
										name="session_count"
										value={this.state.currentUser.tier3}
										placeholder="Tier 3 Referral"
										disabled
									/>
								</div>
								<div className="form-group d-flex flex-column">
									<label>Referred Users</label>
									<button
										className="btn x-btn x-secondary "
										type="button"
										onClick={() => {
											this.setState({ referredUsersModal: true })
										}}
									>
										View Referred Users
									</button>
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<button
							className="btn x-btn x-secondary "
							type="button"
							disabled={this.state.referredUsersModal}
							onClick={() => {
								this.setState({
									editUser: false,
									currentUser: {},
									// subscription: this.state.subscription.filter((plan) => plan !== "Sponsored")
								})
							}}
						>
							Close
						</button>
						<button
							className="btn x-btn x-primary "
							type="button"
							onClick={() => {
								if (this.state.currentUser.first_name === '') {
									this.setState({ firstNameError: 'Enter the first name' })
								} else if (this.state.currentUser.last_name === '') {
									this.setState({ lastNameError: 'Enter the last name' })
								} else if (this.state.currentUser.referral_code === '') {
									this.setState({
										errors: {
											...this.state.errors,
											referralCodeError: 'Referral code cannot be empty',
										},
									})
								} else {
									this.setState({
										firstNameError: false,
										lastNameError: false,
										errors: {
											...this.state.errors,
											referralCodeError: '',
											referredByUserIdError: '',
										},
									})
									this.handleSubmit()
								}
							}}
							disabled={
								this.state.emailError ||
								this.state.errorPassword ||
								this.state.referredUsersModal
							}
						>
							{this.state.isProcessing ? (
								<Spinner animation="border" size="sm" />
							) : (
								<span>Update</span>
							)}
						</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.messageAlert.state} onHide={() => {}}>
					<Modal.Header>{this.state.messageAlert.header}</Modal.Header>
					<Modal.Body>{this.state.messageAlert.message}</Modal.Body>
					<Modal.Footer>
						<button
							className="btn x-btn x-secondary "
							type="button"
							onClick={() => {
								this.setState({
									messageAlert: {
										state: false,
										header: '',
										message: '',
									},
								})
							}}
						>
							Close
						</button>
					</Modal.Footer>
				</Modal>
				<Modal size="xl" show={this.state.referredUsersModal} onHide={() => {}}>
					<Modal.Header>Referred Users</Modal.Header>
					<Modal.Body>
						<ReactTable
							filterable
							data={this.state.referredUsers}
							defaultFilterMethod={(filter, row) =>
								this.filterCaseInsensitive(filter, row)
							}
							columns={[
								{
									id: 'id',
									Header: 'User Id',
									accessor: 'id',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'name',
									Header: 'Full Name',
									accessor: 'full_name',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
									width: 165,
								},

								{
									id: 'email',
									Header: 'Email Address',
									accessor: 'email',
									headerClassName: 'p-3 text-light',
									className: 'text-center',
								},
								{
									id: 'plan',
									Header: 'Plan',
									accessor: (d) => {
										if (d.subscribed_membership_type !== null) {
											return d.subscribed_membership_type
										} else {
											return 'Not Available'
										}
									},
									headerClassName: 'p-3 text-light',
									className: 'text-center',
									width: 180,
								},
							]}
							defaultPageSize={15}
							noDataText="No Data Found"
							style={{ background: 'rgb(111 79 136 / 1)' }}
							getTrProps={(state, rowInfo, column, instance) => {
								if (typeof rowInfo !== 'undefined') {
									return {
										style: {
											background:
												rowInfo.index % 2 === 0
													? 'transparent'
													: 'rgb(96 64 125 / 1)',
											color: rowInfo.index % 2 === 0 ? 'white' : 'white',
										},
									}
								} else {
									return {
										style: {
											background: 'transparent',
											color: 'white',
										},
									}
								}
							}}
							getPaginationProps={() => {
								return { style: { color: 'white' } }
							}}
						/>
					</Modal.Body>
					<Modal.Footer>
						<button
							className="btn x-btn x-secondary "
							type="button"
							onClick={() => {
								this.setState({
									referredUsersModal: false,
								})
							}}
						>
							Close
						</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}

	addUserModal() {
		return (
			<Modal show={this.state.addUser} onHide={() => {}}>
				<Modal.Header>Add User</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={6}>
							<div className={clsx('form-group')}>
								<label>
									First Name <span style={{ color: 'red' }}>*</span>
								</label>
								<input
									type="text"
									className={clsx('form-control', 'setting-input')}
									name="first_name"
									value={this.state.newUser.first_name}
									onChange={this.handleNewUserChange.bind(this)}
									placeholder="First Name"
								/>
							</div>
						</Col>
						<Col md={6}>
							<div className={clsx('form-group')}>
								<label>
									Last Name <span style={{ color: 'red' }}>*</span>
								</label>
								<input
									type="text"
									className={clsx('form-control', 'setting-input')}
									name="last_name"
									value={this.state.newUser.last_name}
									onChange={this.handleNewUserChange.bind(this)}
									placeholder="Last Name"
								/>
							</div>
						</Col>
					</Row>

					<div className={clsx('form-group')}>
						<label>
							Email Address <span style={{ color: 'red' }}>*</span>
						</label>
						<input
							type="text"
							className={clsx('form-control', 'setting-input')}
							name="email"
							value={this.state.newUser.email}
							onChange={this.validateNewUserEmail.bind(this)}
							placeholder="Email"
						/>
						<span className="errorMessage">
							{this.state.newUser.emailError
								? this.state.newUser.emailError
								: ''}
						</span>
					</div>

					<Row>
						<Col md={6}>
							<div className={clsx('form-group')}>
								<label>
									Password <span style={{ color: 'red' }}>*</span>
								</label>
								<input
									type="password"
									className={clsx('form-control', 'setting-input')}
									name="password"
									value={this.state.newUser.password}
									onChange={this.validateNewUserPassword.bind(this)}
									placeholder="Password"
								/>
								<span className="errorMessage">
									{this.state.newUser.errorPassword
										? this.state.newUser.errorPassword
										: ''}
								</span>
							</div>
						</Col>
						<Col md={6}>
							<div className={clsx('form-group')}>
								<label>
									Confirm Password <span style={{ color: 'red' }}>*</span>
								</label>
								<input
									type="password"
									className={clsx('form-control', 'setting-input')}
									name="confirm_password"
									value={this.state.newUser.confirm_password}
									onChange={this.validateNewUserPassword.bind(this)}
									placeholder="Confirm Password"
								/>
								<span className="errorMessage">
									{this.state.newUser.errorConfirmPassword
										? this.state.newUser.errorConfirmPassword
										: ''}
								</span>
							</div>
						</Col>
					</Row>

					<div className={clsx('form-group')}>
						<label>Referral Code</label>
						<input
							type="text"
							className={clsx('form-control', 'setting-input')}
							name="referral_code"
							value={this.state.newUser.referral_code || 'epic'}
							onChange={this.handleNewUserChange.bind(this)}
							placeholder="If you've been given a referral code, please enter code here"
						/>
					</div>

					<div className={clsx('form-group')}>
						<label>Role</label>
						<input
							type="text"
							className={clsx('form-control', 'setting-input')}
							value={'User'}
							disabled
						/>
					</div>

					<div className="form-group mt-4 mb-4">
						<label className="container-checkbox" style={{ fontSize: '15px' }}>
							Add user to infusionsoft ( Tag : Onboarding March 21 )
							<input
								type="checkbox"
								onChange={() => {
									this.setState({ infusionSoft: !this.state.infusionSoft })
								}}
							/>
							<span className="checkmark"></span>
						</label>
					</div>

					<div className="errorMessage w-100 text-center">
						{this.state.newUser.mainError
							? JSON.stringify(this.state.newUser.mainError)
							: ''}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn x-btn x-secondary "
						type="button"
						onClick={() => {
							this.setState({ addUser: false, newUser: {} })
						}}
					>
						Close
					</button>
					<button
						className="btn x-btn x-primary "
						type="button"
						disabled={
							this.state.newUser.errorConfirmPassword ||
							this.state.newUser.errorPassword ||
							this.state.newUser.errorEmail ||
							!this.state.newUser.first_name ||
							!this.state.newUser.last_name ||
							!this.state.newUser.email ||
							!this.state.newUser.password ||
							!this.state.newUser.confirm_password
						}
						onClick={() => {
							this.setState({
								newUser: {
									...this.state.newUser,
									mainError: '',
								},
							})

							if (
								this.state.newUser.first_name === '' ||
								this.state.newUser.last_name === '' ||
								this.state.newUser.email === '' ||
								this.state.newUser.password === '' ||
								this.state.newUser.confirm_password === ''
							) {
								this.setState({
									newUser: {
										...this.state.newUser,
										mainError: 'Please fill the Mandatory fields',
									},
								})
							} else if (
								this.state.newUser.password !==
								this.state.newUser.confirm_password
							) {
								this.setState({
									newUser: {
										...this.state.newUser,
										mainError: 'Passwords are not matching',
									},
								})
							} else {
								this.handleNewUserSubmit()
							}
						}}
					>
						{this.state.isProcessing ? (
							<Spinner animation="border" size="sm" />
						) : (
							<span>Sign Up</span>
						)}
					</button>
				</Modal.Footer>
			</Modal>
		)
	}

	viewUserActivityModal() {
		return (
			<Modal size="xl" show={this.state.userHistory.status} onHide={() => {}}>
				<Modal.Header>User Activity History</Modal.Header>
				<Modal.Body>
					{this.state.userHistory.loading ? (
						<div className="d-flex justify-content-center align-items-center flex-column m-5">
							<Spinner
								animation={'grow'}
								variant={'danger'}
								style={{ width: '3em', height: '3em' }}
							></Spinner>
							<p style={{ fontSize: '25px', fontWeight: 'bolder' }}>
								Loading ....
							</p>
						</div>
					) : (
						<div>
							<Row>
								<Col className="d-flex justify-content-center my-3">
									<input
										type="radio"
										id="watch_history"
										name="history_type"
										value="watch_history"
										className="mr-2 h-100"
										defaultChecked
										onChange={(e) => {
											this.setState({
												userHistory: {
													...this.state.userHistory,
													type: e.target.value,
												},
											})
										}}
									/>
									<label
										for="watch_history"
										className="m-0 d-flex justify-content-center align-items-center font-weight-bolder"
										style={{ fontSize: '16px' }}
									>
										Watch History
									</label>
								</Col>
								<Col className="d-flex justify-content-center my-3">
									<input
										type="radio"
										id="login_history"
										name="history_type"
										value="login_history"
										className="mr-2 h-100"
										onChange={(e) => {
											this.setState({
												userHistory: {
													...this.state.userHistory,
													type: e.target.value,
												},
											})
										}}
									/>
									<label
										for="login_history"
										className="m-0 d-flex justify-content-center align-items-center font-weight-bolder"
										style={{ fontSize: '16px' }}
									>
										Login History
									</label>
								</Col>
								<Col className="d-flex justify-content-center my-3">
									<input
										type="radio"
										id="purchase_history"
										name="history_type"
										value="purchase_history"
										className="mr-2 h-100"
										onChange={(e) => {
											this.setState({
												userHistory: {
													...this.state.userHistory,
													type: e.target.value,
												},
											})
										}}
									/>
									<label
										for="purchase_history"
										className="m-0 d-flex justify-content-center align-items-center font-weight-bolder"
										style={{ fontSize: '16px' }}
									>
										Purchase History
									</label>
								</Col>
							</Row>
							<Row>
								<Col>
									<ReactTable
										filterable
										data={
											this.state.userHistory.type === 'watch_history'
												? this.state.userHistory.watchHistory
												: this.state.userHistory.type === 'login_history'
												? this.state.userHistory.loginHistory
												: this.state.userHistory.purchaseHistory
										}
										defaultFilterMethod={(filter, row) =>
											this.filterCaseInsensitive(filter, row)
										}
										columns={
											this.state.userHistory.type === 'watch_history'
												? this.state.userHistory.watchColumns
												: this.state.userHistory.type === 'login_history'
												? this.state.userHistory.loginColumns
												: this.state.userHistory.purchaseColumns
										}
										defaultPageSize={15}
										noDataText="No Data Found"
										style={{ background: 'rgb(111 79 136 / 1)' }}
										getTrProps={(state, rowInfo, column, instance) => {
											if (typeof rowInfo !== 'undefined') {
												return {
													style: {
														background:
															rowInfo.index % 2 === 0
																? 'transparent'
																: 'rgb(96 64 125 / 1)',
														color: rowInfo.index % 2 === 0 ? 'white' : 'white',
													},
												}
											} else {
												return {
													style: {
														background: 'transparent',
														color: 'white',
													},
												}
											}
										}}
										getPaginationProps={() => {
											return { style: { color: 'white' } }
										}}
									/>
								</Col>
							</Row>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn x-btn x-secondary "
						type="button"
						onClick={() => {
							this.setState({ userHistory: { status: false } })
						}}
					>
						Close
					</button>
				</Modal.Footer>
			</Modal>
		)
	}

	render() {
		return (
			<div className="">
				<div className="d-flex align-items-center justify-items-center">
					<h2 className="main-title m-0 p-3">User Management</h2>
					<button
						className="btn btn-primary ml-auto"
						onClick={() => {
							this.setState({ addUser: true })
						}}
					>
						Add User
					</button>
				</div>
				<hr className="m-0" />
				<Row>
					<Col xs={12}>
						<Row className="my-5">
							<Col>{this.users()}</Col>
						</Row>
					</Col>
				</Row>
				{this.addUserModal()}
				{this.viewUserActivityModal()}
			</div>
		)
	}
}
export default UserManagement
