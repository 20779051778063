import React from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import ReferalRangePicker from './ReferalRangePicker'
import * as actions from "../../actions";
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

class referalAnalysis extends React.Component {

    render() {
        return <div className="mainWidth">
            <p className="text-secondary m-0 p-3">Referal Analysis</p>
            <hr className="m-0" />

            <div className="m-5">
                <Container fluid={true}>
                    <Row className="m-3">
                        <Col className="text-center">
                            <ReferalRangePicker temp="" userId=''/>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    }
}

export default referalAnalysis