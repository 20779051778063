import React from "react";
import { WatchAnalyticsParams } from "../../api/APIParams";
import { Alert, Container, Spinner, Table, Row, Col, Card, InputGroup, Form } from "react-bootstrap";
import { get, set } from "lodash";
import querystring from "query-string";
import { API } from "../../APIAndConfig";
import ReactTable from "react-table-6";
import { FormInput } from "../FormInput";


export interface WatchBreakdownProps {
    sessionId?: number | number[]
    params?: WatchAnalyticsParams,
    titleClass?: string,
    queryString?: string,
    surveyTableUnique: boolean
}
export interface WatchBreakdownState {
    data?: object | null;
    params?: WatchAnalyticsParams,
    isLoading: boolean;
    filteredData: any;
    searchInput: string;
}

export class WatchBreakdown extends React.Component<WatchBreakdownProps, WatchBreakdownState> {
    constructor(props: WatchBreakdownProps) {
        super(props);
        this.state = {
            isLoading: true,
            filteredData: [],
            searchInput: ''
        };
    }

    getQueryString(key: string) {
        let qs = '?';
        if (this.props.queryString)
            qs = qs + this.props.queryString;

        if (this.props.params?.collate)
            qs = qs + '&' + querystring.stringify(set({}, this.props.params?.collate, key));

        return qs;
    }

    componentDidUpdate(prevProps: Readonly<WatchBreakdownProps>, prevState: Readonly<WatchBreakdownState>, snapshot?: any): void {

        let needsFetching: string[] = []

        if (this.props.params?.start !== this.state.params?.start)
            needsFetching.push("startDate")

        if (this.props.params?.end !== this.state.params?.end)
            needsFetching.push("endDate");

        if (this.props.params?.collate !== this.state.params?.collate)
            needsFetching.push("collate")

        if (this.props.params?.filter !== this.state.params?.filter)
            needsFetching.push("filter")

        if (needsFetching.length) {
            this.fetchData().catch((err) => {
                console.error(err);
            })
        }
    }

    async fetchData() {
        await this.setState({
            isLoading: true,
            params: this.props.params
        });

        if (!this.props.params?.start || !this.props.params?.end)
            return this.setState({ data: null, isLoading: false });

        const data = await API.getWatchAnalytics(Object.assign(this.props.params || {}, {
            format: "table",
            max: 25
        }));

        if (data.successful && typeof data.payload === "object") {
            this.setState({
                data: data.payload,
                isLoading: false
            })
        }

    }

    renderSurveyTable() {
        let regrex = /^-?\d+(?:\.\d{0,2})?/
        let beforeLabels = ['happy', 'sad', 'meh', 'angry', 'anxious', 'powerful', 'tired', 'stressed', 'skip', null]
        let afterLabel = ['same', 'better', 'not-better', 'happy', 'anxious', 'meh', 'sad', 'powerful', 'tired', 'stressed', 'skip', null]
        let Labels = ['Happy', 'Sad', 'Meh', 'Angry', 'Anxious', 'Powerful', 'Tired', 'Stressed', 'Skip', 'NULL']

        const tableData = get(this.state.data, 'survey', []);
        if (tableData.length < 1)
            return <Alert className="m-3" variant="light">No data</Alert>

        let $tbody: any[] = [];

        beforeLabels.map(async (bobj, bindex) => {

            let indexObj0 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[0])
            let indexObj1 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[1])
            let indexObj2 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[2])
            let indexObj3 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[3])
            let indexObj4 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[4])
            let indexObj5 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[5])
            let indexObj6 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[6])
            let indexObj7 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[7])
            let indexObj8 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[8])
            let indexObj9 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[9])
            let indexObj10 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[10])
            let indexObj11 = tableData.findIndex((x: any) => x.before_emotion === bobj && x.after_emotion === afterLabel[11])

            $tbody.push(
                <tr key={bindex}>
                    <td>{Labels[bindex]}</td>
                    <td className="text-primary">{indexObj0 >= 0 ? tableData[indexObj0].countFirst + ' (' + (tableData[indexObj0].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-info">{indexObj1 >= 0 ? tableData[indexObj1].countFirst + " (" + (tableData[indexObj1].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-warning">{indexObj2 >= 0 ? tableData[indexObj2].countFirst + " (" + (tableData[indexObj2].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-danger">{indexObj3 >= 0 ? tableData[indexObj3].countFirst + " (" + (tableData[indexObj3].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-success">{indexObj4 >= 0 ? tableData[indexObj4].countFirst + " (" + (tableData[indexObj4].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-danger">{indexObj5 >= 0 ? tableData[indexObj5].countFirst + " (" + (tableData[indexObj5].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-warning">{indexObj6 >= 0 ? tableData[indexObj6].countFirst + " (" + (tableData[indexObj6].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-info">{indexObj7 >= 0 ? tableData[indexObj7].countFirst + " (" + (tableData[indexObj7].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-primary">{indexObj8 >= 0 ? tableData[indexObj8].countFirst + " (" + (tableData[indexObj8].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-success">{indexObj9 >= 0 ? tableData[indexObj9].countFirst + " (" + (tableData[indexObj9].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-success">{indexObj10 >= 0 ? tableData[indexObj10].countFirst + " (" + (tableData[indexObj10].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                    <td className="text-success">{indexObj11 >= 0 ? tableData[indexObj11].countFirst + " (" + (tableData[indexObj11].percent || 0.00).toString().match(regrex)[0] + "%)" : '-'}</td>
                </tr>
            )
        })

        return (
            <Table className="table-analytics" responsive striped hover>
                <thead>
                    <tr className='text-dark'>
                        <th>Emotion Matrix</th>
                        <th>Same</th>
                        <th>Better</th>
                        <th>Non-Better</th>
                        <th>Happy</th>
                        <th>Axious</th>
                        <th>Meh</th>
                        <th>Sad</th>
                        <th>Powerful</th>
                        <th>Tired</th>
                        <th>Stressed</th>
                        <th>Skip</th>
                        <th>Null</th>
                    </tr>
                </thead>
                <tbody>
                    {$tbody}
                </tbody>
            </Table>
        )

    }

    getColumnWidth(rows: any, accessor: any, headerText: any) {
        const maxWidth = 400
        const magicSpacing = 10
        const cellLength = Math.max(
            ...rows.map((row: any) => (`${row[accessor]}` || '').length),
            headerText.length,
        )
        return Math.min(maxWidth, cellLength * magicSpacing)
    }

    searchChange = (event: any) => {
        this.setState({ searchInput: event.target.value }, () => {
            this.globalSearch();
        });
    };

    globalSearch = () => {
        let { searchInput, data } = this.state;
        let filteredData = get(data, 'table', []).filter((value: any) => {
            return (
                value.label.toLowerCase().includes(searchInput.toLowerCase())

            );
        });
        this.setState({ filteredData });
    };

    render() {
        const $loading = <div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>;

        if (!this.props.params?.start || !this.props.params?.end)
            return (
                <p>&nbsp;</p>
            )

        if (!this.state.data || this.state.isLoading)
            return (
                <Container fluid>
                    <div className="text-center py-5">
                        {$loading}
                    </div>
                </Container>
            );

        const tableData = get(this.state.data, 'table', []);
        if (tableData.length < 1)
            return <Alert className="m-3" variant="light">No data</Alert>

        let $tbody: any[] = [];
        let row: any;
        let index: number = 0;
        for (row of tableData) {
            let $label = row.label;
            $label = <a href={this.getQueryString(row.key)}>{row.label}</a>;

            $tbody.push(<tr key={index++}>
                <td>{$label}</td>
                <td className="text-primary"> {row.total}</td>
                <td className="text-info">{row.complete}</td>
            </tr>)
        }

        return (
            <div>
                { this.props.surveyTableUnique ?
                    this.renderSurveyTable()
                    :
                    <Card.Body className="p-0">


                        <Form.Control
                            // style={{ float: "right" }}
                            size="sm"
                            name="searchInput"
                            value={this.state.searchInput || ""}
                            onChange={this.searchChange}
                            placeholder="Search"
                        />
                        <ReactTable
                            data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : get(this.state.data, 'table', [])}
                            columns={[{
                                Header: 'Name',
                                accessor: 'label',
                                headerClassName: 'p-3 text-light',
                                className: 'text-center',
                                Cell: (row: any) => (
                                    <a style={{ color : "white", cursor: "pointer" }} href={this.getQueryString(row.original.key)}>{row.original.label}</a>
                                ),
                                width: this.getColumnWidth(get(this.state.data, 'table', []), 'label', 'Name'),

                            }, {
                                Header: 'Total',
                                headerClassName: 'p-3 text-light',
                                accessor: 'total',
                                className: 'text-center'
                            }, {
                                Header: 'Completed',
                                headerClassName: 'p-3 text-light',
                                accessor: 'complete',
                                className: 'text-center'
                            },
                            ]}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            style={{ background : "rgb(111 79 136 / 1)" }}
                            getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        /></Card.Body>}

            </div>
        )

    }
}


